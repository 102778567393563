import { calculatePageRange } from '@goed-platform/shared/utils';
import { useEffect, useState } from 'react';
import { SVGChevronLeft, SVGChevronRight } from '@goed-platform/shared/utils';
import { Config } from '@goed-platform/shared/constants';

export type PaginationProps = {
    currentPage: number;
    totalPages: number;
    onChange: (newPage: number) => void;
};

/**
 * Calculating the SKIP variables can look hard, but they use this logic:
 *
 * Eg. itemsPerPage = 10
 * For page 1, skip value is 0
 * For page 2, skip value is 10
 * For page 3, skip value is 20
 *
 * If we are on page 2 and we want to calculate
 * the current skip (value 10), we have to call: (currentPage - 1) * itemsPerPage, which results in (2 - 1) * 10 = 10
 * Previous skip (page 1): (currentPage - 2) * itemsPerPage = (2 - 2) * 10 = 0
 * For the next page (page 3): currentPage * itemsPerPage = 2 * 10 = 20;
 * For the current page, it's currentPage - 1 * itemsPerPage
 * For the previous page, it's currentPage - 2 * itemsPerPage
 */
export const Pagination = ({ currentPage, totalPages, onChange }: PaginationProps): JSX.Element => {
    const [pageRange, setPageRange] = useState<(number | string)[]>([]);

    // Find out which pages should be shown.
    // This should be executed every time the currentPage changes.
    useEffect(() => {
        const range = calculatePageRange(currentPage, totalPages);
        setPageRange(range);
    }, [currentPage, totalPages, onChange]);

    if (pageRange.length <= 1) {
        return <></>;
    }

    return (
        <div className="d-flex align-items-center">
            <button
                onClick={() => onChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-prev"
                aria-label="pagination prev"
            >
                <SVGChevronLeft className="sprite sprite-chevron-left sprite-sm" title="chevron-left" />
            </button>

            {pageRange.map((page: number | string, key) =>
                typeof page == 'string' ? (
                    <span key={key} className="pagination-dots">
                        ...
                    </span>
                ) : (
                    <button
                        key={key}
                        onClick={() => onChange(page)}
                        className={`pagination-btn ${currentPage === page ? 'active' : ''}`}
                    >
                        {page}
                    </button>
                )
            )}

            <button
                onClick={() => onChange(currentPage + 1)}
                disabled={
                    currentPage ===
                    (totalPages >= Config.pagination.highestPage ? Config.pagination.highestPage : totalPages)
                }
                className="pagination-next"
                aria-label="pagination next"
            >
                <SVGChevronRight className="sprite sprite-chevron-right sprite-sm" title="chevron-right" />
            </button>
        </div>
    );
};
