import { AlertTypeEnum } from '@goed-platform/shared/types';

export interface AlertProps {
    type: AlertTypeEnum;
    errorMessage: string;
    className?: string;
}

export const Alert: React.FC<AlertProps> = ({
    type,
    errorMessage,
    className,
    ...restProps
}: AlertProps): JSX.Element => {
    return (
        <div
            className={`alert alert-${type} bg-white font-size-14 font-size-md-16 ${className}`}
            data-cy="alert"
            role="alert"
            {...restProps}
            dangerouslySetInnerHTML={{ __html: errorMessage }}
        ></div>
    );
};

export default Alert;
