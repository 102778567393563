import { makeVar } from '@apollo/client';
import { CartTotal, StoreCart, StoreType, UserInfo } from '@goed-platform/graphql/types';
import { SymfonyPharmacy } from '@goed-platform/symfony/types';
import { WebreservationPharmacy } from './webreservation-pharmacy';

export enum StorageEnum {
    token = 'token',
    isTokenBeingRenewed = 'isTokenBeingRenewed',
    cartIdUld = 'cartIdUld',
    cartIdRes = 'cartIdRes',
    correlationId = 'correlationId',
    webResActivePharmacy = 'activePharmacy',
}

// Create reactive variables, which can store arbitrary data outside the cache while still updating queries that
// depend on them.

// Added manual check for isBrowser functionality because the import of isBrowser is giving an undefined on the server.
export const cartIdVarUld = makeVar<string | null>(
    typeof window !== 'undefined' ? localStorage.getItem(StorageEnum.cartIdUld) ?? null : null
);
export const cartIdVarRes = makeVar<string | null>(
    typeof window !== 'undefined' ? localStorage.getItem(StorageEnum.cartIdRes) ?? null : null
);
export const webResActivePharmacy = makeVar<SymfonyPharmacy | null>(
    typeof window !== 'undefined' ? WebreservationPharmacy.getInstance().getActivePharmacy() : null
);

export const cartVarUld = makeVar<CartTotal | null>(null);
export const cartVarRes = makeVar<CartTotal | null>(null);

export const isLoggedInVar = makeVar<boolean>(
    typeof window !== 'undefined' ? !!localStorage.getItem(StorageEnum.token) : false
);
export const correlationIdVar = makeVar<string | null>(null);
export const userInfoVar = makeVar<UserInfo | null>(null);
