import { LinkItem } from '@goed-platform/shared/types';
import { SVGTogetherWithCM, TranslationUtils, UrlUtils } from '@goed-platform/shared/utils';
import { MappedNavigation } from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

type FooterProps = {
    showLinks?: boolean;
    navigation?: MappedNavigation;
};

export const Footer = ({ navigation, showLinks }: FooterProps): JSX.Element => {
    const { t } = useTranslation();

    // Footer legal items
    const footerLegalLabels = TranslationUtils.getTranslationKeyArray('general.footer.legal.label');
    const footerLegalAddresses = TranslationUtils.getTranslationKeyArray('general.footer.legal.address');
    const footerLegalTaxNumbers = TranslationUtils.getTranslationKeyArray('general.footer.legal.tax');
    const footerLegalOrganisations = TranslationUtils.getTranslationKeyArray('general.footer.legal.organisation');
    const footerLegalRecognizedBy = TranslationUtils.getTranslationKeyArray('general.footer.legal.recognized');
    const footerLegalEmails = TranslationUtils.getTranslationKeyArray('general.footer.legal.email');
    const footerLegalPhoneNumbers = TranslationUtils.getTranslationKeyArray('general.footer.legal.phone');

    const footerLegalItems: Array<Array<string | null>> = footerLegalLabels.map((tKey, key) => [
        t(tKey),
        t(footerLegalAddresses[key]),
        t(footerLegalTaxNumbers[key]),
        t(footerLegalOrganisations[key]),
        t(footerLegalRecognizedBy[key]),
        t(footerLegalEmails[key]),
        t(footerLegalPhoneNumbers[key]),
    ]);

    return (
        <footer className="py-8 py-lg-12 bg-primary-dark">
            <div className="container-lg">
                {showLinks ? (
                    <div className="row">
                        <div className="col-12 col-lg-8 pb-4 pb-lg-0">
                            <nav>
                                <ul className="navigation-list d-lg-flex flex-lg-wrap m-0 p-0 list-unstyled">
                                    {navigation?.footer.map(({ url = '#', title }, key) => (
                                        <li className="navigation-item mb-4 mr-lg-8" key={key}>
                                            <Link href={url}>
                                                <a
                                                    className="navigation-link text-neutral-white border-neutral-white-a60"
                                                    target={UrlUtils.isExternalUrl(url) ? '_blank' : '_self'}
                                                >
                                                    {title}
                                                </a>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                        <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end align-items-center align-items-lg-start text-off-white pt-4 pt-lg-0 border-top border-lg-0 border-gray-300a20">
                            <div className="font-weight-bold">&copy; {new Date().getFullYear()} Goed</div>
                            <SVGTogetherWithCM width="120" height="25" />
                        </div>
                    </div>
                ) : (
                    <div className="w-100 d-flex justify-content-center text-off-white">
                        <div>
                            &copy; {new Date().getFullYear()} {t('global.app_name')}
                        </div>
                        <SVGTogetherWithCM width="120" height="25" />
                    </div>
                )}
                <div className="mt-8 text-off-white">
                    {footerLegalItems.map((legalItem, key) => (
                        <p
                            key={key}
                            className={`font-size-12 font-size-md-14 line-height-16 line-height-md-18 ${
                                key < footerLegalItems.length - 1 ? 'mb-1' : 'mb-0'
                            }`}
                        >
                            {legalItem.filter((i) => i && i.length > 0).join(' - ')}
                        </p>
                    ))}
                </div>
            </div>
        </footer>
    );
};
