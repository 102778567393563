import { EventCallbackType, RadioGroupOption } from '@goed-platform/shared/types';
import React from 'react';
import { FieldError, RadioButton } from '../../index';

type RadioButtonGroupProps = {
    className?: string;
    error?: string;
    errorClassName?: string;
    name: string;
    value?: string;
    touched?: boolean;
    options: RadioGroupOption[];
    onChange?: EventCallbackType;
};

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
    name,
    options,
    className = '',
    error = '',
    errorClassName = '',
    touched = false,
    value = '',
    onChange,
    ...restProps
}: RadioButtonGroupProps): JSX.Element => {
    return (
        <>
            {options.map((item, index) => (
                <label data-cy="select-group" key={index} className="mr-6" {...restProps}>
                    <RadioButton
                        id={item.id}
                        data-cy={`${name}-${item.id}`}
                        label={item.label}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={className}
                        touched={touched}
                    />
                </label>
            ))}
            <FieldError className={errorClassName} error={error} touched={touched} />
        </>
    );
};

export default RadioButtonGroup;
