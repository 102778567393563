import { ImageLayoutEnum, ImageObjectfitEnum } from '@goed-platform/product/enums';
import { Config } from '@goed-platform/shared/constants';
import { OptimizedImage } from '@goed-platform/shared/ui';
import { SVGExternalLink, SVGMail, SVGSocialFacebook, UrlUtils } from '@goed-platform/shared/utils';
import { MappedNavigation } from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC } from 'react';

type CustomerBarProps = {
    navigation?: MappedNavigation;
};

export const CustomerBar: FC<CustomerBarProps> = ({ navigation }: CustomerBarProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="pt-8 pb-12 pt-lg-20 pb-lg-16 bg-primary text-neutral-white-a80">
            <div className="container-lg">
                <div className="row">
                    {/* About Goed */}
                    <div className="col-6 col-lg-3 order-1 mb-6 mb-lg-0">
                        <h3 className="mb-2 mb-lg-3 font-size-16 font-size-md-20 text-neutral-white">
                            {t('general.footer.customerBar.title')}
                        </h3>
                        {navigation?.['doormat-primary']?.map(({ url = '#', title }, key) => (
                            <div className="mb-1 mb-lg-2" key={key}>
                                <Link href={url}>
                                    <a
                                        target={UrlUtils.isExternalUrl(url) ? '_blank' : '_self'}
                                        className="font-size-14 font-size-md-16 text-neutral-white border-neutral-white-a60 font-weight-bold d-flex align-items-center w-fit"
                                    >
                                        {title}
                                        {UrlUtils.isExternalUrl(url) && (
                                            <SVGExternalLink className="sprite sprite-sm ml-1 text-neutral-white-a80" />
                                        )}
                                    </a>
                                </Link>
                            </div>
                        ))}
                    </div>

                    {/* Customer service */}
                    <div className="col-6 col-lg-3 order-3 order-lg-2">
                        <h3 className="font-size-16 mb-2 mb-lg-3 font-size-md-20 text-neutral-white">
                            {t('general.customerService.title')}
                        </h3>
                        <div className="font-size-14 font-size-md-18 text-neutral-white-a80">
                            <a
                                href={t('general.customerService.ourCustomerService.url')}
                                target="_blank"
                                className="text-neutral-white-a80 font-weight-normal border-neutral-white-a60"
                                rel="noreferrer"
                            >
                                {t('general.customerService.ourCustomerService')}
                            </a>
                            <span>
                                {' '}
                                {t('general.customerService.availableBetween', {
                                    start: Config.customerService.openingHours.start,
                                    stop: Config.customerService.openingHours.stop,
                                })}
                            </span>
                        </div>
                    </div>

                    {/* Stay in touch */}
                    <div className="d-none d-lg-block col-lg-4 mt--4 order-3 order-lg-4">
                        <div className="py-4 px-5 bg-neutral-white-a5 rounded-lg">
                            <h3 className="font-size-16 font-size-md-20 text-neutral-white mb-2 mb-lg-3">
                                {t('general.social.title')}
                            </h3>
                            <div className="position-relative d-flex align-items-center mb-2">
                                <div className="d-flex align-items-center justify-content-center mr-2 ws-8 hs-8 bg-primary-x-dark rounded-max">
                                    <SVGSocialFacebook className="sprite sprite-md" title="social-facebook" />
                                </div>

                                <a
                                    href={t(Config.social.facebook)}
                                    target={UrlUtils.isExternalUrl(t(Config.social.facebook)) ? '_blank' : '_self'}
                                    rel="noreferrer"
                                    className="stretched-link text-neutral-white-a80 border-neutral-white-a60 font-size-14 font-size-md-16"
                                >
                                    {t('general.social.facebook')}
                                    {UrlUtils.isExternalUrl(t(Config.social.facebook)) && (
                                        <SVGExternalLink className="sprite sprite-sm ml-1" />
                                    )}
                                </a>
                            </div>
                            <div className="position-relative d-flex align-items-center mb-2">
                                <div className="d-flex align-items-center justify-content-center mr-2 ws-8 hs-8 bg-primary-x-dark rounded-max">
                                    <SVGMail className="sprite sprite-md" title="social-newsletter" />
                                </div>

                                <a
                                    href={t(Config.social.newsletter)}
                                    target={UrlUtils.isExternalUrl(t(Config.social.newsletter)) ? '_blank' : '_self'}
                                    rel="noreferrer"
                                    className="stretched-link text-neutral-white-a80 border-neutral-white-a60 font-size-14 font-size-md-16"
                                >
                                    {t('general.social.newsletter')}
                                    {UrlUtils.isExternalUrl(t(Config.social.newsletter)) && (
                                        <SVGExternalLink className="sprite sprite-sm ml-1" />
                                    )}
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Goed logo */}
                    <div className="col-6 col-lg-2 order-2 order-lg-4">
                        <OptimizedImage
                            image={{
                                sortOrder: 0,
                                url: '/assets/images/png/logo-shape-goed.png',
                                label: 'Goed logo',
                            }}
                            layout={ImageLayoutEnum.fill}
                            objectFit={ImageObjectfitEnum.contain}
                            alt="Goed logo"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
