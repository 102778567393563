import { RoutesEnum } from '@goed-platform/shared/types';
import { NextRouter } from 'next/router';

export type AccountOverviewLinkType = {
    translationKey: string;
    path?: string;
    action?: (router: NextRouter) => void;
    withChevron?: boolean;
};

export const overviewLinks: AccountOverviewLinkType[] = [
    {
        translationKey: 'user.account.info',
        path: RoutesEnum.myInfo,
        withChevron: true,
    },
    {
        translationKey: 'user.account.stores',
        path: RoutesEnum.myFavoriteStores,
        withChevron: true,
    },
    {
        translationKey: 'user.account.orders',
        path: RoutesEnum.myOrders,
        withChevron: true,
    },
    {
        translationKey: 'user.account.newsletter',
        path: RoutesEnum.newsletterSettings,
        withChevron: true,
    },
    {
        translationKey: 'user.account.logout',
        path: RoutesEnum.logout,
    },
];
