import { Category, StoreType } from '@goed-platform/graphql/types';
import { SVGArrowRight, UrlUtils } from '@goed-platform/shared/utils';
import Link from 'next/link';
import { PropsWithChildren } from 'react';

type SearchBarResultCategoryType = PropsWithChildren<{ category: Category; storeType: StoreType }>;

export const SearchBarResultCategory = ({ category, storeType }: SearchBarResultCategoryType): JSX.Element => {
    return (
        <div className="position-relative d-flex align-items-center justify-content-between">
            <Link href={UrlUtils.createCategoryUrl(category.urlPath ?? '', storeType)}>
                <a className="p-4 border-0 stretched-link d-block text-neutral font-size-16 font-size-md-18 line-height-24 line-height-md-28">
                    <b>{category.label}</b>
                </a>
                <SVGArrowRight className="sprite sprite-md text-purple" />
            </Link>
        </div>
    );
};
