import { useReactiveVar } from '@apollo/client';
import { Category } from '@goed-platform/graphql/types';
import { isLoggedInVar, userInfoVar } from '@goed-platform/shared/data-access';
import { RoutesEnum } from '@goed-platform/shared/types';
import { LanguageLink } from '@goed-platform/shared/ui';
import {
    isBrowser,
    SVGChevronDown,
    SVGChevronUp,
    SVGGoedLogoNegBasic,
    SVGMenuClose,
    SVGMenuOpen,
    SVGUser,
} from '@goed-platform/shared/utils';
import { MappedNavigation, NavigationLinkItem } from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { MobileHeader } from './mobile-header';
import { MenuDropdown } from './subheader/dropdown-menu';
import { SubheaderUitleendienst } from './subheader/subheader-uitleendienst';
import { SubheaderWebreservatie } from './subheader/subheader-webreservatie';
import { Config } from '@goed-platform/shared/constants';

type HeaderProps = {
    categoryNavigation: Category[];
    showSearchBar: boolean;
    showSubHeader: boolean;
    showReadOnlySubHeader?: boolean;
    centeredLogo: boolean;
    navigation?: MappedNavigation;
};

type LinkProps = NavigationLinkItem & { className?: string };

export const Header = ({
    categoryNavigation,
    showSearchBar,
    showSubHeader,
    showReadOnlySubHeader = false,
    centeredLogo,
    navigation,
}: HeaderProps): JSX.Element => {
    const { t } = useTranslation();
    const isAuthenticated = useReactiveVar(isLoggedInVar);
    const userInfo = useReactiveVar(userInfoVar);
    const router = useRouter();

    const [showAanbodDesktop, setShowAanbodDesktop] = useState(false);
    const [showAanbodMobile, setShowAanbodMobile] = useState(false);

    const Link = ({ url, title, className = '' }: LinkProps): JSX.Element => (
        <div className={`${className}`}>
            <NextLink passHref href={url}>
                <a role="button" className="text-neutral-white d-none d-lg-block border-0">
                    {title}
                </a>
            </NextLink>
        </div>
    );

    const Subheader = (): JSX.Element => {
        if (router.pathname.includes(RoutesEnum.rentalStoreLanding)) {
            return <SubheaderUitleendienst categories={categoryNavigation} readOnly={showReadOnlySubHeader} />;
        }

        if (router.pathname.includes(RoutesEnum.webReservationLanding)) {
            return <SubheaderWebreservatie categories={categoryNavigation} readOnly={showReadOnlySubHeader} />;
        }

        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    };

    const DropdownLink = ({ url, title }: NavigationLinkItem & { className?: string }) => (
        <div>
            <NextLink passHref href={url}>
                <a role="button" className="text-purple font-weight-bold font-size-md-18 border-0 mb-4 d-flex">
                    {title}
                </a>
            </NextLink>
        </div>
    );

    return (
        <>
            {/* Main header */}
            <header className="header position-relative">
                <div className="bg-primary">
                    <div className="container-md py-4 py-lg-6">
                        <div className="row">
                            <div className="header-nav col-12 d-flex justify-content-between align-items-center">
                                <div
                                    className={`d-flex align-items-center font-size-18
                                      ${centeredLogo ? 'justify-content-center w-100' : ''}`}
                                >
                                    {!centeredLogo && (
                                        <div
                                            className="navigation-mobile-open mr-2 d-flex d-lg-none"
                                            role="button"
                                            onClick={() => {
                                                // Prevent page scroll.
                                                document
                                                    ?.querySelector('body')
                                                    ?.classList.toggle('overflow-hidden', !showAanbodMobile);
                                                setShowAanbodMobile(!showAanbodMobile);
                                            }}
                                            aria-label="open side menu"
                                        >
                                            {showAanbodMobile ? (
                                                <SVGMenuClose
                                                    className="sprite sprite-menu-close sprite-sm d-block"
                                                    title="menu-close"
                                                />
                                            ) : (
                                                <SVGMenuOpen
                                                    className="sprite sprite-menu-open sprite-sm d-block"
                                                    title="menu-open"
                                                />
                                            )}
                                        </div>
                                    )}

                                    <NextLink href={Config.sites.main}>
                                        <a className="border-0">
                                            <SVGGoedLogoNegBasic
                                                className={centeredLogo ? 'ws-17 ws-lg-25' : 'hs-7 hs-sm-9'}
                                            />
                                        </a>
                                    </NextLink>
                                    {!centeredLogo && (
                                        <>
                                            <ClickAwayListener
                                                className="border-right-lg-1 border-gray-200"
                                                onClickAway={() => setShowAanbodDesktop(false)}
                                            >
                                                <div
                                                    role="button"
                                                    className="d-none d-lg-inline-flex align-items-end text-white font-weight-bold ml-8 mr-3"
                                                    onClick={() => setShowAanbodDesktop(!showAanbodDesktop)}
                                                >
                                                    {t('global.header.catalog')}{' '}
                                                    {showAanbodDesktop ? (
                                                        <SVGChevronUp className="sprite sprite-md sprite-offer text-white" />
                                                    ) : (
                                                        <SVGChevronDown className="sprite sprite-md sprite-offer text-white" />
                                                    )}
                                                </div>
                                            </ClickAwayListener>

                                            {navigation?.main?.map((link, key) => (
                                                <Link {...link} key={key} className="ml-0 ml-lg-4" />
                                            ))}
                                        </>
                                    )}
                                </div>
                                <div suppressHydrationWarning={true}>
                                    {isBrowser() && !centeredLogo && (
                                        <div className="d-flex align-items-center">
                                            {navigation?.meta?.map((link, key) => (
                                                <Link {...link} key={key} className="mr-0 mr-lg-4" />
                                            ))}

                                            <LanguageLink
                                                href={!isAuthenticated ? RoutesEnum.login : RoutesEnum.account}
                                                key={!isAuthenticated ? 'unauthenticated' : 'authenticated'}
                                            >
                                                <a
                                                    data-cy="login-button"
                                                    className="d-inline-flex align-items-center font-weight-bold text-off-white border-0 rounded-pill border-1 px-4 py-2 line-height-1"
                                                >
                                                    <SVGUser
                                                        className="sprite sprite-user text-off-white mr-2"
                                                        title="user"
                                                    />
                                                    <span className="font-size-14">
                                                        {!isAuthenticated ? t('user.myProfile') : userInfo?.firstName}
                                                    </span>
                                                </a>
                                            </LanguageLink>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Ons aanbod dropdown menu */}
                <MenuDropdown className="d-none d-md-block" show={showAanbodDesktop} setShow={setShowAanbodDesktop}>
                    <div className="container py-8">
                        <div className="row">
                            <div className="col-4 d-flex flex-column">
                                <div className="font-size-md-18 font-weight-bold mb-4">
                                    {t('header.dropdown.main-offer-1.title')}
                                </div>
                                {navigation?.['main-offer-1']?.map((props, key) => (
                                    <DropdownLink {...props} key={key} />
                                ))}
                            </div>
                            <div className="col-4 d-flex flex-column">
                                <div className="font-size-md-18 font-weight-bold mb-4">
                                    {t('header.dropdown.main-offer-2.title')}
                                </div>
                                {navigation?.['main-offer-2']?.map((props, key) => (
                                    <DropdownLink {...props} key={key} />
                                ))}
                            </div>
                            <div className="col-4 d-flex flex-column">
                                <div className="font-size-md-18 font-weight-bold mb-4">
                                    {t('header.dropdown.main-offer-3.title')}
                                </div>
                                {navigation?.['main-offer-3']?.map((props, key) => (
                                    <DropdownLink {...props} key={key} />
                                ))}
                            </div>
                        </div>
                    </div>
                </MenuDropdown>
            </header>

            {/* Subheader */}
            {showSubHeader && <Subheader />}

            {/* Mobile navigation menu */}
            <MobileHeader
                navigation={navigation}
                show={showAanbodMobile}
                onHideModal={() => setShowAanbodMobile(false)}
            />
        </>
    );
};

export default Header;
