import { SymfonyPharmacyOpeningHours } from '@goed-platform/symfony/types';

export function getPharmacyCurrentOpenState(data: SymfonyPharmacyOpeningHours | undefined): boolean {
    if (!data) {
        return false;
    }

    const today = data.byWeek.thisWeek.find((item) => item.isToday);
    if (!today) {
        return false;
    }

    return today.scheme.opening_hours.some((item) => {
        const start = new Date();
        start.setHours(item.start.hours);
        start.setMinutes(item.start.minutes);
        start.setSeconds(0);

        const end = new Date();
        end.setHours(item.end.hours);
        end.setMinutes(item.end.minutes);
        end.setSeconds(0);

        const currentTime = new Date();
        return currentTime > start && currentTime < end;
    });
}
