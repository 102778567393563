type ValidationItem = {
    regex?: RegExp;
    errorMessage: string;
};

type FormComponentValidationItem = {
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
};

export const defaultRegex = /^(^.{5,50})$/;

// Validation regexes are equal to those defined by RB2.
// @see https://rb2support.atlassian.net/wiki/spaces/GOED/pages/2840461313/Data+Validation
export class ValidationConstant {
    public static readonly REQUIRED: ValidationItem = {
        errorMessage: 'validation.required',
    };

    public static readonly INVALID: ValidationItem = {
        errorMessage: 'validation.invalid',
    };

    public static readonly TOO_SHORT: ValidationItem = {
        errorMessage: 'validation.tooShort',
    };

    public static readonly TOO_LONG: ValidationItem = {
        errorMessage: 'validation.tooLong',
    };

    public static readonly EMAIL: ValidationItem = {
        regex: /^([^@"]?[a-zA-Z0-9.+/%!?-_-]{1,64})@([a-zA-Z0-9._-]{1,63})\.(\w{2,63})$/,
        errorMessage: 'validation.invalidEmail',
    };

    public static readonly POST_CODE: ValidationItem = {
        regex: /^(\d{4})$/,
        errorMessage: 'validation.invalidPostCode',
    };

    public static readonly PHONE_NUMBER: ValidationItem = {
        regex: /^(\+|00?)[1-9][0-9 \-()./]{7,20}$/,
        errorMessage: 'validation.invalidPhoneNumber',
    };

    public static readonly BIRTH_DATE: ValidationItem = {
        regex: /^(\d{1,2})[-/](\d{1,2})[-/](\d{4})$/,
        errorMessage: 'validation.dateOfBirth',
    };

    public static readonly HOUSE_NUMBER: ValidationItem = {
        regex: /^(\d{1,5}[ ]?([A-Z]{1,2})?)$/,
        errorMessage: 'validation.invalidHouseNumber',
    };

    public static readonly PASSWORD: ValidationItem = {
        regex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{10,}$/,
        errorMessage: 'validation.invalidPassword',
    };

    public static readonly NUMBERED_XML_FILE_NAME: ValidationItem = {
        regex: /^[0-9]+\.xml$/,
        errorMessage: 'validation.invalid',
    };

    public static readonly NUMBER: ValidationItem = {
        regex: /^[0-9]{1}$/,
        errorMessage: 'validation.invalid',
    };

    public static readonly ACCOUNTREQUIRED: ValidationItem = {
        errorMessage: 'validation.account.required',
    };
}

export class FormComponentValidationConstant {
    public static readonly RADIO_INPUT: FormComponentValidationItem = {};

    public static readonly SELECT_INPUT: FormComponentValidationItem = {};

    public static readonly TEXT_INPUT: FormComponentValidationItem = {};

    public static readonly NUMBER_INPUT: FormComponentValidationItem = {};

    public static readonly TEXTAREA: FormComponentValidationItem = {
        max: 500,
    };
}
