import { i18n } from 'next-i18next';

export class TranslationUtils {
    // Get a list of translations by translation key prefix.
    public static getTranslationKeyArray = (translationKeyPrefix: string): string[] => {
        if (i18n) {
            // Fetch all translation keys.
            const translationKeys: string[] = Object.keys(TranslationUtils.getCurrentLanguageTranslation() ?? {});

            // Filter out and sort all translation keys, which start with the given translationKey.
            return translationKeys
                .filter((key) => key.includes(translationKeyPrefix))
                .filter((tKey) => !!tKey && tKey.length > 0)
                .sort();
        }

        return [];
    };

    // Get a single translation by key.
    public static getTranslation = (translationKey: string): string => {
        if (i18n) {
            const currentLanguageTranslation: Record<string, string> = TranslationUtils.getCurrentLanguageTranslation();

            // Get translation for translationKey.
            const translation: string | undefined = currentLanguageTranslation[translationKey];

            if (translation) {
                return translation;
            }
        }

        return `![${translationKey}]`;
    };

    // Get the full translation object for the current language.
    private static getCurrentLanguageTranslation = (): Record<string, string> => {
        if (i18n) {
            return i18n.store?.data[i18n.language]?.common as Record<string, string>;
        }

        return {};
    };
}
