import { Category, Maybe } from '@goed-platform/graphql/types';
import { RoutesEnum } from '@goed-platform/shared/types';
import { SVGChevronLeft, SVGChevronRight, SVGPromotions } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalHeader } from '../../modal-header';

type SubheaderProps = PropsWithChildren<{
    baseUrl: RoutesEnum;
    title: string;
    className?: string;
    categories?: Category[];
    navigationItems?: LinkItemProps[];
    visible?: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}>;

type LinkItemProps = {
    className?: string;
    isLink?: boolean;
    url?: string;
    label?: string;
    onClick?: () => void;
    isPromotions?: boolean;
};

export const Subheader = ({
    baseUrl,
    title,
    className = '',
    categories = [],
    navigationItems = [],
    visible,
    setVisible,
    ...restProps
}: SubheaderProps) => {
    const { t } = useTranslation();
    const rootCategory: Category = {
        id: 'root',
        isActive: true,
        level: 0,
        label: t('categorieën'),
        sortOrder: 0,
        updatedAt: new Date(),
        children: categories,
        productCount: 0,
    };

    // The level of depth into categories
    const [activeCategory, setActiveCategory] = useState<Maybe<Category>>(rootCategory);
    const [parents, setParents] = useState<Maybe<Category>[]>([]);

    const LinkItem = ({
        url = '#',
        label,
        isLink,
        onClick,
        className = '',
        isPromotions = false,
    }: LinkItemProps): JSX.Element => {
        const cn = `d-flex justify-content-between align-items-center px-6 py-4 font-weight-bold border-bottom border-neutral-a10 bg-white
        ${isPromotions ? 'text-promo' : 'text-purple'} ${className}`;

        if (isLink) {
            return (
                <Link href={url}>
                    <a className={cn}>
                        {isPromotions ? (
                            <span className="flex">
                                <SVGPromotions className={`sprite sprite-md text-purple mr-2`} />
                                {label}
                            </span>
                        ) : (
                            label
                        )}

                        {!isPromotions && <SVGChevronRight className={`sprite sprite-md text-purple`} />}
                    </a>
                </Link>
            );
        }

        return (
            <div className={cn} role="button" onClick={onClick}>
                {label}
                <SVGChevronRight className="sprite sprite-md text-purple" />
            </div>
        );
    };

    const goToChild = (cat: Maybe<Category>): void => {
        setParents([...parents, activeCategory]);
        setActiveCategory(cat);
    };

    const goToParent = (id?: string | null): void => {
        setActiveCategory(parents.find((parent) => parent?.id === id) ?? rootCategory);
        const index = parents.findIndex((parent) => parent?.id === id);
        setParents(parents.slice(0, index));
    };

    const hasChildren = (cat: Maybe<Category>): boolean => !!(cat?.children && cat.children.length > 0);

    return (
        <Modal
            show={visible}
            onHide={() => setVisible(false)}
            animation={true}
            centered
            {...restProps}
            className={`subheader-modal modal-fullscreen-mobile ${className}`}
        >
            <ModalHeader
                title={title}
                displayPrev={false}
                menuItems={2}
                prevFunction={() =>
                    parents && parents.length > 0 ? goToParent(parents.at(-1)?.id) : setVisible(false)
                }
                closeFunction={() => setVisible(false)}
            />

            <Modal.Body className="m-0 p-0 pt-6 d-flex flex-column">
                {/* Categories */}
                {parents?.map((category, key) => (
                    <div
                        className="d-flex align-items-center px-6 py-4 text-purple font-weight-bold border-bottom border-neutral-a10 bg-white"
                        role="button"
                        onClick={() => goToParent(category?.id)}
                        key={key}
                    >
                        <SVGChevronLeft className="sprite sprite-md text-purple mr-2" />
                        {category?.label}
                    </div>
                ))}

                {/* <div
                    className="d-flex align-items-center px-6 py-4 text-purple font-weight-bold border-bottom border-neutral-a10 bg-white"
                    role="button"
                    // onClick={() => goToParent(category?.id)}
                >
                    <SVGChevronLeft className="sprite sprite-md text-purple mr-2" />
                </div> */}

                {/* Active category title/label */}
                {activeCategory?.label && activeCategory.level !== 0 ? (
                    <div className="px-6 pt-6 pb-4 font-weight-bold border-bottom border-neutral-a10">
                        {activeCategory?.label}
                    </div>
                ) : (
                    <></>
                )}

                {/* Categories */}
                {(activeCategory?.children ?? categories).map((category, key) => (
                    <LinkItem
                        key={key}
                        isLink={!hasChildren(category)}
                        url={`${baseUrl}/${category?.urlPath}`}
                        onClick={() => goToChild(category)}
                        label={category?.label ?? ''}
                        isPromotions={category?.slug === 'promoties'}
                    />
                ))}

                {/* Bottom navigation or CTA button */}
                {!activeCategory ? (
                    <div className="mt-6 d-flex flex-column">
                        {navigationItems.map((item, key) => (
                            <LinkItem key={key} isLink url={item.url} label={item.label} />
                        ))}
                    </div>
                ) : (
                    <div className="mt-6 mb-4 px-6">
                        <Link href={`${baseUrl}/${activeCategory?.slug}`}>
                            <a itemProp="url" className="btn btn-tertiary-outline btn-block w-100">
                                {t('subheader.cta.all-in', {
                                    label: activeCategory?.label,
                                })}
                            </a>
                        </Link>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};
