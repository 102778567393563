import { SVGInfo, SVGMenuClose } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export type SubtotalWithInformationModalProps = {
    title: string;
    description: JSX.Element | string;
    className?: string;
};

export const SubtotalWithInformationModal: React.FC<SubtotalWithInformationModalProps> = ({
    title,
    description,
    className = '',
    ...restProps
}: SubtotalWithInformationModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [showSubtotalModal, setShowSubtotalModal] = useState<boolean>(false);

    return (
        <>
            {/* Subtotal */}
            <div className={`${className}`} {...restProps}>
                {t('cart.overview.pricingSummary.rentalAdvance.title')}
                <span role="button" onClick={() => setShowSubtotalModal(true)}>
                    <SVGInfo className="sprite sprite-md ml-1 text-purple" title="info" />
                </span>
            </div>
            {/* Subtotal information modal */}
            <Modal show={showSubtotalModal} onHide={() => setShowSubtotalModal(false)} animation={true} centered>
                <Modal.Header>
                    <Modal.Title className="text-primary">{title}</Modal.Title>
                    <button className="text-purple border-0 bg-white" onClick={() => setShowSubtotalModal(false)}>
                        <SVGMenuClose className="sprite sprite-menu-close sprite-md" title="menu-close" />
                    </button>
                </Modal.Header>

                <Modal.Body>{description}</Modal.Body>
            </Modal>
        </>
    );
};
