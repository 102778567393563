import sanitizeHtml from 'sanitize-html';

export class Sanitizer {
    public static SanitizeMerchantCenterRichTextEditor = (html: string | null) => {
        return sanitizeHtml(html ?? '', {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['del']),
            transformTags: {
                h1: 'h4',
                h2: 'h5',
                h3: 'h6',
                h4: 'h6',
                h5: 'h6',
                h6: 'h6',
                pre: 'p',
            },
        });
    };

    public static SanitizeProductDescription = (html: string | null) => {
        return sanitizeHtml(html ?? '', {
            allowedTags: [],
        });
    };
}
