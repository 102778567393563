import { i18n } from 'next-i18next';
import { t } from 'i18next';

// Convert a date of format "d(d)-m(m)-yyyy" OR "d(d)/m(m)/yyyy" to the CommerceTools date format.
export const convertReadableDateToCTDate = (dateString: string) => {
    const delimiter = dateString.includes('-') ? '-' : '/';
    const birthDateArr = dateString.split(delimiter);
    const day: string = addZeroToDate(birthDateArr[0]);
    const month: string = addZeroToDate(birthDateArr[1]);
    const year: string = birthDateArr[2];

    return `${year}-${month}-${day}T00:00:00.000+0:00`;
};

// Convert the CommerceTools date format to the format YYYY-MM-DDThh:mm:ss.000+0:00
export const convertCTDateToReadableDate = (
    CTDate?: string,
    isMonthTextualRepresentation?: boolean
): string | undefined => {
    if (!CTDate) {
        return undefined;
    }

    const sanitizedDateArray = CTDate.split('T')[0].split('-').reverse();

    // Should the month be converted to a full textual representation, such as "Januari".
    if (isMonthTextualRepresentation) {
        return `${removeZeroFromDate(sanitizedDateArray[0])} ${i18n?.t(
            'general.months.' + removeZeroFromDate(sanitizedDateArray[1])
        )} ${sanitizedDateArray[2]}`;
    }

    return sanitizedDateArray.join('-');
};

// Convert a date of type ISOString to "d(d)/m(m)/yyyy" to the CommerceTools date format.
export const displayAppointDate = (dateString: string): string => {
    const date = new Date(dateString);

    const day: string = addZeroToDate(`${date.getDate()}`);
    const month: string = t(`general.months.${date.getDate()}`);
    const year: number = date.getFullYear();

    return `${day} ${month} ${year}`;
};

const removeZeroFromDate = (n: string): string => (n[0] === '0' ? n[1] : n);
const addZeroToDate = (n: string): string => (+n < 10 ? `0${+n}` : n);
