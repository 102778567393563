import { EventCallbackType } from '@goed-platform/shared/types';
import { Field } from 'formik';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { FieldError } from '../../index';

type TextFieldProps = {
    id: string;
    label: string;
    type?: string;
    value?: string;
    help?: string;
    className?: string;
    placeholder?: string;
    autofocus?: boolean;
    error?: string;
    errorClassName?: string;
    inputClassName?: string;
    touched?: boolean;
    min?: number;
    max?: number;
    onChange?: EventCallbackType;
    required?: boolean;
    pattern?: string;
    autocomplete?: string;
};

export const TextField: React.FC<TextFieldProps> = ({
    id,
    label,
    type,
    value,
    help,
    className = '',
    placeholder,
    autofocus,
    error,
    errorClassName = '',
    inputClassName = '',
    touched,
    min,
    max,
    onChange,
    required,
    pattern,
    autocomplete = '',
    ...restProps
}: TextFieldProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={id}>
                {t(label)}
                {required && '*'}
            </label>
            <Field
                id={id}
                name={id}
                type={type ?? 'text'}
                value={value}
                placeholder={placeholder ? t(placeholder) : null}
                autoComplete={autocomplete}
                autoFocus={autofocus}
                className={`form-control ${inputClassName}`}
                onChange={onChange}
                pattern={pattern ? new RegExp(pattern) : null}
                min={min}
                max={max}
                {...restProps}
            />
            <FieldError className={errorClassName} error={error} touched={touched} />
            {help && <div className="form-text mt-2">{t(help)}</div>}
        </div>
    );
};

export default TextField;
