import {
    SVGAmericanExpress,
    SVGApplePay,
    SVGBancontact,
    SVGMaestro,
    SVGMastercard,
    SVGOgone,
    SVGPaypal,
    SVGVisa,
} from '@goed-platform/shared/utils';
// @TODO: Find a way not to reference this file outside the libs folder.
// This prevents us from enabling the rule "@nrwl/nx/enforce-module-boundaries" in `.eslintrc.json`.
import { nextI18nextConfig } from './next-i18next.config';

export const Config = {
    i18n: nextI18nextConfig.i18n,
    sites: {
        main: 'https://www.goed.be',
        main_uat: 'https://wwwuat.goed.be',
        shop: process.env.NEXT_PUBLIC_URL ?? 'https://shop.goed.be',
        jobs: 'https://jobs.goed.be',
    },
    global: {
        appName: {
            ULD: 'Goed Uitleendienst',
            RES: 'Goed Webreservaties',
            APNT: 'Goed Afspraak',
        },
        defaultCurrency: 'EUR',
        defaultLanguage: nextI18nextConfig.i18n.defaultLocale,
        languages: nextI18nextConfig.i18n.locales.slice(0, -1), // Filter out catchAll route.
        revalidation: 120, // 2 minutes
    },
    boot: {
        refreshInterval: 60 * 1000, // Interval expressed in milliseconds.
    },
    sitemap: {
        basePath: process.env.NEXT_PUBLIC_URL ?? 'https://shop.goed.be',
        batchSize: 500, // Agreement with RB2 not to fetch too many records at once. (Original value was 10000)
        revalidation: 14400, // 4 hours
    },
    robots: {
        revalidation: 86400, // 24 hours
    },
    pagination: {
        defaultItemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100],
        neighbours: 2,
        highestPage: 10000 / 10, // Commercetools restricts it's "skip" parameter, so in order to not fetch a page 1700 * 10, we set a max last page.
    },
    token: {
        renewXSecondsBeforeEndOfLife: 10 * 60, // Renew the token 10 minutes before going end of life.
        keepAliveInterval: 60 * 1000, // Interval expressed in milliseconds.
    },
    axios: {
        timeout: 3000,
    },
    user: {
        minimumPasswordLength: 10,
        amountOfCustomerOrders: 50,
    },
    catalog: {
        filterRenderLimit: 5,
    },
    paymentMethodsAll: [
        {
            name: 'American Express',
            svg: SVGAmericanExpress,
        },
        {
            name: 'Apple Pay',
            svg: SVGApplePay,
        },
        {
            name: 'Bancontact',
            svg: SVGBancontact,
        },
        // {
        //   name: 'Klarna',
        //   svg: SVGKlarna,
        // },
        {
            name: 'Maestro',
            svg: SVGMaestro,
        },
        {
            name: 'Mastercard',
            svg: SVGMastercard,
        },
        {
            name: 'Ogone',
            svg: SVGOgone,
        },
        {
            name: 'Paypal',
            svg: SVGPaypal,
        },
        {
            name: 'Visa',
            svg: SVGVisa,
        },
    ],
    paymentMethodsImportant: [
        // {
        //     name: 'American Express',
        //     svg: SVGAmericanExpress,
        // },
        // {
        //     name: 'Apple Pay',
        //     svg: SVGApplePay,
        // },
        {
            name: 'Bancontact',
            svg: SVGBancontact,
        },
        // {
        //   name: 'Klarna',
        //   svg: SVGKlarna,
        // },
        {
            name: 'Maestro',
            svg: SVGMaestro,
        },
        {
            name: 'Mastercard',
            svg: SVGMastercard,
        },
        // {
        //     name: 'Ogone',
        //     svg: SVGOgone,
        // },
        // {
        //     name: 'Paypal',
        //     svg: SVGPaypal,
        // },
        {
            name: 'Visa',
            svg: SVGVisa,
        },
    ],

    customerService: {
        openingHours: {
            start: '8:00',
            stop: '22:00',
        },
    },

    social: {
        facebook: 'social.facebook.url',
        newsletter: 'social.newsletter.url',
    },
};
