import { Transition } from '@headlessui/react';
import { Dispatch, PropsWithChildren, SetStateAction } from 'react';

type MenuDropdownProps = PropsWithChildren<{
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    rounded?: boolean;
    className?: string;
}>;

export const MenuDropdown = ({ setShow, rounded = true, show, children, className = '' }: MenuDropdownProps) => {
    return (
        <Transition
            show={show}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className={`position-absolute ${rounded ? 'rounded-bottom' : ''} bg-white w-100 menu-dropdown ${className}`}
        >
            {children}
        </Transition>
    );
};
