import { RoutesEnum } from '@goed-platform/shared/types';
import { isBrowser } from '@goed-platform/shared/utils';
import { SymfonyDataAccess } from '@goed-platform/symfony/data-access';
import { SymfonyPharmacy } from '@goed-platform/symfony/types';
import Router from 'next/router';
import { StorageEnum, webResActivePharmacy } from './reactive-vars';

// eslint-disable-next-line @typescript-eslint/ban-types
type WebreservationPharmacyProps = {};

export class WebreservationPharmacy {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor, no-empty-pattern, @typescript-eslint/no-empty-function
    constructor({}: WebreservationPharmacyProps) {}

    public static getInstance(props?: WebreservationPharmacyProps) {
        return new WebreservationPharmacy({ ...props });
    }

    public async onRouteChange(): Promise<SymfonyPharmacy | null> {
        if (isBrowser() && Router.pathname.includes(RoutesEnum.webReservationLanding)) {
            if (Router.pathname.includes(RoutesEnum.webReservationOrder)) {
                return null;
            }
            const id = Router.query.id as string;

            if (id) {
                const data = await SymfonyDataAccess.getInstance()
                    .getPharmacyById(id as string)
                    .catch(() => Router.push(`/${Router.locale}${RoutesEnum.reserveer}`));

                if ((data as SymfonyPharmacy).id) {
                    return this.setActivePharmacy(data as SymfonyPharmacy);
                }
            } else if (this.getActivePharmacy()) {
                return this.getActivePharmacy();
            } else {
                // @TODO: Should redirect to page with more info about webreservation in frontend or Harmony. Ask to Bram.
                Router.push(`/${Router.locale}${RoutesEnum.reserveer}`);
                return null;
            }
        }

        return null;
    }

    public setActivePharmacy(data: SymfonyPharmacy): SymfonyPharmacy | null {
        localStorage.setItem(StorageEnum.webResActivePharmacy, JSON.stringify(data));
        webResActivePharmacy(data);
        return data;
    }

    public getActivePharmacy(): SymfonyPharmacy | null {
        const storageItem = localStorage.getItem(StorageEnum.webResActivePharmacy);
        return storageItem ? (JSON.parse(storageItem) as SymfonyPharmacy) : null;
    }
}
