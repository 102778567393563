import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
    DateTime: any;
    /** The built-in `Decimal` scalar type. */
    Decimal: any;
    /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
    Long: any;
};

export type Accessory = {
    __typename?: 'Accessory';
    type: AccessoryType;
    product?: Maybe<Product>;
};

export enum AccessoryType {
    Mandatory = 'MANDATORY',
    Recommended = 'RECOMMENDED',
}

export type AggregateFieldInput = {
    aggregateName?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type AggregateResponse = {
    __typename?: 'AggregateResponse';
    aggregateName?: Maybe<Scalars['String']>;
    otherDocCount: Scalars['Decimal'];
    errorUpperBound: Scalars['Decimal'];
    buckets?: Maybe<Array<Maybe<AggregateResponseBucket>>>;
    attribute?: Maybe<Attribute>;
};

export type AggregateResponseBucket = {
    __typename?: 'AggregateResponseBucket';
    key: Scalars['Int'];
    count: Scalars['Long'];
    filterKey?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
    BeforeResolver = 'BEFORE_RESOLVER',
    AfterResolver = 'AFTER_RESOLVER',
}

export enum AssemblyOption {
    IncludingInstallation = 'INCLUDING_INSTALLATION',
    ExcludingInstallation = 'EXCLUDING_INSTALLATION',
}

export type Attribute = {
    __typename?: 'Attribute';
    productType?: Maybe<Array<Maybe<Scalars['String']>>>;
    id?: Maybe<Scalars['String']>;
    code?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    type: AttributeType;
    options?: Maybe<Array<Maybe<AttributeOption>>>;
};

export type AttributeOption = {
    __typename?: 'AttributeOption';
    label?: Maybe<Scalars['String']>;
    value: Scalars['Int'];
};

export enum AttributeType {
    Text = 'TEXT',
    Select = 'SELECT',
    Decimal = 'DECIMAL',
    MultiSelect = 'MULTI_SELECT',
    Boolean = 'BOOLEAN',
}

export type CmMembershipCheckResult = {
    __typename?: 'CMMembershipCheckResult';
    success: Scalars['Boolean'];
    errorMessage?: Maybe<Scalars['String']>;
    nrn?: Maybe<Scalars['String']>;
    language?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    familyName?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    municipalitynl?: Maybe<Scalars['String']>;
    municipality?: Maybe<Scalars['String']>;
    countrynl?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    alliance?: Maybe<Scalars['String']>;
    vp: Scalars['Boolean'];
    cMDV: Scalars['Boolean'];
    nonpayer: Scalars['Boolean'];
    vt: Scalars['Boolean'];
    omnio: Scalars['Boolean'];
    omnioentitled: Scalars['Boolean'];
    isCMMember: Scalars['Boolean'];
};

export type CanonicalData = {
    __typename?: 'CanonicalData';
    slug?: Maybe<Scalars['String']>;
    key?: Maybe<Scalars['String']>;
    sku?: Maybe<Scalars['String']>;
};

export enum CartDeliveryOption {
    Delivery = 'DELIVERY',
    Collection = 'COLLECTION',
    ProductDefault = 'PRODUCT_DEFAULT',
}

export type CartItem = {
    __typename?: 'CartItem';
    itemId?: Maybe<Scalars['String']>;
    sku?: Maybe<Scalars['String']>;
    key?: Maybe<Scalars['String']>;
    quantity: Scalars['Long'];
    name?: Maybe<Scalars['String']>;
    price: Scalars['Decimal'];
    priceInclTax: Scalars['Decimal'];
    discountedPrice?: Maybe<Scalars['Decimal']>;
    discountedPriceInclTax?: Maybe<Scalars['Decimal']>;
    totalInclTax: Scalars['Decimal'];
    image?: Maybe<ProductImage>;
    urlPath?: Maybe<Scalars['String']>;
    discountsPerCode?: Maybe<Array<KeyValuePairOfStringAndDecimal>>;
    additionalData?: Maybe<Array<KeyValuePairOfStringAndObject>>;
    productServiceType?: Maybe<ProductServiceType>;
    deliveryOption?: Maybe<DeliveryOption>;
    deliveryCost?: Maybe<Scalars['Decimal']>;
    serviceCost?: Maybe<Scalars['Decimal']>;
    depositAmount?: Maybe<Scalars['Decimal']>;
    originalTotalPrice?: Maybe<Scalars['Decimal']>;
    originalPrice?: Maybe<Scalars['Decimal']>;
    rentalPeriodUnit?: Maybe<Period>;
    variantProperties?: Maybe<Array<Maybe<ProductVariantProperty>>>;
    slug?: Maybe<Scalars['String']>;
    isPublished: Scalars['Boolean'];
    minRentalPeriod: Scalars['Int'];
    rentalAdvance: Scalars['Decimal'];
    categories?: Maybe<Array<Maybe<Scalars['String']>>>;
    lineItemStatus?: Maybe<CustomOrderStatus>;
    deliverySize?: Maybe<Scalars['String']>;
    isMedicine: Scalars['Boolean'];
    refundable: Scalars['Boolean'];
    packageQuantity?: Maybe<Scalars['String']>;
    orderLines?: Maybe<Array<Maybe<GoedOrderLineUpdate>>>;
    rentToOwn?: Maybe<Scalars['Int']>;
    prescriptionRequired?: Maybe<Scalars['Boolean']>;
    specialDelivery?: Maybe<SpecialDelivery>;
    bothDelivery: Scalars['Boolean'];
};

export type CartRequestInput = {
    cartId?: InputMaybe<Scalars['String']>;
    storeType: StoreType;
};

export type CartTotal = {
    __typename?: 'CartTotal';
    customerId?: Maybe<Scalars['String']>;
    grandTotal: Scalars['Decimal'];
    subTotal: Scalars['Decimal'];
    discountAmountInclTax: Scalars['Decimal'];
    currency: Currency;
    totalQuantity: Scalars['Long'];
    deliveryIncludingTax: Scalars['Decimal'];
    taxAmount: Scalars['Decimal'];
    items?: Maybe<Array<Maybe<CartItem>>>;
    deliveryAddress?: Maybe<UserAddress>;
    billingAddress?: Maybe<UserAddress>;
    id?: Maybe<Scalars['String']>;
    orderNumber?: Maybe<Scalars['String']>;
    paymentStatus: PaymentStatus;
    status?: Maybe<CustomOrderStatus>;
    deliveryMethodId?: Maybe<Scalars['String']>;
    deliveryMethodName?: Maybe<DeliveryMethodName>;
    deliveryMethodForDynamics?: Maybe<DeliveryMethod>;
    discountsPerCode?: Maybe<Array<KeyValuePairOfStringAndDecimal>>;
    discountCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    additionalData?: Maybe<Array<KeyValuePairOfStringAndObject>>;
    createdAt: Scalars['DateTime'];
    store?: Maybe<UserStore>;
    comment?: Maybe<Scalars['String']>;
    deliveryCost?: Maybe<Scalars['Decimal']>;
    serviceCostTotal?: Maybe<Scalars['Decimal']>;
    depositAmountTotal?: Maybe<Scalars['Decimal']>;
    isCMMember: Scalars['Boolean'];
    groupedItems?: Maybe<Array<Maybe<GroupedItem>>>;
    cartDeliveryOption?: Maybe<CartDeliveryOption>;
    accessoriesTotal?: Maybe<Scalars['Decimal']>;
    isAboveFloor?: Maybe<Scalars['Boolean']>;
    storeType: StoreType;
    orderStatus?: Maybe<CustomOrderStatus>;
    communicationPreference?: Maybe<Scalars['String']>;
    huurkoopCentAmount?: Maybe<Scalars['Int']>;
    specialDelivery?: Maybe<SpecialDelivery>;
};

export type Category = {
    __typename?: 'Category';
    id?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    key?: Maybe<Scalars['String']>;
    urlPath?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    parentId?: Maybe<Scalars['String']>;
    parents?: Maybe<Array<Maybe<CategoryParent>>>;
    isActive: Scalars['Boolean'];
    level: Scalars['Int'];
    sortOrder: Scalars['Decimal'];
    children?: Maybe<Array<Maybe<Category>>>;
    updatedAt: Scalars['DateTime'];
    additionalData?: Maybe<Array<KeyValuePairOfStringAndObject>>;
    metaDescription?: Maybe<Scalars['String']>;
    metaTitle?: Maybe<Scalars['String']>;
    productCount: Scalars['Int'];
    categoryCustom?: Maybe<CategoryCustom>;
};

export type CategoryCustom = {
    __typename?: 'CategoryCustom';
    imageUrl?: Maybe<Scalars['String']>;
    productCount: Scalars['Int'];
    imageFullWidth?: Maybe<Scalars['String']>;
    imageSplitLeft?: Maybe<Scalars['String']>;
    imageSplitRight?: Maybe<Scalars['String']>;
};

export type CategoryParent = {
    __typename?: 'CategoryParent';
    id?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    urlPath?: Maybe<Scalars['String']>;
};

export type CommerceToolStores = {
    __typename?: 'CommerceToolStores';
    id?: Maybe<Scalars['String']>;
    key?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    storeId: Scalars['Int'];
    street?: Maybe<Scalars['String']>;
    box?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    services?: Maybe<Scalars['String']>;
    version: Scalars['Long'];
    isDelete: Scalars['Boolean'];
    serviceList?: Maybe<Array<Maybe<StoreService>>>;
    isFavorite: Scalars['Boolean'];
    serverMap?: Maybe<Scalars['String']>;
    storeCode?: Maybe<Scalars['String']>;
};

export enum Currency {
    Eur = 'EUR',
}

export enum CustomOrderStatus {
    Open = 'OPEN',
    Deliver = 'DELIVER',
    Pickup = 'PICKUP',
    Rented = 'RENTED',
    Takeback = 'TAKEBACK',
    Closed = 'CLOSED',
    Cancelled = 'CANCELLED',
    DeliverReservation = 'DELIVER_RESERVATION',
    PickupReservation = 'PICKUP_RESERVATION',
    TakebackReservation = 'TAKEBACK_RESERVATION',
    Exchange = 'EXCHANGE',
}

export type CustomerCommunicationPreference = {
    __typename?: 'CustomerCommunicationPreference';
    guestEmail?: Maybe<Scalars['String']>;
    newsLetterOptin?: Maybe<Scalars['Boolean']>;
    pharmacyOptin?: Maybe<Scalars['Boolean']>;
    homeCareOptin?: Maybe<Scalars['Boolean']>;
    hearingLossOptin?: Maybe<Scalars['Boolean']>;
    hearingProtectionOptin?: Maybe<Scalars['Boolean']>;
};

export type CustomerCommunicationPreferenceInput = {
    guestEmail?: InputMaybe<Scalars['String']>;
    newsLetterOptin?: InputMaybe<Scalars['Boolean']>;
    pharmacyOptin?: InputMaybe<Scalars['Boolean']>;
    homeCareOptin?: InputMaybe<Scalars['Boolean']>;
    hearingLossOptin?: InputMaybe<Scalars['Boolean']>;
    hearingProtectionOptin?: InputMaybe<Scalars['Boolean']>;
};

export enum CustomerSort {
    CreatedAtDesc = 'CREATED_AT_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    TotalPriceDesc = 'TOTAL_PRICE_DESC',
    TotalPriceAsc = 'TOTAL_PRICE_ASC',
}

export enum DeliveryMethod {
    Levaanhuis = 'LEVAANHUIS',
    Levkleinud = 'LEVKLEINUD',
    Levahverd = 'LEVAHVERD',
    Levahverd2 = 'LEVAHVERD2',
}

export enum DeliveryMethodName {
    BpostDelivery = 'BPOST_DELIVERY',
    BpostCollection = 'BPOST_COLLECTION',
    UitleendienstDelivery = 'UITLEENDIENST_DELIVERY',
    UitleendienstCollection = 'UITLEENDIENST_COLLECTION',
}

export enum DeliveryOption {
    Collection = 'COLLECTION',
    Delivery = 'DELIVERY',
}

export type Dimensions = {
    __typename?: 'Dimensions';
    width: Scalars['Int'];
    height: Scalars['Int'];
};

export type GetCustomerOrdersByIdParameterInput = {
    orderId?: InputMaybe<Scalars['String']>;
    orderNumber?: InputMaybe<Scalars['String']>;
};

export type GoedOrderLineCrossSell = {
    __typename?: 'GoedOrderLineCrossSell';
    itemId?: Maybe<Scalars['String']>;
    quantity?: Maybe<Scalars['String']>;
    unit?: Maybe<Scalars['String']>;
};

export type GoedOrderLineUpdate = {
    __typename?: 'GoedOrderLineUpdate';
    serialNumber?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    itemId?: Maybe<Scalars['String']>;
    quantity?: Maybe<Scalars['String']>;
    unit?: Maybe<Scalars['String']>;
    deliveryMethod?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    webshopOrderLine?: Maybe<Scalars['String']>;
    crossSell?: Maybe<Array<Maybe<GoedOrderLineCrossSell>>>;
};

export type GoedSubscription = {
    __typename?: 'GoedSubscription';
    userInfo?: Maybe<UserInfo>;
    cart?: Maybe<CartTotal>;
};

export type GroupedItem = {
    __typename?: 'GroupedItem';
    deliveryType: DeliveryOption;
    subTotalQuantity: Scalars['Int'];
    subTotal: Scalars['Decimal'];
    subTotalServiceCost?: Maybe<Scalars['Decimal']>;
    subTotalDepositAmount?: Maybe<Scalars['Decimal']>;
    subTotalDelivery?: Maybe<Scalars['Decimal']>;
    subTotalAccessories?: Maybe<Scalars['Decimal']>;
    groupTotal: Scalars['Decimal'];
    items?: Maybe<Array<Maybe<CartItem>>>;
};

export type GroupedPrice = {
    __typename?: 'GroupedPrice';
    customerGroups?: Maybe<Scalars['String']>;
    price: Scalars['Decimal'];
    priceInclTax: Scalars['Decimal'];
    minQuantity: Scalars['Int'];
    rangePrice?: Maybe<Array<Maybe<RangePrice>>>;
};

export type KeyValuePairOfStringAndAggregateResponse = {
    __typename?: 'KeyValuePairOfStringAndAggregateResponse';
    key: Scalars['String'];
    value: AggregateResponse;
};

export type KeyValuePairOfStringAndDecimal = {
    __typename?: 'KeyValuePairOfStringAndDecimal';
    key: Scalars['String'];
    value: Scalars['Decimal'];
};

export type KeyValuePairOfStringAndListOfProduct = {
    __typename?: 'KeyValuePairOfStringAndListOfProduct';
    key: Scalars['String'];
    value: Array<Product>;
};

export type KeyValuePairOfStringAndListOfStringInput = {
    key: Scalars['String'];
    value: Array<Scalars['String']>;
};

export type KeyValuePairOfStringAndObject = {
    __typename?: 'KeyValuePairOfStringAndObject';
    key: Scalars['String'];
};

export type Mutations = {
    __typename?: 'Mutations';
    customerRenewToken?: Maybe<Scalars['String']>;
    customerSignIn?: Maybe<UserLoginResponse>;
    customerSignUp?: Maybe<UserLoginResponse>;
    changePassword?: Maybe<UserInfo>;
    changeEmail?: Maybe<UserInfo>;
    addFavoriteStore?: Maybe<UserInfo>;
    removeFavoriteStore?: Maybe<UserInfo>;
    requestForgetPasswordToken: Scalars['Boolean'];
    resetPasswordWithToken?: Maybe<ResetPasswordResult>;
    addProductToCart?: Maybe<CartTotal>;
    createOrder?: Maybe<PaymentDetails>;
    updateCart?: Maybe<CartTotal>;
    removeProductFromCart?: Maybe<CartTotal>;
    setDeliveryAddress?: Maybe<CartTotal>;
    setBillingAddress?: Maybe<CartTotal>;
    addDiscountCodeToCart?: Maybe<CartTotal>;
    removeDiscountCodeFromCart?: Maybe<CartTotal>;
    setStoreForCart?: Maybe<CartTotal>;
    setCommentForCart?: Maybe<CartTotal>;
    setCommunicationPreferenceForRES?: Maybe<CartTotal>;
    updateUserInfo?: Maybe<UserInfo>;
    updateUserPersonalInfo?: Maybe<UserInfo>;
    setUserDeliveryAddress?: Maybe<UserInfo>;
    setUserBillingAddress?: Maybe<UserInfo>;
    removeAddress?: Maybe<UserInfo>;
    changeCartCMMember?: Maybe<CartTotal>;
    changeCartDeliveryOptions?: Maybe<CartTotal>;
    reSetCartDeliveryCost?: Maybe<CartTotal>;
    setCustomerCommunicationPreferences?: Maybe<UserInfo>;
    syncCustomerProfileToCart?: Maybe<CartTotal>;
    setAboveFloorForCart?: Maybe<CartTotal>;
    requestEmailConfirmToken: Scalars['Boolean'];
    confirmEmailByToken: Scalars['Boolean'];
    requestEmailConfirmDigits: Scalars['Boolean'];
    confirmEmailByDigits: Scalars['Boolean'];
};

export type MutationsCustomerRenewTokenArgs = {
    token?: InputMaybe<Scalars['String']>;
};

export type MutationsCustomerSignInArgs = {
    email?: InputMaybe<Scalars['String']>;
    password?: InputMaybe<Scalars['String']>;
    anonymousCarts?: InputMaybe<Array<InputMaybe<StoreCartInput>>>;
};

export type MutationsCustomerSignUpArgs = {
    user?: InputMaybe<UserRegisterInput>;
    anonymousCarts?: InputMaybe<Array<InputMaybe<StoreCartInput>>>;
};

export type MutationsChangePasswordArgs = {
    currentPassword?: InputMaybe<Scalars['String']>;
    newPassword?: InputMaybe<Scalars['String']>;
};

export type MutationsChangeEmailArgs = {
    password?: InputMaybe<Scalars['String']>;
    newEmail?: InputMaybe<Scalars['String']>;
    oldEmail?: InputMaybe<Scalars['String']>;
};

export type MutationsAddFavoriteStoreArgs = {
    storeId?: InputMaybe<Scalars['String']>;
};

export type MutationsRemoveFavoriteStoreArgs = {
    storeId?: InputMaybe<Scalars['String']>;
};

export type MutationsRequestForgetPasswordTokenArgs = {
    email?: InputMaybe<Scalars['String']>;
};

export type MutationsResetPasswordWithTokenArgs = {
    token?: InputMaybe<Scalars['String']>;
    newPassword?: InputMaybe<Scalars['String']>;
};

export type MutationsAddProductToCartArgs = {
    quantity: Scalars['Int'];
    sku?: InputMaybe<Scalars['String']>;
    productServiceType?: InputMaybe<ProductServiceType>;
    cartId?: InputMaybe<Scalars['String']>;
    storeType?: StoreType;
    specialDelivery?: SpecialDelivery;
};

export type MutationsCreateOrderArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    storeType?: StoreType;
};

export type MutationsUpdateCartArgs = {
    quantity: Scalars['Int'];
    lineItemId?: InputMaybe<Scalars['String']>;
    cartId?: InputMaybe<Scalars['String']>;
    storeType?: StoreType;
};

export type MutationsRemoveProductFromCartArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    lineItemId?: InputMaybe<Scalars['String']>;
    storeType?: StoreType;
};

export type MutationsSetDeliveryAddressArgs = {
    parameters?: InputMaybe<SetDeliveryAddressParametersInput>;
};

export type MutationsSetBillingAddressArgs = {
    parameters?: InputMaybe<SetBillingAddressParametersInput>;
};

export type MutationsAddDiscountCodeToCartArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    discountCode?: InputMaybe<Scalars['String']>;
    storeType?: StoreType;
};

export type MutationsRemoveDiscountCodeFromCartArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    discountCode?: InputMaybe<Scalars['String']>;
    storeType?: StoreType;
};

export type MutationsSetStoreForCartArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    storeId: Scalars['Int'];
    storeType?: StoreType;
};

export type MutationsSetCommentForCartArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    comment?: InputMaybe<Scalars['String']>;
    storeType?: StoreType;
};

export type MutationsSetCommunicationPreferenceForResArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    method?: InputMaybe<Scalars['String']>;
};

export type MutationsUpdateUserInfoArgs = {
    personalInfo?: InputMaybe<UserPersonalInfoInput>;
    deliveryAddress?: InputMaybe<UserAddressInput>;
    billingAddress?: InputMaybe<UserAddressInput>;
};

export type MutationsUpdateUserPersonalInfoArgs = {
    data?: InputMaybe<UserPersonalInfoInput>;
};

export type MutationsSetUserDeliveryAddressArgs = {
    address?: InputMaybe<UserAddressInput>;
};

export type MutationsSetUserBillingAddressArgs = {
    address?: InputMaybe<UserAddressInput>;
};

export type MutationsRemoveAddressArgs = {
    addressId?: InputMaybe<Scalars['String']>;
};

export type MutationsChangeCartCmMemberArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    isCMMember: Scalars['Boolean'];
    storeType?: StoreType;
};

export type MutationsChangeCartDeliveryOptionsArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    cartDeliveryOption: CartDeliveryOption;
    storeType?: StoreType;
};

export type MutationsReSetCartDeliveryCostArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    skus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    specialDelivery: SpecialDelivery;
};

export type MutationsSetCustomerCommunicationPreferencesArgs = {
    preference?: InputMaybe<CustomerCommunicationPreferenceInput>;
};

export type MutationsSyncCustomerProfileToCartArgs = {
    cartId?: InputMaybe<Scalars['String']>;
};

export type MutationsSetAboveFloorForCartArgs = {
    cartId?: InputMaybe<Scalars['String']>;
    isAboveFloor: Scalars['Boolean'];
    storeType?: StoreType;
};

export type MutationsRequestEmailConfirmTokenArgs = {
    customerId?: InputMaybe<Scalars['String']>;
};

export type MutationsConfirmEmailByTokenArgs = {
    token?: InputMaybe<Scalars['String']>;
};

export type MutationsRequestEmailConfirmDigitsArgs = {
    customerId?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
};

export type MutationsConfirmEmailByDigitsArgs = {
    customerId?: InputMaybe<Scalars['String']>;
    digits?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
};

export type PagedResultsOfCartTotal = {
    __typename?: 'PagedResultsOfCartTotal';
    pageNumber: Scalars['Int'];
    pageSize: Scalars['Int'];
    totalNumberOfPages: Scalars['Int'];
    total: Scalars['Int'];
    items?: Maybe<Array<Maybe<CartTotal>>>;
};

export type PaymentDetails = {
    __typename?: 'PaymentDetails';
    redirectUrl?: Maybe<Scalars['String']>;
    cartId?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
    None = 'NONE',
    Paid = 'PAID',
    Failed = 'FAILED',
    Cancelled = 'CANCELLED',
    Error = 'ERROR',
}

export enum Period {
    Day = 'DAY',
    Week = 'WEEK',
    Month = 'MONTH',
}

export type Product = {
    __typename?: 'Product';
    id?: Maybe<Scalars['String']>;
    sku?: Maybe<Scalars['String']>;
    key?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    categories?: Maybe<Array<Maybe<ProductCategory>>>;
    urlPath?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    buyable: Scalars['Boolean'];
    visible: Scalars['Boolean'];
    label?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    minQuantity: Scalars['Int'];
    properties?: Maybe<Array<Maybe<ProductProperty>>>;
    images?: Maybe<Array<Maybe<ProductImage>>>;
    variants?: Maybe<Array<Maybe<ProductVariant>>>;
    variantOptions?: Maybe<Array<Maybe<ProductVariantProperty>>>;
    options?: Maybe<Array<Maybe<ProductOption>>>;
    bundles?: Maybe<Array<Maybe<ProductBundleOption>>>;
    groupedPrices?: Maybe<Array<Maybe<GroupedPrice>>>;
    price: Scalars['Decimal'];
    priceInclTax: Scalars['Decimal'];
    specialFrom?: Maybe<Scalars['DateTime']>;
    specialTo?: Maybe<Scalars['DateTime']>;
    specialPrice?: Maybe<Scalars['Decimal']>;
    specialPriceInclTax?: Maybe<Scalars['Decimal']>;
    type: ProductConfigType;
    productType?: Maybe<Scalars['String']>;
    rangePrice?: Maybe<Array<Maybe<RangePrice>>>;
    customOptions?: Maybe<Array<Maybe<ProductCustomOption>>>;
    numberOfReviews: Scalars['Int'];
    ratings?: Maybe<ProductRatingStatistics>;
    stock?: Maybe<Scalars['Long']>;
    additionalData?: Maybe<Array<KeyValuePairOfStringAndObject>>;
    transactionType?: Maybe<TransactionType>;
    deliveryOption?: Maybe<DeliveryOption>;
    assemblyOption?: Maybe<AssemblyOption>;
    depositAmount?: Maybe<Scalars['Decimal']>;
    rentalPeriodUnit?: Maybe<Period>;
    brand?: Maybe<Scalars['String']>;
    descriptionShort?: Maybe<Scalars['String']>;
    serviceCost?: Maybe<Scalars['Decimal']>;
    rentToOwn: Scalars['Long'];
    deliveryCost?: Maybe<Scalars['Decimal']>;
    collectionCost?: Maybe<Scalars['Decimal']>;
    deliveryCostCM?: Maybe<Scalars['Decimal']>;
    collectionCostCM?: Maybe<Scalars['Decimal']>;
    minimalRentalPeriod: Scalars['Long'];
    accessories?: Maybe<Array<Maybe<Accessory>>>;
    parentProducts?: Maybe<Array<Maybe<Product>>>;
    manualUrl?: Maybe<Array<Maybe<Scalars['String']>>>;
    videoUrl?: Maybe<Array<Maybe<Scalars['String']>>>;
    additionalCompensation?: Maybe<Scalars['Boolean']>;
    additionalCompensationDescription?: Maybe<Scalars['String']>;
    accessoriesDescription?: Maybe<Scalars['String']>;
    productExceptionsDescription?: Maybe<Scalars['String']>;
    additionalProductSpecification?: Maybe<Scalars['String']>;
    varOption?: Maybe<VarOption>;
    varSex?: Maybe<VarSex>;
    varAgeCategory?: Maybe<VarAgeCategory>;
    varClothingSize?: Maybe<Scalars['Int']>;
    metaDescription?: Maybe<Scalars['String']>;
    metaTitle?: Maybe<Scalars['String']>;
    canonicalData?: Maybe<CanonicalData>;
    deliverySize?: Maybe<Scalars['String']>;
    uLDWeight: Scalars['Long'];
    prescriptionRequired?: Maybe<Scalars['String']>;
    refundable: Scalars['Boolean'];
    additionalCosts: Scalars['Decimal'];
    vat?: Maybe<Scalars['String']>;
    articleStatus?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    activeIngredients?: Maybe<Scalars['String']>;
    indication?: Maybe<Scalars['String']>;
    usage?: Maybe<Scalars['String']>;
    conservation?: Maybe<Scalars['String']>;
    bcfiCategory?: Maybe<Scalars['String']>;
    suppliers?: Maybe<Scalars['String']>;
    allowedSpecies?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    leaflets?: Maybe<Array<Maybe<Scalars['String']>>>;
    contraIndication?: Maybe<Scalars['String']>;
    packageQuantity?: Maybe<Scalars['String']>;
    articleName?: Maybe<Scalars['String']>;
    marginPercentage?: Maybe<Scalars['Decimal']>;
    writtenRequestByPatient: Scalars['Boolean'];
    isMedicine: Scalars['Boolean'];
    accessoryOnly?: Maybe<Scalars['Boolean']>;
    generic?: Maybe<Scalars['String']>;
};

export type ProductBundleOption = {
    __typename?: 'ProductBundleOption';
    id?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    title?: Maybe<Scalars['String']>;
    required: Scalars['Boolean'];
    options?: Maybe<Array<Maybe<ProductBundleOptionItem>>>;
};

export type ProductBundleOptionItem = {
    __typename?: 'ProductBundleOptionItem';
    price?: Maybe<Scalars['Decimal']>;
    quantity: Scalars['Int'];
    canChangeQuantity: Scalars['Boolean'];
    productId?: Maybe<Scalars['String']>;
    productSku?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    isDefault: Scalars['Boolean'];
};

export type ProductCategory = {
    __typename?: 'ProductCategory';
    id?: Maybe<Scalars['String']>;
    path?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    primary: Scalars['Boolean'];
    additionalData?: Maybe<Array<KeyValuePairOfStringAndObject>>;
};

export enum ProductConfigType {
    Simple = 'SIMPLE',
    Configurable = 'CONFIGURABLE',
    Bundle = 'BUNDLE',
}

export type ProductCustomOption = {
    __typename?: 'ProductCustomOption';
    isRequired: Scalars['Boolean'];
    id?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    sortOrder: Scalars['Int'];
    type: ProductCustomOptionType;
    price?: Maybe<Scalars['Decimal']>;
    priceIsPercentage: Scalars['Boolean'];
    image?: Maybe<Scalars['String']>;
    values?: Maybe<Array<Maybe<ProductCustomOptionValue>>>;
};

export enum ProductCustomOptionType {
    Text = 'TEXT',
    MultilineText = 'MULTILINE_TEXT',
    Radio = 'RADIO',
    Select = 'SELECT',
    Checkbox = 'CHECKBOX',
    Markdown = 'MARKDOWN',
    ImageUpload = 'IMAGE_UPLOAD',
}

export type ProductCustomOptionValue = {
    __typename?: 'ProductCustomOptionValue';
    id: Scalars['Int'];
    sortOrder: Scalars['Int'];
    title?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Decimal']>;
    priceIsPercentage: Scalars['Boolean'];
};

export type ProductImage = {
    __typename?: 'ProductImage';
    url?: Maybe<Scalars['String']>;
    sortOrder: Scalars['Int'];
    label?: Maybe<Scalars['String']>;
    dimensions?: Maybe<Dimensions>;
    versions?: Maybe<Array<Maybe<ProductImageVersion>>>;
};

export type ProductImageVersion = {
    __typename?: 'ProductImageVersion';
    type?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type ProductOption = {
    __typename?: 'ProductOption';
    attributeCode?: Maybe<Scalars['String']>;
    attributeId?: Maybe<Scalars['String']>;
    attributeLabel?: Maybe<Scalars['String']>;
    values?: Maybe<Array<Maybe<ProductOptionValue>>>;
};

export type ProductOptionValue = {
    __typename?: 'ProductOptionValue';
    label?: Maybe<Scalars['String']>;
    value: Scalars['Int'];
};

export type ProductProjectionSuggestParametersInput = {
    keyword?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    fuzzy?: InputMaybe<Scalars['Boolean']>;
    storeType: StoreType;
};

export type ProductProperty = {
    __typename?: 'ProductProperty';
    name?: Maybe<Scalars['String']>;
    displayName?: Maybe<Scalars['String']>;
    displayValue?: Maybe<Scalars['String']>;
    stringValue?: Maybe<Scalars['String']>;
    intValue?: Maybe<Scalars['Int']>;
    decimalValue?: Maybe<Scalars['Decimal']>;
    arrayValue?: Maybe<Array<Maybe<ProductProperty>>>;
};

export type ProductRatingStatistics = {
    __typename?: 'ProductRatingStatistics';
    averageRating: Scalars['Float'];
    lowestRating: Scalars['Float'];
    highestRating: Scalars['Float'];
    countRating0: Scalars['Long'];
    countRating1: Scalars['Long'];
    countRating2: Scalars['Long'];
    countRating3: Scalars['Long'];
    countRating4: Scalars['Long'];
    countRating5: Scalars['Long'];
};

export enum ProductServiceType {
    Sale = 'SALE',
    Rental = 'RENTAL',
    RentalAccessory = 'RENTAL_ACCESSORY',
    Prescription = 'PRESCRIPTION',
}

export enum ProductSort {
    CreatedAtDesc = 'CREATED_AT_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    LastModifiedAtDesc = 'LAST_MODIFIED_AT_DESC',
    LastModifiedAtAsc = 'LAST_MODIFIED_AT_ASC',
    PriceDesc = 'PRICE_DESC',
    PriceAsc = 'PRICE_ASC',
    NameDesc = 'NAME_DESC',
    NameAsc = 'NAME_ASC',
    Default = 'DEFAULT',
}

export type ProductVariant = {
    __typename?: 'ProductVariant';
    id?: Maybe<Scalars['String']>;
    variantId?: Maybe<Scalars['String']>;
    sku?: Maybe<Scalars['String']>;
    key?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    properties?: Maybe<Array<Maybe<ProductProperty>>>;
    variantProperties?: Maybe<Array<Maybe<ProductVariantProperty>>>;
    images?: Maybe<Array<Maybe<ProductImage>>>;
    price: Scalars['Decimal'];
    priceInclTax: Scalars['Decimal'];
    minQuantity: Scalars['Int'];
    specialFrom?: Maybe<Scalars['DateTime']>;
    specialTo?: Maybe<Scalars['DateTime']>;
    specialPrice?: Maybe<Scalars['Decimal']>;
    specialPriceInclTax?: Maybe<Scalars['Decimal']>;
    rangePrice?: Maybe<Array<Maybe<RangePrice>>>;
    groupedPrices?: Maybe<Array<Maybe<GroupedPrice>>>;
    stock?: Maybe<Scalars['Long']>;
    slug?: Maybe<Scalars['String']>;
    articleName?: Maybe<Scalars['String']>;
};

export type ProductVariantProperty = {
    __typename?: 'ProductVariantProperty';
    key?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Query = {
    __typename?: 'Query';
    version?: Maybe<Scalars['String']>;
    rootCategories?: Maybe<Array<Maybe<Category>>>;
    checkUser: Scalars['Boolean'];
    orderForCartId?: Maybe<CartTotal>;
    categories?: Maybe<SearchCategoriesResult>;
    products?: Maybe<SearchProductsResult>;
    productProjectionSuggest?: Maybe<SearchProductSuggestionsResult>;
    cart?: Maybe<Array<Maybe<CartTotal>>>;
    userInfo?: Maybe<UserInfo>;
    userAddresses?: Maybe<Array<Maybe<UserAddress>>>;
    customerOrders?: Maybe<PagedResultsOfCartTotal>;
    customerOrdersById?: Maybe<CartTotal>;
    myFavoriteStore?: Maybe<Array<Maybe<Scalars['String']>>>;
    customerCommunicationPreferences?: Maybe<CustomerCommunicationPreference>;
    stores?: Maybe<StoreSelection>;
    checkMembership?: Maybe<CmMembershipCheckResult>;
    accessoryRelatedProducts?: Maybe<SearchProductsResult>;
};

export type QueryRootCategoriesArgs = {
    storeType?: StoreType;
};

export type QueryCheckUserArgs = {
    email?: InputMaybe<Scalars['String']>;
};

export type QueryOrderForCartIdArgs = {
    cartId?: InputMaybe<Scalars['String']>;
};

export type QueryCategoriesArgs = {
    query?: InputMaybe<SearchCategoriesParameterInput>;
};

export type QueryProductsArgs = {
    query?: InputMaybe<SearchProductsParametersInput>;
};

export type QueryProductProjectionSuggestArgs = {
    query?: InputMaybe<ProductProjectionSuggestParametersInput>;
};

export type QueryCartArgs = {
    storeCarts?: InputMaybe<Array<InputMaybe<CartRequestInput>>>;
};

export type QueryCustomerOrdersArgs = {
    data?: InputMaybe<QueryOrderParameterInput>;
};

export type QueryCustomerOrdersByIdArgs = {
    parameter?: InputMaybe<GetCustomerOrdersByIdParameterInput>;
};

export type QueryStoresArgs = {
    request?: InputMaybe<StoreQueryParameterInput>;
};

export type QueryCheckMembershipArgs = {
    rrn?: InputMaybe<Scalars['String']>;
};

export type QueryAccessoryRelatedProductsArgs = {
    sku?: InputMaybe<Scalars['String']>;
};

export type QueryOrderParameterInput = {
    skip: Scalars['Int'];
    take: Scalars['Int'];
    sort?: InputMaybe<CustomerSort>;
    filter?: InputMaybe<Scalars['String']>;
    storeType?: InputMaybe<StoreType>;
};

export type RangePrice = {
    __typename?: 'RangePrice';
    minQuantity: Scalars['Int'];
    price: Scalars['Decimal'];
    priceInclTax: Scalars['Decimal'];
};

export type ResetPasswordResult = {
    __typename?: 'ResetPasswordResult';
    success: Scalars['Boolean'];
    email?: Maybe<Scalars['String']>;
};

export type SearchCategoriesParameterInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    parentId?: InputMaybe<Scalars['String']>;
    slug?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    urlPath?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<Scalars['String']>;
    storeType?: InputMaybe<StoreType>;
};

export type SearchCategoriesResult = {
    __typename?: 'SearchCategoriesResult';
    total: Scalars['Int'];
    items?: Maybe<Array<Maybe<Category>>>;
};

export type SearchProductSuggestionsResult = {
    __typename?: 'SearchProductSuggestionsResult';
    suggestions?: Maybe<Array<Maybe<Suggestion>>>;
    products?: Maybe<Array<KeyValuePairOfStringAndListOfProduct>>;
    total: Scalars['Int'];
};

export type SearchProductsParametersInput = {
    categoryId?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<Scalars['String']>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    urlpath?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<ProductSort>;
    sku?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    propertyFilters?: InputMaybe<Array<KeyValuePairOfStringAndListOfStringInput>>;
    aggregates?: InputMaybe<Array<InputMaybe<AggregateFieldInput>>>;
    minPrice?: InputMaybe<Scalars['Decimal']>;
    maxPrice?: InputMaybe<Scalars['Decimal']>;
    storeType?: InputMaybe<StoreType>;
    includeAccessoryOnlyProducts?: InputMaybe<Scalars['Boolean']>;
    promotion?: InputMaybe<Scalars['String']>;
};

export type SearchProductsResult = {
    __typename?: 'SearchProductsResult';
    total: Scalars['Int'];
    aggregates?: Maybe<Array<KeyValuePairOfStringAndAggregateResponse>>;
    items?: Maybe<Array<Maybe<Product>>>;
    minimumPrice: Scalars['Decimal'];
    maximumPrice: Scalars['Decimal'];
};

export type ServiceType = {
    __typename?: 'ServiceType';
    type?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    uitleendienst: Scalars['Boolean'];
};

export type SetBillingAddressParametersInput = {
    cartId?: InputMaybe<Scalars['String']>;
    customerAddressId?: InputMaybe<Scalars['String']>;
    storeType?: InputMaybe<StoreType>;
    billingAddress?: InputMaybe<UserAddressInput>;
};

export type SetDeliveryAddressParametersInput = {
    cartId?: InputMaybe<Scalars['String']>;
    customerAddressId?: InputMaybe<Scalars['String']>;
    deliveryAddress?: InputMaybe<UserAddressInput>;
    storeType?: InputMaybe<StoreType>;
};

export enum SpecialDelivery {
    Normal = 'NORMAL',
    CollectToDelivery = 'COLLECT_TO_DELIVERY',
    DeliveryToCollect = 'DELIVERY_TO_COLLECT',
}

export type StoreCart = {
    __typename?: 'StoreCart';
    storeType: StoreType;
    cartId?: Maybe<Scalars['String']>;
};

export type StoreCartInput = {
    storeType: StoreType;
    cartId?: InputMaybe<Scalars['String']>;
};

export type StoreQueryParameterInput = {
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    postalCode?: InputMaybe<Scalars['String']>;
    serviceType?: InputMaybe<StoreServiceType>;
};

export type StoreSelection = {
    __typename?: 'StoreSelection';
    total: Scalars['Int'];
    result?: Maybe<Array<Maybe<CommerceToolStores>>>;
};

export type StoreService = {
    __typename?: 'StoreService';
    id: Scalars['Int'];
    type?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    opening_Hours?: Maybe<Scalars['String']>;
    has_Rental_Store_Specialization: Scalars['Boolean'];
};

export enum StoreServiceType {
    Thuiszorgwinkel = 'THUISZORGWINKEL',
    Hoorcentrum = 'HOORCENTRUM',
    Apotheek = 'APOTHEEK',
}

export enum StoreType {
    Uld = 'ULD',
    Res = 'RES',
}

export type Suggestion = {
    __typename?: 'Suggestion';
    locale?: Maybe<Scalars['String']>;
    text?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum TransactionType {
    Sale = 'SALE',
    Rental = 'RENTAL',
    RentalOrSale = 'RENTAL_OR_SALE',
}

export type UserAddress = {
    __typename?: 'UserAddress';
    id?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    region?: Maybe<Scalars['String']>;
    streetName?: Maybe<Scalars['String']>;
    houseNumber?: Maybe<Scalars['String']>;
    box?: Maybe<Scalars['String']>;
    postCode?: Maybe<Scalars['String']>;
    postbox?: Maybe<Scalars['String']>;
    extraAddressInfo?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
};

export type UserAddressInput = {
    id?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    region?: InputMaybe<Scalars['String']>;
    streetName?: InputMaybe<Scalars['String']>;
    houseNumber?: InputMaybe<Scalars['String']>;
    box?: InputMaybe<Scalars['String']>;
    postCode?: InputMaybe<Scalars['String']>;
    postbox?: InputMaybe<Scalars['String']>;
    extraAddressInfo?: InputMaybe<Scalars['String']>;
    company?: InputMaybe<Scalars['String']>;
};

export type UserInfo = {
    __typename?: 'UserInfo';
    id?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    phone?: Maybe<Scalars['String']>;
    sex: UserSex;
    isCMMember: Scalars['Boolean'];
    deliveryAddress?: Maybe<UserAddress>;
    billingAddress?: Maybe<UserAddress>;
    stores?: Maybe<Array<Maybe<UserStore>>>;
    language?: Maybe<Scalars['String']>;
    newsLetterOptin?: Maybe<Scalars['Boolean']>;
    pharmacyOptin?: Maybe<Scalars['Boolean']>;
    hearingProtectionOptin?: Maybe<Scalars['Boolean']>;
    hearingLossOptin?: Maybe<Scalars['Boolean']>;
    homeCareOptin?: Maybe<Scalars['Boolean']>;
    actitoProfileId: Scalars['Int'];
    emailVerified: Scalars['Boolean'];
    emailReadyToChange?: Maybe<Scalars['String']>;
};

export type UserLoginResponse = {
    __typename?: 'UserLoginResponse';
    token?: Maybe<Scalars['String']>;
    carts?: Maybe<Array<Maybe<StoreCart>>>;
    user?: Maybe<UserInfo>;
};

export type UserPersonalInfoInput = {
    id?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    dateOfBirth?: InputMaybe<Scalars['DateTime']>;
    sex?: InputMaybe<UserSex>;
};

export type UserRegisterInput = {
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    sex: UserSex;
    password?: InputMaybe<Scalars['String']>;
    isCMMember?: InputMaybe<Scalars['Boolean']>;
    phone?: InputMaybe<Scalars['String']>;
};

export enum UserSex {
    Male = 'MALE',
    Female = 'FEMALE',
    NotSet = 'NOT_SET',
}

export type UserStore = {
    __typename?: 'UserStore';
    storeId?: Maybe<Scalars['String']>;
    storeName?: Maybe<Scalars['String']>;
    streetName?: Maybe<Scalars['String']>;
    houseNumber?: Maybe<Scalars['String']>;
    box?: Maybe<Scalars['String']>;
    postCode?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    storeUrl?: Maybe<Scalars['String']>;
    serverMap?: Maybe<Scalars['String']>;
    services?: Maybe<Array<Maybe<ServiceType>>>;
};

export enum VarAgeCategory {
    Baby = 'BABY',
    Child = 'CHILD',
    Adult = 'ADULT',
    Senior = 'SENIOR',
    AllAges = 'ALL_AGES',
}

export enum VarOption {
    Left = 'LEFT',
    Right = 'RIGHT',
    Single = 'SINGLE',
    Double = 'DOUBLE',
}

export enum VarSex {
    Male = 'MALE',
    Female = 'FEMALE',
    Unisex = 'UNISEX',
}

export type ProductAccessoryFragment = {
    __typename?: 'Accessory';
    type: AccessoryType;
    product?: {
        __typename?: 'Product';
        sku?: string | null;
        label?: string | null;
        priceInclTax: any;
        specialPriceInclTax?: any | null;
        slug?: string | null;
        brand?: string | null;
        images?: Array<{
            __typename?: 'ProductImage';
            url?: string | null;
            sortOrder: number;
            label?: string | null;
            dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
        } | null> | null;
        variants?: Array<{
            __typename?: 'ProductVariant';
            id?: string | null;
            variantId?: string | null;
            sku?: string | null;
            key?: string | null;
            articleName?: string | null;
            price: any;
            priceInclTax: any;
            specialPrice?: any | null;
            specialPriceInclTax?: any | null;
            slug?: string | null;
            images?: Array<{
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null> | null;
            properties?: Array<{
                __typename?: 'ProductProperty';
                name?: string | null;
                displayName?: string | null;
                intValue?: number | null;
                displayValue?: string | null;
            } | null> | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        variantOptions?: Array<{
            __typename?: 'ProductVariantProperty';
            key?: string | null;
            name?: string | null;
            values?: Array<string | null> | null;
        } | null> | null;
    } | null;
};

export type AddressFragment = {
    __typename?: 'UserAddress';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    city?: string | null;
    postCode?: string | null;
    email?: string | null;
    phone?: string | null;
    streetName?: string | null;
    houseNumber?: string | null;
    box?: string | null;
    extraAddressInfo?: string | null;
    company?: string | null;
};

export type CartFieldsFragment = {
    __typename?: 'CartTotal';
    id?: string | null;
    grandTotal: any;
    comment?: string | null;
    communicationPreference?: string | null;
    subTotal: any;
    orderNumber?: string | null;
    discountAmountInclTax: any;
    currency: Currency;
    totalQuantity: any;
    deliveryIncludingTax: any;
    paymentStatus: PaymentStatus;
    createdAt: any;
    taxAmount: any;
    isCMMember: boolean;
    deliveryCost?: any | null;
    serviceCostTotal?: any | null;
    depositAmountTotal?: any | null;
    accessoriesTotal?: any | null;
    storeType: StoreType;
    huurkoopCentAmount?: number | null;
    discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
    store?: {
        __typename?: 'UserStore';
        storeId?: string | null;
        storeName?: string | null;
        streetName?: string | null;
        houseNumber?: string | null;
        box?: string | null;
        postCode?: string | null;
        city?: string | null;
        country?: string | null;
        storeUrl?: string | null;
        services?: Array<{
            __typename?: 'ServiceType';
            type?: string | null;
            email?: string | null;
            phone?: string | null;
            uitleendienst: boolean;
        } | null> | null;
    } | null;
    items?: Array<{
        __typename?: 'CartItem';
        itemId?: string | null;
        sku?: string | null;
        quantity: any;
        name?: string | null;
        key?: string | null;
        originalPrice?: any | null;
        originalTotalPrice?: any | null;
        price: any;
        priceInclTax: any;
        discountedPrice?: any | null;
        discountedPriceInclTax?: any | null;
        totalInclTax: any;
        deliveryCost?: any | null;
        serviceCost?: any | null;
        depositAmount?: any | null;
        isPublished: boolean;
        deliveryOption?: DeliveryOption | null;
        urlPath?: string | null;
        productServiceType?: ProductServiceType | null;
        slug?: string | null;
        rentalPeriodUnit?: Period | null;
        rentalAdvance: any;
        bothDelivery: boolean;
        categories?: Array<string | null> | null;
        refundable: boolean;
        isMedicine: boolean;
        rentToOwn?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        image?: {
            __typename?: 'ProductImage';
            url?: string | null;
            sortOrder: number;
            label?: string | null;
            dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
        } | null;
        variantProperties?: Array<{
            __typename?: 'ProductVariantProperty';
            key?: string | null;
            name?: string | null;
            values?: Array<string | null> | null;
        } | null> | null;
    } | null> | null;
    groupedItems?: Array<{
        __typename?: 'GroupedItem';
        deliveryType: DeliveryOption;
        subTotalQuantity: number;
        subTotal: any;
        subTotalServiceCost?: any | null;
        subTotalAccessories?: any | null;
        subTotalDepositAmount?: any | null;
        subTotalDelivery?: any | null;
        groupTotal: any;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
    deliveryAddress?: {
        __typename?: 'UserAddress';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        city?: string | null;
        postCode?: string | null;
        email?: string | null;
        phone?: string | null;
        streetName?: string | null;
        houseNumber?: string | null;
        box?: string | null;
        extraAddressInfo?: string | null;
        company?: string | null;
    } | null;
    billingAddress?: {
        __typename?: 'UserAddress';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        city?: string | null;
        postCode?: string | null;
        email?: string | null;
        phone?: string | null;
        streetName?: string | null;
        houseNumber?: string | null;
        box?: string | null;
        extraAddressInfo?: string | null;
        company?: string | null;
    } | null;
};

export type CartItemFieldsFragment = {
    __typename?: 'CartItem';
    itemId?: string | null;
    sku?: string | null;
    quantity: any;
    name?: string | null;
    key?: string | null;
    originalPrice?: any | null;
    originalTotalPrice?: any | null;
    price: any;
    priceInclTax: any;
    discountedPrice?: any | null;
    discountedPriceInclTax?: any | null;
    totalInclTax: any;
    deliveryCost?: any | null;
    serviceCost?: any | null;
    depositAmount?: any | null;
    isPublished: boolean;
    deliveryOption?: DeliveryOption | null;
    urlPath?: string | null;
    productServiceType?: ProductServiceType | null;
    slug?: string | null;
    rentalPeriodUnit?: Period | null;
    rentalAdvance: any;
    bothDelivery: boolean;
    categories?: Array<string | null> | null;
    refundable: boolean;
    isMedicine: boolean;
    rentToOwn?: number | null;
    discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
    image?: {
        __typename?: 'ProductImage';
        url?: string | null;
        sortOrder: number;
        label?: string | null;
        dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
    } | null;
    variantProperties?: Array<{
        __typename?: 'ProductVariantProperty';
        key?: string | null;
        name?: string | null;
        values?: Array<string | null> | null;
    } | null> | null;
};

export type CategoryFieldsFragment = {
    __typename?: 'Category';
    id?: string | null;
    slug?: string | null;
    label?: string | null;
    level: number;
    parentId?: string | null;
    sortOrder: any;
    updatedAt: any;
    urlPath?: string | null;
    isActive: boolean;
    metaTitle?: string | null;
    metaDescription?: string | null;
    parents?: Array<{
        __typename?: 'CategoryParent';
        id?: string | null;
        label?: string | null;
        slug?: string | null;
        urlPath?: string | null;
    } | null> | null;
    categoryCustom?: {
        __typename?: 'CategoryCustom';
        imageUrl?: string | null;
        productCount: number;
        imageFullWidth?: string | null;
        imageSplitLeft?: string | null;
        imageSplitRight?: string | null;
    } | null;
};

export type CategoryParentFieldsFragment = {
    __typename?: 'CategoryParent';
    id?: string | null;
    label?: string | null;
    slug?: string | null;
    urlPath?: string | null;
};

export type ProductCategoryFieldsFragment = {
    __typename?: 'ProductCategory';
    id?: string | null;
    slug?: string | null;
    label?: string | null;
    primary: boolean;
    path?: string | null;
};

export type ProductFieldsFragment = {
    __typename?: 'Product';
    updatedAt: any;
    createdAt: any;
    additionalCompensation?: boolean | null;
    additionalCompensationDescription?: string | null;
    additionalProductSpecification?: string | null;
    activeIngredients?: string | null;
    productExceptionsDescription?: string | null;
    description?: string | null;
    descriptionShort?: string | null;
    depositAmount?: any | null;
    key?: string | null;
    buyable: boolean;
    visible: boolean;
    videoUrl?: Array<string | null> | null;
    minQuantity: number;
    price: any;
    priceInclTax: any;
    specialPriceInclTax?: any | null;
    deliveryCost?: any | null;
    deliveryCostCM?: any | null;
    collectionCost?: any | null;
    collectionCostCM?: any | null;
    serviceCost?: any | null;
    brand?: string | null;
    type: ProductConfigType;
    numberOfReviews: number;
    transactionType?: TransactionType | null;
    rentalPeriodUnit?: Period | null;
    rentToOwn: any;
    minimalRentalPeriod: any;
    manualUrl?: Array<string | null> | null;
    slug?: string | null;
    conservation?: string | null;
    packageQuantity?: string | null;
    indication?: string | null;
    usage?: string | null;
    prescriptionRequired?: string | null;
    leaflets?: Array<string | null> | null;
    generic?: string | null;
    accessoriesDescription?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    refundable: boolean;
    isMedicine: boolean;
    properties?: Array<{
        __typename?: 'ProductProperty';
        name?: string | null;
        displayName?: string | null;
        intValue?: number | null;
        displayValue?: string | null;
    } | null> | null;
    images?: Array<{
        __typename?: 'ProductImage';
        url?: string | null;
        sortOrder: number;
        label?: string | null;
        dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
    } | null> | null;
    accessories?: Array<{
        __typename?: 'Accessory';
        type: AccessoryType;
        product?: {
            __typename?: 'Product';
            sku?: string | null;
            label?: string | null;
            priceInclTax: any;
            specialPriceInclTax?: any | null;
            slug?: string | null;
            brand?: string | null;
            images?: Array<{
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null> | null;
            variants?: Array<{
                __typename?: 'ProductVariant';
                id?: string | null;
                variantId?: string | null;
                sku?: string | null;
                key?: string | null;
                articleName?: string | null;
                price: any;
                priceInclTax: any;
                specialPrice?: any | null;
                specialPriceInclTax?: any | null;
                slug?: string | null;
                images?: Array<{
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null> | null;
                properties?: Array<{
                    __typename?: 'ProductProperty';
                    name?: string | null;
                    displayName?: string | null;
                    intValue?: number | null;
                    displayValue?: string | null;
                } | null> | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
            variantOptions?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null;
    } | null> | null;
    variants?: Array<{
        __typename?: 'ProductVariant';
        id?: string | null;
        variantId?: string | null;
        sku?: string | null;
        key?: string | null;
        articleName?: string | null;
        price: any;
        priceInclTax: any;
        specialPrice?: any | null;
        specialPriceInclTax?: any | null;
        slug?: string | null;
        images?: Array<{
            __typename?: 'ProductImage';
            url?: string | null;
            sortOrder: number;
            label?: string | null;
            dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
        } | null> | null;
        properties?: Array<{
            __typename?: 'ProductProperty';
            name?: string | null;
            displayName?: string | null;
            intValue?: number | null;
            displayValue?: string | null;
        } | null> | null;
        variantProperties?: Array<{
            __typename?: 'ProductVariantProperty';
            key?: string | null;
            name?: string | null;
            values?: Array<string | null> | null;
        } | null> | null;
    } | null> | null;
    variantOptions?: Array<{
        __typename?: 'ProductVariantProperty';
        key?: string | null;
        name?: string | null;
        values?: Array<string | null> | null;
    } | null> | null;
    canonicalData?: {
        __typename?: 'CanonicalData';
        sku?: string | null;
        slug?: string | null;
        key?: string | null;
    } | null;
};

export type ProductImageFragment = {
    __typename?: 'ProductImage';
    url?: string | null;
    sortOrder: number;
    label?: string | null;
    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
};

export type ProductVariantFragment = {
    __typename?: 'ProductVariant';
    id?: string | null;
    variantId?: string | null;
    sku?: string | null;
    key?: string | null;
    articleName?: string | null;
    price: any;
    priceInclTax: any;
    specialPrice?: any | null;
    specialPriceInclTax?: any | null;
    slug?: string | null;
    images?: Array<{
        __typename?: 'ProductImage';
        url?: string | null;
        sortOrder: number;
        label?: string | null;
        dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
    } | null> | null;
    properties?: Array<{
        __typename?: 'ProductProperty';
        name?: string | null;
        displayName?: string | null;
        intValue?: number | null;
        displayValue?: string | null;
    } | null> | null;
    variantProperties?: Array<{
        __typename?: 'ProductVariantProperty';
        key?: string | null;
        name?: string | null;
        values?: Array<string | null> | null;
    } | null> | null;
};

export type StoreCartFieldsFragment = { __typename?: 'StoreCart'; storeType: StoreType; cartId?: string | null };

export type UserInfoFieldsFragment = {
    __typename?: 'UserInfo';
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    dateOfBirth?: any | null;
    phone?: string | null;
    sex: UserSex;
    isCMMember: boolean;
    language?: string | null;
    newsLetterOptin?: boolean | null;
    pharmacyOptin?: boolean | null;
    hearingProtectionOptin?: boolean | null;
    hearingLossOptin?: boolean | null;
    homeCareOptin?: boolean | null;
    actitoProfileId: number;
    emailVerified: boolean;
    emailReadyToChange?: string | null;
    deliveryAddress?: {
        __typename?: 'UserAddress';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        city?: string | null;
        postCode?: string | null;
        email?: string | null;
        phone?: string | null;
        streetName?: string | null;
        houseNumber?: string | null;
        box?: string | null;
        extraAddressInfo?: string | null;
        company?: string | null;
    } | null;
    billingAddress?: {
        __typename?: 'UserAddress';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        city?: string | null;
        postCode?: string | null;
        email?: string | null;
        phone?: string | null;
        streetName?: string | null;
        houseNumber?: string | null;
        box?: string | null;
        extraAddressInfo?: string | null;
        company?: string | null;
    } | null;
    stores?: Array<{
        __typename?: 'UserStore';
        storeId?: string | null;
        storeName?: string | null;
        streetName?: string | null;
        houseNumber?: string | null;
        box?: string | null;
        postCode?: string | null;
        city?: string | null;
        country?: string | null;
        storeUrl?: string | null;
        services?: Array<{
            __typename?: 'ServiceType';
            type?: string | null;
            email?: string | null;
            phone?: string | null;
            uitleendienst: boolean;
        } | null> | null;
    } | null> | null;
};

export type UserStoreFieldsFragment = {
    __typename?: 'UserStore';
    storeId?: string | null;
    storeName?: string | null;
    streetName?: string | null;
    houseNumber?: string | null;
    box?: string | null;
    postCode?: string | null;
    city?: string | null;
    country?: string | null;
    storeUrl?: string | null;
    services?: Array<{
        __typename?: 'ServiceType';
        type?: string | null;
        email?: string | null;
        phone?: string | null;
        uitleendienst: boolean;
    } | null> | null;
};

export type UserStoreServiceTypeFieldsFragment = {
    __typename?: 'ServiceType';
    type?: string | null;
    email?: string | null;
    phone?: string | null;
    uitleendienst: boolean;
};

export type VariantOptionFieldsFragment = {
    __typename?: 'ProductVariantProperty';
    key?: string | null;
    name?: string | null;
    values?: Array<string | null> | null;
};

export type AddFavoriteStoreMutationVariables = Exact<{
    storeId: Scalars['String'];
}>;

export type AddFavoriteStoreMutation = {
    __typename?: 'Mutations';
    addFavoriteStore?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type AddProductToCartMutationVariables = Exact<{
    quantity: Scalars['Int'];
    sku?: InputMaybe<Scalars['String']>;
    productServiceType: ProductServiceType;
    cartId?: InputMaybe<Scalars['String']>;
    storeType?: InputMaybe<StoreType>;
}>;

export type AddProductToCartMutation = {
    __typename?: 'Mutations';
    addProductToCart?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type ChangeCartCmMemberMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
    isCMMember: Scalars['Boolean'];
}>;

export type ChangeCartCmMemberMutation = {
    __typename?: 'Mutations';
    changeCartCMMember?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type ChangeCartDeliveryOptionsMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
    cartDeliveryOption: CartDeliveryOption;
}>;

export type ChangeCartDeliveryOptionsMutation = {
    __typename?: 'Mutations';
    changeCartDeliveryOptions?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type ChangeEmailMutationVariables = Exact<{
    password?: InputMaybe<Scalars['String']>;
    oldEmail?: InputMaybe<Scalars['String']>;
    newEmail?: InputMaybe<Scalars['String']>;
}>;

export type ChangeEmailMutation = {
    __typename?: 'Mutations';
    changeEmail?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type ChangePasswordMutationVariables = Exact<{
    newPassword?: InputMaybe<Scalars['String']>;
    currentPassword?: InputMaybe<Scalars['String']>;
}>;

export type ChangePasswordMutation = {
    __typename?: 'Mutations';
    changePassword?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type ConfirmEmailByDigitsMutationVariables = Exact<{
    customerId?: InputMaybe<Scalars['String']>;
    digits?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
}>;

export type ConfirmEmailByDigitsMutation = { __typename?: 'Mutations'; confirmEmailByDigits: boolean };

export type CreateOrderMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
    storeType?: InputMaybe<StoreType>;
}>;

export type CreateOrderMutation = {
    __typename?: 'Mutations';
    createOrder?: { __typename?: 'PaymentDetails'; redirectUrl?: string | null; cartId?: string | null } | null;
};

export type CustomerSignInMutationVariables = Exact<{
    email?: InputMaybe<Scalars['String']>;
    password?: InputMaybe<Scalars['String']>;
    anonymousCarts?: InputMaybe<Array<InputMaybe<StoreCartInput>> | InputMaybe<StoreCartInput>>;
}>;

export type CustomerSignInMutation = {
    __typename?: 'Mutations';
    customerSignIn?: {
        __typename?: 'UserLoginResponse';
        token?: string | null;
        carts?: Array<{ __typename?: 'StoreCart'; storeType: StoreType; cartId?: string | null } | null> | null;
        user?: {
            __typename?: 'UserInfo';
            id?: string | null;
            email?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            dateOfBirth?: any | null;
            phone?: string | null;
            sex: UserSex;
            isCMMember: boolean;
            language?: string | null;
            newsLetterOptin?: boolean | null;
            pharmacyOptin?: boolean | null;
            hearingProtectionOptin?: boolean | null;
            hearingLossOptin?: boolean | null;
            homeCareOptin?: boolean | null;
            actitoProfileId: number;
            emailVerified: boolean;
            emailReadyToChange?: string | null;
            deliveryAddress?: {
                __typename?: 'UserAddress';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                city?: string | null;
                postCode?: string | null;
                email?: string | null;
                phone?: string | null;
                streetName?: string | null;
                houseNumber?: string | null;
                box?: string | null;
                extraAddressInfo?: string | null;
                company?: string | null;
            } | null;
            billingAddress?: {
                __typename?: 'UserAddress';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                city?: string | null;
                postCode?: string | null;
                email?: string | null;
                phone?: string | null;
                streetName?: string | null;
                houseNumber?: string | null;
                box?: string | null;
                extraAddressInfo?: string | null;
                company?: string | null;
            } | null;
            stores?: Array<{
                __typename?: 'UserStore';
                storeId?: string | null;
                storeName?: string | null;
                streetName?: string | null;
                houseNumber?: string | null;
                box?: string | null;
                postCode?: string | null;
                city?: string | null;
                country?: string | null;
                storeUrl?: string | null;
                services?: Array<{
                    __typename?: 'ServiceType';
                    type?: string | null;
                    email?: string | null;
                    phone?: string | null;
                    uitleendienst: boolean;
                } | null> | null;
            } | null> | null;
        } | null;
    } | null;
};

export type CustomerSignUpMutationVariables = Exact<{
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    password?: InputMaybe<Scalars['String']>;
    sex: UserSex;
    anonymousCarts?: InputMaybe<Array<InputMaybe<StoreCartInput>> | InputMaybe<StoreCartInput>>;
    isCMMember?: InputMaybe<Scalars['Boolean']>;
    phone?: InputMaybe<Scalars['String']>;
}>;

export type CustomerSignUpMutation = {
    __typename?: 'Mutations';
    customerSignUp?: {
        __typename?: 'UserLoginResponse';
        token?: string | null;
        carts?: Array<{ __typename?: 'StoreCart'; storeType: StoreType; cartId?: string | null } | null> | null;
        user?: {
            __typename?: 'UserInfo';
            id?: string | null;
            email?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            dateOfBirth?: any | null;
            phone?: string | null;
            sex: UserSex;
            isCMMember: boolean;
            language?: string | null;
            newsLetterOptin?: boolean | null;
            pharmacyOptin?: boolean | null;
            hearingProtectionOptin?: boolean | null;
            hearingLossOptin?: boolean | null;
            homeCareOptin?: boolean | null;
            actitoProfileId: number;
            emailVerified: boolean;
            emailReadyToChange?: string | null;
            deliveryAddress?: {
                __typename?: 'UserAddress';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                city?: string | null;
                postCode?: string | null;
                email?: string | null;
                phone?: string | null;
                streetName?: string | null;
                houseNumber?: string | null;
                box?: string | null;
                extraAddressInfo?: string | null;
                company?: string | null;
            } | null;
            billingAddress?: {
                __typename?: 'UserAddress';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                city?: string | null;
                postCode?: string | null;
                email?: string | null;
                phone?: string | null;
                streetName?: string | null;
                houseNumber?: string | null;
                box?: string | null;
                extraAddressInfo?: string | null;
                company?: string | null;
            } | null;
            stores?: Array<{
                __typename?: 'UserStore';
                storeId?: string | null;
                storeName?: string | null;
                streetName?: string | null;
                houseNumber?: string | null;
                box?: string | null;
                postCode?: string | null;
                city?: string | null;
                country?: string | null;
                storeUrl?: string | null;
                services?: Array<{
                    __typename?: 'ServiceType';
                    type?: string | null;
                    email?: string | null;
                    phone?: string | null;
                    uitleendienst: boolean;
                } | null> | null;
            } | null> | null;
        } | null;
    } | null;
};

export type RemoveAddressMutationVariables = Exact<{
    addressId?: InputMaybe<Scalars['String']>;
}>;

export type RemoveAddressMutation = {
    __typename?: 'Mutations';
    removeAddress?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type RemoveFavoriteStoreMutationVariables = Exact<{
    storeId: Scalars['String'];
}>;

export type RemoveFavoriteStoreMutation = {
    __typename?: 'Mutations';
    removeFavoriteStore?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type RemoveProductFromCartMutationVariables = Exact<{
    lineItemId?: InputMaybe<Scalars['String']>;
    cartId?: InputMaybe<Scalars['String']>;
    storeType: StoreType;
}>;

export type RemoveProductFromCartMutation = {
    __typename?: 'Mutations';
    removeProductFromCart?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type RequestEmailConfirmDigitsMutationVariables = Exact<{
    customerId?: InputMaybe<Scalars['String']>;
}>;

export type RequestEmailConfirmDigitsMutation = { __typename?: 'Mutations'; requestEmailConfirmDigits: boolean };

export type RequestForgetPasswordTokenMutationVariables = Exact<{
    email?: InputMaybe<Scalars['String']>;
}>;

export type RequestForgetPasswordTokenMutation = { __typename?: 'Mutations'; requestForgetPasswordToken: boolean };

export type ReSetCartDeliveryCostMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
    skus?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
    specialDelivery: SpecialDelivery;
}>;

export type ReSetCartDeliveryCostMutation = {
    __typename?: 'Mutations';
    reSetCartDeliveryCost?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type ResetPasswordWithTokenMutationVariables = Exact<{
    password?: InputMaybe<Scalars['String']>;
    token?: InputMaybe<Scalars['String']>;
}>;

export type ResetPasswordWithTokenMutation = {
    __typename?: 'Mutations';
    resetPasswordWithToken?: { __typename?: 'ResetPasswordResult'; success: boolean; email?: string | null } | null;
};

export type SetBillingAddressMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
    storeType?: InputMaybe<StoreType>;
    customerAddressId?: InputMaybe<Scalars['String']>;
    billingAddress?: InputMaybe<UserAddressInput>;
}>;

export type SetBillingAddressMutation = {
    __typename?: 'Mutations';
    setBillingAddress?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type SetCommentForCartMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
    storeType?: InputMaybe<StoreType>;
    comment?: InputMaybe<Scalars['String']>;
}>;

export type SetCommentForCartMutation = {
    __typename?: 'Mutations';
    setCommentForCart?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type SetCommunicationPreferenceForResMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
    communicationPreference?: InputMaybe<Scalars['String']>;
}>;

export type SetCommunicationPreferenceForResMutation = {
    __typename?: 'Mutations';
    setCommunicationPreferenceForRES?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type SetCustomerCommunicationPreferencesMutationVariables = Exact<{
    preference?: InputMaybe<CustomerCommunicationPreferenceInput>;
}>;

export type SetCustomerCommunicationPreferencesMutation = {
    __typename?: 'Mutations';
    setCustomerCommunicationPreferences?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type SetDeliveryAddressMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
    customerAddressId?: InputMaybe<Scalars['String']>;
    deliveryAddress?: InputMaybe<UserAddressInput>;
}>;

export type SetDeliveryAddressMutation = {
    __typename?: 'Mutations';
    setDeliveryAddress?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type SetStoreForCartMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
    storeType: StoreType;
    storeId: Scalars['Int'];
}>;

export type SetStoreForCartMutation = {
    __typename?: 'Mutations';
    setStoreForCart?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type SetUserBillingAddressMutationVariables = Exact<{
    address?: InputMaybe<UserAddressInput>;
}>;

export type SetUserBillingAddressMutation = {
    __typename?: 'Mutations';
    setUserBillingAddress?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type SetUserDeliveryAddressMutationVariables = Exact<{
    address?: InputMaybe<UserAddressInput>;
}>;

export type SetUserDeliveryAddressMutation = {
    __typename?: 'Mutations';
    setUserDeliveryAddress?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type SyncCustomerProfileToCartMutationVariables = Exact<{
    cartId?: InputMaybe<Scalars['String']>;
}>;

export type SyncCustomerProfileToCartMutation = {
    __typename?: 'Mutations';
    syncCustomerProfileToCart?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type UpdateCartMutationVariables = Exact<{
    quantity: Scalars['Int'];
    lineItemId?: InputMaybe<Scalars['String']>;
    cartId?: InputMaybe<Scalars['String']>;
    storeType: StoreType;
}>;

export type UpdateCartMutation = {
    __typename?: 'Mutations';
    updateCart?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type UpdateUserPersonalInfoMutationVariables = Exact<{
    data?: InputMaybe<UserPersonalInfoInput>;
}>;

export type UpdateUserPersonalInfoMutation = {
    __typename?: 'Mutations';
    updateUserPersonalInfo?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type CartQueryVariables = Exact<{
    carts?: InputMaybe<Array<InputMaybe<CartRequestInput>> | InputMaybe<CartRequestInput>>;
}>;

export type CartQuery = {
    __typename?: 'Query';
    cart?: Array<{
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null> | null;
};

export type CategoriesRootQueryVariables = Exact<{
    storeType?: InputMaybe<StoreType>;
}>;

export type CategoriesRootQuery = {
    __typename?: 'Query';
    rootCategories?: Array<{
        __typename?: 'Category';
        id?: string | null;
        slug?: string | null;
        label?: string | null;
        level: number;
        parentId?: string | null;
        sortOrder: any;
        updatedAt: any;
        urlPath?: string | null;
        isActive: boolean;
        metaTitle?: string | null;
        metaDescription?: string | null;
        children?: Array<{
            __typename?: 'Category';
            id?: string | null;
            slug?: string | null;
            label?: string | null;
            level: number;
            parentId?: string | null;
            sortOrder: any;
            updatedAt: any;
            urlPath?: string | null;
            isActive: boolean;
            metaTitle?: string | null;
            metaDescription?: string | null;
            children?: Array<{
                __typename?: 'Category';
                id?: string | null;
                slug?: string | null;
                label?: string | null;
                level: number;
                parentId?: string | null;
                sortOrder: any;
                updatedAt: any;
                urlPath?: string | null;
                isActive: boolean;
                metaTitle?: string | null;
                metaDescription?: string | null;
                parents?: Array<{
                    __typename?: 'CategoryParent';
                    id?: string | null;
                    label?: string | null;
                    slug?: string | null;
                    urlPath?: string | null;
                } | null> | null;
                categoryCustom?: {
                    __typename?: 'CategoryCustom';
                    imageUrl?: string | null;
                    productCount: number;
                    imageFullWidth?: string | null;
                    imageSplitLeft?: string | null;
                    imageSplitRight?: string | null;
                } | null;
            } | null> | null;
            parents?: Array<{
                __typename?: 'CategoryParent';
                id?: string | null;
                label?: string | null;
                slug?: string | null;
                urlPath?: string | null;
            } | null> | null;
            categoryCustom?: {
                __typename?: 'CategoryCustom';
                imageUrl?: string | null;
                productCount: number;
                imageFullWidth?: string | null;
                imageSplitLeft?: string | null;
                imageSplitRight?: string | null;
            } | null;
        } | null> | null;
        parents?: Array<{
            __typename?: 'CategoryParent';
            id?: string | null;
            label?: string | null;
            slug?: string | null;
            urlPath?: string | null;
        } | null> | null;
        categoryCustom?: {
            __typename?: 'CategoryCustom';
            imageUrl?: string | null;
            productCount: number;
            imageFullWidth?: string | null;
            imageSplitLeft?: string | null;
            imageSplitRight?: string | null;
        } | null;
    } | null> | null;
};

export type CategoryByIdQueryVariables = Exact<{
    storeType?: InputMaybe<StoreType>;
    id?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type CategoryByIdQuery = {
    __typename?: 'Query';
    categories?: {
        __typename?: 'SearchCategoriesResult';
        items?: Array<{
            __typename?: 'Category';
            id?: string | null;
            parentId?: string | null;
            label?: string | null;
            level: number;
            slug?: string | null;
            sortOrder: any;
            isActive: boolean;
            updatedAt: any;
        } | null> | null;
    } | null;
};

export type CategoryBySlugQueryVariables = Exact<{
    storeType?: InputMaybe<StoreType>;
    slug: Scalars['String'];
}>;

export type CategoryBySlugQuery = {
    __typename?: 'Query';
    categories?: {
        __typename?: 'SearchCategoriesResult';
        total: number;
        items?: Array<{
            __typename?: 'Category';
            id?: string | null;
            label?: string | null;
            level: number;
            slug?: string | null;
            metaDescription?: string | null;
            metaTitle?: string | null;
            isActive: boolean;
            sortOrder: any;
            updatedAt: any;
            parentId?: string | null;
            parents?: Array<{
                __typename?: 'CategoryParent';
                label?: string | null;
                slug?: string | null;
            } | null> | null;
            children?: Array<{
                __typename?: 'Category';
                id?: string | null;
                label?: string | null;
                level: number;
                slug?: string | null;
                parentId?: string | null;
            } | null> | null;
            categoryCustom?: {
                __typename?: 'CategoryCustom';
                imageUrl?: string | null;
                productCount: number;
                imageFullWidth?: string | null;
                imageSplitLeft?: string | null;
                imageSplitRight?: string | null;
            } | null;
        } | null> | null;
    } | null;
};

export type CheckUserQueryVariables = Exact<{
    email?: InputMaybe<Scalars['String']>;
}>;

export type CheckUserQuery = { __typename?: 'Query'; checkUser: boolean };

export type CustomerOrdersQueryVariables = Exact<{
    skip: Scalars['Int'];
    take: Scalars['Int'];
    sort?: InputMaybe<CustomerSort>;
}>;

export type CustomerOrdersQuery = {
    __typename?: 'Query';
    customerOrders?: {
        __typename?: 'PagedResultsOfCartTotal';
        total: number;
        totalNumberOfPages: number;
        pageSize: number;
        pageNumber: number;
        items?: Array<{
            __typename?: 'CartTotal';
            id?: string | null;
            grandTotal: any;
            comment?: string | null;
            communicationPreference?: string | null;
            subTotal: any;
            orderNumber?: string | null;
            discountAmountInclTax: any;
            currency: Currency;
            totalQuantity: any;
            deliveryIncludingTax: any;
            paymentStatus: PaymentStatus;
            createdAt: any;
            taxAmount: any;
            isCMMember: boolean;
            deliveryCost?: any | null;
            serviceCostTotal?: any | null;
            depositAmountTotal?: any | null;
            accessoriesTotal?: any | null;
            storeType: StoreType;
            huurkoopCentAmount?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            store?: {
                __typename?: 'UserStore';
                storeId?: string | null;
                storeName?: string | null;
                streetName?: string | null;
                houseNumber?: string | null;
                box?: string | null;
                postCode?: string | null;
                city?: string | null;
                country?: string | null;
                storeUrl?: string | null;
                services?: Array<{
                    __typename?: 'ServiceType';
                    type?: string | null;
                    email?: string | null;
                    phone?: string | null;
                    uitleendienst: boolean;
                } | null> | null;
            } | null;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
            groupedItems?: Array<{
                __typename?: 'GroupedItem';
                deliveryType: DeliveryOption;
                subTotalQuantity: number;
                subTotal: any;
                subTotalServiceCost?: any | null;
                subTotalAccessories?: any | null;
                subTotalDepositAmount?: any | null;
                subTotalDelivery?: any | null;
                groupTotal: any;
                items?: Array<{
                    __typename?: 'CartItem';
                    itemId?: string | null;
                    sku?: string | null;
                    quantity: any;
                    name?: string | null;
                    key?: string | null;
                    originalPrice?: any | null;
                    originalTotalPrice?: any | null;
                    price: any;
                    priceInclTax: any;
                    discountedPrice?: any | null;
                    discountedPriceInclTax?: any | null;
                    totalInclTax: any;
                    deliveryCost?: any | null;
                    serviceCost?: any | null;
                    depositAmount?: any | null;
                    isPublished: boolean;
                    deliveryOption?: DeliveryOption | null;
                    urlPath?: string | null;
                    productServiceType?: ProductServiceType | null;
                    slug?: string | null;
                    rentalPeriodUnit?: Period | null;
                    rentalAdvance: any;
                    bothDelivery: boolean;
                    categories?: Array<string | null> | null;
                    refundable: boolean;
                    isMedicine: boolean;
                    rentToOwn?: number | null;
                    discountsPerCode?: Array<{
                        __typename?: 'KeyValuePairOfStringAndDecimal';
                        key: string;
                        value: any;
                    }> | null;
                    image?: {
                        __typename?: 'ProductImage';
                        url?: string | null;
                        sortOrder: number;
                        label?: string | null;
                        dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                    } | null;
                    variantProperties?: Array<{
                        __typename?: 'ProductVariantProperty';
                        key?: string | null;
                        name?: string | null;
                        values?: Array<string | null> | null;
                    } | null> | null;
                } | null> | null;
            } | null> | null;
            deliveryAddress?: {
                __typename?: 'UserAddress';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                city?: string | null;
                postCode?: string | null;
                email?: string | null;
                phone?: string | null;
                streetName?: string | null;
                houseNumber?: string | null;
                box?: string | null;
                extraAddressInfo?: string | null;
                company?: string | null;
            } | null;
            billingAddress?: {
                __typename?: 'UserAddress';
                id?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                city?: string | null;
                postCode?: string | null;
                email?: string | null;
                phone?: string | null;
                streetName?: string | null;
                houseNumber?: string | null;
                box?: string | null;
                extraAddressInfo?: string | null;
                company?: string | null;
            } | null;
        } | null> | null;
    } | null;
};

export type CustomerOrdersByIdQueryVariables = Exact<{
    orderId?: InputMaybe<Scalars['String']>;
}>;

export type CustomerOrdersByIdQuery = {
    __typename?: 'Query';
    customerOrdersById?: {
        __typename?: 'CartTotal';
        createdAt: any;
        orderNumber?: string | null;
        grandTotal: any;
        discountAmountInclTax: any;
        deliveryIncludingTax: any;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type OrderForCartIdQueryVariables = Exact<{
    cartId: Scalars['String'];
}>;

export type OrderForCartIdQuery = {
    __typename?: 'Query';
    orderForCartId?: {
        __typename?: 'CartTotal';
        id?: string | null;
        grandTotal: any;
        comment?: string | null;
        communicationPreference?: string | null;
        subTotal: any;
        orderNumber?: string | null;
        discountAmountInclTax: any;
        currency: Currency;
        totalQuantity: any;
        deliveryIncludingTax: any;
        paymentStatus: PaymentStatus;
        createdAt: any;
        taxAmount: any;
        isCMMember: boolean;
        deliveryCost?: any | null;
        serviceCostTotal?: any | null;
        depositAmountTotal?: any | null;
        accessoriesTotal?: any | null;
        storeType: StoreType;
        huurkoopCentAmount?: number | null;
        discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
        store?: {
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null;
        items?: Array<{
            __typename?: 'CartItem';
            itemId?: string | null;
            sku?: string | null;
            quantity: any;
            name?: string | null;
            key?: string | null;
            originalPrice?: any | null;
            originalTotalPrice?: any | null;
            price: any;
            priceInclTax: any;
            discountedPrice?: any | null;
            discountedPriceInclTax?: any | null;
            totalInclTax: any;
            deliveryCost?: any | null;
            serviceCost?: any | null;
            depositAmount?: any | null;
            isPublished: boolean;
            deliveryOption?: DeliveryOption | null;
            urlPath?: string | null;
            productServiceType?: ProductServiceType | null;
            slug?: string | null;
            rentalPeriodUnit?: Period | null;
            rentalAdvance: any;
            bothDelivery: boolean;
            categories?: Array<string | null> | null;
            refundable: boolean;
            isMedicine: boolean;
            rentToOwn?: number | null;
            discountsPerCode?: Array<{ __typename?: 'KeyValuePairOfStringAndDecimal'; key: string; value: any }> | null;
            image?: {
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null;
            variantProperties?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
        } | null> | null;
        groupedItems?: Array<{
            __typename?: 'GroupedItem';
            deliveryType: DeliveryOption;
            subTotalQuantity: number;
            subTotal: any;
            subTotalServiceCost?: any | null;
            subTotalAccessories?: any | null;
            subTotalDepositAmount?: any | null;
            subTotalDelivery?: any | null;
            groupTotal: any;
            items?: Array<{
                __typename?: 'CartItem';
                itemId?: string | null;
                sku?: string | null;
                quantity: any;
                name?: string | null;
                key?: string | null;
                originalPrice?: any | null;
                originalTotalPrice?: any | null;
                price: any;
                priceInclTax: any;
                discountedPrice?: any | null;
                discountedPriceInclTax?: any | null;
                totalInclTax: any;
                deliveryCost?: any | null;
                serviceCost?: any | null;
                depositAmount?: any | null;
                isPublished: boolean;
                deliveryOption?: DeliveryOption | null;
                urlPath?: string | null;
                productServiceType?: ProductServiceType | null;
                slug?: string | null;
                rentalPeriodUnit?: Period | null;
                rentalAdvance: any;
                bothDelivery: boolean;
                categories?: Array<string | null> | null;
                refundable: boolean;
                isMedicine: boolean;
                rentToOwn?: number | null;
                discountsPerCode?: Array<{
                    __typename?: 'KeyValuePairOfStringAndDecimal';
                    key: string;
                    value: any;
                }> | null;
                image?: {
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
    } | null;
};

export type ProductProjectionSuggestQueryVariables = Exact<{
    limit: Scalars['Int'];
    filter: Scalars['String'];
    fuzzy: Scalars['Boolean'];
    storeType: StoreType;
}>;

export type ProductProjectionSuggestQuery = {
    __typename?: 'Query';
    productProjectionSuggest?: {
        __typename?: 'SearchProductSuggestionsResult';
        total: number;
        products?: Array<{
            __typename?: 'KeyValuePairOfStringAndListOfProduct';
            key: string;
            value: Array<{
                __typename?: 'Product';
                id?: string | null;
                label?: string | null;
                sku?: string | null;
                slug?: string | null;
                description?: string | null;
                image?: string | null;
                price: any;
                priceInclTax: any;
                urlPath?: string | null;
                categories?: Array<{
                    __typename?: 'ProductCategory';
                    id?: string | null;
                    label?: string | null;
                    path?: string | null;
                    slug?: string | null;
                    primary: boolean;
                } | null> | null;
            }>;
        }> | null;
        suggestions?: Array<{
            __typename?: 'Suggestion';
            locale?: string | null;
            text?: Array<string | null> | null;
        } | null> | null;
    } | null;
};

export type ProductsBySkuQueryVariables = Exact<{
    sku?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    storeType?: InputMaybe<StoreType>;
}>;

export type ProductsBySkuQuery = {
    __typename?: 'Query';
    products?: {
        __typename?: 'SearchProductsResult';
        total: number;
        items?: Array<{
            __typename?: 'Product';
            id?: string | null;
            sku?: string | null;
            label?: string | null;
            stock?: any | null;
            deliveryOption?: DeliveryOption | null;
            assemblyOption?: AssemblyOption | null;
            depositAmount?: any | null;
            rentalPeriodUnit?: Period | null;
            serviceCost?: any | null;
            rentToOwn: any;
            updatedAt: any;
            createdAt: any;
            additionalCompensation?: boolean | null;
            additionalCompensationDescription?: string | null;
            additionalProductSpecification?: string | null;
            activeIngredients?: string | null;
            productExceptionsDescription?: string | null;
            description?: string | null;
            descriptionShort?: string | null;
            key?: string | null;
            buyable: boolean;
            visible: boolean;
            videoUrl?: Array<string | null> | null;
            minQuantity: number;
            price: any;
            priceInclTax: any;
            specialPriceInclTax?: any | null;
            deliveryCost?: any | null;
            deliveryCostCM?: any | null;
            collectionCost?: any | null;
            collectionCostCM?: any | null;
            brand?: string | null;
            type: ProductConfigType;
            numberOfReviews: number;
            transactionType?: TransactionType | null;
            minimalRentalPeriod: any;
            manualUrl?: Array<string | null> | null;
            slug?: string | null;
            conservation?: string | null;
            packageQuantity?: string | null;
            indication?: string | null;
            usage?: string | null;
            prescriptionRequired?: string | null;
            leaflets?: Array<string | null> | null;
            generic?: string | null;
            accessoriesDescription?: string | null;
            metaTitle?: string | null;
            metaDescription?: string | null;
            refundable: boolean;
            isMedicine: boolean;
            categories?: Array<{
                __typename?: 'ProductCategory';
                id?: string | null;
                slug?: string | null;
                label?: string | null;
                primary: boolean;
                path?: string | null;
            } | null> | null;
            properties?: Array<{
                __typename?: 'ProductProperty';
                name?: string | null;
                displayName?: string | null;
                intValue?: number | null;
                displayValue?: string | null;
            } | null> | null;
            images?: Array<{
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null> | null;
            accessories?: Array<{
                __typename?: 'Accessory';
                type: AccessoryType;
                product?: {
                    __typename?: 'Product';
                    sku?: string | null;
                    label?: string | null;
                    priceInclTax: any;
                    specialPriceInclTax?: any | null;
                    slug?: string | null;
                    brand?: string | null;
                    images?: Array<{
                        __typename?: 'ProductImage';
                        url?: string | null;
                        sortOrder: number;
                        label?: string | null;
                        dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                    } | null> | null;
                    variants?: Array<{
                        __typename?: 'ProductVariant';
                        id?: string | null;
                        variantId?: string | null;
                        sku?: string | null;
                        key?: string | null;
                        articleName?: string | null;
                        price: any;
                        priceInclTax: any;
                        specialPrice?: any | null;
                        specialPriceInclTax?: any | null;
                        slug?: string | null;
                        images?: Array<{
                            __typename?: 'ProductImage';
                            url?: string | null;
                            sortOrder: number;
                            label?: string | null;
                            dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                        } | null> | null;
                        properties?: Array<{
                            __typename?: 'ProductProperty';
                            name?: string | null;
                            displayName?: string | null;
                            intValue?: number | null;
                            displayValue?: string | null;
                        } | null> | null;
                        variantProperties?: Array<{
                            __typename?: 'ProductVariantProperty';
                            key?: string | null;
                            name?: string | null;
                            values?: Array<string | null> | null;
                        } | null> | null;
                    } | null> | null;
                    variantOptions?: Array<{
                        __typename?: 'ProductVariantProperty';
                        key?: string | null;
                        name?: string | null;
                        values?: Array<string | null> | null;
                    } | null> | null;
                } | null;
            } | null> | null;
            variants?: Array<{
                __typename?: 'ProductVariant';
                id?: string | null;
                variantId?: string | null;
                sku?: string | null;
                key?: string | null;
                articleName?: string | null;
                price: any;
                priceInclTax: any;
                specialPrice?: any | null;
                specialPriceInclTax?: any | null;
                slug?: string | null;
                images?: Array<{
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null> | null;
                properties?: Array<{
                    __typename?: 'ProductProperty';
                    name?: string | null;
                    displayName?: string | null;
                    intValue?: number | null;
                    displayValue?: string | null;
                } | null> | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
            variantOptions?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
            canonicalData?: {
                __typename?: 'CanonicalData';
                sku?: string | null;
                slug?: string | null;
                key?: string | null;
            } | null;
        } | null> | null;
    } | null;
};

export type ProductsByStringQueryVariables = Exact<{
    take: Scalars['Int'];
    filter: Scalars['String'];
    storeType?: InputMaybe<StoreType>;
}>;

export type ProductsByStringQuery = {
    __typename?: 'Query';
    products?: {
        __typename?: 'SearchProductsResult';
        total: number;
        items?: Array<{
            __typename?: 'Product';
            id?: string | null;
            sku?: string | null;
            label?: string | null;
            image?: string | null;
            updatedAt: any;
            createdAt: any;
            additionalCompensation?: boolean | null;
            additionalCompensationDescription?: string | null;
            additionalProductSpecification?: string | null;
            activeIngredients?: string | null;
            productExceptionsDescription?: string | null;
            description?: string | null;
            descriptionShort?: string | null;
            depositAmount?: any | null;
            key?: string | null;
            buyable: boolean;
            visible: boolean;
            videoUrl?: Array<string | null> | null;
            minQuantity: number;
            price: any;
            priceInclTax: any;
            specialPriceInclTax?: any | null;
            deliveryCost?: any | null;
            deliveryCostCM?: any | null;
            collectionCost?: any | null;
            collectionCostCM?: any | null;
            serviceCost?: any | null;
            brand?: string | null;
            type: ProductConfigType;
            numberOfReviews: number;
            transactionType?: TransactionType | null;
            rentalPeriodUnit?: Period | null;
            rentToOwn: any;
            minimalRentalPeriod: any;
            manualUrl?: Array<string | null> | null;
            slug?: string | null;
            conservation?: string | null;
            packageQuantity?: string | null;
            indication?: string | null;
            usage?: string | null;
            prescriptionRequired?: string | null;
            leaflets?: Array<string | null> | null;
            generic?: string | null;
            accessoriesDescription?: string | null;
            metaTitle?: string | null;
            metaDescription?: string | null;
            refundable: boolean;
            isMedicine: boolean;
            properties?: Array<{
                __typename?: 'ProductProperty';
                name?: string | null;
                displayName?: string | null;
                intValue?: number | null;
                displayValue?: string | null;
            } | null> | null;
            images?: Array<{
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null> | null;
            accessories?: Array<{
                __typename?: 'Accessory';
                type: AccessoryType;
                product?: {
                    __typename?: 'Product';
                    sku?: string | null;
                    label?: string | null;
                    priceInclTax: any;
                    specialPriceInclTax?: any | null;
                    slug?: string | null;
                    brand?: string | null;
                    images?: Array<{
                        __typename?: 'ProductImage';
                        url?: string | null;
                        sortOrder: number;
                        label?: string | null;
                        dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                    } | null> | null;
                    variants?: Array<{
                        __typename?: 'ProductVariant';
                        id?: string | null;
                        variantId?: string | null;
                        sku?: string | null;
                        key?: string | null;
                        articleName?: string | null;
                        price: any;
                        priceInclTax: any;
                        specialPrice?: any | null;
                        specialPriceInclTax?: any | null;
                        slug?: string | null;
                        images?: Array<{
                            __typename?: 'ProductImage';
                            url?: string | null;
                            sortOrder: number;
                            label?: string | null;
                            dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                        } | null> | null;
                        properties?: Array<{
                            __typename?: 'ProductProperty';
                            name?: string | null;
                            displayName?: string | null;
                            intValue?: number | null;
                            displayValue?: string | null;
                        } | null> | null;
                        variantProperties?: Array<{
                            __typename?: 'ProductVariantProperty';
                            key?: string | null;
                            name?: string | null;
                            values?: Array<string | null> | null;
                        } | null> | null;
                    } | null> | null;
                    variantOptions?: Array<{
                        __typename?: 'ProductVariantProperty';
                        key?: string | null;
                        name?: string | null;
                        values?: Array<string | null> | null;
                    } | null> | null;
                } | null;
            } | null> | null;
            variants?: Array<{
                __typename?: 'ProductVariant';
                id?: string | null;
                variantId?: string | null;
                sku?: string | null;
                key?: string | null;
                articleName?: string | null;
                price: any;
                priceInclTax: any;
                specialPrice?: any | null;
                specialPriceInclTax?: any | null;
                slug?: string | null;
                images?: Array<{
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null> | null;
                properties?: Array<{
                    __typename?: 'ProductProperty';
                    name?: string | null;
                    displayName?: string | null;
                    intValue?: number | null;
                    displayValue?: string | null;
                } | null> | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
            variantOptions?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
            canonicalData?: {
                __typename?: 'CanonicalData';
                sku?: string | null;
                slug?: string | null;
                key?: string | null;
            } | null;
        } | null> | null;
    } | null;
};

export type ProductsForPathQueryVariables = Exact<{
    take: Scalars['Int'];
    skip: Scalars['Int'];
    storeType?: InputMaybe<StoreType>;
}>;

export type ProductsForPathQuery = {
    __typename?: 'Query';
    products?: {
        __typename?: 'SearchProductsResult';
        total: number;
        items?: Array<{
            __typename?: 'Product';
            id?: string | null;
            sku?: string | null;
            updatedAt: any;
            createdAt: any;
            additionalCompensation?: boolean | null;
            additionalCompensationDescription?: string | null;
            additionalProductSpecification?: string | null;
            activeIngredients?: string | null;
            productExceptionsDescription?: string | null;
            description?: string | null;
            descriptionShort?: string | null;
            depositAmount?: any | null;
            key?: string | null;
            buyable: boolean;
            visible: boolean;
            videoUrl?: Array<string | null> | null;
            minQuantity: number;
            price: any;
            priceInclTax: any;
            specialPriceInclTax?: any | null;
            deliveryCost?: any | null;
            deliveryCostCM?: any | null;
            collectionCost?: any | null;
            collectionCostCM?: any | null;
            serviceCost?: any | null;
            brand?: string | null;
            type: ProductConfigType;
            numberOfReviews: number;
            transactionType?: TransactionType | null;
            rentalPeriodUnit?: Period | null;
            rentToOwn: any;
            minimalRentalPeriod: any;
            manualUrl?: Array<string | null> | null;
            slug?: string | null;
            conservation?: string | null;
            packageQuantity?: string | null;
            indication?: string | null;
            usage?: string | null;
            prescriptionRequired?: string | null;
            leaflets?: Array<string | null> | null;
            generic?: string | null;
            accessoriesDescription?: string | null;
            metaTitle?: string | null;
            metaDescription?: string | null;
            refundable: boolean;
            isMedicine: boolean;
            properties?: Array<{
                __typename?: 'ProductProperty';
                name?: string | null;
                displayName?: string | null;
                intValue?: number | null;
                displayValue?: string | null;
            } | null> | null;
            images?: Array<{
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null> | null;
            accessories?: Array<{
                __typename?: 'Accessory';
                type: AccessoryType;
                product?: {
                    __typename?: 'Product';
                    sku?: string | null;
                    label?: string | null;
                    priceInclTax: any;
                    specialPriceInclTax?: any | null;
                    slug?: string | null;
                    brand?: string | null;
                    images?: Array<{
                        __typename?: 'ProductImage';
                        url?: string | null;
                        sortOrder: number;
                        label?: string | null;
                        dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                    } | null> | null;
                    variants?: Array<{
                        __typename?: 'ProductVariant';
                        id?: string | null;
                        variantId?: string | null;
                        sku?: string | null;
                        key?: string | null;
                        articleName?: string | null;
                        price: any;
                        priceInclTax: any;
                        specialPrice?: any | null;
                        specialPriceInclTax?: any | null;
                        slug?: string | null;
                        images?: Array<{
                            __typename?: 'ProductImage';
                            url?: string | null;
                            sortOrder: number;
                            label?: string | null;
                            dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                        } | null> | null;
                        properties?: Array<{
                            __typename?: 'ProductProperty';
                            name?: string | null;
                            displayName?: string | null;
                            intValue?: number | null;
                            displayValue?: string | null;
                        } | null> | null;
                        variantProperties?: Array<{
                            __typename?: 'ProductVariantProperty';
                            key?: string | null;
                            name?: string | null;
                            values?: Array<string | null> | null;
                        } | null> | null;
                    } | null> | null;
                    variantOptions?: Array<{
                        __typename?: 'ProductVariantProperty';
                        key?: string | null;
                        name?: string | null;
                        values?: Array<string | null> | null;
                    } | null> | null;
                } | null;
            } | null> | null;
            variants?: Array<{
                __typename?: 'ProductVariant';
                id?: string | null;
                variantId?: string | null;
                sku?: string | null;
                key?: string | null;
                articleName?: string | null;
                price: any;
                priceInclTax: any;
                specialPrice?: any | null;
                specialPriceInclTax?: any | null;
                slug?: string | null;
                images?: Array<{
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null> | null;
                properties?: Array<{
                    __typename?: 'ProductProperty';
                    name?: string | null;
                    displayName?: string | null;
                    intValue?: number | null;
                    displayValue?: string | null;
                } | null> | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
            variantOptions?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
            canonicalData?: {
                __typename?: 'CanonicalData';
                sku?: string | null;
                slug?: string | null;
                key?: string | null;
            } | null;
        } | null> | null;
    } | null;
};

export type ProductsOverviewQueryVariables = Exact<{
    take: Scalars['Int'];
    skip: Scalars['Int'];
    sort?: InputMaybe<ProductSort>;
    propertyFilters?: InputMaybe<
        Array<KeyValuePairOfStringAndListOfStringInput> | KeyValuePairOfStringAndListOfStringInput
    >;
    categoryId: Scalars['String'];
    filter: Scalars['String'];
    storeType?: InputMaybe<StoreType>;
}>;

export type ProductsOverviewQuery = {
    __typename?: 'Query';
    products?: {
        __typename?: 'SearchProductsResult';
        total: number;
        items?: Array<{
            __typename?: 'Product';
            id?: string | null;
            label?: string | null;
            sku?: string | null;
            type: ProductConfigType;
            priceInclTax: any;
            descriptionShort?: string | null;
            specialPriceInclTax?: any | null;
            image?: string | null;
            stock?: any | null;
            createdAt: any;
            brand?: string | null;
            updatedAt: any;
            additionalCompensation?: boolean | null;
            additionalCompensationDescription?: string | null;
            additionalProductSpecification?: string | null;
            activeIngredients?: string | null;
            productExceptionsDescription?: string | null;
            description?: string | null;
            depositAmount?: any | null;
            key?: string | null;
            buyable: boolean;
            visible: boolean;
            videoUrl?: Array<string | null> | null;
            minQuantity: number;
            price: any;
            deliveryCost?: any | null;
            deliveryCostCM?: any | null;
            collectionCost?: any | null;
            collectionCostCM?: any | null;
            serviceCost?: any | null;
            numberOfReviews: number;
            transactionType?: TransactionType | null;
            rentalPeriodUnit?: Period | null;
            rentToOwn: any;
            minimalRentalPeriod: any;
            manualUrl?: Array<string | null> | null;
            slug?: string | null;
            conservation?: string | null;
            packageQuantity?: string | null;
            indication?: string | null;
            usage?: string | null;
            prescriptionRequired?: string | null;
            leaflets?: Array<string | null> | null;
            generic?: string | null;
            accessoriesDescription?: string | null;
            metaTitle?: string | null;
            metaDescription?: string | null;
            refundable: boolean;
            isMedicine: boolean;
            lastModifiedAt: any;
            categories?: Array<{
                __typename?: 'ProductCategory';
                id?: string | null;
                slug?: string | null;
                label?: string | null;
                primary: boolean;
                path?: string | null;
            } | null> | null;
            properties?: Array<{
                __typename?: 'ProductProperty';
                name?: string | null;
                displayName?: string | null;
                intValue?: number | null;
                displayValue?: string | null;
            } | null> | null;
            images?: Array<{
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null> | null;
            accessories?: Array<{
                __typename?: 'Accessory';
                type: AccessoryType;
                product?: {
                    __typename?: 'Product';
                    sku?: string | null;
                    label?: string | null;
                    priceInclTax: any;
                    specialPriceInclTax?: any | null;
                    slug?: string | null;
                    brand?: string | null;
                    images?: Array<{
                        __typename?: 'ProductImage';
                        url?: string | null;
                        sortOrder: number;
                        label?: string | null;
                        dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                    } | null> | null;
                    variants?: Array<{
                        __typename?: 'ProductVariant';
                        id?: string | null;
                        variantId?: string | null;
                        sku?: string | null;
                        key?: string | null;
                        articleName?: string | null;
                        price: any;
                        priceInclTax: any;
                        specialPrice?: any | null;
                        specialPriceInclTax?: any | null;
                        slug?: string | null;
                        images?: Array<{
                            __typename?: 'ProductImage';
                            url?: string | null;
                            sortOrder: number;
                            label?: string | null;
                            dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                        } | null> | null;
                        properties?: Array<{
                            __typename?: 'ProductProperty';
                            name?: string | null;
                            displayName?: string | null;
                            intValue?: number | null;
                            displayValue?: string | null;
                        } | null> | null;
                        variantProperties?: Array<{
                            __typename?: 'ProductVariantProperty';
                            key?: string | null;
                            name?: string | null;
                            values?: Array<string | null> | null;
                        } | null> | null;
                    } | null> | null;
                    variantOptions?: Array<{
                        __typename?: 'ProductVariantProperty';
                        key?: string | null;
                        name?: string | null;
                        values?: Array<string | null> | null;
                    } | null> | null;
                } | null;
            } | null> | null;
            variants?: Array<{
                __typename?: 'ProductVariant';
                id?: string | null;
                variantId?: string | null;
                sku?: string | null;
                key?: string | null;
                articleName?: string | null;
                price: any;
                priceInclTax: any;
                specialPrice?: any | null;
                specialPriceInclTax?: any | null;
                slug?: string | null;
                images?: Array<{
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null> | null;
                properties?: Array<{
                    __typename?: 'ProductProperty';
                    name?: string | null;
                    displayName?: string | null;
                    intValue?: number | null;
                    displayValue?: string | null;
                } | null> | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
            variantOptions?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
            canonicalData?: {
                __typename?: 'CanonicalData';
                sku?: string | null;
                slug?: string | null;
                key?: string | null;
            } | null;
        } | null> | null;
        aggregates?: Array<{
            __typename?: 'KeyValuePairOfStringAndAggregateResponse';
            key: string;
            value: {
                __typename?: 'AggregateResponse';
                aggregateName?: string | null;
                otherDocCount: any;
                errorUpperBound: any;
                buckets?: Array<{
                    __typename?: 'AggregateResponseBucket';
                    key: number;
                    filterKey?: string | null;
                    count: any;
                } | null> | null;
            };
        }> | null;
    } | null;
};

export type ProductsPromotionQueryVariables = Exact<{
    storeType?: InputMaybe<StoreType>;
}>;

export type ProductsPromotionQuery = {
    __typename?: 'Query';
    products?: {
        __typename?: 'SearchProductsResult';
        total: number;
        items?: Array<{
            __typename?: 'Product';
            id?: string | null;
            label?: string | null;
            sku?: string | null;
            type: ProductConfigType;
            priceInclTax: any;
            descriptionShort?: string | null;
            specialPriceInclTax?: any | null;
            image?: string | null;
            stock?: any | null;
            createdAt: any;
            brand?: string | null;
            updatedAt: any;
            additionalCompensation?: boolean | null;
            additionalCompensationDescription?: string | null;
            additionalProductSpecification?: string | null;
            activeIngredients?: string | null;
            productExceptionsDescription?: string | null;
            description?: string | null;
            depositAmount?: any | null;
            key?: string | null;
            buyable: boolean;
            visible: boolean;
            videoUrl?: Array<string | null> | null;
            minQuantity: number;
            price: any;
            deliveryCost?: any | null;
            deliveryCostCM?: any | null;
            collectionCost?: any | null;
            collectionCostCM?: any | null;
            serviceCost?: any | null;
            numberOfReviews: number;
            transactionType?: TransactionType | null;
            rentalPeriodUnit?: Period | null;
            rentToOwn: any;
            minimalRentalPeriod: any;
            manualUrl?: Array<string | null> | null;
            slug?: string | null;
            conservation?: string | null;
            packageQuantity?: string | null;
            indication?: string | null;
            usage?: string | null;
            prescriptionRequired?: string | null;
            leaflets?: Array<string | null> | null;
            generic?: string | null;
            accessoriesDescription?: string | null;
            metaTitle?: string | null;
            metaDescription?: string | null;
            refundable: boolean;
            isMedicine: boolean;
            lastModifiedAt: any;
            categories?: Array<{
                __typename?: 'ProductCategory';
                id?: string | null;
                slug?: string | null;
                label?: string | null;
                primary: boolean;
                path?: string | null;
            } | null> | null;
            properties?: Array<{
                __typename?: 'ProductProperty';
                name?: string | null;
                displayName?: string | null;
                intValue?: number | null;
                displayValue?: string | null;
            } | null> | null;
            images?: Array<{
                __typename?: 'ProductImage';
                url?: string | null;
                sortOrder: number;
                label?: string | null;
                dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
            } | null> | null;
            accessories?: Array<{
                __typename?: 'Accessory';
                type: AccessoryType;
                product?: {
                    __typename?: 'Product';
                    sku?: string | null;
                    label?: string | null;
                    priceInclTax: any;
                    specialPriceInclTax?: any | null;
                    slug?: string | null;
                    brand?: string | null;
                    images?: Array<{
                        __typename?: 'ProductImage';
                        url?: string | null;
                        sortOrder: number;
                        label?: string | null;
                        dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                    } | null> | null;
                    variants?: Array<{
                        __typename?: 'ProductVariant';
                        id?: string | null;
                        variantId?: string | null;
                        sku?: string | null;
                        key?: string | null;
                        articleName?: string | null;
                        price: any;
                        priceInclTax: any;
                        specialPrice?: any | null;
                        specialPriceInclTax?: any | null;
                        slug?: string | null;
                        images?: Array<{
                            __typename?: 'ProductImage';
                            url?: string | null;
                            sortOrder: number;
                            label?: string | null;
                            dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                        } | null> | null;
                        properties?: Array<{
                            __typename?: 'ProductProperty';
                            name?: string | null;
                            displayName?: string | null;
                            intValue?: number | null;
                            displayValue?: string | null;
                        } | null> | null;
                        variantProperties?: Array<{
                            __typename?: 'ProductVariantProperty';
                            key?: string | null;
                            name?: string | null;
                            values?: Array<string | null> | null;
                        } | null> | null;
                    } | null> | null;
                    variantOptions?: Array<{
                        __typename?: 'ProductVariantProperty';
                        key?: string | null;
                        name?: string | null;
                        values?: Array<string | null> | null;
                    } | null> | null;
                } | null;
            } | null> | null;
            variants?: Array<{
                __typename?: 'ProductVariant';
                id?: string | null;
                variantId?: string | null;
                sku?: string | null;
                key?: string | null;
                articleName?: string | null;
                price: any;
                priceInclTax: any;
                specialPrice?: any | null;
                specialPriceInclTax?: any | null;
                slug?: string | null;
                images?: Array<{
                    __typename?: 'ProductImage';
                    url?: string | null;
                    sortOrder: number;
                    label?: string | null;
                    dimensions?: { __typename?: 'Dimensions'; width: number; height: number } | null;
                } | null> | null;
                properties?: Array<{
                    __typename?: 'ProductProperty';
                    name?: string | null;
                    displayName?: string | null;
                    intValue?: number | null;
                    displayValue?: string | null;
                } | null> | null;
                variantProperties?: Array<{
                    __typename?: 'ProductVariantProperty';
                    key?: string | null;
                    name?: string | null;
                    values?: Array<string | null> | null;
                } | null> | null;
            } | null> | null;
            variantOptions?: Array<{
                __typename?: 'ProductVariantProperty';
                key?: string | null;
                name?: string | null;
                values?: Array<string | null> | null;
            } | null> | null;
            canonicalData?: {
                __typename?: 'CanonicalData';
                sku?: string | null;
                slug?: string | null;
                key?: string | null;
            } | null;
        } | null> | null;
        aggregates?: Array<{
            __typename?: 'KeyValuePairOfStringAndAggregateResponse';
            key: string;
            value: {
                __typename?: 'AggregateResponse';
                aggregateName?: string | null;
                otherDocCount: any;
                errorUpperBound: any;
                buckets?: Array<{
                    __typename?: 'AggregateResponseBucket';
                    key: number;
                    filterKey?: string | null;
                    count: any;
                } | null> | null;
            };
        }> | null;
    } | null;
};

export type SearchCategoriesQueryVariables = Exact<{
    storeType?: InputMaybe<StoreType>;
    filter?: InputMaybe<Scalars['String']>;
}>;

export type SearchCategoriesQuery = {
    __typename?: 'Query';
    categories?: {
        __typename?: 'SearchCategoriesResult';
        items?: Array<{
            __typename?: 'Category';
            id?: string | null;
            slug?: string | null;
            label?: string | null;
            level: number;
            parentId?: string | null;
            sortOrder: any;
            updatedAt: any;
            urlPath?: string | null;
            isActive: boolean;
            metaTitle?: string | null;
            metaDescription?: string | null;
            parents?: Array<{
                __typename?: 'CategoryParent';
                id?: string | null;
                label?: string | null;
                slug?: string | null;
                urlPath?: string | null;
            } | null> | null;
            categoryCustom?: {
                __typename?: 'CategoryCustom';
                imageUrl?: string | null;
                productCount: number;
                imageFullWidth?: string | null;
                imageSplitLeft?: string | null;
                imageSplitRight?: string | null;
            } | null;
        } | null> | null;
    } | null;
};

export type SitemapCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type SitemapCategoriesQuery = {
    __typename?: 'Query';
    categories?: {
        __typename?: 'SearchCategoriesResult';
        total: number;
        items?: Array<{ __typename?: 'Category'; urlPath?: string | null; lastModifiedAt: any } | null> | null;
    } | null;
};

export type SitemapProductsQueryVariables = Exact<{
    take: Scalars['Int'];
    skip: Scalars['Int'];
    sort: ProductSort;
    propertyFilters?: InputMaybe<
        Array<KeyValuePairOfStringAndListOfStringInput> | KeyValuePairOfStringAndListOfStringInput
    >;
    categoryId: Scalars['String'];
    filter: Scalars['String'];
    storeType?: InputMaybe<StoreType>;
}>;

export type SitemapProductsQuery = {
    __typename?: 'Query';
    products?: {
        __typename?: 'SearchProductsResult';
        total: number;
        items?: Array<{
            __typename?: 'Product';
            id?: string | null;
            sku?: string | null;
            slug?: string | null;
            key?: string | null;
            lastModifiedAt: any;
            variants?: Array<{
                __typename?: 'ProductVariant';
                sku?: string | null;
                key?: string | null;
                slug?: string | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type UserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserInfoQuery = {
    __typename?: 'Query';
    userInfo?: {
        __typename?: 'UserInfo';
        id?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: any | null;
        phone?: string | null;
        sex: UserSex;
        isCMMember: boolean;
        language?: string | null;
        newsLetterOptin?: boolean | null;
        pharmacyOptin?: boolean | null;
        hearingProtectionOptin?: boolean | null;
        hearingLossOptin?: boolean | null;
        homeCareOptin?: boolean | null;
        actitoProfileId: number;
        emailVerified: boolean;
        emailReadyToChange?: string | null;
        deliveryAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        billingAddress?: {
            __typename?: 'UserAddress';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            city?: string | null;
            postCode?: string | null;
            email?: string | null;
            phone?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            extraAddressInfo?: string | null;
            company?: string | null;
        } | null;
        stores?: Array<{
            __typename?: 'UserStore';
            storeId?: string | null;
            storeName?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            box?: string | null;
            postCode?: string | null;
            city?: string | null;
            country?: string | null;
            storeUrl?: string | null;
            services?: Array<{
                __typename?: 'ServiceType';
                type?: string | null;
                email?: string | null;
                phone?: string | null;
                uitleendienst: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};

export const UserStoreServiceTypeFieldsFragmentDoc = gql`
    fragment UserStoreServiceTypeFields on ServiceType {
        type
        email
        phone
        uitleendienst
    }
`;
export const UserStoreFieldsFragmentDoc = gql`
    fragment UserStoreFields on UserStore {
        storeId
        storeName
        streetName
        houseNumber
        box
        postCode
        city
        country
        storeUrl
        services {
            ...UserStoreServiceTypeFields
        }
    }
    ${UserStoreServiceTypeFieldsFragmentDoc}
`;
export const ProductImageFragmentDoc = gql`
    fragment ProductImage on ProductImage {
        url
        sortOrder
        label
        dimensions {
            width
            height
        }
    }
`;
export const VariantOptionFieldsFragmentDoc = gql`
    fragment VariantOptionFields on ProductVariantProperty {
        key
        name
        values
    }
`;
export const CartItemFieldsFragmentDoc = gql`
    fragment CartItemFields on CartItem {
        itemId
        sku
        quantity
        name
        key
        originalPrice
        originalTotalPrice
        price
        priceInclTax
        discountedPrice
        discountedPriceInclTax
        totalInclTax
        deliveryCost
        serviceCost
        depositAmount
        isPublished
        deliveryOption
        discountsPerCode {
            key
            value
        }
        image {
            ...ProductImage
        }
        urlPath
        productServiceType
        variantProperties {
            ...VariantOptionFields
        }
        slug
        isPublished
        rentalPeriodUnit
        rentalAdvance
        bothDelivery
        categories
        refundable
        isMedicine
        rentToOwn
    }
    ${ProductImageFragmentDoc}
    ${VariantOptionFieldsFragmentDoc}
`;
export const AddressFragmentDoc = gql`
    fragment Address on UserAddress {
        id
        firstName
        lastName
        city
        postCode
        email
        phone
        streetName
        houseNumber
        box
        extraAddressInfo
        company
    }
`;
export const CartFieldsFragmentDoc = gql`
    fragment CartFields on CartTotal {
        id
        grandTotal
        comment
        communicationPreference
        subTotal
        orderNumber
        discountAmountInclTax
        discountsPerCode {
            key
            value
        }
        currency
        totalQuantity
        deliveryIncludingTax
        paymentStatus
        createdAt
        taxAmount
        store {
            ...UserStoreFields
        }
        isCMMember
        items {
            ...CartItemFields
        }
        groupedItems {
            deliveryType
            subTotalQuantity
            subTotal
            subTotalServiceCost
            subTotalAccessories
            subTotalDepositAmount
            subTotalDelivery
            groupTotal
            items {
                ...CartItemFields
            }
        }
        deliveryAddress {
            ...Address
        }
        billingAddress {
            ...Address
        }
        deliveryCost
        serviceCostTotal
        depositAmountTotal
        accessoriesTotal
        storeType
        huurkoopCentAmount
    }
    ${UserStoreFieldsFragmentDoc}
    ${CartItemFieldsFragmentDoc}
    ${AddressFragmentDoc}
`;
export const CategoryParentFieldsFragmentDoc = gql`
    fragment CategoryParentFields on CategoryParent {
        id
        label
        slug
        urlPath
    }
`;
export const CategoryFieldsFragmentDoc = gql`
    fragment CategoryFields on Category {
        id
        slug
        label
        level
        parentId
        parents {
            ...CategoryParentFields
        }
        sortOrder
        updatedAt
        urlPath
        isActive
        metaTitle
        metaDescription
        categoryCustom {
            imageUrl
            productCount
            imageFullWidth
            imageSplitLeft
            imageSplitRight
        }
    }
    ${CategoryParentFieldsFragmentDoc}
`;
export const ProductCategoryFieldsFragmentDoc = gql`
    fragment ProductCategoryFields on ProductCategory {
        id
        slug
        label
        primary
        path
        primary
    }
`;
export const ProductVariantFragmentDoc = gql`
    fragment ProductVariant on ProductVariant {
        id
        variantId
        sku
        key
        articleName
        price
        priceInclTax
        specialPrice
        specialPriceInclTax
        slug
        images {
            ...ProductImage
        }
        properties {
            name
            displayName
            intValue
            displayValue
        }
        variantProperties {
            key
            name
            values
        }
    }
    ${ProductImageFragmentDoc}
`;
export const ProductAccessoryFragmentDoc = gql`
    fragment ProductAccessory on Accessory {
        type
        product {
            sku
            label
            priceInclTax
            specialPriceInclTax
            slug
            images {
                ...ProductImage
            }
            brand
            variants {
                ...ProductVariant
            }
            variantOptions {
                ...VariantOptionFields
            }
        }
    }
    ${ProductImageFragmentDoc}
    ${ProductVariantFragmentDoc}
    ${VariantOptionFieldsFragmentDoc}
`;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on Product {
        updatedAt
        createdAt
        additionalCompensation
        additionalCompensationDescription
        additionalProductSpecification
        activeIngredients
        productExceptionsDescription
        description
        descriptionShort
        depositAmount
        key
        buyable
        properties {
            name
            displayName
            intValue
            displayValue
        }
        visible
        videoUrl
        minQuantity
        price
        priceInclTax
        specialPriceInclTax
        deliveryCost
        deliveryCostCM
        collectionCost
        collectionCostCM
        serviceCost
        brand
        type
        numberOfReviews
        transactionType
        rentalPeriodUnit
        rentToOwn
        minimalRentalPeriod
        manualUrl
        slug
        conservation
        packageQuantity
        indication
        usage
        videoUrl
        prescriptionRequired
        leaflets
        generic
        images {
            ...ProductImage
        }
        accessories {
            ...ProductAccessory
        }
        accessoriesDescription
        variants {
            ...ProductVariant
        }
        variantOptions {
            ...VariantOptionFields
        }
        metaTitle
        metaDescription
        canonicalData {
            sku
            slug
            key
        }
        refundable
        isMedicine
    }
    ${ProductImageFragmentDoc}
    ${ProductAccessoryFragmentDoc}
    ${ProductVariantFragmentDoc}
    ${VariantOptionFieldsFragmentDoc}
`;
export const StoreCartFieldsFragmentDoc = gql`
    fragment StoreCartFields on StoreCart {
        storeType
        cartId
    }
`;
export const UserInfoFieldsFragmentDoc = gql`
    fragment UserInfoFields on UserInfo {
        id
        email
        firstName
        lastName
        dateOfBirth
        phone
        sex
        isCMMember
        deliveryAddress {
            ...Address
        }
        billingAddress {
            ...Address
        }
        stores {
            ...UserStoreFields
        }
        language
        newsLetterOptin
        pharmacyOptin
        hearingProtectionOptin
        hearingLossOptin
        homeCareOptin
        actitoProfileId
        emailVerified
        emailReadyToChange
    }
    ${AddressFragmentDoc}
    ${UserStoreFieldsFragmentDoc}
`;
export const AddFavoriteStoreDocument = gql`
    mutation AddFavoriteStore($storeId: String!) {
        addFavoriteStore(storeId: $storeId) {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type AddFavoriteStoreMutationFn = Apollo.MutationFunction<
    AddFavoriteStoreMutation,
    AddFavoriteStoreMutationVariables
>;
export type AddFavoriteStoreMutationResult = Apollo.MutationResult<AddFavoriteStoreMutation>;
export type AddFavoriteStoreMutationOptions = Apollo.BaseMutationOptions<
    AddFavoriteStoreMutation,
    AddFavoriteStoreMutationVariables
>;
export const AddProductToCartDocument = gql`
    mutation AddProductToCart(
        $quantity: Int!
        $sku: String
        $productServiceType: ProductServiceType!
        $cartId: String
        $storeType: StoreType
    ) {
        addProductToCart(
            quantity: $quantity
            sku: $sku
            productServiceType: $productServiceType
            cartId: $cartId
            storeType: $storeType
        ) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type AddProductToCartMutationFn = Apollo.MutationFunction<
    AddProductToCartMutation,
    AddProductToCartMutationVariables
>;
export type AddProductToCartMutationResult = Apollo.MutationResult<AddProductToCartMutation>;
export type AddProductToCartMutationOptions = Apollo.BaseMutationOptions<
    AddProductToCartMutation,
    AddProductToCartMutationVariables
>;
export const ChangeCartCmMemberDocument = gql`
    mutation ChangeCartCMMember($cartId: String, $isCMMember: Boolean!) {
        changeCartCMMember(cartId: $cartId, isCMMember: $isCMMember) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type ChangeCartCmMemberMutationFn = Apollo.MutationFunction<
    ChangeCartCmMemberMutation,
    ChangeCartCmMemberMutationVariables
>;
export type ChangeCartCmMemberMutationResult = Apollo.MutationResult<ChangeCartCmMemberMutation>;
export type ChangeCartCmMemberMutationOptions = Apollo.BaseMutationOptions<
    ChangeCartCmMemberMutation,
    ChangeCartCmMemberMutationVariables
>;
export const ChangeCartDeliveryOptionsDocument = gql`
    mutation changeCartDeliveryOptions($cartId: String, $cartDeliveryOption: CartDeliveryOption!) {
        changeCartDeliveryOptions(cartId: $cartId, cartDeliveryOption: $cartDeliveryOption) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type ChangeCartDeliveryOptionsMutationFn = Apollo.MutationFunction<
    ChangeCartDeliveryOptionsMutation,
    ChangeCartDeliveryOptionsMutationVariables
>;
export type ChangeCartDeliveryOptionsMutationResult = Apollo.MutationResult<ChangeCartDeliveryOptionsMutation>;
export type ChangeCartDeliveryOptionsMutationOptions = Apollo.BaseMutationOptions<
    ChangeCartDeliveryOptionsMutation,
    ChangeCartDeliveryOptionsMutationVariables
>;
export const ChangeEmailDocument = gql`
    mutation changeEmail($password: String, $oldEmail: String, $newEmail: String) {
        changeEmail(password: $password, oldEmail: $oldEmail, newEmail: $newEmail) {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type ChangeEmailMutationFn = Apollo.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;
export type ChangeEmailMutationResult = Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String, $currentPassword: String) {
        changePassword(newPassword: $newPassword, currentPassword: $currentPassword) {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const ConfirmEmailByDigitsDocument = gql`
    mutation ConfirmEmailByDigits($customerId: String, $digits: String, $email: String) {
        confirmEmailByDigits(customerId: $customerId, digits: $digits, email: $email)
    }
`;
export type ConfirmEmailByDigitsMutationFn = Apollo.MutationFunction<
    ConfirmEmailByDigitsMutation,
    ConfirmEmailByDigitsMutationVariables
>;
export type ConfirmEmailByDigitsMutationResult = Apollo.MutationResult<ConfirmEmailByDigitsMutation>;
export type ConfirmEmailByDigitsMutationOptions = Apollo.BaseMutationOptions<
    ConfirmEmailByDigitsMutation,
    ConfirmEmailByDigitsMutationVariables
>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($cartId: String, $storeType: StoreType) {
        createOrder(cartId: $cartId, storeType: $storeType) {
            redirectUrl
            cartId
        }
    }
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CustomerSignInDocument = gql`
    mutation CustomerSignIn($email: String, $password: String, $anonymousCarts: [StoreCartInput]) {
        customerSignIn(email: $email, password: $password, anonymousCarts: $anonymousCarts) {
            token
            carts {
                ...StoreCartFields
            }
            user {
                ...UserInfoFields
            }
        }
    }
    ${StoreCartFieldsFragmentDoc}
    ${UserInfoFieldsFragmentDoc}
`;
export type CustomerSignInMutationFn = Apollo.MutationFunction<CustomerSignInMutation, CustomerSignInMutationVariables>;
export type CustomerSignInMutationResult = Apollo.MutationResult<CustomerSignInMutation>;
export type CustomerSignInMutationOptions = Apollo.BaseMutationOptions<
    CustomerSignInMutation,
    CustomerSignInMutationVariables
>;
export const CustomerSignUpDocument = gql`
    mutation customerSignUp(
        $email: String
        $firstName: String
        $lastName: String
        $password: String
        $sex: UserSex!
        $anonymousCarts: [StoreCartInput]
        $isCMMember: Boolean
        $phone: String
    ) {
        customerSignUp(
            user: {
                email: $email
                firstName: $firstName
                lastName: $lastName
                password: $password
                sex: $sex
                isCMMember: $isCMMember
                phone: $phone
            }
            anonymousCarts: $anonymousCarts
        ) {
            token
            carts {
                ...StoreCartFields
            }
            user {
                ...UserInfoFields
            }
        }
    }
    ${StoreCartFieldsFragmentDoc}
    ${UserInfoFieldsFragmentDoc}
`;
export type CustomerSignUpMutationFn = Apollo.MutationFunction<CustomerSignUpMutation, CustomerSignUpMutationVariables>;
export type CustomerSignUpMutationResult = Apollo.MutationResult<CustomerSignUpMutation>;
export type CustomerSignUpMutationOptions = Apollo.BaseMutationOptions<
    CustomerSignUpMutation,
    CustomerSignUpMutationVariables
>;
export const RemoveAddressDocument = gql`
    mutation removeAddress($addressId: String) {
        removeAddress(addressId: $addressId) {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type RemoveAddressMutationFn = Apollo.MutationFunction<RemoveAddressMutation, RemoveAddressMutationVariables>;
export type RemoveAddressMutationResult = Apollo.MutationResult<RemoveAddressMutation>;
export type RemoveAddressMutationOptions = Apollo.BaseMutationOptions<
    RemoveAddressMutation,
    RemoveAddressMutationVariables
>;
export const RemoveFavoriteStoreDocument = gql`
    mutation RemoveFavoriteStore($storeId: String!) {
        removeFavoriteStore(storeId: $storeId) {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type RemoveFavoriteStoreMutationFn = Apollo.MutationFunction<
    RemoveFavoriteStoreMutation,
    RemoveFavoriteStoreMutationVariables
>;
export type RemoveFavoriteStoreMutationResult = Apollo.MutationResult<RemoveFavoriteStoreMutation>;
export type RemoveFavoriteStoreMutationOptions = Apollo.BaseMutationOptions<
    RemoveFavoriteStoreMutation,
    RemoveFavoriteStoreMutationVariables
>;
export const RemoveProductFromCartDocument = gql`
    mutation RemoveProductFromCart($lineItemId: String, $cartId: String, $storeType: StoreType!) {
        removeProductFromCart(lineItemId: $lineItemId, cartId: $cartId, storeType: $storeType) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type RemoveProductFromCartMutationFn = Apollo.MutationFunction<
    RemoveProductFromCartMutation,
    RemoveProductFromCartMutationVariables
>;
export type RemoveProductFromCartMutationResult = Apollo.MutationResult<RemoveProductFromCartMutation>;
export type RemoveProductFromCartMutationOptions = Apollo.BaseMutationOptions<
    RemoveProductFromCartMutation,
    RemoveProductFromCartMutationVariables
>;
export const RequestEmailConfirmDigitsDocument = gql`
    mutation RequestEmailConfirmDigits($customerId: String) {
        requestEmailConfirmDigits(customerId: $customerId)
    }
`;
export type RequestEmailConfirmDigitsMutationFn = Apollo.MutationFunction<
    RequestEmailConfirmDigitsMutation,
    RequestEmailConfirmDigitsMutationVariables
>;
export type RequestEmailConfirmDigitsMutationResult = Apollo.MutationResult<RequestEmailConfirmDigitsMutation>;
export type RequestEmailConfirmDigitsMutationOptions = Apollo.BaseMutationOptions<
    RequestEmailConfirmDigitsMutation,
    RequestEmailConfirmDigitsMutationVariables
>;
export const RequestForgetPasswordTokenDocument = gql`
    mutation requestForgetPasswordToken($email: String) {
        requestForgetPasswordToken(email: $email)
    }
`;
export type RequestForgetPasswordTokenMutationFn = Apollo.MutationFunction<
    RequestForgetPasswordTokenMutation,
    RequestForgetPasswordTokenMutationVariables
>;
export type RequestForgetPasswordTokenMutationResult = Apollo.MutationResult<RequestForgetPasswordTokenMutation>;
export type RequestForgetPasswordTokenMutationOptions = Apollo.BaseMutationOptions<
    RequestForgetPasswordTokenMutation,
    RequestForgetPasswordTokenMutationVariables
>;
export const ReSetCartDeliveryCostDocument = gql`
    mutation ReSetCartDeliveryCost($cartId: String, $skus: [String], $specialDelivery: SpecialDelivery!) {
        reSetCartDeliveryCost(cartId: $cartId, skus: $skus, specialDelivery: $specialDelivery) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type ReSetCartDeliveryCostMutationFn = Apollo.MutationFunction<
    ReSetCartDeliveryCostMutation,
    ReSetCartDeliveryCostMutationVariables
>;
export type ReSetCartDeliveryCostMutationResult = Apollo.MutationResult<ReSetCartDeliveryCostMutation>;
export type ReSetCartDeliveryCostMutationOptions = Apollo.BaseMutationOptions<
    ReSetCartDeliveryCostMutation,
    ReSetCartDeliveryCostMutationVariables
>;
export const ResetPasswordWithTokenDocument = gql`
    mutation resetPasswordWithToken($password: String, $token: String) {
        resetPasswordWithToken(newPassword: $password, token: $token) {
            success
            email
        }
    }
`;
export type ResetPasswordWithTokenMutationFn = Apollo.MutationFunction<
    ResetPasswordWithTokenMutation,
    ResetPasswordWithTokenMutationVariables
>;
export type ResetPasswordWithTokenMutationResult = Apollo.MutationResult<ResetPasswordWithTokenMutation>;
export type ResetPasswordWithTokenMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordWithTokenMutation,
    ResetPasswordWithTokenMutationVariables
>;
export const SetBillingAddressDocument = gql`
    mutation SetBillingAddress(
        $cartId: String
        $storeType: StoreType
        $customerAddressId: String
        $billingAddress: UserAddressInput
    ) {
        setBillingAddress(
            parameters: {
                cartId: $cartId
                storeType: $storeType
                customerAddressId: $customerAddressId
                billingAddress: $billingAddress
            }
        ) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type SetBillingAddressMutationFn = Apollo.MutationFunction<
    SetBillingAddressMutation,
    SetBillingAddressMutationVariables
>;
export type SetBillingAddressMutationResult = Apollo.MutationResult<SetBillingAddressMutation>;
export type SetBillingAddressMutationOptions = Apollo.BaseMutationOptions<
    SetBillingAddressMutation,
    SetBillingAddressMutationVariables
>;
export const SetCommentForCartDocument = gql`
    mutation SetCommentForCart($cartId: String, $storeType: StoreType, $comment: String) {
        setCommentForCart(cartId: $cartId, storeType: $storeType, comment: $comment) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type SetCommentForCartMutationFn = Apollo.MutationFunction<
    SetCommentForCartMutation,
    SetCommentForCartMutationVariables
>;
export type SetCommentForCartMutationResult = Apollo.MutationResult<SetCommentForCartMutation>;
export type SetCommentForCartMutationOptions = Apollo.BaseMutationOptions<
    SetCommentForCartMutation,
    SetCommentForCartMutationVariables
>;
export const SetCommunicationPreferenceForResDocument = gql`
    mutation SetCommunicationPreferenceForRES($cartId: String, $communicationPreference: String) {
        setCommunicationPreferenceForRES(cartId: $cartId, method: $communicationPreference) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type SetCommunicationPreferenceForResMutationFn = Apollo.MutationFunction<
    SetCommunicationPreferenceForResMutation,
    SetCommunicationPreferenceForResMutationVariables
>;
export type SetCommunicationPreferenceForResMutationResult =
    Apollo.MutationResult<SetCommunicationPreferenceForResMutation>;
export type SetCommunicationPreferenceForResMutationOptions = Apollo.BaseMutationOptions<
    SetCommunicationPreferenceForResMutation,
    SetCommunicationPreferenceForResMutationVariables
>;
export const SetCustomerCommunicationPreferencesDocument = gql`
    mutation SetCustomerCommunicationPreferences($preference: CustomerCommunicationPreferenceInput) {
        setCustomerCommunicationPreferences(preference: $preference) {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type SetCustomerCommunicationPreferencesMutationFn = Apollo.MutationFunction<
    SetCustomerCommunicationPreferencesMutation,
    SetCustomerCommunicationPreferencesMutationVariables
>;
export type SetCustomerCommunicationPreferencesMutationResult =
    Apollo.MutationResult<SetCustomerCommunicationPreferencesMutation>;
export type SetCustomerCommunicationPreferencesMutationOptions = Apollo.BaseMutationOptions<
    SetCustomerCommunicationPreferencesMutation,
    SetCustomerCommunicationPreferencesMutationVariables
>;
export const SetDeliveryAddressDocument = gql`
    mutation SetDeliveryAddress($cartId: String, $customerAddressId: String, $deliveryAddress: UserAddressInput) {
        setDeliveryAddress(
            parameters: { cartId: $cartId, customerAddressId: $customerAddressId, deliveryAddress: $deliveryAddress }
        ) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type SetDeliveryAddressMutationFn = Apollo.MutationFunction<
    SetDeliveryAddressMutation,
    SetDeliveryAddressMutationVariables
>;
export type SetDeliveryAddressMutationResult = Apollo.MutationResult<SetDeliveryAddressMutation>;
export type SetDeliveryAddressMutationOptions = Apollo.BaseMutationOptions<
    SetDeliveryAddressMutation,
    SetDeliveryAddressMutationVariables
>;
export const SetStoreForCartDocument = gql`
    mutation SetStoreForCart($cartId: String, $storeType: StoreType!, $storeId: Int!) {
        setStoreForCart(cartId: $cartId, storeType: $storeType, storeId: $storeId) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type SetStoreForCartMutationFn = Apollo.MutationFunction<
    SetStoreForCartMutation,
    SetStoreForCartMutationVariables
>;
export type SetStoreForCartMutationResult = Apollo.MutationResult<SetStoreForCartMutation>;
export type SetStoreForCartMutationOptions = Apollo.BaseMutationOptions<
    SetStoreForCartMutation,
    SetStoreForCartMutationVariables
>;
export const SetUserBillingAddressDocument = gql`
    mutation SetUserBillingAddress($address: UserAddressInput) {
        setUserBillingAddress(address: $address) {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type SetUserBillingAddressMutationFn = Apollo.MutationFunction<
    SetUserBillingAddressMutation,
    SetUserBillingAddressMutationVariables
>;
export type SetUserBillingAddressMutationResult = Apollo.MutationResult<SetUserBillingAddressMutation>;
export type SetUserBillingAddressMutationOptions = Apollo.BaseMutationOptions<
    SetUserBillingAddressMutation,
    SetUserBillingAddressMutationVariables
>;
export const SetUserDeliveryAddressDocument = gql`
    mutation SetUserDeliveryAddress($address: UserAddressInput) {
        setUserDeliveryAddress(address: $address) {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type SetUserDeliveryAddressMutationFn = Apollo.MutationFunction<
    SetUserDeliveryAddressMutation,
    SetUserDeliveryAddressMutationVariables
>;
export type SetUserDeliveryAddressMutationResult = Apollo.MutationResult<SetUserDeliveryAddressMutation>;
export type SetUserDeliveryAddressMutationOptions = Apollo.BaseMutationOptions<
    SetUserDeliveryAddressMutation,
    SetUserDeliveryAddressMutationVariables
>;
export const SyncCustomerProfileToCartDocument = gql`
    mutation SyncCustomerProfileToCart($cartId: String) {
        syncCustomerProfileToCart(cartId: $cartId) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type SyncCustomerProfileToCartMutationFn = Apollo.MutationFunction<
    SyncCustomerProfileToCartMutation,
    SyncCustomerProfileToCartMutationVariables
>;
export type SyncCustomerProfileToCartMutationResult = Apollo.MutationResult<SyncCustomerProfileToCartMutation>;
export type SyncCustomerProfileToCartMutationOptions = Apollo.BaseMutationOptions<
    SyncCustomerProfileToCartMutation,
    SyncCustomerProfileToCartMutationVariables
>;
export const UpdateCartDocument = gql`
    mutation UpdateCart($quantity: Int!, $lineItemId: String, $cartId: String, $storeType: StoreType!) {
        updateCart(quantity: $quantity, lineItemId: $lineItemId, cartId: $cartId, storeType: $storeType) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type UpdateCartMutationFn = Apollo.MutationFunction<UpdateCartMutation, UpdateCartMutationVariables>;
export type UpdateCartMutationResult = Apollo.MutationResult<UpdateCartMutation>;
export type UpdateCartMutationOptions = Apollo.BaseMutationOptions<UpdateCartMutation, UpdateCartMutationVariables>;
export const UpdateUserPersonalInfoDocument = gql`
    mutation UpdateUserPersonalInfo($data: UserPersonalInfoInput) {
        updateUserPersonalInfo(data: $data) {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type UpdateUserPersonalInfoMutationFn = Apollo.MutationFunction<
    UpdateUserPersonalInfoMutation,
    UpdateUserPersonalInfoMutationVariables
>;
export type UpdateUserPersonalInfoMutationResult = Apollo.MutationResult<UpdateUserPersonalInfoMutation>;
export type UpdateUserPersonalInfoMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserPersonalInfoMutation,
    UpdateUserPersonalInfoMutationVariables
>;
export const CartDocument = gql`
    query Cart($carts: [CartRequestInput]) {
        cart(storeCarts: $carts) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const CategoriesRootDocument = gql`
    query CategoriesRoot($storeType: StoreType) {
        rootCategories(storeType: $storeType) {
            ...CategoryFields
            children {
                ...CategoryFields
                children {
                    ...CategoryFields
                }
            }
        }
    }
    ${CategoryFieldsFragmentDoc}
`;
export type CategoriesRootQueryResult = Apollo.QueryResult<CategoriesRootQuery, CategoriesRootQueryVariables>;
export const CategoryByIdDocument = gql`
    query CategoryById($storeType: StoreType, $id: [String]) {
        categories(query: { id: $id, storeType: $storeType }) {
            items {
                id
                parentId
                label
                level
                slug
                sortOrder
                isActive
                updatedAt
            }
        }
    }
`;
export type CategoryByIdQueryResult = Apollo.QueryResult<CategoryByIdQuery, CategoryByIdQueryVariables>;
export const CategoryBySlugDocument = gql`
    query CategoryBySlug($storeType: StoreType, $slug: String!) {
        categories(query: { storeType: $storeType, slug: $slug }) {
            total
            items {
                id
                label
                level
                slug
                metaDescription
                metaTitle
                isActive
                sortOrder
                updatedAt
                parentId
                parents {
                    label
                    slug
                }
                children {
                    id
                    label
                    level
                    slug
                    parentId
                }
                categoryCustom {
                    imageUrl
                    productCount
                    imageFullWidth
                    imageSplitLeft
                    imageSplitRight
                }
            }
        }
    }
`;
export type CategoryBySlugQueryResult = Apollo.QueryResult<CategoryBySlugQuery, CategoryBySlugQueryVariables>;
export const CheckUserDocument = gql`
    query CheckUser($email: String) {
        checkUser(email: $email)
    }
`;
export type CheckUserQueryResult = Apollo.QueryResult<CheckUserQuery, CheckUserQueryVariables>;
export const CustomerOrdersDocument = gql`
    query CustomerOrders($skip: Int!, $take: Int!, $sort: CustomerSort) {
        customerOrders(data: { skip: $skip, take: $take, sort: $sort }) {
            total
            totalNumberOfPages
            pageSize
            pageNumber
            items {
                ...CartFields
            }
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type CustomerOrdersQueryResult = Apollo.QueryResult<CustomerOrdersQuery, CustomerOrdersQueryVariables>;
export const CustomerOrdersByIdDocument = gql`
    query CustomerOrdersById($orderId: String) {
        customerOrdersById(parameter: { orderId: $orderId }) {
            createdAt
            orderNumber
            grandTotal
            discountAmountInclTax
            deliveryIncludingTax
            billingAddress {
                ...Address
            }
            deliveryAddress {
                ...Address
            }
            items {
                ...CartItemFields
            }
        }
    }
    ${AddressFragmentDoc}
    ${CartItemFieldsFragmentDoc}
`;
export type CustomerOrdersByIdQueryResult = Apollo.QueryResult<
    CustomerOrdersByIdQuery,
    CustomerOrdersByIdQueryVariables
>;
export const OrderForCartIdDocument = gql`
    query OrderForCartId($cartId: String!) {
        orderForCartId(cartId: $cartId) {
            ...CartFields
        }
    }
    ${CartFieldsFragmentDoc}
`;
export type OrderForCartIdQueryResult = Apollo.QueryResult<OrderForCartIdQuery, OrderForCartIdQueryVariables>;
export const ProductProjectionSuggestDocument = gql`
    query ProductProjectionSuggest($limit: Int!, $filter: String!, $fuzzy: Boolean!, $storeType: StoreType!) {
        productProjectionSuggest(query: { storeType: $storeType, fuzzy: $fuzzy, keyword: $filter, limit: $limit }) {
            total
            products {
                key
                value {
                    id
                    label
                    sku
                    slug
                    description
                    image
                    price
                    priceInclTax
                    urlPath
                    categories {
                        id
                        label
                        path
                        slug
                        primary
                    }
                }
            }
            suggestions {
                locale
                text
            }
        }
    }
`;
export type ProductProjectionSuggestQueryResult = Apollo.QueryResult<
    ProductProjectionSuggestQuery,
    ProductProjectionSuggestQueryVariables
>;
export const ProductsBySkuDocument = gql`
    query ProductsBySku($sku: [String!], $storeType: StoreType) {
        products(query: { take: 1, sku: $sku, storeType: $storeType }) {
            total
            items {
                ...ProductFields
                id
                sku
                label
                stock
                deliveryOption
                assemblyOption
                depositAmount
                rentalPeriodUnit
                serviceCost
                rentToOwn
                categories {
                    ...ProductCategoryFields
                }
            }
        }
    }
    ${ProductFieldsFragmentDoc}
    ${ProductCategoryFieldsFragmentDoc}
`;
export type ProductsBySkuQueryResult = Apollo.QueryResult<ProductsBySkuQuery, ProductsBySkuQueryVariables>;
export const ProductsByStringDocument = gql`
    query ProductsByString($take: Int!, $filter: String!, $storeType: StoreType) {
        products(query: { take: $take, filter: $filter, storeType: $storeType }) {
            total
            items {
                ...ProductFields
                id
                sku
                label
                image
            }
        }
    }
    ${ProductFieldsFragmentDoc}
`;
export type ProductsByStringQueryResult = Apollo.QueryResult<ProductsByStringQuery, ProductsByStringQueryVariables>;
export const ProductsForPathDocument = gql`
    query ProductsForPath($take: Int!, $skip: Int!, $storeType: StoreType) {
        products(query: { skip: $skip, take: $take, storeType: $storeType }) {
            total
            items {
                ...ProductFields
                id
                sku
            }
        }
    }
    ${ProductFieldsFragmentDoc}
`;
export type ProductsForPathQueryResult = Apollo.QueryResult<ProductsForPathQuery, ProductsForPathQueryVariables>;
export const ProductsOverviewDocument = gql`
    query ProductsOverview(
        $take: Int!
        $skip: Int!
        $sort: ProductSort
        $propertyFilters: [KeyValuePairOfStringAndListOfStringInput!]
        $categoryId: String!
        $filter: String!
        $storeType: StoreType
    ) {
        products(
            query: {
                take: $take
                skip: $skip
                sort: $sort
                propertyFilters: $propertyFilters
                categoryId: $categoryId
                filter: $filter
                storeType: $storeType
            }
        ) {
            total
            items {
                ...ProductFields
                id
                label
                sku
                type
                categories {
                    ...ProductCategoryFields
                }
                priceInclTax
                descriptionShort
                specialPriceInclTax
                image
                stock
                lastModifiedAt: updatedAt
                createdAt
                brand
            }
            aggregates {
                key
                value {
                    aggregateName
                    otherDocCount
                    errorUpperBound
                    buckets {
                        key
                        filterKey
                        count
                    }
                }
            }
        }
    }
    ${ProductFieldsFragmentDoc}
    ${ProductCategoryFieldsFragmentDoc}
`;
export type ProductsOverviewQueryResult = Apollo.QueryResult<ProductsOverviewQuery, ProductsOverviewQueryVariables>;
export const ProductsPromotionDocument = gql`
    query ProductsPromotion($storeType: StoreType) {
        products(query: { take: 4, skip: 0, storeType: $storeType, promotion: "1" }) {
            total
            items {
                ...ProductFields
                id
                label
                sku
                type
                categories {
                    ...ProductCategoryFields
                }
                priceInclTax
                descriptionShort
                specialPriceInclTax
                image
                stock
                lastModifiedAt: updatedAt
                createdAt
                brand
            }
            aggregates {
                key
                value {
                    aggregateName
                    otherDocCount
                    errorUpperBound
                    buckets {
                        key
                        filterKey
                        count
                    }
                }
            }
        }
    }
    ${ProductFieldsFragmentDoc}
    ${ProductCategoryFieldsFragmentDoc}
`;
export type ProductsPromotionQueryResult = Apollo.QueryResult<ProductsPromotionQuery, ProductsPromotionQueryVariables>;
export const SearchCategoriesDocument = gql`
    query SearchCategories($storeType: StoreType, $filter: String) {
        categories(query: { storeType: $storeType, filter: $filter }) {
            items {
                ...CategoryFields
            }
        }
    }
    ${CategoryFieldsFragmentDoc}
`;
export type SearchCategoriesQueryResult = Apollo.QueryResult<SearchCategoriesQuery, SearchCategoriesQueryVariables>;
export const SitemapCategoriesDocument = gql`
    query SitemapCategories {
        categories {
            total
            items {
                urlPath
                lastModifiedAt: updatedAt
            }
        }
    }
`;
export type SitemapCategoriesQueryResult = Apollo.QueryResult<SitemapCategoriesQuery, SitemapCategoriesQueryVariables>;
export const SitemapProductsDocument = gql`
    query SitemapProducts(
        $take: Int!
        $skip: Int!
        $sort: ProductSort!
        $propertyFilters: [KeyValuePairOfStringAndListOfStringInput!]
        $categoryId: String!
        $filter: String!
        $storeType: StoreType
    ) {
        products(
            query: {
                take: $take
                skip: $skip
                sort: $sort
                propertyFilters: $propertyFilters
                categoryId: $categoryId
                filter: $filter
                storeType: $storeType
            }
        ) {
            total
            items {
                id
                sku
                slug
                key
                variants {
                    sku
                    key
                    slug
                }
                lastModifiedAt: updatedAt
            }
        }
    }
`;
export type SitemapProductsQueryResult = Apollo.QueryResult<SitemapProductsQuery, SitemapProductsQueryVariables>;
export const UserInfoDocument = gql`
    query UserInfo {
        userInfo {
            ...UserInfoFields
        }
    }
    ${UserInfoFieldsFragmentDoc}
`;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
