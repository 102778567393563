import { useReactiveVar } from '@apollo/client';
import { Config } from '@goed-platform/shared/constants';
import { webResActivePharmacy } from '@goed-platform/shared/data-access';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';

// eslint-disable-next-line @typescript-eslint/ban-types
type WebReservationFooterProps = {};

export const WebReservationFooter = (props: WebReservationFooterProps): JSX.Element => {
    const { t } = useTranslation();
    const router = useRouter();
    const activePharmacy = useReactiveVar(webResActivePharmacy);

    return (
        <div className="bg-purple-light-a30 py-4">
            <div className="container text-neutral-a80" suppressHydrationWarning>
                {t('webreservation.footer.responsible')}:{' '}
                <a
                    href={`${Config.sites.main}/${router.locale}/vestigingen/${activePharmacy?.meta.slug}`}
                    suppressHydrationWarning
                >
                    {activePharmacy?.name}
                </a>{' '}
                • {activePharmacy?.street ?? ''} {activePharmacy?.number ?? ''} {activePharmacy?.box ?? ''},{' '}
                {activePharmacy?.postal_code ?? ''} {activePharmacy?.city ?? ''} • {t('webreservation.footer.titular')}{' '}
                {activePharmacy?.services[0]?.titular ?? ''} •{' '}
                <a
                    href={`${Config.sites.main}/${router.locale}/vestigingen/${
                        activePharmacy?.meta.slug ?? ''
                    }#openingsuren`}
                    suppressHydrationWarning
                >
                    {t('webreservation.footer.info-and-opening-hours')}
                </a>
            </div>
        </div>
    );
};
