import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { BreadcrumbItem } from '../../../type/src/lib/breadcrumb-item';
import { RoutesEnum } from '@goed-platform/shared/types';
import { LanguageLink } from '@goed-platform/shared/ui';
import { SVGHome } from '@goed-platform/shared/utils';
import { Config } from '@goed-platform/shared/constants';

export type BreadcrumbsProps = {
    items: BreadcrumbItem[];
    enableMobileFallback?: boolean;
    homeUrl?: string;
    className?: string;
};

export const Breadcrumbs = ({
    items,
    enableMobileFallback = true,
    className = '',
    homeUrl,
    ...restProps
}: BreadcrumbsProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <section className={`breadcrumb-wrapper ${className}`} {...restProps}>
            <div className="container-md">
                <div className="row">
                    <div className="col-12">
                        <nav aria-label="breadcrumb">
                            <ol
                                className={
                                    'breadcrumb' + (enableMobileFallback ? ' breadcrumb-enable-mobile-fallback' : '')
                                }
                            >
                                <li className="breadcrumb-item">
                                    <LanguageLink href={homeUrl ?? Config.sites.main}>
                                        <a itemProp="url">
                                            <span className="sr-only">{t('general.homepage')}</span>
                                            <SVGHome className="sprite sprite-home sprite-sm mt-n1" title="home" />
                                        </a>
                                    </LanguageLink>
                                </li>

                                {items.map((item: BreadcrumbItem, index: number) => {
                                    const isLast: boolean = index + 1 === items.length;
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    const listItemAttributes: { [key: string]: any } = [];

                                    if (isLast) {
                                        listItemAttributes['aria-current'] = 'page';
                                    }

                                    return (
                                        <li
                                            key={index}
                                            className={'breadcrumb-item' + (isLast ? ' active' : '')}
                                            {...listItemAttributes}
                                            itemScope
                                            itemType="https://data-vocabulary/org/Breadcrumb"
                                        >
                                            <span className="sr-only">&gt;</span>
                                            {item.href && (
                                                <Link href={item.href}>
                                                    <a itemProp="url">
                                                        <span suppressHydrationWarning itemProp="title">
                                                            {item.label}
                                                        </span>
                                                    </a>
                                                </Link>
                                            )}

                                            {!item.href && <span itemProp="title">{item.label}</span>}
                                        </li>
                                    );
                                })}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    );
};
