import { SVGWarning } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import { PropsWithChildren } from 'react';

type InfoPriceIndicationResProps = PropsWithChildren<{ className?: string }>;

export const InfoPriceIndicationRes = ({ className = '' }: InfoPriceIndicationResProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={`p-4 border-1 border-neutral-a10 rounded-sm ${className}`}>
            <div className="d-inline-flex text-promo font-weight-bold mb-2 font-size-16 font-size-md-20 line-height-20 line-height-24">
                <SVGWarning className="sprite sprite-lg text-promo mr-2" />
                <span className="word-break">{t('webreservation.product.info.note.title')}</span>
            </div>
            <p className="small text-neutral-a70 mb-0">{t('webreservation.product.info.note.description')}</p>
        </div>
    );
};
