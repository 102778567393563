import { useTranslation } from 'next-i18next';
import { RoutesEnum } from '@goed-platform/shared/types';
import { SVGCheckmark } from '@goed-platform/shared/utils';
import { LanguageLink } from '@goed-platform/shared/ui';

export const UspsBanner: React.FC = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <section className="mt-0 mt-md-16 bg-off-white border-top">
            <div className="container-lg py-7">
                <div className="row">
                    <div className="col-md-6 mb-1 mb-md-0 d-flex align-items-center justify-content-md-center">
                        <SVGCheckmark className="sprite sprite-checkmark sprite-sm mr-2" title="checkmark" />
                        <LanguageLink href={RoutesEnum.home}>
                            <a className="font-weight-normal text-neutral font-size-14 font-size-md-18">
                                {t('catalog.usps.1')}
                            </a>
                        </LanguageLink>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-md-center">
                        <SVGCheckmark className="sprite sprite-checkmark sprite-sm mr-2" title="checkmark" />
                        <LanguageLink href={RoutesEnum.home}>
                            <a className="font-weight-normal text-neutral font-size-14 font-size-md-18">
                                {t('catalog.usps.2')}
                            </a>
                        </LanguageLink>
                    </div>
                </div>
            </div>
        </section>
    );
};
