import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

export type LanguageLinkProps = {
    href: string;
    children: React.ReactElement;
};

export const LanguageLink = ({ href, children, ...restProps }: LanguageLinkProps): JSX.Element => {
    const router = useRouter();
    let hrefSanitized = null;
    if (href.startsWith('http')) {
        hrefSanitized = href;
    } else if (href.startsWith('/')) {
        hrefSanitized = `/${router.locale}/${href.slice(1)}`;
    } else {
        hrefSanitized = `/${router.locale}/${href}`;
    }

    return (
        <Link href={hrefSanitized} {...restProps}>
            {children}
        </Link>
    );
};
