import { Switch } from '@goed-platform/shared/ui';
import { SyntheticEvent } from 'react';

export type SettingSwitchProps = {
    id: string;
    label: string;
    onChange: (event: SyntheticEvent, id: string) => void;
    defaultChecked: boolean;
    className?: string;
};

export const SettingSwitch = ({
    id,
    label,
    onChange,
    defaultChecked,
    className = '',
    ...restProps
}: SettingSwitchProps): JSX.Element => {
    return (
        <div className={`d-flex align-items-center justify-content-between ${className}`} {...restProps}>
            <label className="font-weight-bold text-neutral mb-0" role="button" htmlFor={id}>
                {label}
            </label>

            <Switch
                id={id}
                onChange={(e) => {
                    onChange(e, id);
                }}
                defaultChecked={defaultChecked}
            />
        </div>
    );
};

export default SettingSwitch;
