import { Config, CTErrors } from '@goed-platform/shared/constants';
import { isLoggedInVar, StorageEnum } from '@goed-platform/shared/data-access';
import { LogAction, LogError, LogInfo, LogSuccess, LogWarning } from '@goed-platform/shared/utils';
import { UserDataAccess } from '@goed-platform/user/data-access';
import axios, { AxiosInstance } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export class Token {
    public static isActive(): boolean {
        const token = Token.getToken();
        return token.length > 0;
    }

    public static heartbeat = async (
        consideredEndOfLife = Config.token.renewXSecondsBeforeEndOfLife
    ): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (Token.isActive()) {
                const token: string = Token.getToken();
                let remainingLifeTime = 0;

                const isTokenBeingRenewed = JSON.parse(
                    localStorage.getItem(StorageEnum.isTokenBeingRenewed) ?? 'false'
                );

                try {
                    const { exp } = jwtDecode<JwtPayload>(token);

                    if (exp) {
                        const now: number = +new Date() / 1000; // Calculate current timestamp.
                        const expRemaining: number = exp - now;
                        remainingLifeTime = expRemaining - consideredEndOfLife;

                        LogInfo(
                            `User token ${Token.getAbbreviation(token)} (EOL ${(remainingLifeTime / 60).toFixed(2)}m)`,
                            token
                        );
                    }

                    // Find out whether or not we should refetch the current token.
                    if ((!exp || remainingLifeTime <= 0) && !isTokenBeingRenewed) {
                        Token.startRenew(token)
                            .then((newToken) => resolve(newToken))
                            .catch(() => reject(''));
                    }

                    resolve(token);
                } catch (error) {
                    Token.startRenew(token)
                        .then((newToken) => resolve(newToken))
                        .catch(() => reject(''));
                }
            }

            resolve('');
        });
    };

    public static keepAlive(keepAliveInterval = Config.token.keepAliveInterval): NodeJS.Timeout {
        return setInterval(() => {
            if (Token.isActive()) {
                LogAction('Keeping user token alive: sending heartbeat...');
                Token.heartbeat();
            } else if (isLoggedInVar()) {
                // The application is still in logged in state, but the token has been removed from the application
                // storage. This means we have to log out.
                // This situation can occur when you have multiple tabs of the application open and you log out on
                // one of them. The other tabs will still be logged in. This logic logs the other tabs out as well.
                UserDataAccess.logout();
            }
        }, keepAliveInterval);
    }

    public static renewToken = async (): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (Token.isActive()) {
                const currentToken: string = Token.getToken();
                LogInfo(`Force renew of user token ${Token.getAbbreviation(currentToken)}`, currentToken);
                Token.startRenew(currentToken)
                    .then((newToken) => resolve(newToken))
                    .catch(() => reject(''));
            } else {
                reject(CTErrors.NOT_LOGGED_ON);
            }
        });
    };

    private static getAxiosInstance(): AxiosInstance {
        return axios.create({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            timeout: Config.axios.timeout,
        });
    }

    private static getToken(): string {
        return localStorage.getItem(StorageEnum.token) ?? '';
    }

    private static async startRenew(token: string): Promise<string> {
        return new Promise((resolve, reject) => {
            LogInfo('User token needs renewal. Renewing...');
            localStorage.setItem(StorageEnum.isTokenBeingRenewed, JSON.stringify(true));
            Token.renew(token)
                .then((newToken) => resolve(newToken))
                .catch(() => reject(''));
        });
    }

    private static async renew(currentToken: string): Promise<string> {
        const endpoint: string | undefined = process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT;

        if (!endpoint) {
            return Promise.reject('GraphQL endpoint is not set.');
        }

        return new Promise((resolve, reject) => {
            Token.getAxiosInstance()
                .post(endpoint, {
                    // Execute the mutation manually, as there is no CommercetoolsClient or useMutation yet.
                    query: 'mutation CustomerRenewToken($token: String) { customerRenewToken(token: $token) }',
                    variables: {
                        token: currentToken,
                    },
                })
                .then((result) => {
                    localStorage.setItem(StorageEnum.isTokenBeingRenewed, JSON.stringify(false));

                    if (result?.data?.data?.customerRenewToken) {
                        const token: string = result.data.data.customerRenewToken;
                        localStorage.setItem(StorageEnum.token, token);
                        LogSuccess(`User token renewed: ${Token.getAbbreviation(token)}`, token);
                        resolve(token);
                    } else {
                        LogError('Unexpected output for customerRenewToken.', result);
                        UserDataAccess.logout();
                        reject();
                    }
                })
                .catch((error) => {
                    LogWarning('Unable to renew token.', error);
                    localStorage.setItem(StorageEnum.isTokenBeingRenewed, JSON.stringify(false));
                    UserDataAccess.logout();
                    reject(error);
                });
        });
    }

    private static getAbbreviation(token: string): string {
        // For ease of reference in the console, output the first and last 5 characters of the token.
        return `${token.substring(0, 5)}/${token.substring(token.length - 5)}`;
    }
}
