import { CartItem, CartTotal, GroupedItem, ProductServiceType, StoreType } from '@goed-platform/graphql/types';
import {
    PrescriptionPriceWithInformationModal,
    PricingSummaryItem,
    PricingSummaryTotal,
    SubtotalWithInformationModal,
} from '@goed-platform/pricing/ui';
import { TFunction } from 'next-i18next';

export class PricingSummaryMapper {
    public static mapGroupedItems(t: TFunction, group: GroupedItem, storeType: StoreType): PricingSummaryItem[] {
        switch (storeType) {
            case StoreType.Uld:
                return this.mapItemsUld(
                    t,
                    group.subTotal,
                    group.subTotalAccessories,
                    group.subTotalDelivery,
                    group.subTotalServiceCost,
                    group.subTotalDepositAmount
                );

            case StoreType.Res:
                return this.mapItemsRes(t, group.subTotal);
        }
    }

    public static mapCartItems(t: TFunction, cart: CartTotal, storeType: StoreType): PricingSummaryItem[] {
        let prescriptionItems = [];
        switch (storeType) {
            case StoreType.Uld:
                return this.mapItemsUld(
                    t,
                    cart.subTotal,
                    cart.accessoriesTotal,
                    cart.deliveryCost,
                    cart.serviceCostTotal,
                    cart.depositAmountTotal
                );

            case StoreType.Res:
                prescriptionItems = (cart?.items ?? []).filter(
                    (i) => i?.productServiceType === ProductServiceType.Prescription
                );
                return this.mapItemsRes(t, cart.subTotal, prescriptionItems.length > 0); // cart.subTotal);
        }
    }

    public static mapTotal(t: TFunction, total: number, articleQuantity: number): PricingSummaryTotal {
        return {
            label: t('cart.overview.total').toLowerCase(),
            price: total,
            articleQuantity: articleQuantity,
        };
    }

    private static mapItemsUld(
        t: TFunction,
        subTotal: number,
        accessoriesTotal: number,
        deliveryCost: number,
        serviceCost: number,
        depositAmount: number
    ): PricingSummaryItem[] {
        return [
            {
                label: (
                    <SubtotalWithInformationModal
                        title={t('cart.overview.pricingSummary.rentalAdvance.title')}
                        description={
                            <>
                                <p>{t('cart.overview.pricingSummary.rentalAdvance.description.1')}</p>
                                <p>{t('cart.overview.pricingSummary.rentalAdvance.description.2')}</p>
                            </>
                        }
                    />
                ),
                price: subTotal,
            },
            {
                label: t('product.cost.accessories').toLowerCase(),
                price: accessoriesTotal,
            },
            {
                label: t('product.cost.delivery').toLowerCase(),
                price: deliveryCost,
            },
            {
                label: t('product.cost.service').toLowerCase(),
                price: serviceCost,
            },
            {
                label: t('product.cost.deposit').toLowerCase(),
                price: depositAmount,
            },
        ];
    }

    private static mapItemsRes(
        t: TFunction,
        noPrescriptionTotal: number,
        containsPrescriptions?: boolean
    ): PricingSummaryItem[] {
        const items = [];
        if (containsPrescriptions)
            items.push({
                label: (
                    <PrescriptionPriceWithInformationModal
                        title={t('res.product.cost.prescriptionTotal.title')}
                        description={
                            <>
                                <p>{t('res.product.cost.prescriptionTotal.description.1')}</p>
                                <p>{t('res.product.cost.prescriptionTotal.description.2')}</p>
                            </>
                        }
                    />
                ),
                price: t('res.product.cost.prescriptionTotal.price') as string,
            });
        items.push({
            label: t('res.product.cost.noPrescriptionTotal').toLowerCase(),
            price: noPrescriptionTotal,
        });
        return items;
    }
}
