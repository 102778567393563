import { Config, PaymentMethodTypeEnum } from '@goed-platform/shared/constants';
import { PaymentMethodType } from './payment-methods-banner';

export type PaymentMethodsListProps = {
    type: PaymentMethodTypeEnum;
    itemClassName?: string;
};

export const PaymentMethodsList = ({ type, itemClassName = '' }: PaymentMethodsListProps): JSX.Element => {
    const paymentMethods =
        type === PaymentMethodTypeEnum.important ? Config.paymentMethodsImportant : Config.paymentMethodsAll;

    return (
        <>
            {paymentMethods.map(({ name, svg: SVGElement }: PaymentMethodType, key: number) => (
                <SVGElement key={key} className={itemClassName} alt={name} />
            ))}
        </>
    );
};
