import { useReactiveVar } from '@apollo/client';
import { CartIndicator } from '@goed-platform/cart/ui';
import { Category, StoreType } from '@goed-platform/graphql/types';
import { cartVarRes, webResActivePharmacy } from '@goed-platform/shared/data-access';
import { RoutesEnum } from '@goed-platform/shared/types';
import { SVGChevronDown, SVGChevronRight, SVGReservation } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC, PropsWithChildren, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { SearchBarDesktop } from './search-bar/search-bar-desktop';
import { SearchBarMobile } from './search-bar/search-bar-mobile';
import { Subheader } from './subheader';

// eslint-disable-next-line @typescript-eslint/ban-types
type SubheaderWebreservatieProps = PropsWithChildren<{
    categories: Category[];
    readOnly?: boolean;
}>;

export const SubheaderWebreservatie: FC<SubheaderWebreservatieProps> = ({
    categories,
    readOnly = false,
}: SubheaderWebreservatieProps) => {
    const { t } = useTranslation();
    const cartRes = useReactiveVar(cartVarRes);
    const activePharmacy = useReactiveVar(webResActivePharmacy);
    const url = RoutesEnum.webReservationLanding;
    const [showCategories, setShowCategories] = useState(false);

    return (
        <div className="bg-off-white border-bottom-1 border-neutral-a10">
            <div className="bg-purple-light-a30">
                <div className="container-lg">
                    <ClickAwayListener onClickAway={() => setShowCategories(false)}>
                        <div className="position-relative d-flex justify-content-between align-items-center py-4 py-md-6">
                            <div className="d-flex align-items-center">
                                {!readOnly ? (
                                    <>
                                        <button
                                            className="btn rounded-sm d-inline-flex align-items-center font-size-14 font-size-md-18 font-weight-bold text-white bg-purple-x-dark mr-6"
                                            onClick={() => setShowCategories(!showCategories)}
                                            suppressHydrationWarning
                                        >
                                            {activePharmacy?.name}
                                            <SVGChevronDown className="text-white ml-2" />
                                        </button>
                                        <Link href={RoutesEnum.webReservationLanding}>
                                            <a className="border-0 d-none d-lg-block font-weight-bold text-purple mr-6 text-truncate">
                                                {t('subheader.webreservation.overview')}
                                            </a>
                                        </Link>
                                        <Link href={RoutesEnum.webReservationPrescriptions}>
                                            <a className="border-0 d-none d-lg-block font-weight-bold text-purple mr-6 text-truncate">
                                                {t('subheader.webreservation.prescriptions')}
                                            </a>
                                        </Link>
                                    </>
                                ) : (
                                    <Link href={url}>
                                        <a className="d-flex justify-content-between align-items-center text-purple font-weight-bold border-bottom-0">
                                            {activePharmacy?.name ?? t('store.service.apotheek')}
                                        </a>
                                    </Link>
                                )}
                            </div>
                            {!readOnly && <SearchBarDesktop storeType={StoreType.Res} />}
                            <div className="d-flex justify-content-end align-items-center">
                                {!readOnly && <SearchBarMobile storeType={StoreType.Res} />}
                                <CartIndicator
                                    href={RoutesEnum.webReservationCart}
                                    text={t('subheader.webreservation.cart')}
                                    quantity={cartRes?.totalQuantity}
                                    Icon={SVGReservation}
                                    iconTitle="reservation"
                                    indicatorClassName="bg-purple-x-dark"
                                    className="text-purple ml-2 ml-md-6"
                                />
                            </div>
                            {!readOnly && (
                                <Subheader
                                    baseUrl={RoutesEnum.webReservationLanding}
                                    title={activePharmacy?.name ?? t('store.service.apotheek')}
                                    visible={showCategories}
                                    setVisible={setShowCategories}
                                    categories={categories}
                                />
                            )}
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
        </div>
    );
};
