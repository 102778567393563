import { MappedNavigation, NavigationLinkItem } from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalHeader } from '../modal-header';
import NextLink from 'next/link';
import { SVGChevronRight } from '@goed-platform/shared/utils';

type MobileHeaderProps = PropsWithChildren<{
    show: boolean;
    onHideModal: () => void;
    navigation?: MappedNavigation;
}>;

export const MobileHeader: FC<MobileHeaderProps> = ({
    show,
    onHideModal,
    navigation,
}: MobileHeaderProps): JSX.Element => {
    const { t } = useTranslation();

    const LinkItem = ({ url = '#', title, first }: NavigationLinkItem & { first?: boolean }) => (
        <NextLink passHref href={url}>
            <a
                className={`d-flex align-items-center justify-content-between py-4 px-6 mx-n6 text-purple border-bottom-1 border-top-${
                    first ? 1 : 0
                } border-neutral-a10 bg-white`}
            >
                {title} <SVGChevronRight className="sprite sprite-md text-purple" />
            </a>
        </NextLink>
    );

    return (
        <Modal show={show} onHide={onHideModal} centered className="modal-fullscreen-mobile">
            <ModalHeader
                menuItems={2}
                displayPrev={false}
                closeFunction={() => onHideModal()}
                title={t('header.mobile.title')}
            />
            <Modal.Body className="d-flex flex-column">
                <div className="font-weight-bold mb-4">{t('header.dropdown.main-offer-1.title')}</div>
                {navigation?.['main-offer-1']?.map((i, key) => (
                    <LinkItem {...i} first={key === 0} key={key} />
                ))}

                <div className="font-weight-bold mb-4 mt-6">{t('header.dropdown.main-offer-2.title')}</div>
                {navigation?.['main-offer-2']?.map((i, key) => (
                    <LinkItem {...i} first={key === 0} key={key} />
                ))}

                <div className="font-weight-bold mb-4 mt-6">{t('header.dropdown.main-offer-3.title')}</div>
                {navigation?.['main-offer-3']?.map((i, key) => (
                    <LinkItem {...i} first={key === 0} key={key} />
                ))}
            </Modal.Body>
        </Modal>
    );
};
