import { SVGLocation } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import { UserAddress, UserStore } from '@goed-platform/graphql/types';

type CheckoutAddressProps = {
    address?: UserAddress | UserStore;
    isRenderAsLink?: boolean;
    className?: string;
    displayIcon?: boolean;
};

export const CheckoutAddress = ({
    address,
    isRenderAsLink = false,
    className = '',
    displayIcon = false,
}: CheckoutAddressProps): JSX.Element => {
    const { t } = useTranslation();

    if (!address || address.city == null) {
        return <></>;
    }

    return (
        <div className="d-flex">
            {displayIcon && (
                <SVGLocation className="sprite sprite-chevron-right sprite-md mr-2" title="chevron-right" />
            )}
            <p className={`line-height-normal ${className}`} suppressHydrationWarning>
                {/* Regular (user) address */}
                {address.__typename === 'UserAddress' && (
                    <>
                        {address?.firstName} {address?.lastName}
                        {(address?.firstName || address?.lastName) && <br />}
                        {address?.company && (
                            <>
                                {address?.company}
                                <br />
                            </>
                        )}
                        {address?.streetName} {address?.houseNumber}
                        {!!address.box && ` ${t('user.box').toLowerCase()} ${address.box}`}
                        <br />
                        {address?.extraAddressInfo && (
                            <>
                                {address?.extraAddressInfo}
                                <br />
                            </>
                        )}
                        {address?.postCode} {address?.city} ({t('user.countryFixedValue')})
                    </>
                )}

                {/* Store address */}
                {address.__typename === 'UserStore' && (
                    <>
                        {isRenderAsLink ? (
                            <a
                                href={address?.storeUrl ?? '#'}
                                target="_blank"
                                rel="noreferrer noopener"
                                className="text-neutral font-weight-normal"
                                suppressHydrationWarning
                            >
                                {address?.storeName}
                            </a>
                        ) : (
                            address?.storeName
                        )}
                        <br />
                        {address?.streetName} {address?.houseNumber}
                        {!!address.box && ` ${t('user.box').toLowerCase()} ${address.box}`}
                        <br />
                        {address?.postCode} {address?.city} ({t('user.countryFixedValue')})
                    </>
                )}
            </p>
        </div>
    );
};
