/* eslint-disable react/jsx-no-useless-fragment */
import { MutationFunction } from '@apollo/client';
import {
    CartItem,
    CartTotal,
    ProductServiceType,
    RemoveProductFromCartMutation,
    RemoveProductFromCartMutationVariables,
    StoreType,
    UpdateCartMutation,
    UpdateCartMutationVariables,
} from '@goed-platform/graphql/types';
import { ImageLayoutEnum, ImageObjectfitEnum } from '@goed-platform/product/enums';
import { fallbackImageUrl } from '@goed-platform/shared/constants';
import { LanguageLink, OptimizedImage } from '@goed-platform/shared/ui';
import { UrlUtils } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { CartRowPriceUld } from './cart-row-price-uld';

type CartRowUldProps = {
    cartItem: CartItem;
    cart: CartTotal;
    updateCallback: MutationFunction<UpdateCartMutation, UpdateCartMutationVariables>;
    removeCallback: MutationFunction<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables>;
    className?: string;
    storeType: StoreType;
};

export const CartRowUld: FC<CartRowUldProps> = ({
    cartItem,
    cart,
    updateCallback,
    removeCallback,
    className = '',
    storeType,
    ...restProps
}: CartRowUldProps): JSX.Element => {
    const { t } = useTranslation();

    const translationServiceTypeBadge = (): string => {
        if (cartItem.productServiceType === ProductServiceType.Rental) {
            if (cartItem.rentToOwn && cartItem.rentToOwn > 0) {
                return 'huurkoop';
            }
            return 'rent';
        }

        return 'buy';
    };

    return (
        <div
            className={`card card-rounded mx-n4 mx-sm-0 border-sm-1 border-sm-neutral-a10 ${className}`}
            {...restProps}
        >
            <div className="card-body">
                <div className="row">
                    {/* Image */}
                    <div className="col-3">
                        {cartItem.image && (
                            <OptimizedImage
                                image={cartItem.image ?? { sortOrder: 0, url: fallbackImageUrl }}
                                layout={ImageLayoutEnum.fill}
                                objectFit={ImageObjectfitEnum.contain}
                                alt={cartItem.name ?? ''}
                            />
                        )}
                    </div>

                    <div className="col-9">
                        <div className="font-size-16 font-size-md-22">
                            <LanguageLink
                                href={UrlUtils.createProductUrl(cartItem.sku, cartItem.slug, cartItem.key, storeType)}
                            >
                                <a className="unstyled font-weight-normal">{cartItem.name}</a>
                            </LanguageLink>
                        </div>
                        {cartItem.key ? (
                            <div className="text-neutral-a70 mb-1 font-size-12 font-size-md-14">{cartItem.key}</div>
                        ) : (
                            <></>
                        )}
                        <p className="text-neutral-a70 mb-1 d-none d-sm-block">
                            {t(`product.cart.${cartItem.deliveryOption?.toLowerCase()}`)}
                        </p>
                        <div className="px-1 mb-5 rounded-sm w-fit text-neutral-a70 bg-neutral-a5 font-size-12 font-size-md-14">
                            {t(`product.type.${translationServiceTypeBadge()}`)}
                        </div>
                        <div className="d-none d-lg-block">
                            <CartRowPriceUld
                                cart={cart}
                                cartItem={cartItem}
                                updateCallback={updateCallback}
                                removeCallback={removeCallback}
                            />
                        </div>
                    </div>

                    <div className="col-12 d-lg-none mt-2">
                        <CartRowPriceUld
                            cart={cart}
                            cartItem={cartItem}
                            updateCallback={updateCallback}
                            removeCallback={removeCallback}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
