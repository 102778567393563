import { Config } from '@goed-platform/shared/constants';
import { SVGExternalLink, SVGMail, SVGSocialFacebook } from '@goed-platform/shared/utils';
import { MappedNavigation } from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

type CustomerBarForMobileProps = {
    navigation?: MappedNavigation;
};

export const CustomerBarForMobile: FC<CustomerBarForMobileProps> = ({
    navigation,
}: CustomerBarForMobileProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="container-lg d-lg-none">
            <div className="row">
                <div className="col-12 py-6 bg-neutral-white">
                    <h3 className="font-size-16 font-size-md-20 text-neutral mb-2 mb-lg-3">
                        {t('general.social.title')}
                    </h3>
                    <div className="position-relative d-flex align-items-center mb-2">
                        <div className="d-flex align-items-center justify-content-center mr-2 ws-8 hs-8 bg-purple rounded-max">
                            <SVGSocialFacebook
                                className="sprite sprite-md text-neutral-white"
                                title="social-facebook"
                            />
                        </div>

                        <a
                            href={t(Config.social.facebook)}
                            target="_blank"
                            rel="noreferrer"
                            className="stretched-link text-purple border-neutral-white-a60 font-size-16 font-weight-bold"
                        >
                            {t('general.social.facebook')}
                            <SVGExternalLink
                                className="sprite sprite-external-link sprite-sm ml-2"
                                title="external-link"
                            />
                        </a>
                    </div>
                    <div className="position-relative d-flex align-items-center mb-1">
                        <div className="d-flex align-items-center justify-content-center mr-2 ws-8 hs-8 bg-purple rounded-max">
                            <SVGMail className="sprite sprite-md text-neutral-white" title="social-newsletter" />
                        </div>

                        <a
                            href={t(Config.social.newsletter)}
                            target="_blank"
                            rel="noreferrer"
                            className="stretched-link text-purple border-neutral-white-a60 font-size-16 font-weight-bold"
                        >
                            {t('general.social.newsletter')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
