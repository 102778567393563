import { ApolloError } from '@apollo/client';
import { CartDataAccess } from '@goed-platform/cart/data-access';
import {
    AddFavoriteStoreDocument,
    AddFavoriteStoreMutation,
    AddFavoriteStoreMutationVariables,
    ChangeEmailDocument,
    ChangeEmailMutation,
    ChangeEmailMutationVariables,
    ChangePasswordDocument,
    ChangePasswordMutation,
    ChangePasswordMutationVariables,
    CheckUserDocument,
    CheckUserQuery,
    CheckUserQueryVariables,
    ConfirmEmailByDigitsDocument,
    ConfirmEmailByDigitsMutation,
    ConfirmEmailByDigitsMutationVariables,
    CustomerCommunicationPreferenceInput,
    CustomerSignInDocument,
    CustomerSignInMutation,
    CustomerSignInMutationVariables,
    CustomerSignUpDocument,
    CustomerSignUpMutation,
    CustomerSignUpMutationVariables,
    RemoveAddressDocument,
    RemoveAddressMutation,
    RemoveAddressMutationVariables,
    RemoveFavoriteStoreDocument,
    RemoveFavoriteStoreMutation,
    RemoveFavoriteStoreMutationVariables,
    RequestEmailConfirmDigitsDocument,
    RequestEmailConfirmDigitsMutation,
    RequestEmailConfirmDigitsMutationVariables,
    RequestForgetPasswordTokenDocument,
    RequestForgetPasswordTokenMutation,
    RequestForgetPasswordTokenMutationVariables,
    ResetPasswordWithTokenDocument,
    ResetPasswordWithTokenMutation,
    ResetPasswordWithTokenMutationVariables,
    SetCustomerCommunicationPreferencesDocument,
    SetCustomerCommunicationPreferencesMutation,
    SetCustomerCommunicationPreferencesMutationVariables,
    SetUserBillingAddressDocument,
    SetUserBillingAddressMutation,
    SetUserBillingAddressMutationVariables,
    SetUserDeliveryAddressDocument,
    SetUserDeliveryAddressMutation,
    SetUserDeliveryAddressMutationVariables,
    StoreType,
    UpdateUserPersonalInfoDocument,
    UpdateUserPersonalInfoMutation,
    UpdateUserPersonalInfoMutationVariables,
    UserAddressInput,
    UserInfo,
    UserInfoDocument,
    UserInfoQuery,
    UserPersonalInfoInput,
    UserSex,
} from '@goed-platform/graphql/types';
import { CTErrors, ErrorItemType } from '@goed-platform/shared/constants';
import {
    CartActions,
    CartIdActions,
    Commercetools,
    isLoggedInVar,
    StorageEnum,
    userInfoVar,
} from '@goed-platform/shared/data-access';
import { LogAction, LogInfo, LogWarning } from '@goed-platform/shared/utils';

export class UserDataAccess {
    public static async customerSignIn(
        email: string,
        password: string,
        storeType?: StoreType
    ): Promise<void | UserInfo | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<CustomerSignInMutation, CustomerSignInMutationVariables>({
                mutation: CustomerSignInDocument,
                variables: {
                    email: email,
                    password: password,
                    anonymousCarts: CartActions.getCarts(),
                },
                fetchPolicy: 'no-cache',
            })
                .then(async (response) => {
                    if (
                        response?.data?.customerSignIn?.token &&
                        response?.data?.customerSignIn?.carts &&
                        response?.data?.customerSignIn?.user
                    ) {
                        response.data.customerSignIn.carts?.map((cart) => {
                            CartIdActions.updateCartId(cart?.storeType ?? StoreType.Uld, cart?.cartId ?? '');
                        });

                        isLoggedInVar(true);
                        localStorage.setItem(StorageEnum.token, response.data.customerSignIn.token);

                        userInfoVar(response.data.customerSignIn.user);
                        await CartDataAccess.syncCustomerProfileToCart(storeType ?? StoreType.Uld);
                        await CartDataAccess.fetchCart();

                        resolve(response.data.customerSignIn.user as UserInfo);
                    } else {
                        reject(CTErrors.processUnexpectedOutput());
                    }
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async customerSignUpAndSignIn(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        sex: UserSex,
        isCMMember: boolean,
        phone: string
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<CustomerSignUpMutation, CustomerSignUpMutationVariables>({
                mutation: CustomerSignUpDocument,
                variables: {
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    sex: sex,
                    isCMMember: isCMMember,
                    anonymousCarts: CartActions.getCarts(),
                    phone: phone,
                },
                fetchPolicy: 'no-cache',
            })
                .then(async (response) => {
                    if (response?.data?.customerSignUp) {
                        response.data.customerSignUp.carts?.map((cart) => {
                            if (cart?.cartId !== null && cart?.cartId !== '')
                                CartIdActions.updateCartId(cart?.storeType ?? StoreType.Uld, cart?.cartId ?? '');
                        });

                        isLoggedInVar(true);
                        localStorage.setItem(StorageEnum.token, response.data.customerSignUp.token ?? '');

                        userInfoVar(response.data.customerSignUp.user);
                        await CartDataAccess.syncCustomerProfileToCart(StoreType.Uld);
                        await CartDataAccess.fetchCart();
                        return resolve();
                    }
                    return reject(CTErrors.processUnexpectedOutput('Triggered in sign up and in'));
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async resetPasswordWithTokenAndSignIn(
        password: string,
        resetToken: string
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<ResetPasswordWithTokenMutation, ResetPasswordWithTokenMutationVariables>({
                mutation: ResetPasswordWithTokenDocument,
                variables: {
                    password: password,
                    token: resetToken,
                },
                fetchPolicy: 'no-cache',
            })
                .then(async (response) => {
                    if (response.data?.resetPasswordWithToken?.email) {
                        await UserDataAccess.customerSignIn(response.data?.resetPasswordWithToken?.email, password);
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async requestForgetPassword(email: string): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<RequestForgetPasswordTokenMutation, RequestForgetPasswordTokenMutationVariables>({
                mutation: RequestForgetPasswordTokenDocument,
                variables: {
                    email: email,
                },
                fetchPolicy: 'no-cache',
            })
                .then(() => {
                    return resolve();
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async fetchUserInfo(): Promise<void | UserInfo | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.query<UserInfoQuery>({ query: UserInfoDocument, fetchPolicy: 'no-cache' })
                    .then((response) => {
                        if (response?.data?.userInfo) {
                            userInfoVar(response.data.userInfo as UserInfo);
                            LogInfo(`User ${response.data.userInfo.email}`, response.data.userInfo);
                            return resolve(response.data.userInfo as UserInfo);
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => {
                        UserDataAccess.logout();
                        return reject(CTErrors.processError(error));
                    });
            } else {
                return reject(CTErrors.NOT_LOGGED_ON);
            }
        });
    }

    public static async updateUserPersonalInfo(personalInfo: UserPersonalInfoInput): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.mutate<UpdateUserPersonalInfoMutation, UpdateUserPersonalInfoMutationVariables>({
                    mutation: UpdateUserPersonalInfoDocument,
                    variables: {
                        data: personalInfo,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (response.data?.updateUserPersonalInfo) {
                            userInfoVar(response.data?.updateUserPersonalInfo as UserInfo);
                            return resolve();
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            }
        });
    }

    public static async setCustomerCommunicationPreferences(
        preferences: CustomerCommunicationPreferenceInput
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<
                SetCustomerCommunicationPreferencesMutation,
                SetCustomerCommunicationPreferencesMutationVariables
            >({
                mutation: SetCustomerCommunicationPreferencesDocument,
                variables: {
                    preference: preferences,
                },
                fetchPolicy: 'no-cache',
            })
                .then((response) => {
                    if (response.data?.setCustomerCommunicationPreferences) {
                        userInfoVar(response.data?.setCustomerCommunicationPreferences as UserInfo);
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async setAnonymousCustomerCommunicationPreferences(
        preferences: CustomerCommunicationPreferenceInput
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<
                SetCustomerCommunicationPreferencesMutation,
                SetCustomerCommunicationPreferencesMutationVariables
            >({
                mutation: SetCustomerCommunicationPreferencesDocument,
                variables: {
                    preference: preferences,
                },
                fetchPolicy: 'no-cache',
            })
                .then(() => resolve())
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async setUserBillingAddress(address: UserAddressInput): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.mutate<SetUserBillingAddressMutation, SetUserBillingAddressMutationVariables>({
                    mutation: SetUserBillingAddressDocument,
                    variables: {
                        address: address,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (response.data?.setUserBillingAddress) {
                            userInfoVar(response.data?.setUserBillingAddress as UserInfo);
                            return resolve();
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            } else {
                return reject(CTErrors.NOT_LOGGED_ON);
            }
        });
    }

    public static async setUserDeliveryAddress(address: UserAddressInput): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.mutate<SetUserDeliveryAddressMutation, SetUserDeliveryAddressMutationVariables>({
                    mutation: SetUserDeliveryAddressDocument,
                    variables: {
                        address: address,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (response.data?.setUserDeliveryAddress) {
                            userInfoVar(response.data?.setUserDeliveryAddress as UserInfo);
                            return resolve();
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            } else {
                return reject(CTErrors.NOT_LOGGED_ON);
            }
        });
    }

    public static async changeEmail(
        password: string,
        oldEmail: string,
        newEmail: string
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.mutate<ChangeEmailMutation, ChangeEmailMutationVariables>({
                    mutation: ChangeEmailDocument,
                    variables: {
                        password: password,
                        oldEmail: oldEmail,
                        newEmail: newEmail,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (response.data?.changeEmail) {
                            userInfoVar(response.data?.changeEmail as UserInfo);
                            return resolve();
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            }
        });
    }

    public static async changePassword(currentPassword: string, newPassword: string): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.mutate<ChangePasswordMutation, ChangePasswordMutationVariables>({
                    mutation: ChangePasswordDocument,
                    variables: {
                        currentPassword: currentPassword,
                        newPassword: newPassword,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (response.data?.changePassword) {
                            userInfoVar(response.data?.changePassword as UserInfo);
                            return resolve();
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            }
        });
    }

    public static async removeAddress(addressId: string): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.mutate<RemoveAddressMutation, RemoveAddressMutationVariables>({
                    mutation: RemoveAddressDocument,
                    variables: {
                        addressId: addressId,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (response.data?.removeAddress) {
                            userInfoVar(response.data?.removeAddress as UserInfo);
                            return resolve();
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            }
        });
    }

    public static async checkUser(email: string): Promise<boolean | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.query<CheckUserQuery, CheckUserQueryVariables>({
                query: CheckUserDocument,
                variables: {
                    email: email,
                },
                fetchPolicy: 'no-cache',
            })
                .then((response) => {
                    if (response.data?.checkUser) {
                        return resolve(!!response.data.checkUser);
                    }

                    return resolve(false);
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async addFavoriteStore(storeId: string): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.mutate<AddFavoriteStoreMutation, AddFavoriteStoreMutationVariables>({
                    mutation: AddFavoriteStoreDocument,
                    variables: {
                        storeId: storeId,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (response.data?.addFavoriteStore) {
                            userInfoVar(response.data?.addFavoriteStore as UserInfo);
                            return resolve();
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            }
        });
    }

    public static async removeFavoriteStore(storeId: string): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.mutate<RemoveFavoriteStoreMutation, RemoveFavoriteStoreMutationVariables>({
                    mutation: RemoveFavoriteStoreDocument,
                    variables: {
                        storeId: storeId,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (response.data?.removeFavoriteStore) {
                            userInfoVar(response.data?.removeFavoriteStore as UserInfo);
                            return resolve();
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            }
        });
    }

    public static logout(): void {
        LogAction('Logging out...');

        localStorage.removeItem(StorageEnum.token);

        userInfoVar(null);
        isLoggedInVar(false);

        // Fetch new cart and reload the application, so the withAuth or withoutAuth HOC can take over the flow.
        // If the user is currently on a page that requires login, he will be redirected to the login page.
        // If the user is currently on a page that doesn't require a login, the current page will be reloaded.
        CartDataAccess.resetCart().then(() => {
            window.location.reload();
        });
    }

    public static async requestEmailConfirmDigits(): Promise<boolean | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar() && userInfoVar()?.id) {
                Commercetools.mutate<RequestEmailConfirmDigitsMutation, RequestEmailConfirmDigitsMutationVariables>({
                    mutation: RequestEmailConfirmDigitsDocument,
                    variables: {
                        customerId: userInfoVar()?.id,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (typeof response.data?.requestEmailConfirmDigits === 'boolean') {
                            return resolve(response.data?.requestEmailConfirmDigits);
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            } else {
                LogWarning('Unable to request confirmation email.');
            }
        });
    }

    public static async confirmEmailByDigits(digits: string): Promise<boolean | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar()) {
                Commercetools.mutate<ConfirmEmailByDigitsMutation, ConfirmEmailByDigitsMutationVariables>({
                    mutation: ConfirmEmailByDigitsDocument,
                    variables: {
                        customerId: userInfoVar()?.id,
                        digits: digits,
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then((response) => {
                        if (typeof response.data?.confirmEmailByDigits === 'boolean') {
                            return resolve(response.data?.confirmEmailByDigits);
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: ApolloError) => reject(CTErrors.processError(error)));
            } else {
                return reject(CTErrors.NOT_LOGGED_ON);
            }
        });
    }
}
