import { useReactiveVar } from '@apollo/client';
import { CartIndicator } from '@goed-platform/cart/ui';
import { Category } from '@goed-platform/graphql/types';
import { cartVarUld } from '@goed-platform/shared/data-access';
import { RoutesEnum } from '@goed-platform/shared/types';
import { SVGChevronDown, SVGExternalLink, SVGShoppingBag } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC, PropsWithChildren, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Subheader } from './subheader';

// eslint-disable-next-line @typescript-eslint/ban-types
type SubheaderUitleendienstProps = PropsWithChildren<{
    categories: Category[];
    readOnly?: boolean;
}>;

export const SubheaderUitleendienst: FC<SubheaderUitleendienstProps> = ({
    categories,
    readOnly = false,
}: SubheaderUitleendienstProps) => {
    const { t } = useTranslation();
    const cartUld = useReactiveVar(cartVarUld);
    const [showCategories, setShowCategories] = useState(false);

    return (
        <div className="bg-primary-a10">
            <div className="container-lg">
                <ClickAwayListener onClickAway={() => setShowCategories(false)}>
                    <div className="position-relative d-flex justify-content-between align-items-center py-4 py-md-6">
                        <div className="d-flex align-items-center">
                            {!readOnly ? (
                                <>
                                    <button
                                        className="btn rounded-sm d-inline-flex align-items-center font-size-14 font-size-md-18 font-weight-bold text-white bg-primary mr-6"
                                        onClick={() => setShowCategories(!showCategories)}
                                    >
                                        {t('subheader.uitleendienst.cta.text')}{' '}
                                        <SVGChevronDown className="text-white ml-2" />
                                    </button>
                                    <Link href={RoutesEnum.rentalStoreProducts}>
                                        <a className="border-0 d-none d-lg-block font-weight-bold text-primary mr-6 text-truncate">
                                            {t('subheader.uitleendienst.overview')}
                                        </a>
                                    </Link>
                                    <a
                                        href="https://hulp-en-contact.goed.be/"
                                        className="border-0 d-none d-lg-inline-flex align-items-center font-weight-bold text-primary mr-6 text-truncate"
                                    >
                                        {t('subheader.uitleendienst.faq')}
                                        <SVGExternalLink className="text-primary ml-1" />
                                    </a>
                                </>
                            ) : (
                                <div className="">{t('subheader.uitleendienst.cta.text')}</div>
                            )}
                        </div>
                        <CartIndicator
                            href={RoutesEnum.rentalStoreCart}
                            text={t('subheader.uitleendienst.cart')}
                            quantity={cartUld?.totalQuantity}
                            Icon={SVGShoppingBag}
                            iconTitle="shopping-bag"
                            className="text-primary"
                            indicatorClassName="bg-primary"
                        />
                        {!readOnly && (
                            <Subheader
                                baseUrl={RoutesEnum.rentalStoreLanding}
                                title={t('ULD.title')}
                                visible={showCategories}
                                setVisible={setShowCategories}
                                categories={categories}
                            />
                        )}
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};
