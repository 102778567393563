import { useReactiveVar } from '@apollo/client';
import { CheckoutReservationAddress } from '@goed-platform/checkout/ui';
import { formatPriceWithValuta } from '@goed-platform/pricing/util';
import { webResActivePharmacy } from '@goed-platform/shared/data-access';
import { SVGPhone } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useState } from 'react';
import { PricingSummaryItem } from './pricing-summary-uld';

export type PricingConfirmationResProps = PropsWithChildren<{
    items: PricingSummaryItem[];
    showQuantity?: boolean;
    showPharmacy?: boolean;
    className?: string;
}>;

export const PricingConfirmationRes: React.FC<PricingConfirmationResProps> = ({
    items = [],
    showQuantity = true,
    showPharmacy = true,
    className = '',
    children,
    ...restProps
}: PricingConfirmationResProps): JSX.Element => {
    const { t } = useTranslation();
    const activePharmacy = useReactiveVar(webResActivePharmacy);

    return (
        <div className={`w-100 d-flex flex-column ${className}`} {...restProps}>
            {children}
            {items.map((item, key) => (
                <React.Fragment key={key}>
                    {item.price !== null && item.price !== undefined && (
                        <div
                            className={`w-100 d-flex justify-content-between align-items-center font-size-14 font-size-md-16 ${
                                key > 0 ? 'mt-2' : ''
                            }`}
                        >
                            {typeof item.label === 'string' ? <span>{item.label}</span> : item.label}
                            <span className="text-number">
                                {typeof item.price === 'number' ? formatPriceWithValuta(item.price) : item.price}
                            </span>
                        </div>
                    )}
                </React.Fragment>
            ))}
            <div className="list-divider-solid border-neutral-a10 mt-3 mb-2 my-md-4"></div>

            {showPharmacy && (
                <>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="text-neutral font-size-16 font-size-md-18 font-weight-bold">
                            {t('checkout.reservation.thankYou.pharmacyPickup')}
                        </div>
                    </div>
                    <CheckoutReservationAddress
                        address={{
                            box: activePharmacy?.box,
                            city: activePharmacy?.city,
                            houseNumber: activePharmacy?.number,
                            postCode: activePharmacy?.postal_code,
                            streetName: activePharmacy?.street,
                            company: activePharmacy?.name,
                            __typename: 'UserAddress',
                        }}
                        className="font-size-16 font-size-md-18 mb-2 mb-md-4"
                        pharmacySlug={activePharmacy?.meta.slug}
                        displayIcon={true}
                    />
                    {activePharmacy?.services[0]?.phone && (
                        <a
                            className="d-inline-flex align-items-center w-fit"
                            href={`tel:${activePharmacy?.services[0]?.phone ?? ''}`}
                            suppressHydrationWarning
                        >
                            <SVGPhone className="sprite sprite-md text-purple-a50 mr-1" />
                            {activePharmacy?.services[0]?.phone ?? ''}
                        </a>
                    )}
                </>
            )}
        </div>
    );
};
