export enum TargetTypeEnum {
    blank = '_blank',
    self = '_self',
    top = '_top',
    parent = '_parent',
}

export type NavigationItemTargetType = TargetTypeEnum.blank | TargetTypeEnum.self;

export type NavigationItem = {
    locale: string;
    label: string;
    href: string;
    target?: NavigationItemTargetType;
    image?: string;
};
