// import { LogError } from '@goed-platform/shared/utils';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { Coordinate } from '@goed-platform/symfony/types';
import { Config } from '@goed-platform/shared/constants';

const axiosClient: AxiosInstance = axios.create({
    baseURL: process.env['NEXT_PUBLIC_GOOGLE_BASEURL'],
    headers: {
        // 'Content-Type': 'application/json',
        // 'Cache-Control': 'no-cache',
    },
    params: {
        key: process.env['NEXT_PUBLIC_GOOGLE_API_KEY'],
    },
    timeout: Config.axios.timeout,
});

/**
 * Find coordinates by querystring with Google Places API. This is the actual call to the
 * Example: await getCoordinates('Gent');
 */
export const getCoordinates = async (text: string): Promise<Coordinate | Error | AxiosError | undefined> => {
    return await axiosClient
        .get(process.env['NEXT_PUBLIC_GOOGLE_ENDPOINT'] ?? '', {
            params: {
                input: text + ', Belgium',
                inputtype: 'textquery',
                fields: 'geometry',
            },
        })
        .then((response) => {
            const location = response.data?.candidates[0]?.geometry?.location as Coordinate;
            if (!location) {
                return Promise.resolve(undefined);
            }

            return Promise.resolve(location);
        })
        .catch((error: Error | AxiosError) => {
            console.error(`Unable to fetch coordinates for "${text}"`, error); // Don't use LogError function because Bugsnag isn't initialized serverside.
            return Promise.reject(error);
        });
};

/**
 * Find coordinates by querystring with Google Places API. This call runs internally to the /api/places/[queryString]
 * endpoint, to bypass CORS related issues, as the follow up call (to getCoordinates() is ran serverside).
 * Example: await internalFetchCoordinates('Gent');
 */
export const internalFetchCoordinates = async (text: string): Promise<Coordinate | Error | AxiosError> => {
    return await axios
        .get(`/api/places/${text}`)
        .then((response) => {
            return Promise.resolve(response.data as Coordinate);
        })
        .catch((error: Error | AxiosError) => {
            // Don't use LogError function because Bugsnag isn't initialized
            console.error(`Unable to (internally) fetch coordinates for "${text}"`, error);
            return Promise.reject(error);
        });
};
