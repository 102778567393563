/* eslint-disable @typescript-eslint/no-explicit-any */
import { CartItem, CartTotal, Product, ProductCategory, StoreType } from '@goed-platform/graphql/types';
import { Config } from '@goed-platform/shared/constants';
import { isBrowser } from '@goed-platform/shared/utils';

declare global {
    interface Window {
        dataLayer: any;
    }
}

type DataLayersProps = {
    storeType?: StoreType;
};

export class DataLayers {
    private storeType?: StoreType;

    constructor(props: DataLayersProps) {
        this.storeType = props.storeType;
    }

    public static getInstance(props: DataLayersProps) {
        return new DataLayers({ ...props });
    }

    private pushEvent(event: string, data: any): void {
        if (isBrowser()) {
            window.dataLayer = window.dataLayer ?? [];
            window.dataLayer.push({
                event: event,
                store: this.storeType,
                ...data,
            });
        }
    }

    private pushGA4(event: string, key: string, data: unknown): void {
        if (isBrowser()) {
            window.dataLayer = window.dataLayer ?? [];
            window.dataLayer.push({
                event: event,
                store: this.storeType,
                [key]: data,
            });
        }
    }

    private getAppName(): string {
        switch (this.storeType) {
            case StoreType.Uld:
                return Config.global.appName.ULD;

            case StoreType.Res:
                return Config.global.appName.RES;

            default:
                return '';
        }
    }

    public accountCreated(): void {
        this.pushGA4('DLV-GoedShopProfiel', 'accountCreated', 'Yes');
    }

    public accountLogin(): void {
        this.pushGA4('DLV-GoedShopProfiel', 'accountLogin', 'Yes');
    }

    public addToCart(product: Product, quantity = 1) {
        this.pushGA4('add_to_cart', 'ecommerce', {
            currency: Config.global.defaultCurrency,
            value: +product.priceInclTax,
            items: [
                {
                    item_id: product.sku,
                    item_name: product.label,
                    item_category: product.categories
                        ? product.categories?.map((category: ProductCategory | null) => category?.label).join(',')
                        : '',
                    affiliation: this.getAppName(),
                    price: +product.priceInclTax,
                    deposit: +product.depositAmount,
                    quantity: quantity,
                    discount: (product.priceInclTax ?? 0) - (product.specialPriceInclTax ?? 0),
                },
            ],
        });
    }

    public removeFromCart(cartItem: CartItem) {
        this.pushGA4('remove_from_cart', 'ecommerce', {
            currency: Config.global.defaultCurrency,
            value: +cartItem.priceInclTax,
            items: [
                {
                    item_id: cartItem.sku,
                    item_name: cartItem.name,
                    item_category: cartItem.categories ? cartItem.categories?.join(',') : '',
                    affiliation: this.getAppName(),
                    price: +cartItem.priceInclTax,
                    deposit: +cartItem?.depositAmount,
                    quantity: cartItem.quantity,
                    discount: (cartItem.originalPrice ?? 0) - (cartItem.priceInclTax ?? 0),
                },
            ],
        });
    }

    public viewCart(cart: CartTotal) {
        this.pushGA4('view_cart', 'ecommerce', {
            currency: Config.global.defaultCurrency,
            value: cart.grandTotal,
            items:
                cart.items?.map((cartItem) => ({
                    item_id: cartItem?.sku,
                    item_name: cartItem?.name,
                    item_category: cartItem?.categories ? cartItem?.categories?.join(',') : '',
                    affiliation: this.getAppName(),
                    price: +cartItem?.priceInclTax,
                    deposit: +cartItem?.depositAmount,
                    quantity: cartItem?.quantity,
                    discount: (cartItem?.originalPrice ?? 0) - (cartItem?.priceInclTax ?? 0),
                })) ?? [],
        });
    }

    public beginCheckout(cart: CartTotal) {
        this.pushGA4('begin_checkout', 'ecommerce', {
            currency: Config.global.defaultCurrency,
            value: cart.grandTotal,
            items:
                cart.items?.map((cartItem) => ({
                    item_id: cartItem?.sku,
                    item_name: cartItem?.name,
                    item_category: cartItem?.categories ? cartItem?.categories?.join(',') : '',
                    affiliation: this.getAppName(),
                    price: +cartItem?.priceInclTax,
                    deposit: +cartItem?.depositAmount,
                    quantity: cartItem?.quantity,
                    discount: (cartItem?.originalPrice ?? 0) - (cartItem?.priceInclTax ?? 0),
                })) ?? [],
        });
    }

    public addShippingInfo(cart: CartTotal) {
        this.pushGA4('add_shipping_info', 'ecommerce', {
            currency: Config.global.defaultCurrency,
            shipping_tier: `${cart.deliveryAddress?.streetName ?? ''} ${cart.deliveryAddress?.houseNumber ?? ''} ${
                cart.deliveryAddress?.box ?? ''
            }, ${cart.deliveryAddress?.postCode ?? ''} ${cart.deliveryAddress?.city ?? ''}`,
            value: cart.grandTotal,
            items:
                cart.items?.map((cartItem) => ({
                    item_id: cartItem?.sku,
                    item_name: cartItem?.name,
                    item_category: cartItem?.categories ? cartItem?.categories?.join(',') : '',
                    affiliation: this.getAppName(),
                    price: +cartItem?.priceInclTax,
                    deposit: +cartItem?.depositAmount,
                    quantity: cartItem?.quantity,
                    discount: (cartItem?.originalPrice ?? 0) - (cartItem?.priceInclTax ?? 0),
                })) ?? [],
        });
    }

    public addPaymentInfo(cart: CartTotal) {
        this.pushGA4('add_payment_info', 'ecommerce', {
            currency: Config.global.defaultCurrency,
            value: cart.grandTotal,
            coupon: null,
            payment_type: 'on location',
            items:
                cart.items?.map((cartItem) => ({
                    item_id: cartItem?.sku,
                    item_name: cartItem?.name,
                    item_category: cartItem?.categories ? cartItem?.categories?.join(',') : '',
                    affiliation: this.getAppName(),
                    price: +cartItem?.priceInclTax,
                    deposit: +cartItem?.depositAmount,
                    quantity: cartItem?.quantity,
                    discount: (cartItem?.originalPrice ?? 0) - (cartItem?.priceInclTax ?? 0),
                })) ?? [],
        });
    }

    public viewItem(product: Product) {
        this.pushGA4('view_item', 'ecommerce', {
            currency: Config.global.defaultCurrency,
            value: +product.priceInclTax,
            items: [
                {
                    item_id: product.sku,
                    item_name: product.label,
                    item_category: product.categories
                        ? product.categories?.map((category: ProductCategory | null) => category?.label).join(',')
                        : '',
                    affiliation: this.getAppName(),
                    price: +product.priceInclTax,
                    deposit: +product.depositAmount,
                    quantity: +product.minQuantity,
                    discount: (product.priceInclTax ?? 0) - (product.specialPriceInclTax ?? 0),
                },
            ],
        });
    }

    public purchase(order: CartTotal) {
        this.pushGA4('purchase', 'ecommerce', {
            transaction_id: order.orderNumber ?? order.id,
            affiliation: this.getAppName(),
            value: +(order.grandTotal ?? 0),
            deposit: +(order.depositAmountTotal ?? 0),
            tax: +(order.taxAmount ?? 0),
            shipping: +(order.deliveryCost ?? 0),
            currency: Config.global.defaultCurrency,
            items: order?.items?.map((item, key) => ({
                item_id: item?.sku,
                item_name: item?.name,
                item_category: item?.categories ? item?.categories?.join(',') : '',
                affiliation: this.getAppName(),
                price: +item?.priceInclTax,
                deposit: +item?.depositAmount,
                quantity: +item?.quantity,
                index: key,
            })),
        });
        this.pushEvent('purchase', {
            transactionId: order.orderNumber ?? order.id,
            transactionAffiliation: this.getAppName(),
            transactionTotal: +(order.grandTotal ?? 0),
            transactionDepositTotal: +(order.depositAmountTotal ?? 0),
            transactionTax: +(order.taxAmount ?? 0),
            transactionShipping: +(order.deliveryCost ?? 0),
            transactionProducts: order?.items?.map((item) => ({
                sku: item?.sku,
                name: item?.name,
                category: item?.categories ? item?.categories?.join(',') : '',
                price: +item?.priceInclTax,
                deposit: +item?.depositAmount,
                quantity: +item?.quantity,
            })),
        });
    }

    public reservationComplete(order: CartTotal) {
        this.pushGA4('reservation', 'ecommerce', {
            transaction_id: order.orderNumber ?? order.id,
            affiliation: this.getAppName(),
            value: +(order.grandTotal ?? 0),
            deposit: +(order.depositAmountTotal ?? 0),
            tax: +(order.taxAmount ?? 0),
            shipping: +(order.deliveryCost ?? 0),
            currency: Config.global.defaultCurrency,
            items: order?.items?.map((item, key) => ({
                item_id: item?.sku,
                item_name: item?.name,
                item_category: item?.categories ? item?.categories?.join(',') : '',
                affiliation: this.getAppName(),
                price: +item?.priceInclTax,
                deposit: +item?.depositAmount,
                quantity: +item?.quantity,
                index: key,
            })),
        });
        this.pushEvent('reservation', {
            transactionId: order.orderNumber ?? order.id,
            transactionAffiliation: this.getAppName(),
            transactionTotal: +(order.grandTotal ?? 0),
            transactionTax: +(order.taxAmount ?? 0),
            transactionShipping: +(order.deliveryCost ?? 0),
            transactionProducts: order?.items?.map((item) => ({
                sku: item?.sku,
                name: item?.name,
                category: item?.categories ? item?.categories?.join(',') : '',
                price: +item?.priceInclTax,
                deposit: +item?.depositAmount,
                quantity: +item?.quantity,
            })),
        });
    }

    public appointCreated(): void {
        this.pushGA4('Appoint', 'created', 'Yes');
    }

    public appointUpdated(): void {
        this.pushGA4('Appoint', 'updated', 'Yes');
    }

    public appointMoved(): void {
        this.pushGA4('Appoint', 'moved', 'Yes');
    }

    public appointDeleted(): void {
        this.pushGA4('Appoint', 'deleted', 'Yes');
    }
}
