import { MutationFunction } from '@apollo/client';
import {
    CartItem,
    CartTotal,
    ProductServiceType,
    RemoveProductFromCartMutation,
    RemoveProductFromCartMutationVariables,
    StoreType,
    UpdateCartMutation,
    UpdateCartMutationVariables,
} from '@goed-platform/graphql/types';
import { formatPriceWithValuta } from '@goed-platform/pricing/util';
import { cartVarUld } from '@goed-platform/shared/data-access';
import { DataLayers } from '@goed-platform/shared/ui';
import { SVGThrash } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';

type CartRowPriceUldProps = {
    cartItem: CartItem;
    cart: CartTotal;
    updateCallback: MutationFunction<UpdateCartMutation, UpdateCartMutationVariables>;
    removeCallback: MutationFunction<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables>;
};

export const CartRowPriceUld: React.FC<CartRowPriceUldProps> = ({
    cartItem,
    cart,
    updateCallback,
    removeCallback,
    ...restProps
}: CartRowPriceUldProps): JSX.Element => {
    const { t } = useTranslation();
    const betterAsCM = cartItem.originalPrice > cartItem.priceInclTax;

    const onRemove = (): void => {
        removeCallback({
            variables: {
                lineItemId: cartItem.itemId,
                cartId: cart.id,
                storeType: StoreType.Uld,
            },
        }).then((result) => cartVarUld(result?.data?.removeProductFromCart as CartTotal));
        DataLayers.getInstance({ storeType: cart.storeType }).removeFromCart(cartItem);
    };

    const onUpdate = (quantity: number): void => {
        if (quantity > 0) {
            updateCallback({
                variables: {
                    quantity: quantity,
                    cartId: cart.id,
                    lineItemId: cartItem.itemId,
                    storeType: StoreType.Uld,
                },
            }).then((result) => cartVarUld(result?.data?.updateCart as CartTotal));
        }
    };

    const frequency =
        cartItem.productServiceType === ProductServiceType.Rental && (cartItem?.rentToOwn || 0) <= 0
            ? '/' + t(`general.periods.${(cartItem.rentalPeriodUnit ?? 'month').toLowerCase()}`)
            : '';

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <div
                        className="hs-8 ws-8 rounded-50 border-0 bg-purple-a10 text-purple d-flex align-items-center justify-content-center mr-6"
                        role="button"
                        onClick={() => onRemove()}
                    >
                        <SVGThrash data-cy="remove-item" className="sprite sprite-trash sprite-sm" title="trash" />
                    </div>

                    <input
                        type="number"
                        data-cy="quantity-input"
                        min="1"
                        max="100"
                        pattern="[0-9]*"
                        className="form-control input-sm hs-12 ws-16 d-inline-block"
                        value={cartItem.quantity}
                        onChange={(e) => onUpdate(parseInt(e.target.value))}
                    />
                </div>
                <div className="d-flex flex-column align-items-end text-end">
                    {cart.isCMMember && betterAsCM && (
                        <div className="text-neutral-a70 font-size-12 font-size-md-16 text-right">
                            <s>
                                {formatPriceWithValuta(cartItem.originalPrice)}
                                {frequency}
                            </s>
                        </div>
                    )}

                    <div
                        className={`font-size-16 font-size-md-22 font-weight-bold text-right line-height-1 mb-0 ${
                            cart.isCMMember && betterAsCM ? 'text-promo' : 'text-neutral'
                        }`}
                    >
                        {formatPriceWithValuta(cartItem.priceInclTax)}
                        {frequency}
                        {cart.isCMMember && betterAsCM && (
                            <span className="font-size-12 font-size-md-14 font-weight-normal">
                                <br /> {t('product.price.cm.asCMMember')}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className="text-neutral-a70 font-size-12 font-size-md-16 text-right mb-0 mt-1">
                {/* Delivery cost */}
                {cartItem.deliveryCost && (
                    <div>
                        {t('product.cost.delivery').toLowerCase()}: {formatPriceWithValuta(cartItem.deliveryCost)}
                    </div>
                )}

                {/* Service cost */}
                {cartItem.serviceCost && (
                    <div>
                        {t('product.cost.service').toLowerCase()}: {formatPriceWithValuta(cartItem.serviceCost)}
                    </div>
                )}

                {/* Deposit amount */}
                {cartItem.depositAmount && (
                    <div>
                        {t('product.cost.deposit').toLowerCase()}: {formatPriceWithValuta(cartItem.depositAmount)}
                    </div>
                )}

                {/* Rental advance */}
                {cartItem.productServiceType === ProductServiceType.Rental && (
                    <div>
                        {t('product.cost.rentalAdvance').toLowerCase()}: {formatPriceWithValuta(cartItem.rentalAdvance)}
                    </div>
                )}
            </div>
        </>
    );
};
