export enum OGType {
    article = 'article',
    place = 'place',
    product = 'product',
    productGroup = 'product.group',
    profile = 'profile',
    website = 'website',
}

export type OGImage = {
    url: string;
    width: number;
    height: number;
};
