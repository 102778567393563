/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ApolloProvider } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { BugsnagDataAccess } from '@goed-platform/bugsnag/data-access';
import { nextI18nextConfig } from '@goed-platform/shared/constants';
import { Commercetools } from '@goed-platform/shared/data-access';
import '@goed-platform/shared/styles';
import { CustomAppProps } from '@goed-platform/shared/types';
import { GTMScript, initPiwikPro } from '@goed-platform/shared/ui';
import { Boot, isBrowser, LogError, Token } from '@goed-platform/shared/utils';
import { SymfonyDataAccess } from '@goed-platform/symfony/data-access';
import { WebreservationPharmacy } from '@goed-platform/shared/data-access';
import { appWithTranslation, UserConfig } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { lastUpdatedDate } from '../../../statistics/lastupdate';
import PiwikProProvider from '@piwikpro/next-piwik-pro';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any;
    }
}

console.log('Adding log to see if the deploy was successful!');

function App({ Component, pageProps, navigation }: CustomAppProps): JSX.Element {
    const router = useRouter();
    const webResDataAccessInstance = WebreservationPharmacy.getInstance();

    // Bugsnag
    BugsnagDataAccess.getInstance().connect();
    const ErrorBoundary = BugsnagDataAccess.shouldFireOnThisEnvironment()
        ? Bugsnag.getPlugin('react').createErrorBoundary(React)
        : React.Fragment;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (isBrowser()) {
            const bootIntervalId = Boot.start(router);
            const keepTokenAliveIntervalId = Token.keepAlive();

            return () => {
                // Cleanup intervals after hot module refresh.
                clearInterval(bootIntervalId);
                clearInterval(keepTokenAliveIntervalId);
            };
        }
    }, []);

    useEffect((): (() => void) => {
        if (router.isReady) {
            webResDataAccessInstance.onRouteChange();
            return () => {
                webResDataAccessInstance.onRouteChange();
            };
        }
    }, [router.query.id, router.pathname, router.isReady]);

    useEffect(() => {
        let timesRun = 0;
        const interval = setInterval(() => {
            timesRun += 1;
            if (timesRun === 5) {
                clearInterval(interval);
            }

            initPiwikPro(interval);
        }, 100);
    }, []);

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="last-update" content={`${new Date(lastUpdatedDate).toString()}`} />
            </Head>
            <PiwikProProvider
                containerUrl={process.env.NEXT_PUBLIC_PIWIK_CONTAINER_URL}
                containerId={process.env.NEXT_PUBLIC_PIWIK_PRO_ID}
            >
                <GTMScript />
                <ErrorBoundary>
                    <ApolloProvider client={Commercetools}>
                        <Component {...pageProps} navigation={navigation} />
                    </ApolloProvider>
                </ErrorBoundary>
            </PiwikProProvider>
        </>
    );
}

App.getInitialProps = async (ctx) => {
    try {
        const data = await SymfonyDataAccess.getInstance().getNavigation();
        return { navigation: data };
    } catch (e) {
        LogError('Something went wrong when loading the navigation via SSR getInitialProps in app.tsx', e);
    }
};

export default appWithTranslation(App, nextI18nextConfig as UserConfig);
