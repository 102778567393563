export enum RoutesEnum {
    // Default
    account = '/account',
    appoint = '/afspraak',
    confirmEmail = '/email-bevestigen',
    error404 = '/404',
    forgotPassword = '/wachtwoord-vergeten',
    forgotPasswordSucces = '/wachtwoord-vergeten/success',
    home = '/',
    login = '/inloggen',
    order = '/order',
    ourOffering = '/ons-aanbod',
    register = '/registreren',
    reserveer = '/reserveer',

    // Account
    logout = '/account/logout',
    myFavoriteStores = '/account/stores',
    myInfo = '/account/info',
    myOrders = '/account/orders',
    newsletterSettings = '/account/newsletter',

    // Uitleendienst
    rentalStoreCart = '/hulpmiddelen-huren-uitleendienst/cart',
    rentalStoreCheckout = '/hulpmiddelen-huren-uitleendienst/checkout',
    rentalStoreCheckoutGuest = '/hulpmiddelen-huren-uitleendienst/checkout/guest',
    rentalStoreCheckoutVerify = '/hulpmiddelen-huren-uitleendienst/checkout/verify',
    rentalStoreCheckoutCompleteAccount = '/hulpmiddelen-huren-uitleendienst/checkout/complete-account',
    rentalStoreCheckoutLogin = '/hulpmiddelen-huren-uitleendienst/checkout/login',
    rentalStoreCheckoutRegister = '/hulpmiddelen-huren-uitleendienst/checkout/registreren',
    rentalStoreDeliveryDetails = '/hulpmiddelen-huren-uitleendienst/checkout/delivery-details',
    rentalStoreLanding = '/hulpmiddelen-huren-uitleendienst',
    rentalStorePaymentInfo = '/hulpmiddelen-huren-uitleendienst/checkout/payment-info',
    rentalStoreProduct = '/hulpmiddelen-huren-uitleendienst/product',
    rentalStoreProducts = '/hulpmiddelen-huren-uitleendienst/aanbod-hulpmiddelen-huren',
    rentalStoreSearch = '/hulpmiddelen-huren-uitleendienst/search',

    // Webreservation
    webReservationLanding = '/webreservatie',
    webReservationProducts = '/webreservatie/aanbod-webreservatie',
    webReservationProduct = '/webreservatie/product',
    webReservationCart = '/webreservatie/cart',
    webReservationCheckout = '/webreservatie/checkout',
    webReservationCheckoutVerify = '/webreservatie/checkout/verify',
    webReservationCheckoutCompleteAccount = '/webreservatie/checkout/complete-account',
    webReservationCheckoutReservationDetails = '/webreservatie/checkout/reservation-details',
    webReservationCheckoutLogin = '/webreservatie/checkout/login',
    webReservationCheckoutRegister = '/webreservatie/checkout/registreren',
    webReservationPrescriptions = '/webreservatie/mijn-voorschriften',
    webReservationOrder = '/webreservatie/order',
    webReservationSearch = '/webreservatie/search',

    // External
    locations = 'https://www.goed.be/nl/vestigingen',
}
