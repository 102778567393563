/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Config } from '@goed-platform/shared/constants';
import { LogError } from '@goed-platform/shared/utils';
import {
    Coordinate,
    InfoHubExpert,
    MappedNavigation,
    NavigationItem,
    PharmacyWithOpeningHours,
    RentalStore,
    RentalStoreWithOpeningHours,
    SymfonyCategory,
    SymfonyPharmacy,
    SymfonyStore,
} from '@goed-platform/symfony/types';
import axios, { AxiosError, AxiosInstance, AxiosRequestHeaders } from 'axios';

type SymfonyDataAccessProps = {
    locale?: string;
    username?: string;
    password?: string;
    baseUrl?: string;
    cacheMaxAge?: number;
};

export class SymfonyDataAccess {
    private headers: AxiosRequestHeaders;
    private username: string;
    private password: string;
    private token: string;
    private hasCredentials: boolean;
    private baseUrl: string;
    private axiosClient: AxiosInstance;
    private cacheMaxAge?: number;
    private locale: string;

    private constructor({ username, password, baseUrl, cacheMaxAge, locale }: SymfonyDataAccessProps) {
        this.locale = locale ?? Config.i18n.realDefaultLocale;
        this.username = username ?? process.env['NEXT_PUBLIC_SYMFONY_BASIC_AUTH_USERNAME']!;
        this.password = password ?? process.env['NEXT_PUBLIC_SYMFONY_BASIC_AUTH_PASSWORD']!;
        this.token = `${this.username}:${this.password}`;
        this.hasCredentials = !!this.username && !!this.password;
        this.headers = this.getHeaders(cacheMaxAge);
        this.baseUrl = baseUrl ?? process.env['NEXT_PUBLIC_SYMFONY_BASE_ENDPOINT']!;
        this.axiosClient = axios.create({
            baseURL: this.baseUrl,
            headers: this.headers,
            withCredentials: this.hasCredentials,
            timeout: Config.axios.timeout,
        });
    }

    public static getInstance(props?: SymfonyDataAccessProps) {
        return new SymfonyDataAccess({ ...props });
    }

    private getHeaders(cacheMaxAge = 3600): AxiosRequestHeaders {
        let authorizationHeaders = {};

        if (this.hasCredentials) {
            authorizationHeaders = {
                Authorization: `Basic ${Buffer.from(this.token, 'utf8').toString('base64')}`,
            };
        }

        return {
            'Content-Type': 'application/json',
            'Cache-Control': `public max-age ${cacheMaxAge}`,
            ...authorizationHeaders,
        };
    }

    /**
     * Search for any stores from a given name and coordinate.
     * Example: await SymfonyDataAccess.searchStores('Gent', { lat: 51.06783069999999, lng: 3.7290914 });
     */
    public async searchStores(name: string, coordinate: Coordinate): Promise<SymfonyStore[] | Error | AxiosError> {
        return new Promise((resolve, reject) => {
            this.axiosClient
                .post(process.env['NEXT_PUBLIC_SYMFONY_LOCATION_SEARCH_STORES_ENDPOINT']!, {
                    location: { name: name, coordinate: coordinate },
                })
                .then((response) => {
                    if (response.data?.data) {
                        resolve(response.data.data as SymfonyStore[]);
                    } else {
                        LogError('Unexpected output for searchStores', response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(`Unable to fetch stores for coordinate ${coordinate.lat}, ${coordinate.lng}`, error);
                    reject(error);
                });
        });
    }

    /**
     * Search for rental stores from a given name and coordinate.
     * Example: await SymfonyDataAccess.searchRentalStores('Gent', { lat: 51.06783069999999, lng: 3.7290914 });
     */
    public async searchRentalStores(name: string, coordinate: Coordinate): Promise<RentalStore[] | Error | AxiosError> {
        return new Promise((resolve, reject) => {
            this.axiosClient
                .post(process.env['NEXT_PUBLIC_SYMFONY_LOCATION_SEARCH_RENTAL_STORES_ENDPOINT']!, {
                    location: { name: name, coordinate: coordinate },
                })
                .then((response) => {
                    if (response.data?.data) {
                        resolve(response.data.data as RentalStore[]);
                    } else {
                        LogError('Unexpected output for searchRentalStores', response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(
                        `Unable to fetch rental stores for coordinate ${coordinate.lat}, ${coordinate.lng}`,
                        error
                    );
                    reject(error);
                });
        });
    }

    /**
     * Search for rental stores from a given name and coordinate.
     * Example: await SymfonyDataAccess.searchRentalStores('Gent', { lat: 51.06783069999999, lng: 3.7290914 });
     */
    public async searchPharmacies(
        name: string,
        coordinate: Coordinate
    ): Promise<SymfonyPharmacy[] | Error | AxiosError> {
        return new Promise((resolve, reject) => {
            this.axiosClient
                .post(process.env['NEXT_PUBLIC_SYMFONY_LOCATION_SEARCH_APOTHECARY_ENDPOINT']!, {
                    location: { name: name, coordinate: coordinate },
                })
                .then((response) => {
                    if (response.data?.data) {
                        resolve(response.data.data as SymfonyPharmacy[]);
                    } else {
                        LogError('Unexpected output for searchPharmacies', response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(`Unable to fetch pharmacies for coordinate ${coordinate.lat}, ${coordinate.lng}`, error);
                    reject(error);
                });
        });
    }

    /**
     * Fetch the details (incl. opening hours) of a rental store.
     * Example: await SymfonyDataAccess.getRentalStore(36);
     */
    public async getRentalStore(id: number): Promise<RentalStoreWithOpeningHours | Error | AxiosError> {
        if (id === null) {
            return Error('Not a valid id');
        }

        return new Promise((resolve, reject) => {
            this.axiosClient
                .get(process.env['NEXT_PUBLIC_SYMFONY_LOCATION_RENTAL_STORE_ENDPOINT'] + `?id=${id}`)
                .then((response) => {
                    if (response.data?.data?.[0]) {
                        resolve(response.data.data[0] as RentalStoreWithOpeningHours);
                    } else {
                        LogError('Unexpected output for getRentalStore', response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(`Unable to fetch rental store for rental store id ${id}`, error);
                    reject(error);
                });
        });
    }

    public async getShopGlobalCategories(): Promise<SymfonyCategory[] | Error | AxiosError> {
        return new Promise((resolve, reject) => {
            this.axiosClient
                .get(`/public/${this.locale}/catalog/category`)
                .then((response) => {
                    if (response.data?.data) {
                        resolve(response.data.data as SymfonyCategory[]);
                    } else {
                        LogError('Unexpected output for getShopGlobalCategories', response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(`Unable to fetch shop global categories.`, error);
                    reject(error);
                });
        });
    }

    public async getInfoHubExperts(): Promise<InfoHubExpert[] | Error | AxiosError> {
        return new Promise((resolve, reject) => {
            this.axiosClient
                .get(`/public/${this.locale}/infohub/expert`)
                .then((response) => {
                    if (response.data?.data) {
                        resolve(response.data.data as InfoHubExpert[]);
                    } else {
                        LogError('Unexpected output for infohub experts', response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(`Unable to fetch infohub experts.`, error);
                    reject(error);
                });
        });
    }

    private mapNavigation(data: NavigationItem[]): MappedNavigation {
        return data.reduce(
            (previousValue: any, currentValue: NavigationItem) => ({
                ...previousValue,
                [`${currentValue.type}`]: currentValue.items,
            }),
            {}
        );
    }

    public async getNavigation(): Promise<MappedNavigation | Error | AxiosError> {
        return new Promise((resolve, reject) => {
            this.axiosClient
                .get(`/public/${this.locale}/navigation`)
                .then((response) => {
                    if (response.data?.data) {
                        resolve(this.mapNavigation(response.data.data));
                    } else {
                        LogError('Unexpected output for navigation', response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(`Unable to fetch navigation.`, error);
                    reject(error);
                });
        });
    }

    public async getPharmacies(): Promise<SymfonyPharmacy[] | Error | AxiosError> {
        return new Promise((resolve, reject) => {
            this.axiosClient
                .get(`/public/${this.locale}/location/apothecary`)
                .then((response) => {
                    if (response.data?.data) {
                        resolve(response.data.data as SymfonyPharmacy[]);
                    } else {
                        LogError('Unexpected output for pharmacies', response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(`Unable to fetch pharmacies.`, error);
                    reject(error);
                });
        });
    }

    public async getPharmacyById(id: string): Promise<SymfonyPharmacy | Error | AxiosError> {
        return new Promise((resolve, reject) => {
            this.axiosClient
                .get(`/public/${this.locale}/location/apothecary/${id}`)
                .then((response) => {
                    if (response.data?.data[0]) {
                        resolve(response.data.data[0] as SymfonyPharmacy);
                    } else {
                        LogError(`Unexpected output for pharmacy with id: ${id}`, response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(`Unable to fetch pharmacy with id: ${id}.`, error);
                    reject(error);
                });
        });
    }

    public async getPharmacyWithHours(id: number): Promise<PharmacyWithOpeningHours | Error | AxiosError> {
        return new Promise((resolve, reject) => {
            this.axiosClient
                .get(process.env['NEXT_PUBLIC_SYMFONY_LOCATION_APOTHECARY_ENDPOINT'] + `?id=${id}`)
                .then((response) => {
                    if (response.data?.data[0]) {
                        resolve(response.data.data[0] as PharmacyWithOpeningHours);
                    } else {
                        LogError(`Unexpected output for pharmacy with id: ${id}`, response);
                        reject();
                    }
                })
                .catch((error: Error | AxiosError) => {
                    LogError(`Unable to fetch pharmacy with id: ${id}.`, error);
                    reject(error);
                });
        });
    }
}
