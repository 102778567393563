import React from 'react';
import { SVGAlertTriangle } from '@goed-platform/shared/utils';

type FieldErrorProps = {
    className?: string;
    error?: string;
    touched?: boolean;
};

export const FieldError: React.FC<FieldErrorProps> = ({
    className = '',
    error,
    touched,
    ...restProps
}: FieldErrorProps): JSX.Element => {
    if (!!error && touched) {
        return (
            <div className={`d-flex align-items-center mt-2 text-danger font-size-14 ${className}`} role="alert">
                <SVGAlertTriangle className="sprite sprite-xs mr-1" />
                {error}
            </div>
        );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};

export default FieldError;
