import { useReactiveVar } from '@apollo/client';
import { webResActivePharmacy } from '@goed-platform/shared/data-access';
import { RoutesEnum } from '@goed-platform/shared/types';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren } from 'react';
import { Button } from '../button';

type WebreservationPrescriptionCTAProps = PropsWithChildren<{
    className?: string;
}>;

export const WebreservationPrescriptionCTA: FC<WebreservationPrescriptionCTAProps> = ({
    className = '',
    children,
}: WebreservationPrescriptionCTAProps): JSX.Element => {
    const router = useRouter();
    const { t } = useTranslation();
    const activePharmacy = useReactiveVar(webResActivePharmacy);

    return (
        <div className={`bg-off-white-dark p-4 p-md-8 rounded-lg ${className}`}>
            <h4 className="text-primary font-size-20 font-size-md-24 line-height-28 mb-2 mb-md-4">
                {t('webreservation.categories.overview.cta.title')}
            </h4>
            <p
                className="font-size-16 font-size-md-18 line-height-24 line-height-md-28 mb-4 mb-md-6"
                suppressHydrationWarning
            >
                {t('webreservation.categories.overview.cta.description', {
                    name: activePharmacy?.name,
                })}
            </p>
            <Button
                onClick={() => router.push(RoutesEnum.webReservationCart)}
                className="w-fit"
                label={t('webreservation.categories.overview.cta.text')}
            />
            {children}
        </div>
    );
};
