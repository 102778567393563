import { useTranslation } from 'next-i18next';
import { TranslationUtils } from '@goed-platform/shared/utils';

type CheckmarkListProps = {
    translationKeyPrefix: string;
};

const CheckmarkListItems = (translationItems: string[]): JSX.Element[] => {
    return translationItems.map((item, key) => (
        <li key={key} className="mb-1">
            {item}
        </li>
    ));
};

export const CheckmarkList: React.FC<CheckmarkListProps> = ({
    translationKeyPrefix,
}: CheckmarkListProps): JSX.Element => {
    const { t } = useTranslation();

    const translationItems: string[] = TranslationUtils.getTranslationKeyArray(translationKeyPrefix).map((key) =>
        t(key)
    );

    return <ul className="list-checkmarks font-size-16 font-size-md-18">{CheckmarkListItems(translationItems)}</ul>;
};
