import { AxiosError } from 'axios';
import { SymfonyPharmacyByWeek, SymfonyPharmacyCurrentOrNextOpenState } from './symfony-pharmacy';

export type PharmacyWithOpeningHours = {
    id: number;
    name: string;
    street: string;
    number: string;
    box?: string;
    city: string;
    postal_code: string;
    country: string;
    url: string;
    services: PharmacyWithOpeningHoursService[];
};

export type PharmacyWithOpeningHoursService = {
    id: number;
    specializations: string[];
    has_rental_store_specialization: boolean;
    type: string;
    email: string;
    phone: string;
    opening_hours: PharmacyWithOpeningHoursList;
};

export type PharmacyWithOpeningHoursList = {
    byWeek: SymfonyPharmacyByWeek;
    currentOrNextOpenState: SymfonyPharmacyCurrentOrNextOpenState;
};

export type PharmacyWithOpeningHoursListByWeek = {
    thisWeek: PharmacyWithOpeningHoursListDay[];
    nextWeek: PharmacyWithOpeningHoursListDay[];
};

export type PharmacyWithOpeningHoursListDay = {
    scheme: PharmacyWithOpeningHoursDayScheme;
    isToday: boolean;
    isInPast: boolean;
};

export type PharmacyWithOpeningHoursDayScheme = {
    opening_hours: PharmacyWithOpeningHoursDaySchemeOpeningHour[];
    data?: PharmacyWithOpeningHoursData;
};

export type PharmacyWithOpeningHoursDaySchemeOpeningHour = {
    start: PharmacyWithOpeningHoursDaySchemeOpeningHourFragment;
    end: PharmacyWithOpeningHoursDaySchemeOpeningHourFragment;
    data?: PharmacyWithOpeningHoursData;
};

export type PharmacyWithOpeningHoursDaySchemeOpeningHourFragment = {
    hours: number;
    minutes: number;
    data?: PharmacyWithOpeningHoursData;
};

export type PharmacyWithOpeningHoursData = {
    onlyByAppointment: boolean;
    description?: string;
};

export const isPharmacyWithOpeningHours = (pharmacy: PharmacyWithOpeningHours | AxiosError | Error): boolean => {
    return (pharmacy as PharmacyWithOpeningHours).city !== undefined;
};
