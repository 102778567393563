export enum ImageLayoutEnum {
    fixed = 'fixed',
    fill = 'fill',
    intrinsic = 'intrinsic',
    responsive = 'responsive',
}

export enum ImageObjectfitEnum {
    fill = 'fill',
    contain = 'contain',
    cover = 'cover',
    none = 'none',
    scaleDown = 'scale-down',
}

export enum ImageRatio {
    is21by9 = '21by9',
    is16by9 = '16by9',
    is4by3 = '4by3',
    is3by2 = '3by2',
    is2by1 = '2by1',
    is1by1 = '1by1',
}
