import { ApolloError } from '@apollo/client';
import {
    AddProductToCartDocument,
    AddProductToCartMutation,
    AddProductToCartMutationVariables,
    CartDeliveryOption,
    CartDocument,
    CartQuery,
    CartQueryVariables,
    CartTotal,
    ChangeCartCmMemberDocument,
    ChangeCartCmMemberMutation,
    ChangeCartCmMemberMutationVariables,
    ChangeCartDeliveryOptionsDocument,
    ChangeCartDeliveryOptionsMutation,
    ChangeCartDeliveryOptionsMutationVariables,
    ProductServiceType,
    ReSetCartDeliveryCostDocument,
    ReSetCartDeliveryCostMutation,
    ReSetCartDeliveryCostMutationVariables,
    SetBillingAddressDocument,
    SetBillingAddressMutation,
    SetBillingAddressMutationVariables,
    SetCommentForCartDocument,
    SetCommentForCartMutation,
    SetCommentForCartMutationVariables,
    SetCommunicationPreferenceForResDocument,
    SetCommunicationPreferenceForResMutation,
    SetCommunicationPreferenceForResMutationVariables,
    SetDeliveryAddressDocument,
    SetDeliveryAddressMutation,
    SetDeliveryAddressMutationVariables,
    SetStoreForCartDocument,
    SetStoreForCartMutation,
    SetStoreForCartMutationVariables,
    SpecialDelivery,
    StoreType,
    SyncCustomerProfileToCartDocument,
    SyncCustomerProfileToCartMutation,
    SyncCustomerProfileToCartMutationVariables,
    UserAddressInput,
} from '@goed-platform/graphql/types';
import { CTErrors, ErrorItemType } from '@goed-platform/shared/constants';
import {
    CartActions,
    CartIdActions,
    cartIdVarRes,
    cartIdVarUld,
    cartVarRes,
    cartVarUld,
    Commercetools,
    isLoggedInVar,
    StorageEnum,
    userInfoVar,
} from '@goed-platform/shared/data-access';
import { LogInfo, LogSuccess } from '@goed-platform/shared/utils';

export class CartDataAccess {
    public static async fetchCart(): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            const carts = CartActions.getCarts();
            if (carts.find((cart) => cart.cartId && cart.cartId.length > 0)) {
                Commercetools.query<CartQuery, CartQueryVariables>({
                    query: CartDocument,
                    variables: {
                        carts: carts.filter((cart) => cart.cartId && cart.cartId.length > 0),
                    },
                    fetchPolicy: 'no-cache',
                })
                    .then(async (response) => {
                        if (response?.data?.cart) {
                            response.data.cart?.forEach((cart) => {
                                CartActions.updateCart(cart?.storeType ?? StoreType.Uld, cart as CartTotal);
                            });
                            return resolve();
                        }

                        return reject(CTErrors.processUnexpectedOutput());
                    })
                    .catch((error: Error | ApolloError) => reject(CTErrors.processError(error)));
            }
            resolve();
        });
    }

    public static async resetCart(storeType?: StoreType): Promise<void> {
        if (!storeType) {
            LogInfo(`Resetting all carts...`);
            Object.values(StoreType).forEach((storeType) => CartDataAccess.destroyCart(storeType));
        } else {
            LogInfo(`Resetting the cart ${storeType} ...`);
            CartDataAccess.destroyCart(storeType);
        }
        return Promise.resolve();
    }

    public static async setBillingAddress(
        storeType: StoreType,
        billingAddress: UserAddressInput,
        customerAddressId?: string
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<SetBillingAddressMutation, SetBillingAddressMutationVariables>({
                mutation: SetBillingAddressDocument,
                variables: {
                    cartId: CartIdActions.getCartId(storeType),
                    storeType: storeType,
                    customerAddressId: customerAddressId,
                    billingAddress: billingAddress,
                },
                fetchPolicy: 'no-cache',
            })
                .then(async (response) => {
                    if (response?.data?.setBillingAddress) {
                        CartActions.updateCart(storeType, response.data.setBillingAddress as CartTotal);
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async setDeliveryAddress(
        storeType: StoreType,
        deliveryAddress: UserAddressInput,
        customerAddressId?: string
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<SetDeliveryAddressMutation, SetDeliveryAddressMutationVariables>({
                mutation: SetDeliveryAddressDocument,
                variables: {
                    cartId: CartIdActions.getCartId(storeType),
                    customerAddressId: customerAddressId,
                    deliveryAddress: deliveryAddress,
                },
                fetchPolicy: 'no-cache',
            })
                .then(async (response) => {
                    if (response?.data?.setDeliveryAddress) {
                        CartActions.updateCart(storeType, response.data.setDeliveryAddress as CartTotal);
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async resetCartDeliveryCost(
        storeType: StoreType,
        skus: string[],
        specialDelivery: SpecialDelivery
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<ReSetCartDeliveryCostMutation, ReSetCartDeliveryCostMutationVariables>({
                mutation: ReSetCartDeliveryCostDocument,
                variables: {
                    cartId: CartIdActions.getCartId(storeType),
                    skus: skus,
                    specialDelivery: specialDelivery,
                },
            })
                .then(async (response) => {
                    if (response?.data?.reSetCartDeliveryCost) {
                        CartActions.updateCart(storeType, response.data.reSetCartDeliveryCost as CartTotal);
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async setComment(storeType: StoreType, comment: string): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<SetCommentForCartMutation, SetCommentForCartMutationVariables>({
                mutation: SetCommentForCartDocument,
                variables: {
                    cartId: CartIdActions.getCartId(storeType),
                    storeType: storeType,
                    comment: comment,
                },
                fetchPolicy: 'no-cache',
            })
                .then(async (response) => {
                    if (response?.data?.setCommentForCart) {
                        CartActions.updateCart(storeType, response.data.setCommentForCart as CartTotal);
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async setCommunicationPreference(
        storeType: StoreType,
        communicationPreference: string
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<
                SetCommunicationPreferenceForResMutation,
                SetCommunicationPreferenceForResMutationVariables
            >({
                mutation: SetCommunicationPreferenceForResDocument,
                variables: {
                    cartId: CartIdActions.getCartId(storeType),
                    communicationPreference: communicationPreference,
                },
                fetchPolicy: 'no-cache',
            })
                .then(async (response) => {
                    if (response?.data?.setCommunicationPreferenceForRES) {
                        CartActions.updateCart(storeType, response.data.setCommunicationPreferenceForRES as CartTotal);
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async changeCartDeliveryOptions(
        storeType: StoreType,
        cartDeliveryOption: CartDeliveryOption
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<ChangeCartDeliveryOptionsMutation, ChangeCartDeliveryOptionsMutationVariables>({
                mutation: ChangeCartDeliveryOptionsDocument,
                variables: {
                    cartId: CartIdActions.getCartId(storeType),
                    cartDeliveryOption: cartDeliveryOption,
                },
                fetchPolicy: 'no-cache',
            })
                .then(async (response) => {
                    if (response?.data?.changeCartDeliveryOptions) {
                        CartActions.updateCart(storeType, response.data.changeCartDeliveryOptions as CartTotal);
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async changeCartCMMember(storeType: StoreType, state: boolean): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<ChangeCartCmMemberMutation, ChangeCartCmMemberMutationVariables>({
                mutation: ChangeCartCmMemberDocument,
                variables: {
                    cartId: CartIdActions.getCartId(storeType),
                    isCMMember: state,
                },
                fetchPolicy: 'no-cache',
            })
                .then((response) => {
                    if (response?.data?.changeCartCMMember) {
                        CartActions.updateCart(storeType, response.data.changeCartCMMember as CartTotal);
                        LogSuccess(
                            `${state ? 'Activated' : 'Deactivated'} CM membership on cart ${CartIdActions.getCartId(
                                storeType
                            )}`
                        );
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async addProductToCart(
        storeType: StoreType,
        quantity: number,
        sku: string,
        productServiceType: ProductServiceType
    ): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            switch (storeType) {
                case StoreType.Uld:
                    productServiceType =
                        productServiceType === ProductServiceType.Rental
                            ? ProductServiceType.Rental
                            : ProductServiceType.RentalAccessory;
                    break;

                case StoreType.Res:
                    break;

                default:
                    break;
            }

            Commercetools.mutate<AddProductToCartMutation, AddProductToCartMutationVariables>({
                mutation: AddProductToCartDocument,
                variables: {
                    quantity: quantity,
                    sku: sku,
                    productServiceType: productServiceType,
                    cartId: CartIdActions.getCartId(storeType),
                    storeType: storeType,
                },
                fetchPolicy: 'no-cache',
            })
                .then(async (response) => {
                    if (response?.data?.addProductToCart) {
                        CartActions.updateCart(storeType, response.data.addProductToCart as CartTotal);
                        return resolve();
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async syncCustomerProfileToCart(storeType: StoreType): Promise<void | ErrorItemType> {
        return new Promise((resolve, reject) => {
            if (isLoggedInVar() && userInfoVar()?.emailVerified) {
                const cartId = CartIdActions.getCartId(storeType);
                if (cartId !== null && cartId !== '') {
                    Commercetools.mutate<SyncCustomerProfileToCartMutation, SyncCustomerProfileToCartMutationVariables>(
                        {
                            mutation: SyncCustomerProfileToCartDocument,
                            variables: {
                                cartId: cartId,
                            },
                        }
                    )
                        .then(async (response) => {
                            if (response?.data?.syncCustomerProfileToCart) {
                                CartActions.updateCart(storeType, response.data.syncCustomerProfileToCart as CartTotal);
                                LogSuccess(`Synced customer profile data to cart. Store: ${storeType}`);
                                return resolve();
                            }
                            return reject(CTErrors.processUnexpectedOutput());
                        })
                        .catch((error: ApolloError) => reject(CTErrors.processError(error)));
                } else {
                    return resolve();
                }
            } else return resolve();
        });
    }

    public static async addAccessoriesToCart(storeType: StoreType, accessoriesSkuList: string[]): Promise<void> {
        for (let i = 0; i < accessoriesSkuList.length; i++) {
            await CartDataAccess.addProductToCart(
                storeType,
                1,
                accessoriesSkuList[i],
                // @TODO: check if sale products are stored and replace ProductServiceType to Sale
                // productServiceType: ProductServiceType.Sale,
                ProductServiceType.RentalAccessory
            );
        }

        return;
    }

    private static destroyCart(storeType: StoreType): void {
        switch (storeType) {
            case StoreType.Uld:
                localStorage.removeItem(StorageEnum.cartIdUld);
                cartIdVarUld(null);
                cartVarUld(null);
                break;
            case StoreType.Res:
                localStorage.removeItem(StorageEnum.cartIdRes);
                cartIdVarRes(null);
                cartVarRes(null);
                break;
        }
        return;
    }

    public static async setStoreForCart(storeType: StoreType, storeId: number): Promise<CartTotal | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<SetStoreForCartMutation, SetStoreForCartMutationVariables>({
                mutation: SetStoreForCartDocument,
                variables: {
                    cartId: CartIdActions.getCartId(storeType),
                    storeType: storeType,
                    storeId: storeId,
                },
            })
                .then((response) => {
                    if (response?.data?.setStoreForCart) {
                        CartActions.updateCart(storeType, response.data.setStoreForCart as CartTotal);
                        resolve(response.data.setStoreForCart as CartTotal);
                    } else {
                        reject(CTErrors.processUnexpectedOutput());
                    }
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }
}
