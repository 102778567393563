import { CartItem, ProductServiceType } from '@goed-platform/graphql/types';
import { formatPriceWithValuta } from '@goed-platform/pricing/util';
import { ImageLayoutEnum, ImageObjectfitEnum } from '@goed-platform/product/enums';
import { fallbackImageUrl } from '@goed-platform/shared/constants';
import { OptimizedImage } from '@goed-platform/shared/ui';
import { useTranslation } from 'next-i18next';
import { PropsWithChildren } from 'react';

type CheckoutProductBasicProps = PropsWithChildren<{
    className?: string;
    product: CartItem;
    isCMMember?: boolean;
    displayPrice?: boolean;
    displayProductServiceTypeBadge?: boolean;
}>;

export const CheckoutProductBasic = ({
    className = '',
    product,
    isCMMember = false,
    displayPrice = false,
    displayProductServiceTypeBadge = false,
    children,
    ...restProps
}: CheckoutProductBasicProps): JSX.Element => {
    const { t } = useTranslation();
    const frequency =
        product.productServiceType === ProductServiceType.Rental
            ? '/' + t(`general.periods.${(product.rentalPeriodUnit ?? 'month').toLowerCase()}`)
            : '';

    const translationServiceTypeBadge = (): string => {
        if (product.productServiceType === ProductServiceType.Rental) {
            if (product.rentToOwn && product.rentToOwn > 0) {
                return 'huurkoop';
            }
            return 'rent';
        }

        return 'buy';
    };

    return (
        <div className={`row ${className}`} {...restProps}>
            <div className="col-3">
                <OptimizedImage
                    image={product.image ?? { sortOrder: 0, url: fallbackImageUrl }}
                    layout={ImageLayoutEnum.fill}
                    objectFit={ImageObjectfitEnum.contain}
                    alt={product.name ?? ''}
                />
            </div>
            <div className="col-9 pl-0 d-flex flex-column justify-content-center">
                <div className="font-size-14 font-size-md-18">{product.name}</div>
                {product.variantProperties?.map((variant, key) => (
                    <div className="text-neutral-a70 mb-1 font-size-12 font-size-md-14" key={key}>
                        {variant?.name}: {variant && variant.values ? variant?.values[0] : ''}
                    </div>
                ))}
                {displayProductServiceTypeBadge && (
                    <div className="d-flex px-1 rounded-sm w-fit text-neutral-a70 bg-neutral-a5 font-size-12 font-size-md-14">
                        {t(`product.type.${translationServiceTypeBadge()}`)}
                    </div>
                )}
                {product.isMedicine && (
                    <div className="d-flex px-1 rounded-sm w-fit text-neutral-a70 bg-neutral-a5 font-size-12 font-size-md-14">
                        {t(`product.medicine`)}
                    </div>
                )}
                {product.refundable && (
                    <div className="d-flex px-1 rounded-sm w-fit text-neutral-a70 bg-neutral-a5 font-size-12 font-size-md-14">
                        {t(`product.refundable`)}
                    </div>
                )}
                {/* Todo: enable when available */}
                {/*{product.presciptionRequired && (*/}
                {/*    <div className="d-flex px-1 rounded-sm w-fit text-neutral-a70 bg-neutral-a5 font-size-12 font-size-md-14">*/}
                {/*        {t(`product.prescription`)}*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className="font-size-12 font-size-md-16 text-neutral-a70">
                    {t('cart.overview.quantity').toLowerCase()}: {product.quantity}
                </div>
                {displayPrice ? (
                    <div
                        className={`mb-0 font-size-12 font-size-md-16 font-weight-bold ${
                            isCMMember ? 'text-promo' : 'text-neutral'
                        }`}
                    >
                        {t(`product.type.${translationServiceTypeBadge()}`)}
                        :&nbsp;
                        {formatPriceWithValuta(product.totalInclTax)}
                        {frequency}
                        {isCMMember && ` ${t('product.price.cm.cmMember')}`}
                    </div>
                ) : (
                    <></>
                )}
                {children}
            </div>
        </div>
    );
};
