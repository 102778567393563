import Error from 'next/error';
import Head from 'next/head';

export const ErrorNotFound = (): JSX.Element => {
    return (
        <>
            <Head>
                <meta name="robots" content="noindex" />
            </Head>
            <Error statusCode={404} />
        </>
    );
};
