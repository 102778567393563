import { RoutesEnum } from '@goed-platform/shared/types';
import { useTranslation } from 'next-i18next';
import { SEOTags, LanguageLink } from '../..';

type ErrorContentProps = {
    error: string | number;
    homepageUrl?: string;
};

export const ErrorContent = ({ error, homepageUrl = RoutesEnum.home }: ErrorContentProps) => {
    const { t } = useTranslation();

    return (
        <>
            <SEOTags pageTitle={t(`error.${error}.title`)} metaDescription={t(`error.${error}.description`)} />
            <div className="container py-10">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <h1>{t(`error.${error}.title`)}</h1>
                        <p>
                            <span dangerouslySetInnerHTML={{ __html: t(`error.${error}.description`) }} />{' '}
                            <LanguageLink href={homepageUrl}>
                                <a>{t('general.homepage')}</a>
                            </LanguageLink>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
