import { Category, Product, StoreType } from '@goed-platform/graphql/types';
import { SVGSearch } from '@goed-platform/shared/utils';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { SearchBarForm } from './search-bar-form';
import { SearchBarResultCategory } from './search-bar-result-category';
import { SearchBarResultProduct } from './search-bar-result-product';
import { SearchBarResultSuggestion } from './search-bar-result-suggestion';

type SearchBarMobileProps = {
    storeType: StoreType;
    className?: string;
};

export const SearchBarMobile: FC<SearchBarMobileProps> = ({
    storeType,
    className = '',
}: SearchBarMobileProps): JSX.Element => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [categoriesResults, setCategoriesResults] = useState<Category[]>([]);
    const [productsResults, setProductsResults] = useState<Product[]>([]);
    const [suggestionResults, setSuggestionResults] = useState<string[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            <button
                onClick={() => setShowModal(true)}
                className={`d-md-none bg-neutral-white rounded-circle border-0  ws-9 ws-md-10 hs-9 hs-md-10 d-flex justify-content-center align-items-center font-weight-bold ${className}`}
            >
                <SVGSearch className="sprite sprite-md text-purple" />
            </button>
            <Modal show={showModal} centered className="modal-fullscreen-mobile">
                <Modal.Body>
                    <SearchBarForm
                        searchValue={searchValue}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        setSearchValue={setSearchValue}
                        setSuggestionResults={setSuggestionResults}
                        setProductsResults={setProductsResults}
                        setCategoriesResults={setCategoriesResults}
                        storeType={storeType}
                    />
                    <div className="search-results-mobile d-flex flex-column bg-neutral-white mt-6 mx--4">
                        {suggestionResults?.map((suggestion, key) => (
                            <SearchBarResultSuggestion
                                key={key}
                                suggestion={suggestion}
                                searchValue={searchValue}
                                storeType={storeType}
                            />
                        ))}
                        {categoriesResults?.map((category, key) => (
                            <SearchBarResultCategory key={key} category={category} storeType={storeType} />
                        ))}
                        {productsResults?.map((product, key) => (
                            <SearchBarResultProduct key={key} product={product} storeType={storeType} />
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
