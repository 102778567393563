import { Period, TransactionType } from '@goed-platform/graphql/types';
import { formatPriceWithValuta } from '@goed-platform/pricing/util';
import { SVGInfo, SVGMenuClose, TranslationUtils } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export enum CMPriceSizeEnum {
    large = 'lg',
    medium = 'md',
    small = 'sm',
}

type CMPriceProps = {
    value: number;
    transactionType: TransactionType;
    size: CMPriceSizeEnum;
    frequency?: Period;
    showInfoToggle?: boolean;
    className?: string;
};

export const CMPrice: React.FC<CMPriceProps> = ({
    value,
    transactionType,
    frequency,
    size,
    showInfoToggle,
    className = '',
    ...restProps
}: CMPriceProps): JSX.Element => {
    const { t } = useTranslation();
    const [showPriceAdvantageModal, setShowPriceAdvantageModal] = useState<boolean>(false);
    const incentives = TranslationUtils.getTranslationKeyArray('product.price.cm.incentives');
    const translatedFrequency: string = t(`general.periods.${frequency?.toLowerCase()}`);

    return (
        <>
            {/* Price badge */}
            <div
                className={`d-inline-block px-2 py-1 text-promo bg-promo-a5 rounded-sm cm-price-${size} ${className}`}
                {...restProps}
            >
                <b>
                    {formatPriceWithValuta(value)}
                    {transactionType === TransactionType.Rental && !!frequency && `/${translatedFrequency}`}
                </b>
                &nbsp;{t('product.price.cm.asCMMember')}
                {showInfoToggle && (
                    <span role="button" onClick={() => setShowPriceAdvantageModal(true)}>
                        <SVGInfo className="sprite sprite-sm ml-1" title="info" />
                    </span>
                )}
            </div>

            {/* Information modal */}
            <Modal
                show={showPriceAdvantageModal}
                onHide={() => setShowPriceAdvantageModal(false)}
                animation={true}
                centered
            >
                <Modal.Header>
                    <Modal.Title className="text-primary">{t('product.price.cm.title')}</Modal.Title>
                    <button className="text-purple border-0 bg-white" onClick={() => setShowPriceAdvantageModal(false)}>
                        <SVGMenuClose className="sprite sprite-menu-close sprite-md" title="menu-close" />
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <p>{t('product.price.cm.description.1')}</p>
                    <ul className="list-colored-dots mb-2">
                        {incentives.map((translationKey, key) => (
                            <li key={key}>{t(translationKey)}</li>
                        ))}
                    </ul>
                    <p>{t('product.price.cm.description.2')}</p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CMPrice;
