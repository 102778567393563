import { AxiosError } from 'axios';

export interface SymfonyPharmacy {
    id: number;
    name: string;
    street: string;
    number: string;
    box?: string;
    postal_code: string;
    city: string;
    distance: number;
    image_path?: string;
    services: SymfonyPharmacyService[];
    meta: SymfonyPharmacyMeta;
    servermap?: string;
}

export interface SymfonyPharmacyMeta {
    slug: string;
    title: string;
    title_for_slug: string;
    description: string;
    locale?: string;
    no_index: boolean;
    no_follow: boolean;
    og_title: string;
    og_description: string;
    og_image_path?: string;
}

export interface SymfonyPharmacyService {
    type: string;
    openingHours: SymfonyPharmacyOpeningHours;
    titular: string;
    phone?: string;
}

export interface SymfonyPharmacyOpeningHours {
    byWeek: SymfonyPharmacyByWeek;
    currentOrNextOpenState: SymfonyPharmacyCurrentOrNextOpenState;
}

export interface SymfonyPharmacyByWeek {
    thisWeek: SymfonyPharmacyWeek[];
    nextWeek: SymfonyPharmacyWeek[];
}

export interface SymfonyPharmacyWeek {
    scheme: SymfonyPharmacyScheme;
    isToday: boolean;
    isInPast: boolean;
}

export interface SymfonyPharmacyScheme {
    opening_hours: SymfonyPharmacyOpeningHour[];
    data?: SymfonyPharmacySchemeData | null;
}

export interface SymfonyPharmacySchemeData {
    onlyByAppointment: boolean;
    description: string;
}

export interface SymfonyPharmacyOpeningHour {
    start: SymfonyPharmacyTime;
    end: SymfonyPharmacyTime;
    data: SymfonyPharmacyOpeningHourData;
}

export interface SymfonyPharmacyOpeningHourData {
    onlyByAppointment: boolean;
}

export interface SymfonyPharmacyTime {
    hours: number;
    minutes: number;
    data?: SymfonyPharmacyRentalStoreWithOpeningHoursData | null;
}

export interface SymfonyPharmacyRentalStoreWithOpeningHoursData {
    onlyByAppointment: boolean;
    description?: string;
}

export interface SymfonyPharmacyCurrentOrNextOpenState {
    isOpen: boolean;
}

export const isPharmacyArray = (
    pharmacies: SymfonyPharmacy[] | AxiosError | Error
): pharmacies is SymfonyPharmacy[] => {
    return (pharmacies as SymfonyPharmacy[])[0]?.city !== undefined;
};
