import { SVGInfo, SVGMenuClose } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export type PrescriptionPriceWithInformationModalProps = {
    title: string;
    description: JSX.Element | string;
    className?: string;
};

export const PrescriptionPriceWithInformationModal: React.FC<PrescriptionPriceWithInformationModalProps> = ({
    title,
    description,
    className = '',
    ...restProps
}: PrescriptionPriceWithInformationModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [showPrescriptiontotalModal, setShowPrescriptiontotalModal] = useState<boolean>(false);

    return (
        <>
            {/* Subtotal */}
            <div className={`${className}`} {...restProps}>
                {t('res.product.cost.prescriptionTotal')}
                <span role="button" onClick={() => setShowPrescriptiontotalModal(true)}>
                    <SVGInfo className="sprite sprite-md ml-1 text-purple" title="info" />
                </span>
            </div>
            {/* Subtotal information modal */}
            <Modal
                show={showPrescriptiontotalModal}
                onHide={() => setShowPrescriptiontotalModal(false)}
                animation={true}
                centered
            >
                <Modal.Header>
                    <Modal.Title className="text-primary">{title}</Modal.Title>
                    <button
                        className="text-purple border-0 bg-white"
                        onClick={() => setShowPrescriptiontotalModal(false)}
                    >
                        <SVGMenuClose className="sprite sprite-menu-close sprite-md" title="menu-close" />
                    </button>
                </Modal.Header>

                <Modal.Body>{description}</Modal.Body>
            </Modal>
        </>
    );
};
