import { MutationFunction } from '@apollo/client';
import {
    CartItem,
    CartTotal,
    ProductServiceType,
    RemoveProductFromCartMutation,
    RemoveProductFromCartMutationVariables,
    StoreType,
    UpdateCartMutation,
    UpdateCartMutationVariables,
} from '@goed-platform/graphql/types';
import { formatPriceWithValuta } from '@goed-platform/pricing/util';
import { cartVarRes } from '@goed-platform/shared/data-access';
import { DataLayers } from '@goed-platform/shared/ui';
import { SVGThrash } from '@goed-platform/shared/utils';

type CartRowPriceResProps = {
    cartItem: CartItem;
    cart: CartTotal;
    updateCallback: MutationFunction<UpdateCartMutation, UpdateCartMutationVariables>;
    removeCallback: MutationFunction<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables>;
};

export const CartRowPriceRes: React.FC<CartRowPriceResProps> = ({
    cartItem,
    cart,
    updateCallback,
    removeCallback,
    ...restProps
}: CartRowPriceResProps): JSX.Element => {
    const onRemove = (): void => {
        removeCallback({
            variables: {
                lineItemId: cartItem.itemId,
                cartId: cart.id,
                storeType: StoreType.Res,
            },
        }).then((result) => cartVarRes(result?.data?.removeProductFromCart as CartTotal));
        DataLayers.getInstance({ storeType: cart.storeType }).removeFromCart(cartItem);
    };

    const onUpdate = (quantity: number): void => {
        if (quantity > 0) {
            updateCallback({
                variables: {
                    quantity: quantity,
                    cartId: cart.id,
                    lineItemId: cartItem.itemId,
                    storeType: StoreType.Res,
                },
            }).then((result) => cartVarRes(result?.data?.updateCart as CartTotal));
        }
    };

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                <div
                    className="hs-8 ws-8 rounded-50 border-0 bg-purple-a10 text-purple d-flex align-items-center justify-content-center mr-6"
                    role="button"
                    onClick={() => onRemove()}
                >
                    <SVGThrash data-cy="remove-item" className="sprite sprite-trash sprite-sm" title="trash" />
                </div>

                {cartItem.productServiceType !== ProductServiceType.Prescription && (
                    <input
                        type="number"
                        data-cy="quantity-input"
                        min="1"
                        max="100"
                        pattern="[0-9]*"
                        className="form-control input-sm hs-12 ws-16 d-inline-block"
                        value={cartItem.quantity}
                        onChange={(e) => onUpdate(parseInt(e.target.value))}
                    />
                )}
            </div>
            <div className="d-flex flex-column align-items-end text-end">
                {cart.storeType === StoreType.Res && (
                    <div className="text-neutral font-size-16 font-size-md-22 line-height-24 line-height-md-26 font-weight-bold">
                        {formatPriceWithValuta(cartItem.priceInclTax)}
                    </div>
                )}
            </div>
        </div>
    );
};
