/* eslint-disable react/jsx-no-useless-fragment */
import { CategoriesDataAccess } from '@goed-platform/categories/data-access';
import { Category, Product, StoreType } from '@goed-platform/graphql/types';
import { FuzzySearchResult, ProductDataAccess } from '@goed-platform/product/data-access';
import { RoutesEnum } from '@goed-platform/shared/types';
import { SVGArrowLeft, SVGClose, SVGSearch, useDebounce } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

type SearchBarFormProps = {
    storeType: StoreType;
    showModal: boolean;
    searchValue: string;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setSearchValue: Dispatch<SetStateAction<string>>;
    setSuggestionResults: Dispatch<SetStateAction<string[]>>;
    setCategoriesResults: Dispatch<SetStateAction<Category[]>>;
    setProductsResults: Dispatch<SetStateAction<Product[]>>;
    className?: string;
};

export const SearchBarForm: FC<SearchBarFormProps> = ({
    storeType,
    searchValue,
    showModal = false,
    setSearchValue,
    setSuggestionResults,
    setCategoriesResults,
    setProductsResults,
    setShowModal,
    className = '',
}: SearchBarFormProps): JSX.Element => {
    const { t } = useTranslation();
    const router = useRouter();
    const debouncedSearchValue: string = useDebounce(searchValue, 1000) as string;

    const search = async () => {
        if (debouncedSearchValue.length > 0) {
            await ProductDataAccess.fuzzySearchProducts({
                storeType: storeType,
                filter: debouncedSearchValue,
            })
                .then((response: FuzzySearchResult) => {
                    if (response?.suggestions) setSuggestionResults(response?.suggestions as string[]);
                    if (response?.products) setProductsResults(response?.products as Product[]);
                })
                .catch(() => {
                    return;
                });

            await CategoriesDataAccess.searchCategories(storeType, debouncedSearchValue)
                .then((response) => setCategoriesResults((Array.isArray(response) ? response : []) as Category[]))
                .catch(() => {
                    return;
                });
        }
    };

    const onSubmit = (): void => {
        router.push({
            pathname: getSearchTarget(storeType),
            query: {
                search: searchValue,
            },
        });
        return;
    };

    const getSearchTarget = (storeType: StoreType): RoutesEnum => {
        switch (storeType) {
            case StoreType.Uld:
                return RoutesEnum.rentalStoreSearch;
            case StoreType.Res:
                return RoutesEnum.webReservationSearch;
        }
        return RoutesEnum.rentalStoreSearch;
    };

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchValue]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
            }}
            onClick={() => !showModal && setShowModal(true)}
            onFocus={() => !showModal && setShowModal(true)}
            className={`search-bar rounded-xl bg-neutral-white w-100 p-1 position-relative d-flex align-items-center hs-11 ${className}`}
        >
            {showModal && (
                <button
                    onClick={() => setShowModal(false)}
                    type="button"
                    className="btn p-0 pl-2 line-height-1"
                    aria-label="close"
                >
                    <SVGArrowLeft className="sprite sprite-md text-purple" title="close" />
                </button>
            )}
            <input
                type="text"
                className={`${
                    showModal ? 'ml-2' : 'ml-5'
                } h-100 w-100 border-0 font-size-16 shadow-none bg-transparent`}
                placeholder={t('search.text.placeholder')}
                value={searchValue}
                aria-label="Search"
                onKeyUp={() => !showModal && setShowModal(true)}
                onChange={(e) => setSearchValue(e.target.value)}
                tabIndex={10000}
            />
            {searchValue.length > 0 && (
                <button
                    onClick={() => setSearchValue('')}
                    type="button"
                    className="btn py-0 px-2 line-height-1"
                    aria-label="delete"
                >
                    <SVGClose className="sprite sprite-md text-purple" title="delete" />
                </button>
            )}
            {searchValue.length <= 0 && (
                <button type="submit" className="btn py-0 px-2 line-height-1" aria-label="search">
                    <SVGSearch className="sprite sprite-md text-purple" title="search" />
                </button>
            )}
        </form>
    );
};
