import { ButtonSizeEnum, ButtonTypeEnum } from '..';

export type ButtonLinkProps = {
    label: string;
    type?: ButtonTypeEnum;
    size?: ButtonSizeEnum;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    isLoading?: boolean;
    showArrow?: boolean;
    disabled?: boolean;
};

export const ButtonLink: React.FC<ButtonLinkProps> = ({
    label,
    type = ButtonTypeEnum.button,
    size = ButtonSizeEnum.small,
    onClick,
    className = '',
    showArrow = true,
    isLoading,
    disabled,
    ...restProps
}: ButtonLinkProps): JSX.Element => {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`btn btn-link font-size-14 font-size-md-16 ${className} ${size} ${
                isLoading ? 'bg-purple-a50 border-purple-a50' : ''
            }`}
            disabled={disabled}
            {...restProps}
        >
            {label}
        </button>
    );
};
