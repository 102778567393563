import { Category } from '@goed-platform/graphql/types';
import { RoutesEnum } from '@goed-platform/shared/types';
import {
    CustomerBar,
    CustomerBarForMobile,
    Footer,
    FooterNavigation,
    Header,
    PaymentMethodsBanner,
} from '@goed-platform/shared/ui';
import { MappedNavigation } from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { WebReservationFooter } from '../layout-blocks/webreservation-footer';
import { UspsBanner } from '../usps/usps-banner';

type BaseLayoutProps = PropsWithChildren<{
    showBlobLeft?: boolean;
    showBlobRight?: boolean;
    showSearchBar?: boolean;
    showSubHeader?: boolean;
    showReadOnlySubHeader?: boolean;
    showUspsBanner?: boolean;
    showPaymentsMethodBanner?: boolean;
    showCustomerBar?: boolean;
    showMinimalizedFooter?: boolean;
    showFooterNavigation?: boolean;
    centeredLogo?: boolean;
    categoryNavigation?: Category[];
    navigation?: MappedNavigation;
}>;

export const BaseLayout: React.FC<BaseLayoutProps> = ({
    children,
    showBlobLeft = true,
    showBlobRight = true,
    showSubHeader = true,
    showReadOnlySubHeader = false,
    showUspsBanner = false,
    showSearchBar = false,
    showPaymentsMethodBanner = false,
    showCustomerBar = true,
    showMinimalizedFooter = false,
    showFooterNavigation = true,
    centeredLogo = false,
    categoryNavigation = [],
    navigation,
}: BaseLayoutProps): JSX.Element => {
    const { t } = useTranslation();
    const router = useRouter();

    const calculateHeight = (): number | undefined => {
        const header: HTMLElement | null = document.querySelector('header');
        const main: HTMLElement | null = document.querySelector('main');
        const footer: HTMLElement | null = document.querySelector('.footer-content');

        if (header && main && footer) {
            const headerHeight = header.scrollHeight;
            const mainHeight = main.scrollHeight;
            const footerHeight = footer.scrollHeight;
            return headerHeight + mainHeight + footerHeight;
        }

        return undefined;
    };

    const pageHeight: number | undefined = typeof document !== 'undefined' ? calculateHeight() : undefined;

    const validateShowParmacyHeader = (): boolean => {
        const excludedRoutes = [
            RoutesEnum.webReservationCheckoutLogin,
            RoutesEnum.webReservationCheckoutVerify,
            RoutesEnum.webReservationCheckoutRegister,
        ];
        const currentRoute: string = router.pathname;

        return (
            currentRoute.includes(RoutesEnum.webReservationLanding) &&
            !excludedRoutes.includes(currentRoute as RoutesEnum)
        );
    };

    return (
        <>
            <a className="sr-only sr-only-focusable text-primary" href="#main">
                {t('nav.screenreader.skipToMainContent')}
            </a>

            <Header
                categoryNavigation={categoryNavigation}
                showSearchBar={showSearchBar}
                showSubHeader={showSubHeader}
                showReadOnlySubHeader={showReadOnlySubHeader}
                centeredLogo={centeredLogo}
                navigation={navigation}
            />
            <div
                suppressHydrationWarning={true}
                className={`${showBlobLeft ? 'blob-left' : ''} ${
                    showBlobRight && pageHeight && pageHeight > 1500 ? 'blob-right' : ''
                } `}
            ></div>
            <main id="main" className="bg-off-white">
                {children}
            </main>
            <div className="footer-content">
                {validateShowParmacyHeader() && <WebReservationFooter />}

                {showUspsBanner && <UspsBanner />}

                {showCustomerBar && <CustomerBar navigation={navigation} />}

                {showFooterNavigation && <FooterNavigation navigation={navigation} />}

                {showCustomerBar && <CustomerBarForMobile navigation={navigation} />}

                {showPaymentsMethodBanner && <PaymentMethodsBanner />}
                <Footer navigation={navigation} showLinks={!showMinimalizedFooter} />
            </div>
        </>
    );
};
