import { Breadcrumbs } from '@goed-platform/breadcrumb/ui';
import { overviewLinks } from '@goed-platform/shared/constants';
import { LinkItem } from '@goed-platform/shared/types';
import { MappedNavigation } from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { BaseLayout } from './base-layout';

type AccountLayoutProps = PropsWithChildren<{
    className?: string;
    breadcrumbItems?: LinkItem[];
    navigation: MappedNavigation;
}>;

export const AccountLayout: React.FC<AccountLayoutProps> = ({
    className = '',
    breadcrumbItems = [],
    children,
    navigation,
}: AccountLayoutProps): JSX.Element => {
    const { t } = useTranslation();
    const router = useRouter();

    return (
        <BaseLayout navigation={navigation}>
            <Breadcrumbs items={breadcrumbItems} />
            <div className="container mt-lg-12">
                <div className="row">
                    <div className="d-none d-lg-flex flex-column col-12 col-lg-3">
                        {overviewLinks.map((link, key) =>
                            link.path !== router.route ? (
                                <div className="mb-5" key={key}>
                                    <Link href={link.path ?? '#'}>
                                        <a>{t(link.translationKey)}</a>
                                    </Link>
                                </div>
                            ) : (
                                <div key={key} className="font-weight-bold mb-5">
                                    {t(link.translationKey)}
                                </div>
                            )
                        )}
                    </div>
                    <div className="col-12 col-lg-8">{children}</div>
                </div>
            </div>
        </BaseLayout>
    );
};
