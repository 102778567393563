import { StoreType } from '@goed-platform/graphql/types';
import { cartIdVarRes, cartIdVarUld, StorageEnum } from './reactive-vars';

export class CartIdActions {
    public static getCartId(type: StoreType): string | null {
        switch (type) {
            case StoreType.Uld:
                return cartIdVarUld();

            case StoreType.Res:
                return cartIdVarRes();
        }
    }

    public static updateCartId(type: StoreType, id: string | null): string | null {
        switch (type) {
            case StoreType.Uld:
                cartIdVarUld(id);
                localStorage.setItem(StorageEnum.cartIdUld, id ?? '');
                break;
            case StoreType.Res:
                cartIdVarRes(id);
                localStorage.setItem(StorageEnum.cartIdRes, id ?? '');
                break;
        }

        return id;
    }
}
