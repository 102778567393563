import { useReactiveVar } from '@apollo/client';
import { CartDataAccess } from '@goed-platform/cart/data-access';
import { StoreType, UserAddressInput } from '@goed-platform/graphql/types';
import {
    CTErrors,
    defaultRegex,
    ErrorItemType,
    ErrorMessageEnum,
    ValidationConstant,
} from '@goed-platform/shared/constants';
import { cartVarUld, isLoggedInVar, userInfoVar } from '@goed-platform/shared/data-access';
import { AlertTypeEnum } from '@goed-platform/shared/types';
import { Alert, Button, ButtonSizeEnum, Checkbox, Loader, ModalHeader, TextField } from '@goed-platform/shared/ui';
import { UserDataAccess } from '@goed-platform/user/data-access';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';

type EditAccountAddressModalProps = {
    modalIsVisible: boolean;
    onHideModal: () => void;
    storeType?: StoreType;
};

type FormValues = UserAddressInput & {
    defaultBilling?: boolean;
};

export const EditAccountAddressModal: React.FC<EditAccountAddressModalProps> = ({
    modalIsVisible,
    onHideModal,
    storeType = StoreType.Res,
}: EditAccountAddressModalProps): JSX.Element => {
    const { t } = useTranslation();
    const isLoggedIn = useReactiveVar(isLoggedInVar);
    const user = useReactiveVar(userInfoVar);
    const cart = useReactiveVar(cartVarUld);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const schema = Yup.object().shape({
        firstName: Yup.string().required(t(ValidationConstant.REQUIRED.errorMessage)),
        lastName: Yup.string().required(t(ValidationConstant.REQUIRED.errorMessage)),
        company: Yup.string().nullable(true),
        extraAddressInfo: Yup.string().nullable(true),
        streetName: Yup.string().required(t(ValidationConstant.REQUIRED.errorMessage)),
        houseNumber: Yup.string()
            .matches(
                ValidationConstant.HOUSE_NUMBER.regex ?? defaultRegex,
                t(ValidationConstant.HOUSE_NUMBER.errorMessage)
            )
            .required(t(ValidationConstant.REQUIRED.errorMessage)),
        box: Yup.string().nullable(true),
        postCode: Yup.string()
            .matches(ValidationConstant.POST_CODE.regex ?? defaultRegex, t(ValidationConstant.POST_CODE.errorMessage))
            .required(t(ValidationConstant.REQUIRED.errorMessage)),
        city: Yup.string().required(t(ValidationConstant.REQUIRED.errorMessage)),
        defaultBilling: Yup.bool(),
        phone: Yup.string().matches(
            ValidationConstant.PHONE_NUMBER.regex ?? defaultRegex,
            t(ValidationConstant.PHONE_NUMBER.errorMessage)
        ),
    });

    const handleCTError = (error: ErrorItemType) => {
        setIsLoadingOnSubmit(false);
        setErrorMessage(CTErrors.generateErrorTranslation(error));
        throw new Error();
    };

    const handleOnSubmit = async (values: FormValues) => {
        setIsLoadingOnSubmit(true);
        setIsSubmitted(true);
        setErrorMessage('');

        const userAddress: UserAddressInput = {
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            streetName: values.streetName,
            houseNumber: values.houseNumber,
            box: values.box,
            postCode: values.postCode,
            city: values.city,
            company: values.company,
            postbox: values.postbox,
            region: values.region,
            extraAddressInfo: values.extraAddressInfo,
            email: values.email,
            phone: values.phone,
        };

        await CartDataAccess.setBillingAddress(storeType, userAddress)
            .then(async () => {
                // Overwrite the default billing address, if the user is logged in.
                if (isLoggedIn && values.defaultBilling) {
                    await UserDataAccess.setUserBillingAddress(userAddress)
                        .then(() => {
                            setIsLoadingOnSubmit(false);
                            onHideModal();
                        })
                        .catch(handleCTError);
                } else {
                    setIsLoadingOnSubmit(false);
                    onHideModal();
                }
            })
            .catch(handleCTError);
    };

    if (isLoggedIn && !user) {
        return <Loader />;
    } else {
        const initialValues: UserAddressInput = cart?.billingAddress ??
            user?.billingAddress ?? {
                box: '',
                city: '',
                company: '',
                email: '',
                extraAddressInfo: '',
                firstName: '',
                houseNumber: '',
                id: '',
                lastName: '',
                phone: '',
                postCode: '',
                postbox: '',
                region: '',
                streetName: '',
            };

        return (
            <Modal show={modalIsVisible} onHide={onHideModal} centered className="modal-fullscreen-mobile">
                <ModalHeader closeFunction={() => onHideModal()} title={t('checkout.reservation.changeInfo')} />
                <Modal.Body>
                    <Formik
                        initialValues={{ ...initialValues, defaultBilling: true }}
                        validationSchema={schema}
                        onSubmit={handleOnSubmit}
                    >
                        {({ errors, touched, handleChange, values }) => (
                            <Form>
                                <div className="row pt-4">
                                    <div className="col-12">
                                        <TextField
                                            id="firstName"
                                            label="user.firstName"
                                            onChange={handleChange}
                                            error={errors.firstName as string}
                                            touched={touched.firstName}
                                            value={values.firstName ?? undefined}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            id="lastName"
                                            label="user.lastName"
                                            onChange={handleChange}
                                            error={errors.lastName as string}
                                            touched={touched.lastName}
                                            value={values.lastName ?? undefined}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            id="company"
                                            label="user.company"
                                            onChange={handleChange}
                                            error={errors.company as string}
                                            touched={touched.company}
                                            value={values.company ?? undefined}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            id="extraAddressInfo"
                                            label="user.extraAddressInfo"
                                            onChange={handleChange}
                                            error={errors.extraAddressInfo as string}
                                            touched={touched.extraAddressInfo}
                                            value={values.extraAddressInfo ?? undefined}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            id="streetName"
                                            label="user.streetName"
                                            onChange={handleChange}
                                            error={errors.streetName as string}
                                            touched={touched.streetName}
                                            value={values.streetName ?? undefined}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextField
                                            id="houseNumber"
                                            label="user.houseNumber"
                                            onChange={handleChange}
                                            error={errors.houseNumber as string}
                                            touched={touched.houseNumber}
                                            value={values.houseNumber ?? undefined}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextField
                                            id="box"
                                            label="user.box"
                                            onChange={handleChange}
                                            error={errors.box as string}
                                            touched={touched.box}
                                            value={values.box ?? undefined}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            id="postCode"
                                            label="user.postCode"
                                            onChange={handleChange}
                                            error={errors.postCode as string}
                                            touched={touched.postCode}
                                            value={values.postCode ?? undefined}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            id="city"
                                            label="user.city"
                                            onChange={handleChange}
                                            error={errors.city as string}
                                            touched={touched.city}
                                            value={values.city ?? undefined}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>{t('user.country')}</label>
                                            <div className="py-2 px-3 border rounded-sm bg-purple-a5">
                                                {t('user.countryFixedValue')}
                                            </div>
                                        </div>
                                    </div>
                                    {isLoggedIn ? (
                                        <div className="col-12 mt-2">
                                            <Checkbox
                                                id="defaultBilling"
                                                label="user.defaultBilling"
                                                onChange={handleChange}
                                                error={errors.defaultBilling}
                                                touched={touched.defaultBilling}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <h5 className="col-12">{t('user.contact.title')}</h5>
                                    <p className="col-12">{t('user.contact.info')}</p>
                                    <div className="col-12">
                                        <TextField
                                            id="phone"
                                            label="user.phone"
                                            onChange={handleChange}
                                            error={errors.phone as string}
                                            touched={touched.phone}
                                            value={values.phone ?? undefined}
                                        />
                                    </div>
                                </div>

                                <div>
                                    {isSubmitted &&
                                    !isLoadingOnSubmit &&
                                    (errorMessage || Object.keys(errors).length > 0) ? (
                                        <Alert
                                            type={AlertTypeEnum.danger}
                                            errorMessage={
                                                Object.keys(errors).length > 0
                                                    ? t(ErrorMessageEnum.formError)
                                                    : errorMessage
                                            }
                                            className="mt-2"
                                        />
                                    ) : (
                                        <></>
                                    )}

                                    <Button
                                        label={t('checkout.delivery.changeAccountAddress.cta')}
                                        isLoading={isLoadingOnSubmit}
                                        size={ButtonSizeEnum.medium}
                                        onClick={() => setIsSubmitted(true)}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        );
    }
};

export default EditAccountAddressModal;
