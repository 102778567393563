export type YoutubeEmbedProps = {
    videoUrls: string[];
    className?: string;
};

export const YoutubeEmbed = ({ videoUrls = [], className = '', ...restProps }: YoutubeEmbedProps): JSX.Element => {
    const CheckYoutubeUrls = (videoUrl: string) => {
        if (!videoUrl.includes('www.youtube.com')) {
            return;
        }
        if (videoUrl.includes('watch?v=')) {
            return videoUrl.replace('watch?v=', 'embed/');
        }
        if (videoUrl.includes('/embed/')) {
            return videoUrl;
        }
        return;
    };

    const youtubeEmbedUrls = videoUrls?.map((item) => CheckYoutubeUrls(item)).filter((item) => item);

    return (
        <div className={className}>
            {youtubeEmbedUrls?.map((youtubeUrl, key) => (
                <div key={key} className="position-relative embed-responsive embed-responsive-16by9 my-4">
                    <iframe
                        className="position-absolute top-0 left-0 embed-responsive-item"
                        title="Video player"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        src={youtubeUrl}
                    />
                </div>
            ))}
        </div>
    );
};

export default YoutubeEmbed;
