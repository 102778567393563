import { StoreType } from '@goed-platform/graphql/types';
import { SVGArrowRight, UrlUtils } from '@goed-platform/shared/utils';
import Link from 'next/link';
import { PropsWithChildren } from 'react';

type SearchBarResultSuggestionType = PropsWithChildren<{
    suggestion: string;
    searchValue: string;
    storeType: StoreType;
}>;

export const SearchBarResultSuggestion = ({
    suggestion,
    searchValue,
    storeType,
}: SearchBarResultSuggestionType): JSX.Element => {
    return (
        <div className="position-relative d-flex align-items-center p-4">
            <div className="d-flex flex-md-fill">
                <Link href={UrlUtils.createSearchUrl(suggestion, storeType)}>
                    <a className="border-0 stretched-link d-block mb-1 font-size-16 line-height-16 w-100 font-weight-normal justify-content-center align-items-center ">
                        {suggestion.indexOf(searchValue) > -1 ? (
                            <>
                                {suggestion.substring(0, suggestion.indexOf(searchValue))}
                                <b>
                                    {suggestion.substring(
                                        suggestion.indexOf(searchValue),
                                        searchValue.length - suggestion.indexOf(searchValue)
                                    )}
                                </b>
                                {suggestion.substring(suggestion.indexOf(searchValue) + searchValue.length)}
                            </>
                        ) : (
                            <>{suggestion}</>
                        )}
                        <SVGArrowRight className="ml-1 sprite sprite-sm float-right" />
                    </a>
                </Link>
            </div>
        </div>
    );
};
