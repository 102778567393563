import Script from 'next/script';

type GTMScriptProps = {
    GTM_ID?: string;
};

export const GTMScript = ({ GTM_ID = process.env.NEXT_PUBLIC_GTM_ID, ...restProps }: GTMScriptProps): JSX.Element => {
    if (!GTM_ID || GTM_ID.length === 0) {
        return <></>;
    }

    return (
        <Script
            {...restProps}
            id="google-tag-manager"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${GTM_ID}');
                    `,
            }}
        />
    );
};
