/* eslint-disable react/jsx-no-useless-fragment */
import { MutationFunction } from '@apollo/client';
import {
    CartItem,
    CartTotal,
    ProductServiceType,
    RemoveProductFromCartMutation,
    RemoveProductFromCartMutationVariables,
    StoreType,
    UpdateCartMutation,
    UpdateCartMutationVariables,
} from '@goed-platform/graphql/types';
import { ImageLayoutEnum, ImageObjectfitEnum } from '@goed-platform/product/enums';
import { fallbackImageUrl } from '@goed-platform/shared/constants';
import { LanguageLink, OptimizedImage } from '@goed-platform/shared/ui';
import { UrlUtils } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { CartRowPriceRes } from './cart-row-price-res';

type CartRowResProps = {
    cartItem: CartItem;
    cart: CartTotal;
    updateCallback: MutationFunction<UpdateCartMutation, UpdateCartMutationVariables>;
    removeCallback: MutationFunction<RemoveProductFromCartMutation, RemoveProductFromCartMutationVariables>;
    className?: string;
    storeType: StoreType;
};

export const CartRowRes: FC<CartRowResProps> = ({
    cartItem,
    cart,
    updateCallback,
    removeCallback,
    className = '',
    storeType,
    ...restProps
}: CartRowResProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div
            className={`card card-rounded mx-n4 mx-sm-0 border-sm-1 border-sm-neutral-a10 ${className}`}
            {...restProps}
        >
            <div className="card-body">
                <div className="row">
                    {/* Image */}
                    <div className="col-3">
                        {cartItem.image && (
                            <OptimizedImage
                                image={cartItem.image ?? { sortOrder: 0, url: fallbackImageUrl }}
                                layout={ImageLayoutEnum.fill}
                                objectFit={ImageObjectfitEnum.contain}
                                alt={cartItem.name ?? ''}
                            />
                        )}
                    </div>

                    <div className="col-9">
                        <div className="font-size-16 font-size-md-22">
                            <LanguageLink
                                href={UrlUtils.createProductUrl(cartItem.sku, cartItem.slug, cartItem.key, storeType)}
                            >
                                <a className="unstyled font-weight-normal text-purple font-size-16 font-size-md-22 line-height-18 line-height-md-28">
                                    {cartItem.name}
                                </a>
                            </LanguageLink>
                        </div>
                        {cartItem.isMedicine ||
                        cartItem.refundable ||
                        cartItem.productServiceType === ProductServiceType.Prescription ? (
                            <div className="d-flex align-items-center mb-2 mb-md-4">
                                {/* Medicine badge */}
                                {cartItem.isMedicine && (
                                    <div className="px-2 py-1 mr-2 rounded-sm w-fit text-camel bg-off-white-dark font-size-12 line-height-14 font-size-md-14 line-height-md-16">
                                        {t('product.medicine')}
                                    </div>
                                )}
                                {/* Refundable badge */}
                                {cartItem.refundable && (
                                    <div className="px-2 py-1 mr-2 rounded-sm w-fit text-camel bg-off-white-dark font-size-12 line-height-14 font-size-md-14 line-height-md-16">
                                        {t('product.refundable')}
                                    </div>
                                )}
                                {/* Prescription badge */}
                                {cartItem.productServiceType === ProductServiceType.Prescription && (
                                    <div className="px-2 py-1 mr-2 rounded-sm w-fit text-camel bg-off-white-dark font-size-12 line-height-14 font-size-md-14 line-height-md-16">
                                        {t('product.prescription')}
                                    </div>
                                )}
                            </div>
                        ) : null}
                        <div className="d-none d-lg-block">
                            <CartRowPriceRes
                                cart={cart}
                                cartItem={cartItem}
                                updateCallback={updateCallback}
                                removeCallback={removeCallback}
                            />
                        </div>
                    </div>

                    <div className="col-12 d-lg-none mt-2">
                        <CartRowPriceRes
                            cart={cart}
                            cartItem={cartItem}
                            updateCallback={updateCallback}
                            removeCallback={removeCallback}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
