import { RoutesEnum } from '@goed-platform/shared/types';

export const disallowedRoutes = [
    RoutesEnum.rentalStoreCart,
    RoutesEnum.login,
    RoutesEnum.rentalStoreCheckout,
    RoutesEnum.forgotPassword,
    RoutesEnum.order,
    RoutesEnum.register,
    RoutesEnum.account,
    RoutesEnum.webReservationLanding,
];
