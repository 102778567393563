import { EventCallbackType } from '@goed-platform/shared/types';
import { Field } from 'formik';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { FieldError } from '../../index';

type SwitchProps = {
    id: string;
    label?: string;
    help?: string;
    className?: string;
    error?: string;
    errorClassName?: string;
    touched?: boolean;
    onChange?: EventCallbackType;
    defaultChecked?: boolean;
    isFormik?: boolean;
};

export const Switch: React.FC<SwitchProps> = ({
    id,
    label,
    help,
    className = '',
    error,
    errorClassName = '',
    touched,
    onChange,
    defaultChecked = false,
    isFormik,
    ...restProps
}: SwitchProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="form-group mb-0">
            <div className="custom-control custom-switch">
                {isFormik ? (
                    <Field
                        id={id}
                        name={id}
                        type="checkbox"
                        className={`custom-control-input ${className}`}
                        onChange={onChange}
                        defaultChecked={defaultChecked}
                        {...restProps}
                    />
                ) : (
                    <input
                        id={id}
                        name={id}
                        type="checkbox"
                        data-cy="switch"
                        className={`custom-control-input ${className}`}
                        onChange={onChange}
                        defaultChecked={defaultChecked}
                        {...restProps}
                    />
                )}
                <label htmlFor={id} role="button" className="custom-control-label">
                    {label && t(label)}
                </label>
            </div>
            {isFormik && <FieldError className={errorClassName} error={error} touched={touched} />}
            {help && <div className="form-text mt-2">{t(help)}</div>}
        </div>
    );
};

export default Switch;
