import { EventCallbackType } from '@goed-platform/shared/types';
import { ButtonLink } from '@goed-platform/shared/ui';
import { Field } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { FieldError } from '../../index';

type PasswordProps = {
    id: string;
    label: string;
    value?: string;
    help?: string;
    className?: string;
    placeholder?: string;
    autofocus?: boolean;
    error?: string;
    errorClassName?: string;
    inputClassName?: string;
    touched?: boolean;
    min?: number;
    max?: number;
    onChange?: EventCallbackType;
    required?: boolean;
    pattern?: string;
    autocomplete?: string;
};

export const PasswordField: React.FC<PasswordProps> = ({
    id,
    label,
    value = '',
    help = '',
    className = '',
    placeholder = '',
    autofocus = false,
    error = '',
    errorClassName = '',
    inputClassName = '',
    touched = false,
    min = 0,
    max = 100,
    onChange = () => null,
    required = false,
    pattern,
    autocomplete = '',
    ...restProps
}: PasswordProps): JSX.Element => {
    const { t } = useTranslation();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={id}>
                {t(label)}
                {required && '*'}
            </label>
            <div className="position-relative">
                <Field
                    id={id}
                    name={id}
                    type={isPasswordVisible ? 'text' : 'password'}
                    value={value}
                    placeholder={placeholder ? t(placeholder) : null}
                    autoComplete={autocomplete}
                    autoFocus={autofocus}
                    className={`form-control ${inputClassName} pr-16`}
                    onChange={onChange}
                    pattern={pattern ? new RegExp(pattern) : null}
                    min={min}
                    max={max}
                    {...restProps}
                ></Field>

                <ButtonLink
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    className="form-control-password-toggle"
                    label={t('general.show')}
                />
            </div>
            <FieldError className={errorClassName} error={error} touched={touched} />
            {help && <div className="form-text mt-2">{t(help)}</div>}
        </div>
    );
};

export default PasswordField;
