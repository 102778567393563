import { RoutesEnum } from '@goed-platform/shared/types';
import Link from 'next/link';
import { FC, PropsWithChildren } from 'react';

type CartIndicatorProps = PropsWithChildren<{
    href: RoutesEnum;
    text?: string;
    Icon: FC<{ className?: string; title?: string }>;
    iconTitle: string;
    quantity?: number;
    className?: string;
    indicatorClassName?: string;
}>;

export const CartIndicator = ({
    href,
    text,
    Icon,
    iconTitle,
    quantity,
    className = '',
    indicatorClassName = '',
}: CartIndicatorProps): JSX.Element => {
    return (
        <Link href={href}>
            <a
                role="button"
                className={`border-0 d-flex align-items-center cart-indicator font-weight-bold ${className}`}
            >
                {text && <span className="d-none d-md-block mr-2">{text}</span>}
                <div
                    className={`rounded-circle font-weight-bold ws-9 ws-md-10 hs-9 hs-md-10 d-inline-flex align-items-center justify-content-center position-relative ${indicatorClassName}`}
                >
                    {(quantity ?? 0) > 0 && (
                        <span
                            className={`rounded-circle bg-promo ws-5 ws-md-6 hs-5 hs-md-6 text-white font-size-12 font-size-md-14 position-absolute right-0 top-0 d-inline-flex justify-content-center align-items-center indicator`}
                        >
                            {quantity}
                        </span>
                    )}
                    <Icon className="sprite sprite-md text-white" title={iconTitle} />
                </div>
            </a>
        </Link>
    );
};
