import { setContext } from '@apollo/client/link/context';
import { CustomHeaderKeys } from '@goed-platform/shared/constants';
import { isBrowser, Token } from '@goed-platform/shared/utils';
import { IncomingHttpHeaders } from 'http';
import { StorageEnum } from './reactive-vars';

export type RenderHeadersType = {
    headers: IncomingHttpHeaders;
};

// This authLink will execute a certain logic that will be applied before each call to the endpoint.
// This is handy for setting headers and/or executing logic for token renewal.
export const authLink = setContext(async (_, { headers }) => {
    // If the server requests the link, there is no session storage, so we shouldn't even try to fetch the token.
    if (!isBrowser()) {
        return renderHeaders(headers);
    }

    const customHeaders = {};

    // Correlation id.
    const correlationId = sessionStorage.getItem(StorageEnum.correlationId) ?? '';
    if (correlationId) {
        Object.assign(customHeaders, { [CustomHeaderKeys.xCorrelationId]: correlationId });
    }

    // Renewal flow: if we're using a token (i.e. we're logged in), send out a heartbeat to verify the token is
    // still active. If it isn't, renew the token.
    if (Token.isActive()) {
        const token: string = await Token.heartbeat();
        Object.assign(customHeaders, { authorization: `Bearer ${token}` });
    }

    return renderHeaders(headers, customHeaders);
});

const renderHeaders = (
    currentHeaders: IncomingHttpHeaders,
    customHeaders: IncomingHttpHeaders = {}
): RenderHeadersType => {
    return {
        headers: {
            ...currentHeaders,
            ...customHeaders,
        },
    };
};
