import { EventCallbackType } from '@goed-platform/shared/types';
import { Field } from 'formik';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { FieldError } from '../../index';

type RadioButtonProps = {
    id: string;
    label: string | JSX.Element;
    help?: string;
    className?: string;
    error?: string;
    errorClassName?: string;
    name: string;
    value?: string;
    touched?: boolean;
    onChange?: EventCallbackType;
};

export const RadioButton: React.FC<RadioButtonProps> = ({
    id,
    label,
    name,
    help = '',
    className = '',
    error = '',
    errorClassName = '',
    touched = false,
    value = '',
    onChange,
    ...restProps
}: RadioButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="form-group">
            <div className="custom-control custom-radio mb-4">
                <Field
                    id={id}
                    value={id}
                    checked={value === id}
                    name={name}
                    type="radio"
                    className={`custom-control-input ${className}`}
                    onChange={onChange}
                    {...restProps}
                />
                <label
                    htmlFor={id}
                    role="button"
                    className="custom-control-label text-body font-size-16 font-size-lg-18"
                >
                    {typeof label === 'string' ? t(label) : label}
                </label>
            </div>
            <FieldError className={errorClassName} error={error} touched={touched} />
            {help && <div className="form-text mt-2">{t(help)}</div>}
        </div>
    );
};

export default RadioButton;
