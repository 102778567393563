import { CartTotal, StoreCart, StoreType } from '@goed-platform/graphql/types';
import { cartIdVarRes, cartIdVarUld, cartVarRes, cartVarUld } from './reactive-vars';
import { CartIdActions } from './cart-id-actions';

export class CartActions {
    public static getCart(type: StoreType) {
        switch (type) {
            case StoreType.Uld:
                return cartVarUld();

            case StoreType.Res:
                return cartVarRes();
        }

        return null;
    }

    public static updateCart(type: StoreType, data: CartTotal | null): CartTotal | null {
        switch (type) {
            case StoreType.Uld:
                cartVarUld(data);
                break;
            case StoreType.Res:
                cartVarRes(data);
                break;
        }
        CartIdActions.updateCartId(type, data?.id ?? '');
        return data;
    }

    public static getCarts(): StoreCart[] {
        return [
            {
                storeType: StoreType.Uld,
                cartId: cartIdVarUld(),
            },
            {
                storeType: StoreType.Res,
                cartId: cartIdVarRes(),
            },
        ];
    }
}
