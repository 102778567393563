type LoaderProps = {
    overlayScreen?: boolean;
};

export const Loader: React.FC<LoaderProps> = ({ overlayScreen = false, ...restProps }): JSX.Element => {
    return (
        <div
            className={`${
                overlayScreen ? 'position-fixed z-index-10 top-0 left-0 bg-off-white' : ''
            } vw-100 vh-100 d-flex justify-content-center align-items-center`}
            {...restProps}
        >
            <div className="logo-loader"></div>
        </div>
    );
};

export default Loader;
