import { AxiosError } from 'axios';

export type Coordinate = {
    lat: number;
    lng: number;
};

export const isCoordinate = (coordinate: Coordinate | AxiosError | Error): coordinate is Coordinate => {
    return (coordinate as Coordinate).lat !== undefined;
};
