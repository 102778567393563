import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { isLoggedInVar, userInfoVar } from '@goed-platform/shared/data-access';
import { appVersion } from '../../../../../statistics/appversion';
import { LogSuccess } from '@goed-platform/shared/utils';

export class BugsnagDataAccess {
    private static instance: BugsnagDataAccess;
    private isConnected = false;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    // Expose as singleton.
    public static getInstance(): BugsnagDataAccess {
        if (!BugsnagDataAccess.instance) {
            BugsnagDataAccess.instance = new BugsnagDataAccess();
        }

        return BugsnagDataAccess.instance;
    }

    public static shouldFireOnThisEnvironment(): boolean {
        return +(process.env['NEXT_PUBLIC_BUGSNAG_ACTIVATE'] ?? 0) === 1;
    }

    public connect(): void {
        if (BugsnagDataAccess.shouldFireOnThisEnvironment() && !this.isConnected) {
            Bugsnag.start({
                apiKey: process.env['NEXT_PUBLIC_BUGSNAG_API_KEY'] ?? '',
                plugins: [new BugsnagPluginReact()],
                appVersion: appVersion ?? '',
                releaseStage: process.env['NEXT_PUBLIC_ENVIRONMENT'],
                onError: function (event) {
                    if (isLoggedInVar()) {
                        const user = userInfoVar();
                        if (user) {
                            event.setUser(user.id ?? '', user.email ?? '', `${user.firstName} ${user.lastName}`);
                        }
                    }
                },
            });

            LogSuccess('Bugsnag: Connected');

            this.isConnected = true;
        }
    }
}
