import { SVGCheckmark } from '@goed-platform/shared/utils';

export enum CheckmarkSizeEnum {
    'xs',
    'sm',
    'md',
    'lg',
}

export type CheckmarkProps = {
    size: CheckmarkSizeEnum;
    className?: string;
};

export const Checkmark: React.FC<CheckmarkProps> = ({ size, className = '' }): JSX.Element => {
    const spriteIconSize: string = size === CheckmarkSizeEnum.lg ? `sprite-md sprite-md-lg` : `sprite-${size}`;

    return (
        <div
            className={`
                d-flex justify-content-center align-items-center
                ${size === CheckmarkSizeEnum.xs ? 'ws-4 hs-4' : ''}
                ${size === CheckmarkSizeEnum.sm ? 'ws-8 hs-8' : ''}
                ${size === CheckmarkSizeEnum.md ? 'ws-10 hs-10' : ''}
                ${size === CheckmarkSizeEnum.lg ? 'ws-10 hs-10 ws-md-16 hs-md-16' : ''}
                ${className}
            `}
        >
            <SVGCheckmark className={`sprite sprite-checkmark ${spriteIconSize}`} title="checkmark" />
        </div>
    );
};
