import { EventCallbackType } from '@goed-platform/shared/types';
import { Field } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren } from 'react';
import { FieldError } from '../../index';

type CheckboxProps = PropsWithChildren<{
    id: string;
    label: string;
    hasHtmlInLabel?: boolean;
    help?: string;
    className?: string;
    labelClassName?: string;
    error?: string;
    errorClassName?: string;
    touched?: boolean;
    onChange?: EventCallbackType;
}>;

export const Checkbox: React.FC<CheckboxProps> = ({
    id,
    label,
    hasHtmlInLabel = false,
    help,
    className = '',
    error,
    errorClassName = '',
    labelClassName = '',
    touched = false,
    onChange,
    children,
    ...restProps
}: CheckboxProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="form-group">
            <div className="custom-control custom-checkbox">
                <Field
                    id={id}
                    data-cy="checkbox"
                    name={id}
                    type="checkbox"
                    className={`custom-control-input ${className}`}
                    onChange={onChange}
                    {...restProps}
                />

                {hasHtmlInLabel ? (
                    <label
                        htmlFor={id}
                        role="button"
                        className={`custom-control-label text-body font-size-16 font-size-lg-18 ${labelClassName}`}
                        dangerouslySetInnerHTML={{ __html: t(label) }}
                    ></label>
                ) : (
                    <label
                        htmlFor={id}
                        role="button"
                        className={`custom-control-label text-body font-size-16 font-size-lg-18 ${labelClassName}`}
                    >
                        {t(label)}
                    </label>
                )}
                {children}
            </div>
            <FieldError className={errorClassName} error={error} touched={touched} />
            {help && <div className="form-text mt-2">{t(help)}</div>}
        </div>
    );
};

export default Checkbox;
