import { ApolloError } from '@apollo/client';
import {
    Product,
    ProductProjectionSuggestDocument,
    ProductProjectionSuggestQuery,
    ProductProjectionSuggestQueryVariables,
    ProductSort,
    ProductsOverviewDocument,
    ProductsOverviewQuery,
    ProductsOverviewQueryVariables,
    ProductsPromotionDocument,
    ProductsPromotionQuery,
    ProductsPromotionQueryVariables,
    StoreType,
} from '@goed-platform/graphql/types';
import { CTErrors, ErrorItemType } from '@goed-platform/shared/constants';
import { Commercetools } from '@goed-platform/shared/data-access';

export type FuzzySearchResult = {
    suggestions: string[];
    products: Product[];
    total: number;
};

export class ProductDataAccess {
    public static async getProducts({
        take = 25,
        skip = 0,
        sort = ProductSort.Default,
        propertyFilters = [],
        categoryId = '',
        filter = '',
        storeType,
    }: Partial<ProductsOverviewQueryVariables>): Promise<ProductsOverviewQuery | ErrorItemType> {
        return new Promise((resolve, reject) => {
            const searchVariables: ProductsOverviewQueryVariables = {
                take: take,
                skip: skip,
                propertyFilters: propertyFilters,
                categoryId: categoryId,
                filter: filter,
                storeType: storeType,
            };
            if (sort !== ProductSort.Default) searchVariables['sort'] = sort;

            Commercetools.mutate<ProductsOverviewQuery, ProductsOverviewQueryVariables>({
                mutation: ProductsOverviewDocument,
                variables: searchVariables,
                fetchPolicy: 'no-cache',
            })
                .then((response) => {
                    if (response?.data?.products) {
                        return resolve(response.data);
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async getPopularProducts(storeType: StoreType): Promise<Product[] | ErrorItemType> {
        return new Promise((resolve, reject) => {
            const searchVariables: ProductsPromotionQueryVariables = {
                storeType: storeType,
            };
            Commercetools.mutate<ProductsPromotionQuery, ProductsPromotionQueryVariables>({
                mutation: ProductsPromotionDocument,
                variables: searchVariables,
                fetchPolicy: 'no-cache',
            })
                .then((response) => {
                    if (response?.data?.products?.items) {
                        const products: unknown = response?.data?.products?.items ?? [];
                        return resolve(products as Product[]);
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async searchProducts({
        take = 5,
        skip = 0,
        sort = ProductSort.Default,
        propertyFilters = [],
        categoryId = '',
        filter = '',
        storeType,
    }: Partial<ProductsOverviewQueryVariables>): Promise<Product[] | ErrorItemType> {
        return new Promise((resolve, reject) => {
            const searchVariables: ProductsOverviewQueryVariables = {
                take: take,
                skip: skip,
                propertyFilters: propertyFilters,
                categoryId: categoryId,
                filter: filter,
                storeType: storeType,
            };
            if (sort !== ProductSort.Default) searchVariables['sort'] = sort;
            Commercetools.mutate<ProductsOverviewQuery, ProductsOverviewQueryVariables>({
                mutation: ProductsOverviewDocument,
                variables: searchVariables,
                fetchPolicy: 'no-cache',
            })
                .then((response) => {
                    if (response?.data?.products?.items) {
                        const products: unknown = response?.data?.products?.items ?? [];
                        return resolve(products as Product[]);
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async fuzzySearchProducts({
        limit = 5,
        fuzzy = true,
        filter = '',
        storeType,
    }: Partial<ProductProjectionSuggestQueryVariables>): Promise<FuzzySearchResult | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.mutate<ProductProjectionSuggestQuery, ProductProjectionSuggestQueryVariables>({
                mutation: ProductProjectionSuggestDocument,
                variables: {
                    limit: limit,
                    fuzzy: fuzzy,
                    filter: filter,
                    storeType: storeType ?? StoreType.Res,
                },
                fetchPolicy: 'no-cache',
            })
                .then((response) => {
                    if (response?.data?.productProjectionSuggest) {
                        const suggestions: unknown =
                            response?.data?.productProjectionSuggest.suggestions?.[0]?.text ?? [];
                        const products: unknown = response?.data?.productProjectionSuggest.products?.[0]?.value ?? [];
                        return resolve({
                            suggestions: suggestions as string[],
                            products: products as Product[],
                            total: response?.data?.productProjectionSuggest?.total ?? 0,
                        });
                    }

                    return reject(CTErrors.processUnexpectedOutput());
                })
                .catch((error: ApolloError) => {
                    return reject(CTErrors.processError(error));
                });
        });
    }
}
