import { ApolloError } from '@apollo/client';
import {
    CategoriesRootDocument,
    CategoriesRootQuery,
    CategoriesRootQueryVariables,
    Category,
    CategoryBySlugDocument,
    CategoryBySlugQuery,
    CategoryBySlugQueryVariables,
    SearchCategoriesDocument,
    SearchCategoriesQuery,
    SearchCategoriesQueryVariables,
    SearchCategoriesResult,
    StoreType,
} from '@goed-platform/graphql/types';
import { CTErrors, ErrorItemType } from '@goed-platform/shared/constants';
import { Commercetools } from '@goed-platform/shared/data-access';

export class CategoriesDataAccess {
    public static async getRootCategories(storeType: StoreType): Promise<Category[] | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.query<CategoriesRootQuery, CategoriesRootQueryVariables>({
                query: CategoriesRootDocument,
                fetchPolicy: 'no-cache',
                variables: {
                    storeType: storeType,
                },
            })
                .then((response) => {
                    if (response?.data?.rootCategories) {
                        resolve(response.data.rootCategories as Category[]);
                    } else {
                        reject(CTErrors.processUnexpectedOutput());
                    }
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async getCategoryNavigationBySlug(
        storeType: StoreType,
        slug: string
    ): Promise<SearchCategoriesResult | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.query<CategoryBySlugQuery, CategoryBySlugQueryVariables>({
                query: CategoryBySlugDocument,
                variables: {
                    storeType: storeType,
                    slug: slug,
                },
                fetchPolicy: 'no-cache',
            })
                .then((response) => {
                    if (response?.data?.categories) {
                        resolve(response.data.categories as SearchCategoriesResult);
                    } else {
                        reject(CTErrors.processUnexpectedOutput());
                    }
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }

    public static async searchCategories(storeType: StoreType, search: string): Promise<Category[] | ErrorItemType> {
        return new Promise((resolve, reject) => {
            Commercetools.query<SearchCategoriesQuery, SearchCategoriesQueryVariables>({
                query: SearchCategoriesDocument,
                fetchPolicy: 'no-cache',
                variables: {
                    storeType: storeType,
                    filter: search,
                },
            })
                .then((response) => {
                    if (response?.data?.categories) {
                        resolve(response.data.categories as Category[]);
                    } else {
                        reject(CTErrors.processUnexpectedOutput());
                    }
                })
                .catch((error: ApolloError) => reject(CTErrors.processError(error)));
        });
    }
}
