import { CheckoutUldProgressBar } from '@goed-platform/checkout/ui';
import { Category, StoreType } from '@goed-platform/graphql/types';
import { MappedNavigation } from '@goed-platform/symfony/types';
import { BaseLayout } from './base-layout';
import { CheckoutReservationProgressBar } from '../../../../../checkout/ui/src/lib/checkout-reservation-progress-bar';

type CheckoutLayoutProps = {
    children: React.ReactElement | React.ReactElement[];
    showProgressBar: boolean;
    progressBarStep?: number;
    storeType?: StoreType;
    categoryNavigation?: Category[];
    navigation: MappedNavigation;
};

export const CheckoutLayout: React.FC<CheckoutLayoutProps> = ({
    storeType,
    children,
    categoryNavigation,
    showProgressBar,
    progressBarStep,
    navigation,
}: CheckoutLayoutProps): JSX.Element => {
    return (
        <BaseLayout
            showSubHeader={true}
            showReadOnlySubHeader={true}
            centeredLogo={true}
            showCustomerBar={false}
            showFooterNavigation={false}
            showMinimalizedFooter={true}
            categoryNavigation={categoryNavigation}
            navigation={navigation}
        >
            {/* pt-sm-8 pt-lg-16 pb-sm-12 pb-lg-28  */}
            <div className="container">
                {showProgressBar && !!progressBarStep && (
                    <div className="row justify-content-center">
                        <div className="col-lg-10 pt-8 pt-md-16 pb-12 pb-lg-22">
                            <div className="mx-n4 mx-sm-0">
                                {storeType === StoreType.Uld && <CheckoutUldProgressBar activeStep={progressBarStep} />}
                                {storeType === StoreType.Res && (
                                    <CheckoutReservationProgressBar activeStep={progressBarStep} />
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className="row justify-content-center mb-sm-6">
                    <div className="col-12 col-lg-8 col-xl-6">{children}</div>
                </div>
            </div>
        </BaseLayout>
    );
};
