import { Product, StoreType } from '@goed-platform/graphql/types';
import { ImageLayoutEnum, ImageObjectfitEnum } from '@goed-platform/product/enums';
import { UrlUtils } from '@goed-platform/shared/utils';
import Link from 'next/link';
import { PropsWithChildren } from 'react';
import OptimizedImage from '../../../optimized-image';

type SearchBarResultProductType = PropsWithChildren<{ product: Product; storeType: StoreType }>;

export const SearchBarResultProduct = ({ product, storeType }: SearchBarResultProductType): JSX.Element => {
    return (
        <div className="position-relative d-flex align-items-center p-4">
            <div className="ws-13 hs-13 mr-2 d-flex align-items-center justify-content-center">
                <OptimizedImage
                    image={{
                        sortOrder: 0,
                        url: product?.image,
                    }}
                    layout={ImageLayoutEnum.fill}
                    objectFit={ImageObjectfitEnum.contain}
                    alt={product.label ?? ''}
                    priority={false}
                />
            </div>
            <div className="d-flex flex-column">
                <Link
                    href={UrlUtils.createProductUrl(
                        product.sku,
                        product.variants?.[0]?.slug ?? product.slug,
                        product.variants?.[0]?.key ?? product.key,
                        storeType
                    )}
                >
                    <a className="border-0 stretched-link d-block mb-1 font-size-16 line-height-18">
                        <b>{product.label}</b>
                    </a>
                </Link>
                <div className="small line-height-14 text-neutral-a80">
                    {product.categories?.find((cat) => cat?.primary)?.label}
                </div>
            </div>
        </div>
    );
};
