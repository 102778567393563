import { Maybe, UserStore } from '@goed-platform/graphql/types';
import { RentalStore } from '@goed-platform/symfony/types';

export class StoreMapper {
    public static SymfonyStoreToCTStore(store: RentalStore): UserStore {
        return {
            box: store?.box ?? '',
            city: store?.city ?? '',
            country: store?.country ?? '',
            houseNumber: store?.number,
            postCode: '',
            services: [], // @TODO: map over services
            storeId: `${store?.id ?? ''}`,
            storeName: store.name,
            storeUrl: store.slug,
            streetName: store.street,
        };
    }

    public static CTStoreToSymfonyStore(store: Maybe<UserStore>): RentalStore {
        return {
            box: store?.box ?? '',
            city: store?.city ?? '',
            country: store?.country ?? '',
            currentOrNextOpenState: undefined,
            distance: 0,
            id: +(store?.storeId ?? 0),
            lat: 0,
            lng: 0,
            name: store?.storeName ?? '',
            number: store?.houseNumber ?? '',
            services: [],
            slug: store?.storeUrl ?? '',
            street: store?.streetName ?? '',
        };
    }
}
