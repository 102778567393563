/* eslint-disable @typescript-eslint/no-empty-function */
import { CartDataAccess } from '@goed-platform/cart/data-access';
import { Config } from '@goed-platform/shared/constants';
import { RoutesEnum } from '@goed-platform/shared/types';
import { LogInfo, LogWarning } from '@goed-platform/shared/utils';
import { UserDataAccess } from '@goed-platform/user/data-access';
import { NextRouter } from 'next/router';
import { appVersion } from '../../../../../statistics/appversion';

export class Boot {
    public static start(router: NextRouter, refreshInterval = Config.boot.refreshInterval): NodeJS.Timeout {
        LogInfo(`Version ${appVersion}`);

        UserDataAccess.fetchUserInfo().catch(() => {});

        CartDataAccess.fetchCart();

        // Unhandled promise rejections should not bubble up in the global scope, so they are also
        // flagged in Bugsnag, but appear as a warning in the console.
        window.addEventListener('unhandledrejection', (event) => {
            LogWarning(`Unhandled promise rejection: ${event.reason}`, event);
            event.preventDefault();
        });

        // @TODO: This should be reworked with Apollo Subscriptions. Check with RB2 if they can implement this.
        // More info: https://www.apollographql.com/docs/react/data/subscriptions/
        return setInterval(() => {
            CartDataAccess.fetchCart();
        }, refreshInterval);
    }
}
