import { Maybe, StoreType } from '@goed-platform/graphql/types';
import { RoutesEnum } from '@goed-platform/shared/types';
import slugify from 'slugify';

export class UrlUtils {
    public static isExternalUrl(url?: string, hostName?: string): boolean {
        if (!url || url[0] === '/') {
            return false;
        }

        if (hostName && url.includes(hostName)) {
            return false;
        }

        if (url.includes('http') || url.includes('www')) {
            return true;
        }

        return false;
    }

    public static slugifyUrl(text: string): string {
        if (!text) {
            return '';
        }

        return slugify(text, {
            lower: true,
            remove: /[*+~.()[\]'"!:@]/g,
        });
    }

    public static generateProductSlug(slug: Maybe<string> | undefined, key: Maybe<string> | undefined): string {
        const sluggifiedKey = key ? UrlUtils.slugifyUrl(key) : '';

        // If the variantKey or sluggifiedKey is empty - or - sluggifiedKey equals the slug, leave it out of the product slug.
        if (!key || !sluggifiedKey || sluggifiedKey === slug) {
            return slug ?? '';
        }

        return `${slug ?? ''}-${sluggifiedKey}`;
    }

    public static createProductUrl(
        sku: Maybe<string> | undefined,
        slug: Maybe<string> | undefined,
        key: Maybe<string> | undefined,
        storeType: StoreType
    ): string {
        switch (storeType) {
            case StoreType.Uld:
                return `${RoutesEnum.rentalStoreProduct}/${sku ?? ''}/${UrlUtils.generateProductSlug(slug, key)}`;

            case StoreType.Res:
                return `${RoutesEnum.webReservationProduct}/${sku ?? ''}/${UrlUtils.generateProductSlug(slug, key)}`;
        }
    }

    public static createSearchUrl(searchTerm: Maybe<string> | undefined, storeType: StoreType): string {
        switch (storeType) {
            case StoreType.Uld:
                return `${RoutesEnum.rentalStoreSearch}?search=${searchTerm}`;

            case StoreType.Res:
                return `${RoutesEnum.webReservationSearch}?search=${searchTerm}`;
        }
    }

    public static createCategoryUrl(categoryPath: string, storeType: StoreType): string {
        switch (storeType) {
            case StoreType.Uld:
                return `${RoutesEnum.rentalStoreLanding}/${categoryPath}`;

            case StoreType.Res:
                return `${RoutesEnum.webReservationLanding}/${categoryPath}`;
        }

        return `${RoutesEnum.rentalStoreLanding}/${categoryPath}`;
    }
}
