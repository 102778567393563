import { ApolloError } from '@apollo/client';
import { StorageEnum } from '@goed-platform/shared/data-access';
import { LogError } from '@goed-platform/shared/utils';
import { i18n } from 'next-i18next';

export enum ErrorMessageEnum {
    serverError = 'general.serverError',
    formError = 'validation.generalFormError',
    billingAddressError = 'validation.billingAddressError',
}

export type ErrorItemType = {
    inCodeConstant: string;
    publicTranslationKey: string;
    privateTranslationKey: string;
    appendServerError?: boolean;
};

export class CTErrors {
    public static generateErrorTranslation = (error?: ErrorItemType): string => `
        ${i18n?.t(error?.publicTranslationKey ?? '') ?? ''}
        ${error?.appendServerError && error?.publicTranslationKey.length !== 0 ? '<br/><br/>' : ''}
        ${
            error?.appendServerError || error?.publicTranslationKey.length === 0
                ? i18n?.t(ErrorMessageEnum.serverError, {
                      correlationID: sessionStorage.getItem(StorageEnum.correlationId),
                  }) ?? ''
                : ''
        }
    `;

    public static processUnexpectedOutput = (context?: unknown): ErrorItemType => {
        LogError('Unexpected output from endpoint', context);
        return {
            inCodeConstant: 'UNEXPECTED OUTPUT FROM ENDPOINT',
            publicTranslationKey: '',
            privateTranslationKey: context as string,
        };
    };

    public static processError = ({
        graphQLErrors,
        networkError,
        extraInfo,
        clientErrors,
        message,
    }: Partial<ApolloError>): ErrorItemType => {
        if (graphQLErrors) {
            return CTErrors.getErrorItem(graphQLErrors[0]?.extensions?.code, graphQLErrors[0]?.extensions?.detail);
        }

        if (networkError) {
            return CTErrors.getErrorItem(networkError.name, networkError.message);
        }

        if (clientErrors) {
            return CTErrors.getErrorItem(clientErrors[0]?.name, clientErrors[0]?.message);
        }

        return {
            inCodeConstant: message ?? 'ERROR NOT FOUND',
            publicTranslationKey: ErrorMessageEnum.serverError,
            privateTranslationKey: extraInfo,
        };
    };

    public static getErrorItem = (key: string, description?: string): ErrorItemType => {
        const actualKey = (key ?? '').toUpperCase() as keyof CTErrors;
        const error = CTErrors[actualKey];

        if (!error) {
            return {
                inCodeConstant: key,
                publicTranslationKey: '',
                privateTranslationKey: description ?? '',
            };
        }

        return error;
    };

    public static readonly UNEXPECTED_001: ErrorItemType = {
        inCodeConstant: 'Unexpected',
        publicTranslationKey: '',
        privateTranslationKey: 'error.UNEXPECTED_001.private',
    };

    public static readonly NOT_LOGGED_ON: ErrorItemType = {
        inCodeConstant: 'Unexpected',
        publicTranslationKey: '',
        privateTranslationKey: 'error.UNEXPECTED_001.private',
    };

    public static readonly AUTH_001: ErrorItemType = {
        inCodeConstant: 'InvalidUserNameOrPassword',
        publicTranslationKey: 'error.AUTH_001.public',
        privateTranslationKey: 'error.AUTH_001.private',
    };

    public static readonly AUTH_002: ErrorItemType = {
        inCodeConstant: 'InvalidToken',
        publicTranslationKey: '',
        privateTranslationKey: 'error.AUTH_002.private',
    };

    public static readonly AUTH_003: ErrorItemType = {
        inCodeConstant: 'InvalidPassword',
        publicTranslationKey: '',
        privateTranslationKey: 'error.AUTH_003.private',
    };

    public static readonly AUTH_004: ErrorItemType = {
        inCodeConstant: 'CartRequireAuth',
        publicTranslationKey: '',
        privateTranslationKey: 'error.AUTH_004.private',
    };

    public static readonly AUTH_NOT_AUTHORIZED: ErrorItemType = {
        inCodeConstant: 'AUTH_NOT_AUTHORIZED',
        publicTranslationKey: '',
        privateTranslationKey: 'error.AUTH_NOT_AUTHORIZED.private',
    };

    public static readonly NOTFOUND_001: ErrorItemType = {
        inCodeConstant: 'AddressNotFound',
        publicTranslationKey: 'error.NOTFOUND_001.public',
        privateTranslationKey: 'error.NOTFOUND_001.private',
        appendServerError: true,
    };

    public static readonly NOTFOUND_002: ErrorItemType = {
        inCodeConstant: 'CartNotFound',
        publicTranslationKey: '',
        privateTranslationKey: 'error.NOTFOUND_002.private',
    };

    public static readonly NOTFOUND_003: ErrorItemType = {
        inCodeConstant: 'CustomerNotFound',
        publicTranslationKey: 'error.NOTFOUND_003.public',
        privateTranslationKey: 'error.NOTFOUND_003.private',
        appendServerError: true,
    };

    public static readonly NOTFOUND_004: ErrorItemType = {
        inCodeConstant: 'CustomerGroupNotFound',
        publicTranslationKey: '',
        privateTranslationKey: 'error.NOTFOUND_004.private',
    };

    public static readonly NOTFOUND_005: ErrorItemType = {
        inCodeConstant: 'ChannelSettingNotFound',
        publicTranslationKey: '',
        privateTranslationKey: 'error.NOTFOUND_005.private',
    };

    public static readonly NOTFOUND_006: ErrorItemType = {
        inCodeConstant: 'LineItemNotFound',
        publicTranslationKey: '',
        privateTranslationKey: 'error.NOTFOUND_006.private',
    };

    public static readonly NOTFOUND_007: ErrorItemType = {
        inCodeConstant: 'OrderNotFound',
        publicTranslationKey: 'error.NOTFOUND_007.public',
        privateTranslationKey: 'error.NOTFOUND_007.private',
    };

    public static readonly NOTFOUND_008: ErrorItemType = {
        inCodeConstant: 'ProductNotFound',
        publicTranslationKey: 'error.NOTFOUND_008.public',
        privateTranslationKey: 'error.NOTFOUND_008.private',
    };

    public static readonly NOTFOUND_009: ErrorItemType = {
        inCodeConstant: 'SKUNotFound',
        publicTranslationKey: 'error.NOTFOUND_009.public',
        privateTranslationKey: 'error.NOTFOUND_009.private',
    };

    public static readonly NOTFOUND_010: ErrorItemType = {
        inCodeConstant: 'StoreNotFound',
        publicTranslationKey: 'error.NOTFOUND_010.public',
        privateTranslationKey: 'error.NOTFOUND_010.private',
    };

    public static readonly NOTFOUND_011: ErrorItemType = {
        inCodeConstant: 'ShippingMethodNotFound',
        publicTranslationKey: '',
        privateTranslationKey: 'error.NOTFOUND_011.private',
    };

    public static readonly NOTFOUND_012: ErrorItemType = {
        inCodeConstant: 'ShippingRateNotFound',
        publicTranslationKey: '',
        privateTranslationKey: 'error.NOTFOUND_012.private',
    };

    public static readonly NOTFOUND_013: ErrorItemType = {
        inCodeConstant: 'ShoppingListNotFound',
        publicTranslationKey: '',
        privateTranslationKey: 'error.NOTFOUND_013.private',
    };

    public static readonly NOTFOUND_014: ErrorItemType = {
        inCodeConstant: 'TaxSettingNotFound',
        publicTranslationKey: '',
        privateTranslationKey: 'error.NOTFOUND_014.private',
    };

    public static readonly NOTFOUND_015: ErrorItemType = {
        inCodeConstant: 'CategoryNotFound',
        publicTranslationKey: 'error.NOTFOUND_015.public',
        privateTranslationKey: 'error.NOTFOUND_015.private',
    };

    public static readonly VALIDATION_001: ErrorItemType = {
        inCodeConstant: 'InvalidEmail',
        publicTranslationKey: 'error.VALIDATION_001.public',
        privateTranslationKey: 'error.VALIDATION_001.private',
    };

    public static readonly REQUIRED_001: ErrorItemType = {
        inCodeConstant: 'EmailRequired',
        publicTranslationKey: 'error.REQUIRED_001.public',
        privateTranslationKey: 'error.REQUIRED_001.private',
    };

    public static readonly REQUIRED_002: ErrorItemType = {
        inCodeConstant: 'BillingAddressRequired',
        publicTranslationKey: 'error.REQUIRED_002.public',
        privateTranslationKey: 'error.REQUIRED_002.private',
    };

    public static readonly REQUIRED_003: ErrorItemType = {
        inCodeConstant: 'CartLineItemRequired',
        publicTranslationKey: '',
        privateTranslationKey: 'error.REQUIRED_003.private',
    };

    public static readonly REQUIRED_004: ErrorItemType = {
        inCodeConstant: 'CartIDRequired',
        publicTranslationKey: '',
        privateTranslationKey: 'error.REQUIRED_004.private',
    };

    public static readonly REQUIRED_005: ErrorItemType = {
        inCodeConstant: 'DeliveryAddressRequired',
        publicTranslationKey: 'error.REQUIRED_005.public',
        privateTranslationKey: 'error.REQUIRED_005.private',
    };

    public static readonly REQUIRED_006: ErrorItemType = {
        inCodeConstant: 'StoreIDRequired',
        publicTranslationKey: 'error.REQUIRED_006.public',
        privateTranslationKey: 'error.REQUIRED_006.private',
    };

    public static readonly REQUIRED_007: ErrorItemType = {
        inCodeConstant: 'ShoppingListIDRequired',
        publicTranslationKey: 'error.REQUIRED_007.public',
        privateTranslationKey: 'error.REQUIRED_007.private',
    };

    public static readonly OUTOFRANGE_001: ErrorItemType = {
        inCodeConstant: 'QuantityOverLimit',
        publicTranslationKey: 'error.OUTOFRANGE_001.public',
        privateTranslationKey: 'error.OUTOFRANGE_001.private',
    };

    public static readonly OUTOFRANGE_002: ErrorItemType = {
        inCodeConstant: 'QuantityMustGreaterThanZero',
        publicTranslationKey: 'error.OUTOFRANGE_002.public',
        privateTranslationKey: 'error.OUTOFRANGE_002.private',
    };

    public static readonly OUTOFRANGE_003: ErrorItemType = {
        inCodeConstant: 'QuantityMustGreaterOrEqualThanMinQuantity',
        publicTranslationKey: 'error.OUTOFRANGE_003.public',
        privateTranslationKey: 'error.OUTOFRANGE_003.private',
    };

    public static readonly OUTOFRANGE_004: ErrorItemType = {
        inCodeConstant: 'QuantityMustLittleOrEqualThanMaxQuantity',
        publicTranslationKey: 'error.OUTOFRANGE_004.public',
        privateTranslationKey: 'error.OUTOFRANGE_004.private',
    };

    public static readonly WRONGFORMAT_001: ErrorItemType = {
        inCodeConstant: 'InvalidAddressDataFormat',
        publicTranslationKey: '',
        privateTranslationKey: 'error.WRONGFORMAT_001.private',
    };

    public static readonly WRONGFORMAT_002: ErrorItemType = {
        inCodeConstant: 'InvalidPersonalDataFormat',
        publicTranslationKey: '',
        privateTranslationKey: 'error.WRONGFORMAT_002.private',
    };

    public static readonly WRONGFORMAT_003: ErrorItemType = {
        inCodeConstant: 'InvalidEmailAddressFormat',
        publicTranslationKey: 'error.WRONGFORMAT_003.public',
        privateTranslationKey: 'error.WRONGFORMAT_003.private',
    };

    public static readonly WRONGFORMAT_004: ErrorItemType = {
        inCodeConstant: 'InvalidEmailDomainFormat',
        publicTranslationKey: '',
        privateTranslationKey: 'error.WRONGFORMAT_004.private',
    };

    public static readonly EXISTED_001: ErrorItemType = {
        inCodeConstant: 'EmailExisted',
        publicTranslationKey: 'error.EXISTED_001.public',
        privateTranslationKey: 'error.EXISTED_001.private',
    };

    public static readonly EXISTED_002: ErrorItemType = {
        inCodeConstant: 'CustomerGroupCannotBeChanged',
        publicTranslationKey: '',
        privateTranslationKey: 'error.EXISTED_002.private',
    };

    public static readonly UNACCEPTABLE_001: ErrorItemType = {
        inCodeConstant: 'InvalidCustomer',
        publicTranslationKey: '',
        privateTranslationKey: 'error.UNACCEPTABLE_001.private',
    };

    public static readonly UNACCEPTABLE_002: ErrorItemType = {
        inCodeConstant: 'InvalidProductData',
        publicTranslationKey: '',
        privateTranslationKey: 'error.UNACCEPTABLE_002.private',
    };

    public static readonly CONFIGURATION_CT_001: ErrorItemType = {
        inCodeConstant: 'NoDefaultProductType',
        publicTranslationKey: '',
        privateTranslationKey: 'error.CONFIGURATION_CT_001.private',
    };

    public static readonly INTEGRATIONERROR_BPOST_001: ErrorItemType = {
        inCodeConstant: 'FailureSendingOrderToBpost',
        publicTranslationKey: '',
        privateTranslationKey: 'error.INTEGRATIONERROR_BPOST_001.private',
    };

    public static readonly INTEGRATIONERROR_BPOST_002: ErrorItemType = {
        inCodeConstant: 'FailureRetrievingOrderFromBpost',
        publicTranslationKey: '',
        privateTranslationKey: 'error.INTEGRATIONERROR_BPOST_002.private',
    };

    public static readonly INTEGRATIONERROR_DYN_001: ErrorItemType = {
        inCodeConstant: 'FailureCreatingDynamicsOrder',
        publicTranslationKey: '',
        privateTranslationKey: 'error.INTEGRATIONERROR_DYN_001.private',
    };
}
