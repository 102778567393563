import { Category } from '@goed-platform/graphql/types';
import { MappedNavigation } from '@goed-platform/symfony/types';
import { PropsWithChildren } from 'react';
import { BaseLayout } from './base-layout';

type FocusLayoutProps = PropsWithChildren<{
    title: string;
    description?: string | JSX.Element;
    categoryNavigation?: Category[];
    className?: string;
    isCentered?: boolean;
    navigation: MappedNavigation;
}>;

export const FocusLayout: React.FC<FocusLayoutProps> = ({
    title,
    description,
    children,
    className = 'col-12 col-lg-4',
    categoryNavigation,
    isCentered = true,
    navigation,
    ...restProps
}: FocusLayoutProps): JSX.Element => {
    return (
        <BaseLayout navigation={navigation} categoryNavigation={categoryNavigation}>
            <div data-cy="confirm-info" className="container-lg py-lg-13 py-6" {...restProps}>
                <div className={`row ${isCentered ? 'justify-content-center' : ''}`}>
                    <div className={className}>
                        <h1 data-cy="account-title" className="mb-4 mb-lg-8 font-size-20 font-size-md-48 text-primary">
                            {title}
                        </h1>
                        {description && typeof description === 'string' ? (
                            <p className="font-size-16 font-size-md-18">{description}</p>
                        ) : (
                            // JSX Element
                            description
                        )}
                    </div>
                </div>

                {children}
            </div>
        </BaseLayout>
    );
};
