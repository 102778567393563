import { SVGLocation } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import { UserAddress, UserStore } from '@goed-platform/graphql/types';
import { Config } from '@goed-platform/shared/constants';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

type CheckoutReservationAddressProps = PropsWithChildren<{
    address?: UserAddress | UserStore;
    className?: string;
    displayIcon?: boolean;
    pharmacySlug?: string;
}>;

export const CheckoutReservationAddress = ({
    address,
    className = '',
    displayIcon = false,
    children,
    pharmacySlug = '',
}: CheckoutReservationAddressProps): JSX.Element => {
    const { t } = useTranslation();
    const router = useRouter();

    if (!address || address.city == null) {
        return <></>;
    }

    return (
        <div className="d-flex">
            {displayIcon && (
                <SVGLocation className="sprite sprite-chevron-right sprite-md mr-2" title="chevron-right" />
            )}
            <div className={`line-height-normal ${className}`} suppressHydrationWarning>
                {/* Regular (user) address with contact information */}
                {address.__typename === 'UserAddress' && (
                    <>
                        {address?.company && (
                            <>
                                <a
                                    href={`${Config.sites.main}/${router.locale}/vestigingen/${pharmacySlug}`}
                                    suppressHydrationWarning
                                >
                                    {address?.company}
                                </a>
                                <br />
                            </>
                        )}
                        {address?.streetName} {address?.houseNumber}
                        {!!address.box && ` ${t('user.box').toLowerCase()} ${address.box}`}
                        <br />
                        {address?.extraAddressInfo && (
                            <>
                                {address?.extraAddressInfo}
                                <br />
                            </>
                        )}
                        {address?.postCode} {address?.city} ({t('user.countryFixedValue')}){address?.phone && <br />}
                        {address?.phone}
                        {address?.email && <br />}
                        {address?.email}
                    </>
                )}
                {children}
            </div>
        </div>
    );
};
