import { SpecialComponents } from 'react-markdown/lib/ast-to-react';
import { NormalComponents } from 'react-markdown/lib/complex-types';

type componentSettings = Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents>;

export const componentSettings: componentSettings = {
    h1: 'h4',
    h2: 'h5',
    h3: 'h6',
    h4: 'h6',
    h5: 'h6',
    h6: 'h6',
    blockquote: ({ children }) => <blockquote className="blockquote">{children}</blockquote>,
    table: (props) => <table className="table table-bordered bg-white" {...props} />,
    th: ({ children }) => (children ? <th>{children}</th> : <></>), // Hide empty table headers.
};
