/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
// @ts-nocheck
const I18NextHttpBackend = require('i18next-http-backend');
const axios = require('axios');

export const nextI18nextConfig = {
    i18n: {
        // Hack to make sure all urls are preceded by a locale (otherwise this isn't possible in Next.js).
        // This, combined with a redirect, makes the whole thing work (see next.config.js for redirects).
        // @see https://www.mutual.agency/blog/force-a-locale-sub-folder-with-next-js/
        defaultLocale: 'catchAll',
        realDefaultLocale: 'nl',
        locales: ['nl', 'catchAll'],
    },
    lng: 'nl',
    react: { useSuspense: false },
    allowMultiLoading: false,
    debug: false,
    ns: ['common'],
    serializeConfig: false,
    backend: {
        loadPath: `${process.env.NEXT_PUBLIC_SYMFONY_BASE_ENDPOINT}/public/translation?locale={{lng}}`,
        crossDomain: true,
        requestOptions: {
            mode: 'no-cors',
            cache: 'no-cache',
        },
        request: async (options, url, payload, callback) => {
            try {
                const translation = await axios.get(url);
                callback(null, {
                    status: 200,
                    data: translation.data?.data ?? {},
                });
            } catch (e) {
                callback(e);
            }
        },
    },
    reloadInterval: 60 * 60 * 1000, // Reload translations every hour.
    use: [I18NextHttpBackend],
};
