/* eslint-disable react/jsx-no-useless-fragment */
import { Period, TransactionType } from '@goed-platform/graphql/types';
import { formatPrice, formatPriceWithValuta } from '@goed-platform/pricing/util';
import { useTranslation } from 'next-i18next';
import React from 'react';

export enum PriceSizeEnum {
    large = 'lg',
    medium = 'md',
}

type PriceProps = {
    value: number;
    transactionType: TransactionType;
    size: PriceSizeEnum;
    frequency?: Period;
    enableSuperscript?: boolean;
    className?: string;
    frequencyClassName?: string;
};

export const Price: React.FC<PriceProps> = ({
    value,
    transactionType,
    frequency,
    size,
    enableSuperscript,
    className = '',
    frequencyClassName = '',
    ...restProps
}: PriceProps): JSX.Element => {
    const { t } = useTranslation();
    const formattedPrice: string[] = formatPrice(value).split(',');

    const FrequencyBadge = (): JSX.Element =>
        transactionType === TransactionType.Rental && frequency ? (
            <span className={`font-size-16 ${frequencyClassName}`}>
                &nbsp;/ {t(`general.periods.${frequency?.toLowerCase()}`)}
            </span>
        ) : (
            <></>
        );

    if (enableSuperscript) {
        return (
            <>
                <span
                    className={`price-superscript price-superscript-${size} d-inline-flex font-weight-bold ${className}`}
                    {...restProps}
                >
                    <span className="price-superscript-little mr-1">&euro;</span>
                    <span className="price-superscript-big">{formattedPrice[0]}</span>
                    <span className="price-superscript-comma">,</span>
                    <span className="price-superscript-little">{formattedPrice[1]}</span>
                </span>
                <FrequencyBadge />
            </>
        );
    }

    return (
        <>
            <span className={`price price-${size} font-weight-bold line-height-1 ${className}`} {...restProps}>
                {formatPriceWithValuta(value)}
            </span>
            <FrequencyBadge />
        </>
    );
};

export default Price;
