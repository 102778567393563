import { SVGChevronLeft, SVGMenuClose } from '@goed-platform/shared/utils';
import React from 'react';

type ModalHeaderProps = {
    displayClose?: boolean;
    displayPrev?: boolean;
    closeFunction: () => void;
    prevFunction?: () => void;
    title: string;
    menuItems?: number;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
    displayClose = true,
    displayPrev = true,
    closeFunction,
    prevFunction = closeFunction,
    title = '',
    menuItems = 3,
}: ModalHeaderProps): JSX.Element => {
    const DisplayPrevDefaultContent = () => (menuItems === 2 ? <></> : <div></div>);

    return (
        <div className="bg-primary py-3 px-4 d-flex justify-content-between align-items-center text-white">
            {displayPrev ? (
                <button className="bg-transparent border-0 text-white" onClick={prevFunction}>
                    <SVGChevronLeft className="sprite sprite-chevron-left sprite-md" title="chevron-left" />
                </button>
            ) : (
                <DisplayPrevDefaultContent />
            )}

            <div className="font-weight-bold text-center font-size-18 font-size-md-20">{title}</div>
            {displayClose ? (
                <button className="bg-transparent border-0 text-white" onClick={closeFunction}>
                    <SVGMenuClose className="sprite sprite-menu-close sprite-md" title="menu-close" />
                </button>
            ) : (
                <div></div>
            )}
        </div>
    );
};
