import { SVGChevronDown, SVGChevronUp, SVGExternalLink, UrlUtils } from '@goed-platform/shared/utils';
import { MappedNavigation, NavigationLinkItem } from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { PropsWithChildren, useContext } from 'react';
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { v4 as uuidV4 } from 'uuid';

type FooterNavigationProps = {
    className?: string;
    navigation?: MappedNavigation;
};

type ColumnContentProps = {
    title: string;
    linkItems: NavigationLinkItem[];
    id?: string;
};

type TitleAccordionProps = PropsWithChildren<{
    callback?: (eventKey?: string) => void;
    eventKey: string;
}>;

export const FooterNavigation = ({ navigation, className = '' }: FooterNavigationProps): JSX.Element => {
    const { t } = useTranslation();
    let hostname = '';

    if (typeof window !== 'undefined') {
        hostname = window.location.hostname;
    }

    const linkItemsList = (items: NavigationLinkItem[]): JSX.Element => (
        <>
            {items.map(({ url = '#', title }, key) => (
                <div className="mb-2" key={key}>
                    <Link href={url}>
                        <a
                            target={UrlUtils.isExternalUrl(url, hostname) ? '_blank' : '_self'}
                            className="d-inline-flex align-items-center"
                        >
                            {title}
                            {UrlUtils.isExternalUrl(url, hostname) && (
                                <SVGExternalLink className="sprite sprite-sm ml-1 text-neutral" />
                            )}
                        </a>
                    </Link>
                </div>
            ))}
        </>
    );

    const TitleAccordion = ({ children, eventKey, callback }: TitleAccordionProps): JSX.Element => {
        const currentEventKey = useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <div
                className="text-purple text-sm-neutral border-bottom border-purple-light border-sm-0 py-4 py-sm-0 mb-2 font-weight-bold d-inline-flex align-items-center justify-content-between w-100"
                role="button"
                onClick={decoratedOnClick}
            >
                {children}
                {isCurrentEventKey ? (
                    <SVGChevronUp className="sprite sprite-md d-sm-none" />
                ) : (
                    <SVGChevronDown className="sprite sprite-md d-sm-none" />
                )}
            </div>
        );
    };

    const ColumnContent = ({ title, linkItems, id = uuidV4() }: ColumnContentProps): JSX.Element => (
        <>
            <div className="d-sm-none">
                <TitleAccordion eventKey={id} callback={undefined}>
                    {title}
                </TitleAccordion>
                <Accordion.Collapse eventKey={id}>{linkItemsList(linkItems)}</Accordion.Collapse>
            </div>
            <div className="d-none d-sm-block">
                <div className="text-neutral mb-2 font-weight-bold">{title}</div>
                {linkItemsList(linkItems)}
            </div>
        </>
    );

    return (
        <div className={`bg-off-white py-4 py-lg-16 ${className}`}>
            <div className="container-lg">
                <Accordion className="row">
                    <div className="col-12 col-sm-6 col-lg-3 mb-sm-4 mb-lg-0">
                        <ColumnContent
                            title={t('general.footer.navigation.suggestion.title')}
                            linkItems={navigation?.['doormat-secondary-1'] ?? []}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 mb-sm-4 mb-lg-0">
                        <ColumnContent
                            title={t('general.footer.navigation.help.title')}
                            linkItems={navigation?.['doormat-secondary-2'] ?? []}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 mb-sm-4 mb-lg-0">
                        <ColumnContent
                            title={t('general.footer.navigation.branch.title')}
                            linkItems={navigation?.['doormat-secondary-3'] ?? []}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 mb-sm-4 mb-lg-0">
                        <ColumnContent
                            title={t('general.footer.navigation.about.title')}
                            linkItems={navigation?.['doormat-secondary-4'] ?? []}
                        />
                    </div>
                </Accordion>
            </div>
        </div>
    );
};
