import { useReactiveVar } from '@apollo/client';
import { CartDataAccess } from '@goed-platform/cart/data-access';
import { internalFetchCoordinates } from '@goed-platform/google/data-access';
import { StoreType } from '@goed-platform/graphql/types';
import { cartVarRes, isLoggedInVar, userInfoVar, WebreservationPharmacy } from '@goed-platform/shared/data-access';
import { Button, ButtonSizeEnum, ModalHeader } from '@goed-platform/shared/ui';
import { LogError, SVGChevronRight, SVGSearch, TranslationUtils } from '@goed-platform/shared/utils';
import { SymfonyDataAccess } from '@goed-platform/symfony/data-access';
import {
    isCoordinate,
    isPharmacyArray,
    isPharmacyWithOpeningHours,
    PharmacyWithOpeningHours,
    PharmacyWithOpeningHoursDaySchemeOpeningHour,
    SymfonyPharmacy,
} from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

type ChangePharmacyModalProps = {
    modalIsVisible: boolean;
    onHideModal: () => void;
};

let searchTimeout: NodeJS.Timeout;

export const ChangePharmacyModal = ({ modalIsVisible, onHideModal }: ChangePharmacyModalProps): JSX.Element => {
    const cart = useReactiveVar(cartVarRes);
    const user = useReactiveVar(userInfoVar);
    const isLoggedIn = useReactiveVar(isLoggedInVar);

    const { t } = useTranslation();

    const defaultSearchValue = (): string => {
        if (isLoggedIn) {
            if (user?.stores && user.stores.length > 0) {
                return '';
            }

            return (
                user?.deliveryAddress?.postCode ??
                user?.billingAddress?.postCode ??
                cart?.deliveryAddress?.postCode ??
                cart?.billingAddress?.postCode ??
                ''
            );
        }

        return cart?.deliveryAddress?.postCode ?? cart?.billingAddress?.postCode ?? '';
    };
    const [searchValue, setSearchValue] = useState(defaultSearchValue());

    const [searchResultStores, setSearchResultStores] = useState<SymfonyPharmacy[] | undefined>(undefined);
    const [detailStore, setDetailStore] = useState<PharmacyWithOpeningHours | undefined>(undefined);
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
    const [isLoadingResults, setIsLoadingResults] = useState(false);

    const searchStores = async (value = searchValue): Promise<void> => {
        clearTimeout(searchTimeout);
        setIsLoadingResults(true);

        if (value.length < 4) {
            setIsLoadingResults(false);
            setSearchResultStores([]);
            return;
        }

        searchTimeout = setTimeout(async () => {
            try {
                // Fetch coordinates via Google Places API.
                const coordinates = await internalFetchCoordinates(value);

                if (!coordinates) {
                    LogError('Unable to get coordinates of location.', coordinates);
                    setSearchResultStores([]);
                }

                if (isCoordinate(coordinates)) {
                    // Fetch rental stores from Harmony API.
                    const pharmacies = await SymfonyDataAccess.getInstance().searchPharmacies(value, coordinates);

                    if (isPharmacyArray(pharmacies)) {
                        // Filter stores that are in radius of 25km.
                        setSearchResultStores(pharmacies.filter((pharmacy) => pharmacy.distance < 25));
                    }
                }
            } catch (error) {
                LogError('Unable to get search results for pharmacies by coordinates', error);
                setSearchResultStores([]);
            } finally {
                setIsLoadingResults(false);
            }
        }, 1000);
    };

    const submitLocation = async (): Promise<void> => {
        setIsLoadingOnSubmit(true);

        if (!detailStore?.id) {
            return;
        }

        await CartDataAccess.setStoreForCart(StoreType.Res, detailStore.id)
            .then(async () => {
                await SymfonyDataAccess.getInstance()
                    .getPharmacyById(detailStore.id as unknown as string)
                    .then((data) => {
                        WebreservationPharmacy.getInstance().setActivePharmacy(data as SymfonyPharmacy);
                    });

                setDetailStore(undefined);
                onHideModal();
            })
            .finally(() => setIsLoadingOnSubmit(false));
    };

    const selectLocation = async (storeId: number): Promise<void> => {
        const pharmacyData = await SymfonyDataAccess.getInstance().getPharmacyWithHours(storeId);

        if (isPharmacyWithOpeningHours(pharmacyData)) {
            setDetailStore(pharmacyData as PharmacyWithOpeningHours);
        }
    };

    const displayMinutes = (n: number): string => (n < 10 ? `0${n}` : `${n}`);

    const displayOpeningHours = (
        openingHours: PharmacyWithOpeningHoursDaySchemeOpeningHour[]
    ): JSX.Element[] | string => {
        if (openingHours.length > 0) {
            return openingHours.map((period, key) => (
                <span key={key}>
                    {`${key !== 0 ? ' / ' : ''}${period.start.hours}:${displayMinutes(period.start.minutes)}-${
                        period.end.hours
                    }:${displayMinutes(period.end.minutes)}`}
                </span>
            ));
        }

        return t('checkout.deliveryDetails.closed');
    };

    useEffect(() => {
        searchStores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const weekDays: string[] = TranslationUtils.getTranslationKeyArray('general.weekdays').map((key) => t(key));

    const displayStores = (list: SymfonyPharmacy[]): JSX.Element[] =>
        list?.map((store, key) => (
            <div className="mx--4 mx-sm-0" key={key}>
                {/* Divider */}
                {key !== 0 && <div className="list-divider-solid border-neutral-a10"></div>}
                {/* 1 item */}
                <div
                    className="w-100 bg-neutral-white px-6 py-4 d-flex justify-content-between"
                    role="button"
                    onClick={() => selectLocation(store.id)}
                >
                    <div>
                        <div className="h4 mb-1 text-purple">{store.name}</div>
                        <p className="font-size-16 font-size-md-18 text-neutral-a70 mb-0">
                            {store.street} {store.number} {store.box && `${t('user.box').toLowerCase()} ${store.box}`}
                        </p>
                    </div>
                    <SVGChevronRight className="sprite sprite-search sprite-md text-purple" />
                </div>
            </div>
        ));

    return (
        <Modal
            show={modalIsVisible}
            onHide={onHideModal}
            centered
            className="modal-fullscreen-mobile search-store-modal"
        >
            <ModalHeader
                prevFunction={() => (detailStore ? setDetailStore(undefined) : onHideModal())}
                closeFunction={() => onHideModal()}
                title={t('checkout.deliveryDetails.selectCollectionPoint')}
            />
            {!detailStore && (
                <div className="bg-off-white px-4 py-6 px-sm-6">
                    <form
                        className="search-bar rounded-pill bg-neutral-white border border-neutral-a10 mb-6 py-1 pr-2 d-flex align-items-center justify-content-between"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <input
                            type="text"
                            className="ml-4 border-0 font-size-16 w-100 shadow-none bg-transparent"
                            placeholder={t('cart.store.search.placeholder')}
                            value={searchValue}
                            aria-label="Search"
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                                searchStores(e.target.value);
                            }}
                        />
                        <button
                            type="submit"
                            className={`search-bar-button bg-transparent ${
                                searchValue.length > 0 ? 'text-purple' : 'text-neutral-a70'
                            }`}
                            aria-label="search"
                        >
                            <SVGSearch className="sprite sprite-search sprite-md" title="search" />
                        </button>
                    </form>

                    {!isLoadingResults && searchValue.length > 0 && !searchResultStores?.length ? (
                        <div className="border-top border-light-grey mx--4 p-4">{t('search.text.noResultsFound')}</div>
                    ) : (
                        <></>
                    )}

                    <div
                        className={`stores-list d-flex flex-column w-100 ${
                            isLoadingResults ? 'justify-content-center' : ''
                        }`}
                    >
                        {!isLoadingResults && searchValue.length > 0 && !!searchResultStores?.length ? (
                            displayStores(searchResultStores ?? [])
                        ) : (
                            <></>
                        )}

                        {isLoadingResults && (
                            <div className="d-flex justify-content-center">
                                <div className="spinner spinner-lg my-4"></div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {!!detailStore && (
                <div className="bg-off-white px-4 pt-6 pb-0 p-sm-6 d-flex flex-column justify-content-between d-sm-block">
                    <div>
                        <div className="mb-6">
                            <p className="mb-2 text-primary font-weight-bold font-size-18 font-size-md-24">
                                {detailStore.name}
                            </p>
                            <p className="">
                                {detailStore.street} {detailStore.number}{' '}
                                {detailStore.box && `${t('user.box').toLowerCase()} ${detailStore.box}`}
                                <br />
                                {detailStore.postal_code} {detailStore.city} ({t('user.countryFixedValue')})
                            </p>
                        </div>
                        <div className="mb-2 font-size-md-20 font-weight-bold">
                            {t('checkout.deliveryDetails.openingHours')}
                        </div>
                        <div className="px-3 py-1 font-size-14 font-size-md-16 bg-neutral-white rounded-md rounded-bottom ">
                            <table className="table table-lg mb-0 w-100 opening-hours">
                                <tbody>
                                    {weekDays.map((value: string, index: number) => (
                                        <tr key={index}>
                                            <td className={index === 0 ? 'border-top-0' : ''}>{value}</td>
                                            <td className={index === 0 ? 'border-top-0' : ''}>
                                                {displayOpeningHours(
                                                    detailStore.services[0].opening_hours.byWeek.thisWeek[index].scheme
                                                        .opening_hours
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="bg-purple-a10 bg-sm-transparent px-4 py-6 p-sm-0 mt-8 mt-sm-10 mx-n4 mx-sm-0">
                        <Button
                            onClick={submitLocation}
                            label={t('checkout.deliveryDetails.selectEstablishment')}
                            isLoading={isLoadingOnSubmit}
                            size={ButtonSizeEnum.medium}
                        />
                    </div>
                </div>
            )}
        </Modal>
    );
};
