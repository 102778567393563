import { Config } from '@goed-platform/shared/constants';

export const createRange = (from: number, to: number, step = 1): number[] => {
    let i = from;
    const range: number[] = [];
    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

const LEFT_PAGE = 'left';
const RIGHT_PAGE = 'right';

// The goal of this method is to create a range of pages
// That represent the pagination.
// Goal: if the currentpage is 300, totalPages = 400
// Result should be: [1, 'left', 298, 299, 300, 301, 302, 'right', 400]
// For more examples, check out the tests of this file.
export const calculatePageRange = (currentPage: number, totalPages: number): (number | string)[] => {
    if (totalPages > Config.pagination.highestPage) {
        totalPages = Config.pagination.highestPage;
    }

    // If the totalpages is lower than the neighbours * 2 + 1
    // Eg. 5 pages [1, 2, 3, 4, 5] < [1, 2, 3, 4, 5, 6, 7],
    // There's no need to show bounds,
    // Simply return the range then.
    if (totalPages <= Config.pagination.neighbours * 2 + 1) {
        return createRange(1, totalPages);
    }

    const pageNeighbours = Config.pagination.neighbours;
    const totalNumbers = Config.pagination.neighbours * 2 + 3;
    let pages = [];

    // Calculate the bounds of the pagination (total range)
    const leftBound: number = currentPage - pageNeighbours;
    const rightBound: number = currentPage + pageNeighbours;
    // Find out the last but one page.
    const beforeLastPage: number = totalPages - 1;

    // Let the start page start at 2, or at the left bound
    const startPage: number = leftBound > 2 ? leftBound : 2;
    // Let the endpage be the right bound, or the last but one page.
    const endPage: number = rightBound < beforeLastPage ? rightBound : beforeLastPage;

    // if currentpage = 300, totalpages = 400
    // This should result in [298, 299, 300, 301, 302]
    pages = createRange(startPage, endPage);

    // This logic is to add "spills" in the form of ... in between te pages.
    const singleSpillOffset: number = totalNumbers - totalPages - 1;

    // Find out if the 'left spill' should be shown.
    // If the startpage of the range, is higher than 2, we should.
    // Eg; result will be [1, ..., startpage] or [1, 2, 3]
    const leftSpill: boolean = startPage > 2;
    // Find out if the right spill should be shown
    const rightSpill: boolean = endPage < beforeLastPage;

    const leftSpillPage: string = LEFT_PAGE;
    const rightSpillPage: string = RIGHT_PAGE;

    // Then do the magic to find the extra pages and add the spills if necessary.
    if (leftSpill && !rightSpill) {
        const extraPages = createRange(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
    } else if (!leftSpill && rightSpill) {
        const extraPages = createRange(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
    } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
    }

    // Return an array with the pages, prefix by the first page, suffixed by the last page (which equals totalPages)
    return [1, ...pages, totalPages];
};

export const calcTotalPages = (
    totalRecords: number,
    itemsPerPage: number = Config.pagination.defaultItemsPerPage
): number => Math.ceil(totalRecords / itemsPerPage);
