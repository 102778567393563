import { ProductImage } from '@goed-platform/graphql/types';
import { ImageLayoutEnum, ImageObjectfitEnum } from '@goed-platform/product/enums';
import { blurDataUrl, fallbackImageUrl } from '@goed-platform/shared/constants';
import Image from 'next/image';

type OptimizedImageProps = {
    image: ProductImage;
    alt: string;
    layout?: ImageLayoutEnum;
    objectFit?: ImageObjectfitEnum;
    priority?: boolean;
    className?: string;
    imageClassName?: string;
};

export const OptimizedImage: React.FC<OptimizedImageProps> = ({
    image,
    layout,
    className = '',
    objectFit,
    alt,
    priority = false,
    imageClassName = '',
    ...restProps
}: OptimizedImageProps): JSX.Element => {
    const source = image?.url ?? fallbackImageUrl;

    const layoutClass: string = layout === ImageLayoutEnum.fill ? 'position-relative w-100 h-100' : '';

    if (!source) {
        return <></>;
    }

    return (
        <div className={`${className} ${layoutClass}`} {...restProps}>
            <Image
                src={source}
                layout={layout}
                objectFit={objectFit}
                alt={alt}
                width={layout !== ImageLayoutEnum.fill ? image?.dimensions?.width : 0}
                height={layout !== ImageLayoutEnum.fill ? image?.dimensions?.height : 0}
                priority={priority}
                blurDataURL={blurDataUrl}
                placeholder="blur"
                className={imageClassName}
                unoptimized={true} // @TODO: As soon as CDN to store optimized images, is available, "unoptimized" can be removed again.
            />
        </div>
    );
};

export default OptimizedImage;
