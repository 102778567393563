import { useReactiveVar } from '@apollo/client';
import { CartDataAccess } from '@goed-platform/cart/data-access';
import { StoreType, UserAddressInput } from '@goed-platform/graphql/types';
import {
    CTErrors,
    defaultRegex,
    ErrorItemType,
    ErrorMessageEnum,
    ValidationConstant,
} from '@goed-platform/shared/constants';
import { cartVarUld, userInfoVar } from '@goed-platform/shared/data-access';
import { AlertTypeEnum } from '@goed-platform/shared/types';
import { Alert, Button, ButtonSizeEnum, ModalHeader, TextField } from '@goed-platform/shared/ui';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';

type EditDeliveryAddressModalProps = {
    modalIsVisible: boolean;
    onHideModal: () => void;
};

export const EditDeliveryAddressModal: React.FC<EditDeliveryAddressModalProps> = ({
    modalIsVisible,
    onHideModal,
}: EditDeliveryAddressModalProps): JSX.Element => {
    const { t } = useTranslation();
    const user = useReactiveVar(userInfoVar);
    const cart = useReactiveVar(cartVarUld);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const schema = Yup.object().shape({
        firstName: Yup.string().required(t(ValidationConstant.REQUIRED.errorMessage)),
        lastName: Yup.string().required(t(ValidationConstant.REQUIRED.errorMessage)),
        streetName: Yup.string().required(t(ValidationConstant.REQUIRED.errorMessage)),
        houseNumber: Yup.string()
            .matches(
                ValidationConstant.HOUSE_NUMBER.regex ?? defaultRegex,
                t(ValidationConstant.HOUSE_NUMBER.errorMessage)
            )
            .required(t(ValidationConstant.REQUIRED.errorMessage)),
        box: Yup.string(),
        extraAddressInfo: Yup.string(),
        postCode: Yup.string()
            .matches(ValidationConstant.POST_CODE.regex ?? defaultRegex, t(ValidationConstant.POST_CODE.errorMessage))
            .required(t(ValidationConstant.REQUIRED.errorMessage)),
        city: Yup.string().required(t(ValidationConstant.REQUIRED.errorMessage)),
    });

    const handleCTError = (error: ErrorItemType) => {
        setIsLoadingOnSubmit(false);
        setErrorMessage(CTErrors.generateErrorTranslation(error));
        throw new Error();
    };

    const handleOnSubmit = async (values: UserAddressInput) => {
        setIsLoadingOnSubmit(true);
        setIsSubmitted(true);
        setErrorMessage('');

        const userAddress: UserAddressInput = {
            id: null, // For new addresses, this should be null.
            firstName: values.firstName,
            lastName: values.lastName,
            streetName: values.streetName,
            houseNumber: values.houseNumber,
            box: values.box ?? '',
            postCode: values.postCode,
            city: values.city,
            extraAddressInfo: values.extraAddressInfo,
            email: cart?.deliveryAddress?.email ?? user?.deliveryAddress?.email ?? user?.email ?? '',
            phone: cart?.deliveryAddress?.phone ?? user?.deliveryAddress?.phone ?? user?.phone ?? '',
        };

        await CartDataAccess.setDeliveryAddress(StoreType.Uld, userAddress)
            .then(() => onHideModal())
            .catch(handleCTError)
            .finally(() => setIsLoadingOnSubmit(false));
    };

    const initialValues: UserAddressInput = {
        firstName: cart?.deliveryAddress?.firstName ?? user?.deliveryAddress?.firstName ?? user?.firstName ?? '',
        lastName: cart?.deliveryAddress?.lastName ?? user?.deliveryAddress?.lastName ?? user?.lastName ?? '',
        city: cart?.deliveryAddress?.city ?? user?.deliveryAddress?.city ?? '',
        postCode: cart?.deliveryAddress?.postCode ?? user?.deliveryAddress?.postCode ?? '',
        streetName: cart?.deliveryAddress?.streetName ?? user?.deliveryAddress?.streetName ?? '',
        houseNumber: cart?.deliveryAddress?.houseNumber ?? user?.deliveryAddress?.houseNumber ?? '',
        box: cart?.deliveryAddress?.box ?? user?.deliveryAddress?.box ?? '',
        extraAddressInfo: cart?.deliveryAddress?.extraAddressInfo ?? user?.deliveryAddress?.extraAddressInfo ?? '',
        email: cart?.deliveryAddress?.email ?? user?.deliveryAddress?.email ?? user?.email ?? '',
        phone: cart?.deliveryAddress?.phone ?? user?.deliveryAddress?.phone ?? user?.phone ?? '',
    };

    return (
        <Modal show={modalIsVisible} onHide={onHideModal} centered className="modal-fullscreen-mobile">
            <ModalHeader closeFunction={() => onHideModal()} title={t('checkout.delivery.changeAddress')} />
            <Modal.Body>
                <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleOnSubmit}>
                    {({ errors, touched, handleChange, values }) => (
                        <Form>
                            <div className="row pt-6">
                                <div className="col-12 col-md-6">
                                    <TextField
                                        id="firstName"
                                        label="user.firstName"
                                        onChange={handleChange}
                                        error={errors.firstName}
                                        touched={touched.firstName}
                                        value={values.firstName ?? ''}
                                        required
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <TextField
                                        id="lastName"
                                        label="user.lastName"
                                        onChange={handleChange}
                                        error={errors.lastName}
                                        touched={touched.lastName}
                                        value={values.lastName ?? ''}
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <TextField
                                        id="streetName"
                                        label="user.streetName"
                                        onChange={handleChange}
                                        error={errors.streetName}
                                        touched={touched.streetName}
                                        value={values.streetName ?? ''}
                                        required
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        id="houseNumber"
                                        label="user.houseNumber"
                                        onChange={handleChange}
                                        error={errors.houseNumber}
                                        touched={touched.houseNumber}
                                        value={values.houseNumber ?? ''}
                                        required
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        id="box"
                                        label="user.box"
                                        onChange={handleChange}
                                        error={errors.box}
                                        touched={touched.box}
                                        value={values.box ?? ''}
                                    />
                                </div>
                                <div className="col-12">
                                    <TextField
                                        id="extraAddressInfo"
                                        label="user.extraAddressInfo"
                                        onChange={handleChange}
                                        error={errors.extraAddressInfo}
                                        touched={touched.extraAddressInfo}
                                        value={values.extraAddressInfo ?? ''}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        id="postCode"
                                        label="user.postCode"
                                        onChange={handleChange}
                                        error={errors.postCode}
                                        touched={touched.postCode}
                                        value={values.postCode ?? ''}
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <TextField
                                        id="city"
                                        label="user.city"
                                        onChange={handleChange}
                                        error={errors.city}
                                        touched={touched.city}
                                        value={values.city ?? ''}
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>{t('user.country')}</label>
                                        <div className="py-2 px-3 border rounded-sm bg-purple-a5">
                                            {t('user.countryFixedValue')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    {isSubmitted &&
                                    !isLoadingOnSubmit &&
                                    (errorMessage || Object.keys(errors).length > 0) ? (
                                        <Alert
                                            type={AlertTypeEnum.danger}
                                            errorMessage={
                                                Object.keys(errors).length > 0
                                                    ? t(ErrorMessageEnum.formError)
                                                    : errorMessage
                                            }
                                            className="mt-2"
                                        />
                                    ) : (
                                        <></>
                                    )}

                                    {/* Submit */}
                                    <Button
                                        label={t('checkout.delivery.addAddressButton')}
                                        isLoading={isLoadingOnSubmit}
                                        size={ButtonSizeEnum.medium}
                                        onClick={() => setIsSubmitted(true)}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};
