import { useReactiveVar } from '@apollo/client';
import { CheckoutReservationAddress } from '@goed-platform/checkout/ui';
import { formatPriceWithValuta } from '@goed-platform/pricing/util';
import { webResActivePharmacy } from '@goed-platform/shared/data-access';
import { InfoPriceIndicationRes } from '@goed-platform/shared/ui';
import { SVGEdit, SVGPhone } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useState } from 'react';
import { PricingSummaryItem, PricingSummaryTotal } from './pricing-summary-uld';
import { ChangePharmacyModal } from '@goed-platform/checkout/feature';

export type PricingSummaryResProps = PropsWithChildren<{
    items: PricingSummaryItem[];
    total?: PricingSummaryTotal;
    showQuantity?: boolean;
    showTotal?: boolean;
    showPharmacy?: boolean;
    className?: string;
}>;

export const PricingSummaryRes: React.FC<PricingSummaryResProps> = ({
    items = [],
    total = undefined,
    showQuantity = true,
    showTotal = false,
    showPharmacy = true,
    className = '',
    children,
    ...restProps
}: PricingSummaryResProps): JSX.Element => {
    const { t } = useTranslation();
    const articleTranslation: string = total ? t(`cart.overview.article${total.articleQuantity === 1 ? '' : 's'}`) : '';
    const activePharmacy = useReactiveVar(webResActivePharmacy);
    const [showSelectStoreModal, setShowSelectStoreModal] = useState(false);

    return (
        <div className={`w-100 d-flex flex-column ${className}`} {...restProps}>
            {children}
            {items.map((item, key) => (
                <React.Fragment key={key}>
                    {item.price !== null && item.price !== undefined && (
                        <div
                            className={`w-100 d-flex justify-content-between align-items-center font-size-14 font-size-md-16 ${
                                key > 0 ? 'mt-2' : ''
                            }`}
                        >
                            {typeof item.label === 'string' ? <span>{item.label}</span> : item.label}
                            <span className="text-number">
                                {typeof item.price === 'number' ? formatPriceWithValuta(item.price) : item.price}
                            </span>
                        </div>
                    )}
                </React.Fragment>
            ))}
            <div className="list-divider-solid border-neutral-a10 mt-3 mb-2 my-md-4"></div>
            {/* Total */}
            {total && showTotal && total.price !== null && (
                <div className="w-100 d-flex justify-content-between align-items-center font-size-16 font-size-md-18">
                    {typeof total.label === 'string' ? (
                        <div>
                            <span className="font-weight-bold">{total.label}</span>
                            {!!total.articleQuantity && showQuantity && (
                                <span className="font-size-12 text-neutral-a70 text-number">
                                    {` (${total.articleQuantity} ${articleTranslation})`}
                                </span>
                            )}
                        </div>
                    ) : (
                        total.label
                    )}
                    <span className="font-weight-bold text-number">
                        {typeof total.price === 'number' ? formatPriceWithValuta(total.price) : total.price}
                    </span>
                </div>
            )}
            <InfoPriceIndicationRes className="my-4" />
            {showPharmacy && (
                <>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="text-neutral font-size-16 font-size-md-18 font-weight-bold">
                            {t('pharmacy')}
                        </div>
                        <div
                            className="text-purple font-size-14 font-size-md-16 font-weight-bold border-bottom-1 border-purple-a25 line-height-1"
                            role="button"
                            onClick={() => setShowSelectStoreModal(true)}
                        >
                            <SVGEdit className="sprite sprite-sm text-purple" /> {t('pharmacy.edit')}
                        </div>
                    </div>
                    <CheckoutReservationAddress
                        address={{
                            box: activePharmacy?.box,
                            city: activePharmacy?.city,
                            houseNumber: activePharmacy?.number,
                            postCode: activePharmacy?.postal_code,
                            streetName: activePharmacy?.street,
                            company: activePharmacy?.name,
                            __typename: 'UserAddress',
                        }}
                        className="font-size-16 font-size-md-18 mb-2 mb-md-4"
                        pharmacySlug={activePharmacy?.meta.slug}
                    />
                    {activePharmacy?.services[0]?.phone && (
                        <a
                            className="d-inline-flex align-items-center w-fit"
                            href={`tel:${activePharmacy?.services[0]?.phone ?? ''}`}
                            suppressHydrationWarning
                        >
                            <SVGPhone className="sprite sprite-md text-purple-a50 mr-1" />
                            {activePharmacy?.services[0]?.phone ?? ''}
                        </a>
                    )}
                    {/* Modals */}
                    <ChangePharmacyModal
                        modalIsVisible={showSelectStoreModal}
                        onHideModal={() => setShowSelectStoreModal(false)}
                    />
                </>
            )}
        </div>
    );
};
