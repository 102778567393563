/* eslint-disable @typescript-eslint/no-explicit-any */
import Bugsnag from '@bugsnag/js';
import { BugsnagDataAccess } from '@goed-platform/bugsnag/data-access';
import { EnvironmentEnum } from '@goed-platform/shared/types';

enum LogType {
    'Error' = '#CD3131',
    'Warning' = '#E88500',
    'Success' = '#00BC00',
    'Info' = '#0598BC',
    'Action' = '#8431C5',
}

export const LogError = (description: string, error?: unknown, context?: unknown): void =>
    Log(LogType.Error, description, error, context, true);

export const LogWarning = (description: string, data?: unknown, context?: unknown): void =>
    Log(LogType.Warning, description, data, context);

export const LogSuccess = (description: string, data?: unknown, context?: unknown): void =>
    Log(LogType.Success, description, data, context);

export const LogInfo = (description: string, data?: unknown, context?: unknown): void =>
    Log(LogType.Info, description, data, context);

export const LogAction = (description: string, data?: unknown, context?: unknown): void =>
    Log(LogType.Action, description, data, context);

const Log = (
    type: LogType,
    description: string,
    data?: unknown,
    context?: unknown,
    pushToErrorMonitoring = false
): void => {
    // Log errors on non production environments only.
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== EnvironmentEnum.production) {
        console.groupCollapsed(`%c${description}`, `color:${type}`);

        if (data) {
            if (type === LogType.Error) {
                console.error(JSON.stringify(data, null, 2));
            } else {
                console.info(JSON.stringify(data, null, 2));
            }
        }

        if (context) {
            console.info('Context', context);
        }

        console.groupEnd();
    }

    // Error monitoring (on PROD environment only)
    if (pushToErrorMonitoring && BugsnagDataAccess.shouldFireOnThisEnvironment()) {
        //Make sure it's already connected
        BugsnagDataAccess.getInstance().connect();
        Bugsnag.notify(new Error(description), (event) => {
            if (data) {
                event.addMetadata('data', data);
            }

            if (context) {
                event.addMetadata('context', context);
            }
        });
    }
};
