const saveConsents = () => {
    const consents = {} as Record<string, any>;
    const consentCheckboxes = document.getElementsByClassName('ch2-switch-value');
    let i, currentCheckbox, currentConsentType, currentChecked;

    for (i = 0; i < consentCheckboxes.length; i++) {
        currentCheckbox = consentCheckboxes[i] as any;
        currentConsentType = currentCheckbox.name;
        currentChecked = currentCheckbox.checked ? 1 : 0;
        consents[currentConsentType] = { status: currentChecked };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    ppms.cm.api('setComplianceSettings', { consents: consents }, console.error);
};

const initPiwik = (interval: NodeJS.Timer) => {
    clearInterval(interval);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    ppms.cm.api(
        'setInitialComplianceSettings',
        {
            consents: ['analytics', 'necessary', 'preferences', 'marketing', 'uncategorized'],
        },
        console.log,
        console.error
    );
};

const acceptPiwik = () => {
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    ppms.cm.api(
        'setComplianceSettings',
        {
            consents: {
                analytics: {
                    status: 1,
                },
                necessary: {
                    status: 1,
                },
                preferences: {
                    status: 1,
                },
                marketing: {
                    status: 1,
                },
                uncategorized: {
                    status: 1,
                },
            },
        },
        console.log,
        console.error
    );
};

const denyPiwik = () => {
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    ppms.cm.api(
        'setComplianceSettings',
        {
            consents: {
                analytics: {
                    status: 0,
                },
                necessary: {
                    status: 0,
                },
                preferences: {
                    status: 0,
                },
                marketing: {
                    status: 0,
                },
                uncategorized: {
                    status: 0,
                },
            },
        },
        console.log,
        console.error
    );
};

export const initPiwikPro = (interval: NodeJS.Timer) => {
    const addButton = document.querySelector('.ch2-allow-all-btn');
    const settingsButton = document.querySelector('.ch2-save-settings-btn');
    const denyButton = document.querySelector('.ch2-deny-all-btn');

    if (settingsButton) {
        settingsButton.addEventListener('click', function () {
            saveConsents();
        });
    }

    if (denyButton) {
        denyButton?.addEventListener('click', function () {
            denyPiwik();
        });
    }

    if (addButton) {
        initPiwik(interval);
        addButton?.addEventListener('click', function () {
            acceptPiwik();
        });
    }
};
