/* eslint-disable @typescript-eslint/no-explicit-any */
import { PaymentMethodTypeEnum } from '@goed-platform/shared/constants';
import { PaymentMethodsList } from './payment-methods-list';

export type PaymentMethodType = {
    name: string;
    svg: any;
};

export const PaymentMethodsBanner = (): JSX.Element => {
    return (
        <section className="mt-0 bg-white border-top">
            <div className="container-lg py-7">
                <div className="row">
                    <div className="col-12 d-flex flex-wrap justify-content-center">
                        <PaymentMethodsList type={PaymentMethodTypeEnum.all} itemClassName="mx-1 mx-md-2 mx-lg-4" />
                    </div>
                </div>
            </div>
        </section>
    );
};
