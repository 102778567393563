import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'next-i18next';
import { formatPriceWithValuta } from '@goed-platform/pricing/util';

export type PricingSummaryItem = {
    label: string | JSX.Element;
    price?: number | string;
};

export type PricingSummaryTotal = PricingSummaryItem & {
    articleQuantity?: number;
};

export type PricingSummaryUldProps = PropsWithChildren<{
    items: PricingSummaryItem[];
    total: PricingSummaryTotal;
    showQuantity?: boolean;
    className?: string;
}>;

export const PricingSummaryUld: React.FC<PricingSummaryUldProps> = ({
    items = [],
    total,
    showQuantity = true,
    className = '',
    children,
    ...restProps
}: PricingSummaryUldProps): JSX.Element => {
    const { t } = useTranslation();
    const articleTranslation: string = t(`cart.overview.article${total.articleQuantity === 1 ? '' : 's'}`);

    return (
        <div className={`w-100 d-flex flex-column ${className}`} {...restProps}>
            {children}
            {items.map((item, key) => (
                <React.Fragment key={key}>
                    {item.price !== null && item.price !== undefined && (
                        <div
                            className={`w-100 d-flex justify-content-between align-items-center font-size-14 font-size-md-16 ${
                                key > 0 ? 'mt-2' : ''
                            }`}
                        >
                            {typeof item.label === 'string' ? <span>{item.label}</span> : item.label}
                            <span className="text-number">
                                {typeof item.price === 'number' ? formatPriceWithValuta(item.price) : item.price}
                            </span>
                        </div>
                    )}
                </React.Fragment>
            ))}
            <div className="list-divider-solid border-neutral-a10 mt-3 mb-2 my-md-4"></div>
            {/* Total */}
            {total.price !== null && (
                <div className="w-100 d-flex justify-content-between align-items-center font-size-16 font-size-md-18">
                    {typeof total.label === 'string' ? (
                        <div>
                            <span className="font-weight-bold">{total.label}</span>
                            {!!total.articleQuantity && showQuantity && (
                                <span className="font-size-12 text-neutral-a70 text-number">
                                    {` (${total.articleQuantity} ${articleTranslation})`}
                                </span>
                            )}
                        </div>
                    ) : (
                        total.label
                    )}
                    <span className="font-weight-bold text-number">
                        {typeof total.price === 'number' ? formatPriceWithValuta(total.price) : total.price}
                    </span>
                </div>
            )}
        </div>
    );
};
