/* eslint-disable react/jsx-no-useless-fragment */
import { Category, Product, StoreType } from '@goed-platform/graphql/types';
import { FC, useState } from 'react';
import { SearchBarForm } from './search-bar-form';
import { SearchBarResultCategory } from './search-bar-result-category';
import { SearchBarResultProduct } from './search-bar-result-product';
import { SearchBarResultSuggestion } from './search-bar-result-suggestion';

type SearchBarDesktopProps = {
    storeType: StoreType;
};

export const SearchBarDesktop: FC<SearchBarDesktopProps> = ({ storeType }: SearchBarDesktopProps): JSX.Element => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [categoriesResults, setCategoriesResults] = useState<Category[]>([]);
    const [productsResults, setProductsResults] = useState<Product[]>([]);
    const [suggestionResults, setSuggestionResults] = useState<string[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    const displayModal: boolean =
        showModal && ((categoriesResults ?? []).length > 0 || (productsResults ?? []).length > 0);

    return (
        <div className="position-relative d-none d-md-block flex-grow-1">
            <SearchBarForm
                storeType={storeType}
                searchValue={searchValue}
                showModal={showModal}
                setShowModal={setShowModal}
                setSearchValue={setSearchValue}
                setSuggestionResults={setSuggestionResults}
                setCategoriesResults={setCategoriesResults}
                setProductsResults={setProductsResults}
            />
            {displayModal && (
                <div className="search-results-desktop position-absolute left-0 w-100 rounded-sm bg-neutral-white d-flex flex-column shadow">
                    {suggestionResults?.map((suggestion, key) => (
                        <SearchBarResultSuggestion
                            key={key}
                            suggestion={suggestion}
                            searchValue={searchValue}
                            storeType={storeType}
                        />
                    ))}
                    {categoriesResults?.map((category, key) => (
                        <SearchBarResultCategory key={key} category={category} storeType={storeType} />
                    ))}
                    {productsResults?.map((product, key) => (
                        <SearchBarResultProduct key={key} product={product} storeType={storeType} />
                    ))}
                </div>
            )}
        </div>
    );
};
