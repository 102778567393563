import { RoutesEnum } from '@goed-platform/shared/types';
import { BaseLayout, LanguageLink, SEOTags } from '@goed-platform/shared/ui';
import { MappedNavigation } from '@goed-platform/symfony/types';
import { useTranslation } from 'next-i18next';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    navigation?: MappedNavigation;
    productPage?: RoutesEnum;
}>;

export const EmptyCart = ({ navigation, productPage, children }: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <BaseLayout navigation={navigation}>
            <SEOTags pageTitle={t('cart.overview.title')} />
            <div className="container pb-md-22">
                {/* Title */}
                <h1 className="d-md-none text-primary pt-6">{t('cart.overview.title')}</h1>

                <div className="row py-6">
                    <div className="col-6 col-md-12 d-flex flex-column justify-content-center">
                        <LanguageLink href={productPage as string}>
                            <a className="w-fit font-size-14 font-size-md-18">{t('cart.overview.continueShopping')}</a>
                        </LanguageLink>
                        <div className="list-divider-solid d-none d-md-block mt-6 mb-10 opacity-50"></div>
                    </div>
                </div>

                {/* Notice: no articles */}
                <h1 className="d-none d-md-block text-primary mb-8">{t('cart.overview.title')}</h1>
                <p data-cy="empty-cart-text">{t('cart.overview.cartIsEmpty')}</p>
                {children}
            </div>
        </BaseLayout>
    );
};
