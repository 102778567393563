import { AxiosError } from 'axios';
import { RentalStoreServiceCurrentOrNextOpenState } from './symfony-rental-store';

export type RentalStoreWithOpeningHours = {
    id: number;
    name: string;
    street: string;
    number: string;
    box?: string;
    city: string;
    postal_code: string;
    country: string;
    url: string;
    services: RentalStoreWithOpeningHoursService[];
};

export type RentalStoreWithOpeningHoursService = {
    id: number;
    specializations: string[];
    has_rental_store_specialization: boolean;
    type: string;
    email: string;
    phone: string;
    opening_hours: RentalStoreWithOpeningHoursList;
};

export type RentalStoreWithOpeningHoursList = {
    byWeek: RentalStoreWithOpeningHoursListByWeek;
    currentOrNextOpenState: RentalStoreServiceCurrentOrNextOpenState;
};

export type RentalStoreWithOpeningHoursListByWeek = {
    thisWeek: RentalStoreWithOpeningHoursListDay[];
    nextWeek: RentalStoreWithOpeningHoursListDay[];
};

export type RentalStoreWithOpeningHoursListDay = {
    scheme: RentalStoreWithOpeningHoursDayScheme;
    isToday: boolean;
    isInPast: boolean;
};

export type RentalStoreWithOpeningHoursDayScheme = {
    opening_hours: RentalStoreWithOpeningHoursDaySchemeOpeningHour[];
    data?: RentalStoreWithOpeningHoursData;
};

export type RentalStoreWithOpeningHoursDaySchemeOpeningHour = {
    start: RentalStoreWithOpeningHoursDaySchemeOpeningHourFragment;
    end: RentalStoreWithOpeningHoursDaySchemeOpeningHourFragment;
    data?: RentalStoreWithOpeningHoursData;
};

export type RentalStoreWithOpeningHoursDaySchemeOpeningHourFragment = {
    hours: number;
    minutes: number;
    data?: RentalStoreWithOpeningHoursData;
};

export type RentalStoreWithOpeningHoursData = {
    onlyByAppointment: boolean;
    description?: string;
};

export const isRentalStoreWithOpeningHours = (
    rentalStore: RentalStoreWithOpeningHours | AxiosError | Error
): boolean => {
    return (rentalStore as RentalStoreWithOpeningHours).city !== undefined;
};
