import { SVGCheckmark } from '@goed-platform/shared/utils';
import { useTranslation } from 'next-i18next';

type CheckoutReservationStep = {
    index: number;
    label: string;
};

type CheckoutReservationProgressBarProps = {
    activeStep: number;
    className?: string;
};

export const CheckoutReservationProgressBar = ({
    activeStep,
    className = '',
}: CheckoutReservationProgressBarProps): JSX.Element => {
    const { t } = useTranslation();

    const checkoutSteps: CheckoutReservationStep[] = [
        {
            index: 1,
            label: t('checkout.confirm.title'),
        },
        {
            index: 2,
            label: t('checkout.thanks.title'),
        },
    ];

    return (
        <ul className={`progressbar-steps ${className}`}>
            {checkoutSteps.map((checkoutStep: CheckoutReservationStep, key: number) => (
                <li
                    key={key}
                    className={`
                            ${activeStep === checkoutStep.index ? 'active' : ''}
                            ${checkoutStep.index < activeStep ? 'done' : ''}
                        `}
                >
                    <div className="indicator">
                        {checkoutStep.index < activeStep && (
                            <SVGCheckmark className="sprite sprite-checkmark text-white sprite-xs" title="checkmark" />
                        )}
                        {checkoutStep.index >= activeStep && <div className="indicator-bg"></div>}
                    </div>

                    {checkoutStep.label}
                </li>
            ))}
        </ul>
    );
};
