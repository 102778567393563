import { AxiosError } from 'axios';
import { DateTime } from '@goed-platform/shared/types';

export type RentalStore = {
    id: number;
    name: string;
    street: string;
    number: string;
    box?: string;
    city: string;
    country: string;
    slug: string;
    distance: number;
    lat: number;
    lng: number;
    services?: RentalStoreService[];
    currentOrNextOpenState?: RentalStoreServiceCurrentOrNextOpenState;
};

export type RentalStoreService = {
    id: number;
    name: string;
    specializations: RentalStoreServiceSpecialization[];
};

export type RentalStoreServiceSpecialization = {
    id: number;
    name: string;
};

export type RentalStoreServiceCurrentOrNextOpenState = {
    isOpen: boolean;
    nextOpen?: RentalStoreServiceNextOpen;
};

export type RentalStoreServiceNextOpen = {
    datetime: DateTime;
    isToday: boolean;
    isWithinUpcomingWeek: boolean;
};

export const isRentalStoreArray = (rentalStores: RentalStore[] | AxiosError | Error): rentalStores is RentalStore[] => {
    return (rentalStores as RentalStore[])[0]?.city !== undefined;
};
