import { ApolloClient, ApolloLink, createHttpLink, gql, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { CTErrors, CustomHeaderKeys, ErrorItemType } from '@goed-platform/shared/constants';
import { isBrowser, LogError } from '@goed-platform/shared/utils';
import fetch from 'cross-fetch';
import { authLink } from './auth-link';
import { StorageEnum } from './reactive-vars';

// Create a client side schema to query the local cache.
const typeDefs = gql`
    extend type Query {
        isLoggedIn: Boolean!
        cartId: String!
    }
`;

// CommerceTools client.
const httpLink = createHttpLink({
    uri: process.env['NEXT_PUBLIC_GRAPHQL_API_ENDPOINT'],
    fetch,
});

const errorLink = onError(({ graphQLErrors, networkError, response, forward, operation }): void => {
    const errors: ErrorItemType[] = [];

    if (graphQLErrors) {
        errors.push(...graphQLErrors.map((err) => CTErrors.getErrorItem(err?.extensions?.code)));
    }

    if (networkError) {
        errors.push(CTErrors.getErrorItem(networkError.name));
    }

    if (graphQLErrors || networkError) {
        for (let i = 0; i < errors.length; i++) {
            const error = errors[i];
            LogError(
                `An error occured while executing the ${operation.operationName} query/mutation.`,
                error,
                operation
            );
        }
    }
});

const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        if (isBrowser()) {
            const context = operation.getContext();
            const correlationIdHeader = context.response.headers.get(CustomHeaderKeys.xCorrelationId);

            if (correlationIdHeader !== sessionStorage.getItem(StorageEnum.correlationId)) {
                sessionStorage.setItem(StorageEnum.correlationId, correlationIdHeader);
            }
        }

        return response;
    });
});

export const Commercetools: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: errorLink.concat(authLink.concat(afterwareLink.concat(httpLink))),
    cache: new InMemoryCache(),
    typeDefs,
    resolvers: {},
});
