import { SVGArrowRight } from '@goed-platform/shared/utils';
import React from 'react';

export enum ButtonTypeEnum {
    submit = 'submit',
    button = 'button',
}

export enum ButtonSizeEnum {
    small = 'btn-sm',
    medium = '',
    large = 'py-3',
}

export type ButtonProps = {
    label: string | JSX.Element;
    type?: ButtonTypeEnum;
    size?: ButtonSizeEnum;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    isLoading?: boolean;
    showArrow?: boolean;
    disabled?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
    label,
    type = ButtonTypeEnum.submit,
    size = ButtonSizeEnum.large,
    onClick,
    className = '',
    showArrow = true,
    isLoading,
    disabled,
    ...restProps
}: ButtonProps): JSX.Element => {
    return (
        <button
            type={type}
            data-cy={`${type}-button`}
            onClick={onClick}
            className={`d-flex justify-content-center align-items-center btn btn-tertiary btn-block font-size-14 font-size-md-18 ${className} ${size} ${
                isLoading ? 'bg-purple-a50 border-purple-a50' : ''
            }`}
            disabled={disabled}
            {...restProps}
        >
            {label}
            {showArrow && !isLoading && <SVGArrowRight className="ml-2" width="20px" height="16px" />}
            {isLoading && <div className="spinner spinner-sm spinner-neutral-white ml-2"></div>}
        </button>
    );
};
