import { PriceFormatLocale } from './price-format-locale';

export function formatPrice(price: number): string {
    return new Intl.NumberFormat(PriceFormatLocale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(price);
}

export function formatPriceWithValuta(price: number): string {
    return `€ ${formatPrice(price)}`;
}
