import { ogImage, ogImageDefaultHeight, ogImageDefaultWidth } from '@goed-platform/shared/constants';
import { OGImage, OGType } from '@goed-platform/shared/types';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

export type SEOTagsProps = PropsWithChildren<{
    canonicalRoute?: string;
    pageTitle: string;
    metaTitle?: string;
    metaDescription?: string;
    route?: string;
    image?: OGImage;
    type?: OGType;
}>;

const fallbackImage: OGImage = {
    url: ogImage,
    width: 1200,
    height: 630,
};

export const SEOTags: React.FC<SEOTagsProps> = ({
    pageTitle,
    metaTitle = pageTitle,
    metaDescription,
    canonicalRoute,
    route,
    image = fallbackImage,
    type = OGType.website,
    children,
}: SEOTagsProps): JSX.Element => {
    const { t } = useTranslation();
    const router = useRouter();

    const formattedTitle = `${pageTitle} - ${t('global.app_name')}`;

    const url = `${process.env.NEXT_PUBLIC_URL}/${router.locale}${route ?? ''}`;

    const canonical = `${process.env.NEXT_PUBLIC_URL}/${router.locale}${canonicalRoute ?? ''}`;

    return (
        <Head>
            <title>{formattedTitle}</title>
            <meta name="title" content={metaTitle} />
            {!!metaDescription && <meta name="description" content={metaDescription} />}

            {/* NL version and redirect to master variant */}
            {!!canonicalRoute && <link rel="canonical" href={canonical} />}

            {/* OG Tags Facebook */}
            {/* 1200 x 630px */}
            {!!type && <meta property="og:type" content={type} />}
            {!!route && <meta property="og:url" content={url} />}
            <meta property="og:title" content={metaTitle} />
            <meta property="og:site_name" content={t('sitename')} />
            {!!metaDescription && <meta property="og:description" content={metaDescription} />}
            <meta property="og:image" content={image.url ?? ogImage} />
            <meta property="og:image:width" content={(image.width ?? ogImageDefaultWidth).toString()} />
            <meta property="og:image:height" content={(image.height ?? ogImageDefaultHeight).toString()} />

            {/* OG Tags Twitter */}
            {/* min 600 x 600px => HQ 2:1 */}
            <meta property="twitter:card" content="summary_large_image" />
            {!!route && <meta property="twitter:url" content={url} />}
            <meta property="twitter:title" content={metaTitle} />
            {!!metaDescription && <meta property="twitter:description" content={metaDescription} />}
            <meta property="twitter:image" content={image.url ?? ogImage} />

            {children}
        </Head>
    );
};
